import React from 'react';
import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';

import sustain from '../../assets/Sustainability_banner.jpg';
import whoweare from '../../assets/WhoWeAre_Banner.jpg';
// import { ReactComponent as EyeIcon } from '../../assets/Eye-Icon-size=default.svg';
// import { ReactComponent as HiddenEyeIcon } from '../../assets/Hidden-Eye-Icon-size=default.svg';

export default function Account({ width }) {
  const history = useHistory();

  return (
    <Grid
      item
      container
      justifyContent="center"
      style={{
        marginTop: width < 1024 ? 20 : 66,
        maxHeight:
          width < 1024 ? window.innerHeight - 140 : window.innerHeight - 350,
        overflowY: 'auto',
        overflowX: 'hidden',
        background: width < 1024 && '#fff',
        padding: width < 1024 && '5%',
        width: width < 1024 && width * 0.9,
        paddingBottom: 50,
      }}
    >
      <Grid container justifyContent="center">
        <Typography
          align="center"
          style={{
            fontSize: width > 1024 ? 26 : 20,
            lineHeight: width > 1024 ? '36px' : '24px',
            padding: '16px 0px 40px',
          }}
        >
          Your registration has been submitted and is now{' '}
          <span style={{ fontWeight: 'bold' }}>pending</span> approval.
        </Typography>
      </Grid>
      <Typography
        align="center"
        style={{
          width: 565,
          fontSize: 14,
          lineHeight: width > 1024 ? '28px' : '24px',
        }}
      >
        Thank you for submitting your registration with FARM Rio.
        <br />
        <br />
        The Sales team will reach out if there are any other questions, and in 5
        business days you'll receive your feedback via email.
        <br />
        <br />
        In the meantime, if you haven't already, take you time to get to know us
        a little better and take a look at our sustainability commitments.
      </Typography>
      <Grid container justifyContent="center" style={{ paddingTop: 40 }}>
        <Grid onClick={() => history.push('/whoweare')}>
          <img
            src={whoweare}
            alt="whoweare"
            style={{
              width: 287,
              height: 152,
              margin: '0px 60px',
              cursor: 'pointer',
            }}
          />
        </Grid>
        <Grid onClick={() => history.push('/sustainability')}>
          <img
            src={sustain}
            alt="sustain"
            style={{
              width: 287,
              height: 152,
              margin: '0px 60px',
              cursor: 'pointer',
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
