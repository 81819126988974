import React from 'react';
import {
  FormControlLabel,
  Checkbox,
  Typography,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    width: 16,
    height: 16,
    backgroundColor: '#ffffff',
    border: '1px solid #000000',
    'input:hover ~ &': {
      backgroundColor: '#f2f2f2',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: '#eeeeee',
      border: '0.5px solid #bdbdbd',
    },
  },
  checkedIcon: {
    backgroundColor: '#ffffff',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
    },
  },
});

// import { Container } from './styles';

function CheckLabel({
  marginTop,
  onChange,
  checked,
  label,
  props,
  marginRight,
  value,
  disabled,
}) {
  const classes = useStyles();

  return (
    <FormControlLabel
      style={{ marginTop, marginRight }}
      control={
        <Checkbox
          className={classes.root}
          value={value}
          disableRipple
          color="default"
          checked={checked}
          disabled={disabled}
          onChange={onChange}
          checkedIcon={
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              className={clsx(classes.icon, classes.checkedIcon)}
            >
              <Grid
                style={{
                  width: 10,
                  height: 10,
                  background: disabled ? '#bdbdbd' : '#000000',
                  border: disabled
                    ? '0.5px solid #bdbdbd'
                    : '0.5px solid #000000',
                }}
              />
            </Grid>
          }
          icon={<span className={classes.icon} />}
          inputProps={{ 'aria-label': 'decorative checkbox' }}
          {...props}
        />
      }
      label={<Typography style={{ fontSize: 12 }}>{label}</Typography>}
    />
  );
}

export default CheckLabel;
