import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TableHead from '@material-ui/core/TableHead';
import { useDebounce } from 'use-lodash-debounce';
import ClearIcon from '@material-ui/icons/Clear';
import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Checkbox,
  TableSortLabel,
  TablePagination,
} from '@material-ui/core';
import ModalChangestatus from './ModalChangeProductStatus';

import Button from '../../components/Button';
import api from '../../services/api';

export const dictIdDelivery = {
  '5f34a6b5ed5ff21fbc9939c6': 'Delivery 1 FEB 15-28',
  '5f34a7b6bc82e351349c76bf': 'Delivery 2 MAR 15-30',
  '5f34a7c2bc82e351349c76c0': 'Delivery 3 APR 15-30',
  All: 'All',
};

const headCells = [
  { id: 'mainly_image', numeric: true, disablePadding: false, label: '' },

  { id: 'style_code', numeric: true, disablePadding: false, label: 'SKU' },
  { id: 'description', numeric: false, disablePadding: true, label: 'Name' },
  {
    id: 'category_name',
    numeric: false,
    disablePadding: false,
    label: 'Category',
  },
  {
    id: 'delivery_id',
    numeric: false,
    disablePadding: false,
    label: 'Shipping date',
  },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
];

const Chip = ({ label, handleDelete }) => {
  return (
    <div style={{ margin: 10 }} onClick={handleDelete}>
      <Typography display="inline">{label}</Typography>
      <img
        alt="icon"
        src="https://icons-for-free.com/iconfiles/png/512/close+cross+delete+remove+square+icon-1320195275754208861.png"
        style={{
          cursor: 'pointer',
          width: 16,
          height: 16,
          marginLeft: 5,
          position: 'relative',
          top: 3,
        }}
      />
    </div>
  );
};

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#fff',
    color: '#000',
    border: 0,
    borderBottom: '1px solid #000',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);
function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{ background: 'blue' }}>
      <TableRow>
        <StyledTableCell padding="checkbox">
          <span />
        </StyledTableCell>
        {headCells.map(headCell => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.id === 'status' ? 'left' : 'center'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              style={{ marginLeft: 30 }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: '0px 30px',
    maxHeight: '700px',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableRow: {
    '&$selected, &$selected:hover': {
      border: '3px solid #000',
      borderBottom: '1px solid #000',
      background: '#fff',
    },
  },
  tableCell: {
    borderBottom: 'none',
    '$selected &': {
      borderTop: '1px solid #000',
      borderBottom: '1px solid #000 !important',
    },
  },
  tableCellFirst: {
    borderBottom: 'none',
    '$selected &': {
      borderTop: '1px solid #000',
      borderLeft: '1px solid #000',
      borderBottom: '1px solid #000 !important',
    },
  },
  tableCellLast: {
    borderBottom: 'none',
    '$selected &': {
      borderTop: '1px solid #000',
      borderRight: '1px solid #000',
      borderBottom: '1px solid #000 !important',
    },
  },
  selected: {},
}));

export default function ManageProducts() {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('shopName');
  const [selected, setSelected] = React.useState([]);
  const [dense] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [styleCode] = React.useState('');
  const [deliveryId] = React.useState('All');
  const [printName] = React.useState('All');
  const [status, setStatus] = React.useState('All');
  const [categoryName] = React.useState('All');
  const [selectStatus, setSelectStatus] = React.useState('');
  const [openChangeStatus, setOpenChangestatus] = React.useState(false);
  const [selectedInfo, setSelectedInfo] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  // const [rows, setRows] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [page, setPage] = React.useState(0);

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDelete = id => {
    setSelected(selected.filter(item => item !== id));
  };

  const getProducts = async () => {
    const { data } = await api.get(
      `/productsManage?limit=${rowsPerPage}&skip=${page *
        rowsPerPage}&style_code=${styleCode}&delivery_id=${deliveryId}&print_name=${printName}&status=${status}&category_name=${categoryName}`
    );
    const arr = data.products.map((item, index) => ({
      mainly_image: item.mainly_image,
      style_code: item.style_code,
      description: item.description,
      category_name: item.category_name,
      delivery_id: item.delivery_id,
      status: item.status,
      id: index + 1,
    }));

    setRows(arr);
    setLoading(false);
    setTotal(data.count);
  };
  const debouncedSKU = useDebounce(styleCode, 500);

  React.useEffect(() => {
    getProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    debouncedSKU,
    styleCode,
    printName,
    categoryName,
    status,
    deliveryId,
    loading,
    page,
    rowsPerPage,
  ]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.email);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectStatus && selectStatus !== status)
      if (!selectStatus) setSelectStatus(status);

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      if (newSelected.length === 0) setSelectStatus('');
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleClickOpen = () => {
    const arr = selected.map(item =>
      rows.filter(product => product.style_code === item)
    );
    setSelectedInfo(arr.map(item => item[0]));
    setOpenChangestatus(true);
  };

  const isSelected = name => selected.indexOf(name) !== -1;

  const removeProduct = index => {
    setSelectedInfo(selectedInfo.filter((item, i) => index !== i));
    setSelected(selected.filter((item, i) => index !== i));
    if (selectedInfo?.length === 1) setOpenChangestatus(false);
  };

  const StyledTableRow = withStyles(theme => ({
    root: {
      border: 0,
      background: '#fff',
    },

    tableCell: {
      '$selected &': {
        color: 'yellow',
      },
    },
    selected: {
      border: '1px solid #000',
    },
  }))(TableRow);

  const Option = ({ text, color }) => {
    return (
      <Grid>
        <Grid container alignItems="center">
          {color && (
            <div
              style={{
                width: 14,
                height: 14,
                background: color,
                borderRadius: 7,
                marginRight: 8,
              }}
            />
          )}
          <Typography
            onClick={() => {
              setStatus(text);
              setPage(0);
            }}
            style={{
              fontSize: 14,
              lineheight: '18px',
              fontWeight: status === text && 'bold',
              cursor: 'pointer',
              paddingBottom: 4,
              borderBottom: status === text && '2px solid #000',
              height: 26,
            }}
          >
            {text}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid
      container
      justifyContent="center"
      style={{ alignContent: 'flex-start' }}
    >
      <Grid container justifyContent="center">
        <Typography
          style={{
            padding: '20px 0px 0px',
            fontFamily: 'Galano Grotesque Light',
            fontSize: 24,
            lineHeight: '28px',
            letterSpacing: '0.1em',
          }}
        >
          MANAGE PRODUCTS
        </Typography>
      </Grid>

      <Grid
        container
        justifyContent="space-between"
        style={{ width: 800, margin: '30px 0px' }}
      >
        <Option text="All" />
        <Option text="Available" color="green" />
        <Option text="Sold Out" color="orange" />
        <Option text="Cancelled" color="red" />
      </Grid>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <TableContainer style={{ height: window.innerHeight * 0.62 }}>
            <Table
              stickyHeader
              className={classes.table}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                style={{ background: 'blue' }}
              />
              <TableBody align>
                {stableSort(rows, getComparator(order, orderBy))
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.style_code);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <StyledTableRow
                        hover
                        onClick={event =>
                          handleClick(
                            event,
                            row.style_code,
                            row.status,
                            row.description,
                            row.mainly_image
                          )
                        }
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.description}
                        selected={isItemSelected}
                        classes={{ selected: classes.selected }}
                        className={classes.tableRow}
                      >
                        <TableCell
                          padding="checkbox"
                          style={{ borderBottom: 'none' }}
                          className={classes.tableCellFirst}
                        >
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </TableCell>
                        <TableCell
                          className={classes.tableCell}
                          align="center"
                          style={{ borderBottom: 'none' }}
                        >
                          <div
                            style={{
                              cursor: 'pointer',
                            }}
                          >
                            <img
                              alt="product_image"
                              src={row.mainly_image}
                              style={{ width: 34, height: 49 }}
                            />
                          </div>
                        </TableCell>
                        <TableCell
                          className={classes.tableCell}
                          style={{ borderBottom: 'none' }}
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          align="center"
                        >
                          {row.style_code}
                        </TableCell>
                        <TableCell
                          className={classes.tableCell}
                          style={{ borderBottom: 'none' }}
                          align="center"
                        >
                          {row.description}
                        </TableCell>
                        <TableCell
                          className={classes.tableCell}
                          style={{ borderBottom: 'none' }}
                          align="center"
                        >
                          {row.category_name}
                        </TableCell>
                        <TableCell
                          className={classes.tableCell}
                          style={{ borderBottom: 'none' }}
                          align="center"
                        >
                          {dictIdDelivery[row.delivery_id]}
                        </TableCell>
                        <TableCell
                          className={classes.tableCellLast}
                          style={{ borderBottom: 'none' }}
                          align="left"
                        >
                          <Grid container alignItems="center">
                            <div
                              style={{
                                width: 14,
                                height: 14,
                                background:
                                  row.status === 'Available'
                                    ? 'green'
                                    : row.status === 'Sold Out'
                                    ? 'orange'
                                    : 'red',
                                borderRadius: 7,
                                marginRight: 8,
                              }}
                            />
                            {row.status}
                          </Grid>
                        </TableCell>
                      </StyledTableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ padding: '0px 50px' }}
      >
        <Grid>
          <Grid container>
            {selected.map(item => (
              <Chip label={item} handleDelete={() => handleDelete(item)} />
            ))}
          </Grid>
        </Grid>
        <Grid style={{ cursor: 'pointer' }} onClick={() => setSelected([])}>
          {selected.length > 0 && (
            <Grid container alignItems="center">
              Clear Selection
              <ClearIcon
                style={{ position: 'relative', top: 3 }}
                size="small"
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid container style={{ paddingBottom: 20, paddingTop: 30 }}>
        <Button
          text="Change Status"
          width={268}
          height={40}
          textColor={selected.length > 0 ? '#fff' : 'rgba(0,0,0,0.35)'}
          color={selected.length > 0 ? '#000' : '#fff'}
          borderColor="1px solid #ebebeb"
          justifyContent="center"
          onClick={() => selected.length > 0 && handleClickOpen()}
        />
      </Grid>

      <ModalChangestatus
        open={openChangeStatus}
        onClose={() => {
          setOpenChangestatus(false);
        }}
        products={selectedInfo}
        removeProduct={removeProduct}
        setLoading={value => setLoading(value)}
        cleanSelected={() => setSelected([])}
      />
    </Grid>
  );
}
