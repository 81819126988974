import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { Typography, IconButton, Dialog } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

import Input from '../TextInput';
import Button from '../Button';
import { country_list } from '../../consts';

export default function ModalAddress({
  open,
  handleClose,
  handleSaveAddress,
  editValues,
}) {
  const [modalAddress, setModalAddress] = useState('');
  const [modalCity, setModalCity] = useState('');
  const [modalStreet, setModalStreet] = useState('');
  const [modalUnit, setModalUnit] = useState('');
  const [modalStoreProv, setModalStoreProv] = useState('');
  const [modalZipcode, setModalZipcode] = useState('');
  const [modalCountry, setModalCountry] = useState('');
  const [errorbAddress1, setErrorbAddress1] = useState(null);
  const [errorbCity, setErrorbCity] = useState(null);
  const [errorbStoreProvince, setErrorbStoreProvince] = useState('');
  const [errorbZipcode, setErrorbZipcode] = useState('');
  const [errorStreet, setErrorStreet] = useState('');

  useEffect(() => {
    setModalAddress(editValues.modalAddress);
    setModalCity(editValues.modalCity);
    setModalStreet(editValues.modalStreet);
    setModalUnit(editValues.modalUnit);
    setModalStoreProv(editValues.modalStoreProv);
    setModalCountry(editValues.modalCountry);
    setModalZipcode(editValues.modalZipcode);
  }, [editValues]);

  const validateAddress = () => {
    if (!modalCity) setErrorbCity('City is required');
    if (!modalAddress) setErrorbAddress1('Name is required');
    if (!modalStreet) setErrorStreet('Street is required');
    if (!modalStoreProv) setErrorbStoreProvince('State/Province is required');
    if (!modalZipcode) setErrorbZipcode('Zipcode is required');

    if (
      !modalCity ||
      !modalAddress ||
      !modalStreet ||
      !modalStoreProv ||
      !modalZipcode ||
      !modalCountry
    )
      return false;
    return true;
  };

  const cleanErrors = () => {
    setModalAddress('');
    setModalCity('');
    setModalStoreProv('');
    setModalZipcode('');
    setModalCountry('');
    setModalStreet('');
    setModalUnit('');
  };

  const handleCleanForm = () => {
    setModalAddress('');
    setModalCity('');
    setModalStreet('');
    setModalUnit('');
    setModalStoreProv('');
    setModalZipcode('');
    setModalZipcode('');
    setModalCountry('');
    cleanErrors();
  };

  const closeModalAddresses = () => {
    handleCleanForm();
    handleClose();
  };

  const handleClickSave = () => {
    if (!validateAddress()) return;
    const address = {
      modalAddress,
      modalCity,
      modalStoreProv,
      modalZipcode,
      modalCountry,
      modalStreet,
      modalUnit,
    };
    handleSaveAddress(address);
    closeModalAddresses();
  };

  return (
    <Dialog
      open={open}
      onClose={closeModalAddresses}
      style={{
        width: '100%',
        maxWidth: '100vw',
        height: window.innerHeight,
        position: 'fixed',
        top: '50%',
        left: '0',
        transform: 'translate(0, -50%)',
        overflowY: 'auto',
        zIndex: 2000,
      }}
    >
      <Grid style={{ padding: '5%', width: 350 }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography>Add Address</Typography>
          <IconButton onClick={closeModalAddresses}>
            <ClearIcon />
          </IconButton>
        </Grid>

        <Input
          field="Address Name"
          width={320}
          height={33}
          marginTop={20}
          value={modalAddress}
          onChange={event => {
            setModalAddress(event.target.value);
            setErrorbAddress1('');
          }}
          error={errorbAddress1}
          helperText={errorbAddress1}
        />

        <Input
          field="Street Address"
          width={320}
          height={33}
          marginTop={45}
          value={modalStreet}
          onChange={event => {
            setModalStreet(event.target.value);
            setErrorStreet('');
          }}
          error={errorStreet}
          helperText={errorStreet}
        />
        <Input
          field="Building, unit, floor etc"
          width={320}
          height={33}
          marginTop={45}
          value={modalUnit}
          onChange={event => {
            setModalUnit(event.target.value);
          }}
        />

        <Input
          field="City"
          width={320}
          height={33}
          marginTop={45}
          value={modalCity}
          onChange={event => {
            setModalCity(event.target.value);
            setErrorbCity('');
          }}
          error={errorbCity}
          helperText={errorbCity}
        />
        <Input
          field="State/Province"
          width={320}
          height={33}
          marginTop={45}
          value={modalStoreProv}
          onChange={event => {
            setModalStoreProv(event.target.value);
            setErrorbStoreProvince('');
          }}
          error={errorbStoreProvince}
          helperText={errorbStoreProvince}
        />
        <Input
          field="Zip / Postal Code"
          width={320}
          height={33}
          marginTop={45}
          value={modalZipcode}
          onChange={event => {
            setModalZipcode(event.target.value);
            setErrorbZipcode('');
          }}
          error={errorbZipcode}
          helperText={errorbZipcode}
        />
        <Input
          field="Country"
          country
          background="#EBEBEB"
          width={320}
          height={40}
          marginTop={45}
          select
          onChange={event => setModalCountry(event.target.value)}
          value={modalCountry}
          options={country_list}
        />
        <Grid container justifyContent="center" style={{ paddingBottom: 44 }}>
          <Button
            justifyContent="center"
            width={128}
            height={40}
            color="#000000"
            textColor="#FFFFFF"
            text="save"
            marginTop={54}
            fontSize={16}
            onClick={handleClickSave}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
}
