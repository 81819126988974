import React from 'react';

export default function Maintenance() {
  const screen = window.innerWidth < 1024 ? 'Mobile' : 'Desk';
  return (
    <div
      style={{
        background: `url("https://storage.googleapis.com/showroom-bucket-images/RESORT23/Home/Na%CC%83o%20Logada/${screen}/Who_we_are.jpg") no-repeat center top / cover`,
        height: '100vh',
        width: '100vw',
        color: 'white',
        position: 'relative',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'rgba(0,0,0,0.3)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <h1>
          We are under maintenance. <br />
          Come back soon!
        </h1>
      </div>
    </div>
  );
}
