import React, { createContext, useState } from 'react';

export const initialState = false;

const ModalLoginContext = createContext();

export { ModalLoginContext };

export default function ModalLoginContextProvider({ children }) {
  const state = useState(initialState);

  return (
    <ModalLoginContext.Provider value={state}>
      {children}
    </ModalLoginContext.Provider>
  );
}
