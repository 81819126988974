import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TableHead from '@material-ui/core/TableHead';
import { FaUserEdit } from 'react-icons/fa';
import { useDebounce } from 'use-lodash-debounce';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Paper,
  Checkbox,
  FormControlLabel,
  Switch,
  TableSortLabel,
} from '@material-ui/core';
import TextInput from '../TextInput';
import history from '../../services/history';

import Button from '../Button';
import api from '../../services/api';

import useUserContext from '../../hooks/useUserContext';

const headCells = [
  { id: 'shopName', numeric: false, disablePadding: false, label: 'Shop name' },
  { id: 'email', numeric: false, disablePadding: true, label: 'Email' },
  { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
  { id: 'phone', numeric: true, disablePadding: false, label: 'Phone' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
  { id: 'infos', label: 'More infos' },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              style={{ marginLeft: 30 }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: '0px 30px',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default function EnhancedTable() {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('shopName');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const { userState } = useUserContext();
  const { user: profile } = userState;
  const [email, setEmail] = React.useState('');
  const [total, setTotal] = React.useState(0);

  const getUsers = async () => {
    const { data } = await api.get(
      `/newCostumers?limit=${rowsPerPage}&skip=${page *
        rowsPerPage}&word=${email}`
    );
    const arr = data?.costumers?.map((item, index) => ({
      shopName: item?.account?.name,
      email: item.email,
      name: item.name,
      phone: item.phone,
      isApproved: item.isApproved || 'Waiting',
      id: index + 1,
    }));
    setTotal(data.count);
    setRows(arr);
  };

  const debouncedEmail = useDebounce(email, 300);

  React.useEffect(() => {
    if (!profile.admin) return history.push('/');
    if (!loading) {
      getUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, profile, rowsPerPage, page, debouncedEmail]);

  React.useEffect(() => {
    setPage(0);
  }, [debouncedEmail]);

  const updateApprove = async value => {
    const obj = selected.map(item => ({
      email: item,
      isApproved: value,
    }));
    setLoading(true);
    await Promise.all(obj.map(data => api.put('/newCostumers', data)));
    setLoading(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.email);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = event => {
    setDense(event.target.checked);
  };

  const isSelected = name => selected.indexOf(name) !== -1;

  return (
    <div className={classes.root}>
      <div style={{ padding: 10, marginBottom: 10 }}>
        <TextInput
          field="Find user by email"
          width={300}
          marginTop={20}
          value={email}
          onChange={event => setEmail(event.target.value)}
          variant
        />
      </div>

      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows?.length}
            />
            <TableBody align>
              {rows?.map((row, index) => {
                const isItemSelected = isSelected(row.email);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={event => handleClick(event, row.email)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.name}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      align="center"
                    >
                      {row.shopName}
                    </TableCell>
                    <TableCell align="center">{row.email}</TableCell>
                    <TableCell align="center">{row.name}</TableCell>
                    <TableCell align="center">{row.phone}</TableCell>
                    <TableCell align="center">
                      <div
                        style={{
                          background:
                            row.isApproved === 'Approved'
                              ? '#00a000'
                              : row.isApproved === 'Disapproved'
                              ? '#f00000'
                              : '#a9a9a9',
                        }}
                      >
                        {row.isApproved}
                      </div>
                    </TableCell>
                    <TableCell
                      align="center"
                      onClick={() => window.open(`/register/${row.email}`)}
                    >
                      <div
                        style={{
                          cursor: 'pointer',
                        }}
                      >
                        <FaUserEdit />
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
              {/* {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )} */}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>

      <Grid container justifyContent="space-between">
        <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label="Dense padding"
        />
        <Grid>
          <Grid container>
            <Grid onClick={() => updateApprove('Disapproved')}>
              <Button
                text="Reprove"
                textColor="#ffffff"
                color="#f00000"
                width={140}
                height={40}
              />
            </Grid>
            <Grid onClick={() => updateApprove('Approved')}>
              <Button
                text="Approve"
                textColor="#ffffff"
                color="#00a000"
                width={140}
                height={40}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
