export default theme => ({
  gridHeight: {
    height: 650,
    margin: '30px 0px',
  },
  fontWhite: {
    color: '#ffffff',
    padding: '4px 12px',
    fontSize: 10,
  },
  fontPadding: {
    padding: '2px 8px',
    fontSize: 10,
    marginTop: 3,
    margin: '0px 20px',
    cursor: 'pointer',
  },
  fontBorder: {
    padding: '2px 8px',
    fontSize: 10,
    margin: '0px 20px',
    border: '2px solid #000000',
    cursor: 'pointer',
  },

  marginTop: {
    marginTop: 200,
  },
});
