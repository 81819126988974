import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TextCard from '../../components/TextCard';
import Grid from '@material-ui/core/Grid';
import { ReactComponent as UserLogo } from '../../assets/user.svg';
import { ReactComponent as OrderLogo } from '../../assets/order.svg';
import { ReactComponent as AddressLogo } from '../../assets/address.svg';
import { ReactComponent as UsersLogo } from '../../assets/users.svg';

const useStyles = makeStyles(() => ({
  centralTitle: {
    textAlign: 'center',
  },

  contentCardText: {
    fontSize: 12,
    lineHeight: '18px',
  },
}));

export default function Dashboard({ cardClick }) {
  const classes = useStyles();

  return (
    <Grid container item xs={9} style={{ gridGap: 'clamp(8px, 10px, 13px)' }}>
      <TextCard
        xs={4}
        title={'Profile & Preferences'}
        logo={<UserLogo />}
        linkMessage="View Profile Preferences"
        cardClick={cardClick}
      >
        <Typography className={classes.contentCardText}>
          View and update your personal information and login preferences.
        </Typography>
      </TextCard>

      <TextCard
        xs={4}
        title="Order History"
        logo={<OrderLogo />}
        linkMessage="View Orders"
        cardClick={cardClick}
      >
        <Typography className={classes.contentCardText}>
          View your order history.
        </Typography>
      </TextCard>

      <TextCard
        xs={4}
        title="Address List"
        logo={<AddressLogo />}
        linkMessage="View Profile Preferences"
        cardClick={cardClick}
      >
        <Typography className={classes.contentCardText}>
          View, update or delete your address, set them as shipping or billing
          address or add new orders.
        </Typography>
      </TextCard>
      <TextCard
        xs={4}
        title={'Account & Users'}
        logo={<UsersLogo />}
        linkMessage="View Account Information"
        cardClick={cardClick}
      >
        <Typography className={classes.contentCardText}>
          View and update information about your account and connected users.
        </Typography>
      </TextCard>

      {/* <TextCard
        xs={4}
        title="Business Information"
        logo={<OfficeLogo />}
        linkMessage="View Business Information"
        cardClick={cardClick}
      >
        <Typography className={classes.contentCardText}>
          View and update information about your business.
        </Typography>
      </TextCard>

      <TextCard
        xs={4}
        title="Payment"
        logo={<PaymentLogo />}
        linkMessage="View Payment Information"
        cardClick={cardClick}
      >
        <Typography className={classes.contentCardText}>
          View and update payment information.
        </Typography>
      </TextCard> */}
    </Grid>
  );
}
