import React from 'react';
import GoogleTagManagerEvents from './googleTagManagerEvents';

export default function Plugins() {
  return (
    <>
      <GoogleTagManagerEvents />
    </>
  );
}
