import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import logoPdf from '../../assets/pdf/logo_farmRio_pdf.png';
import useViewAsContext from '../../hooks/useViewAsContext';
import useConfigContext from '../../hooks/useConfigContext';

// import logoResort from '../../assets/pdf/R21.png';

const GridItem = ({ product }) => {
  return (
    <Grid style={{ cursor: 'pointer', marginRight: 29 }}>
      <img
        src={
          product.mainly_image ||
          'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAPFBMVEXu7u62trbx8fHPz8/t7e2zs7O8vLy5ubnl5eWrq6vc3Nzq6urZ2dnh4eHV1dW6urrHx8fBwcHKysqlpaXy5cTEAAAEF0lEQVR4nO3bjXKkKBQFYLj8CAIK5v3fdS/Y9mi3md1UZavFOt9UTTpgujwBQaEjBAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAEDH6N2nT+l3TfaNulNEGo18Y+KnT+sXUXkPKGW5USOGs4BShk+f1++JJ52Uu+l0m0akfN6GN+im66RA7jygNK7veYNcnBo1fJNQDmo9IrouQ2az+S4gt+ImdzjqlLMp0Pz5/6XGfvp8f4qm9xhmVimwNNmTys4GVv0+x5uStiGFKO1a+NGktq+EQtjXgGo3ZOpdG5tlaiPR0HvC+BJgu1U1C7eo6T+heQQkEYJ4vHzc6PD1F/pPaHI7fXKZi+2yzn60tLrBhdx/Qqlbmdpmi6WlCcN+pOk7YXvYpeU5fj4iqv2k0XVC48TLU/B6XYbbJGz3K/rQqi0PDXdJ2MYZd7iPMXW0OTxV9ZzQ1MuQjk/BrZvS/kLsOmEdV15uVNttKKm7tGEbOceTNlxuknBdrTiuRpn6PHi4Pe864VAnfMr7RmyhSd4loRlfG7HNkCLdZj5cb0t3U/464R+X4LpOKE1qiRw/2/M/Y9db7yTvk1DOayEllbNKdHZI5wkfj09itzx6HHm6T8gR9SEB6ZeA3SeUxnLn1FttuyRvlrA247aIz8/678uJN0hYB9FlinFa7NmacG8Jz/dE12X885re1kvP1rz/qrc17/pI/6OIZtb//p4XE6z5gdLh3hPfwEzqP5pSb1304eRTQt/49JkCAAAAAEBftHt/0D0rq9zFHhmfH1n7U0LHkrY749uC/lnZ/r3aMet+zjXoGEaVRN18UfG58hvVWBdGp6kGiDoqRy76aWxV9S8RjmXCRdLCjfwjiotDNOo6f66g/TBYHwUVX8y6cSaCNPwdTd5yDWkvZ+vHcfBz4apZSSv2ZbWfpi/H71Bo9Hnx0Q1+3Xe8BG0yUbGkfOCv87pzZohCCF+RKPog/EJkCyd1RLnwU70f92X1XWjO/DoJ7p2csvXSy3RTXU9GzWTr/svo2wb29gGM+pqb1yc+whJfc1w1lFI43q6s4t+EiIac59rC7Xmp69AkUROW0hK2Pe0tIX9DX9xe9YgtYR6Z25e1d+HIVnHCWKv1JROO3PXCY8NX8XmnxD1R6MwxazJOo/2oSZlAgct3Ze2CIyVr+8vMv5lIwkx0neuwtYbkPueNL4+yzK8XSsZ749r1RYvlq9Rz3FqV9b5snfoCD008lBqu5mEre3+dGbG1Qe2PIYXnVNdek3CtD9Zz1aFO7+552KGsvY1v00b9kdrFt+IrOblhOfvyTRlN5v85rasYzdfrh8FvJox9/m3QD9w9HwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAANzJP/MdJGqyWhqXAAAAAElFTkSuQmCC'
        }
        alt="farm"
        style={{
          width: 125.8,
          height: 178.5,
        }}
      />
      <Grid>
        <Grid
          style={{
            width: 116,
            height: 114,
            display: 'inline-grid',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          <Typography style={{ fontSize: 14, marginTop: 9 }}>
            {product.style_code}
          </Typography>
          <Typography style={{ fontSize: 12 }}>
            {product.description}
          </Typography>
          <Typography style={{ fontSize: 10, marginTop: 6 }}>
            <span style={{ fontWeight: 'bold' }}>WS </span>$
            {product.wholesale_usd}/&euro;{product.wholesale_eur}{' '}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

const Header = ({ name, id, capsula }) => {
  const { viewAsState } = useViewAsContext();
  const { selectedUser } = viewAsState;
  const { configState } = useConfigContext();
  const seasonName = configState.season.name || '';
  const { deliveries } = configState;

  return (
    <Grid container alignItems="center" style={{ marginTop: 38, height: 61 }}>
      <Grid container justifyContent="space-between">
        <Grid>
          <img src={logoPdf} alt="logoPdf" />
          <Typography
            display="inline"
            style={{ fontSize: 20, fontFamily: 'Galano Grotesque Light' }}
          >
            {` | ${seasonName}`}
          </Typography>
        </Grid>
        <Typography
          style={{
            letterSpacing: '0.05em',
            textTransform: 'uppercase',
            marginRight: 45,
          }}
        >
          {selectedUser ? selectedUser.name.split(' ')[0] : name.split(' ')[0]}
          'S COLLECTION
        </Typography>
      </Grid>

      <Grid style={{ marginTop: 11 }}>
        <Typography
          display="inline"
          style={{ fontWeight: 'bold', fontSize: 12 }}
        >
          {capsula || `${deliveries[id].title}`}
        </Typography>
        <Typography display="inline" style={{ fontSize: 12 }}>
          {` ${deliveries[id].subTitle1}`} | {deliveries[id].subTitle2}
        </Typography>
      </Grid>

      <Grid />
    </Grid>
  );
};

const Footer = ({ page }) => {
  return (
    <Grid
      container
      justifyContent="flex-end"
      alignItems="center"
      style={{ height: 41, marginBottom: 20.3, marginTop: 27 }}
    >
      <Typography
        style={{
          letterSpacing: '0.05em',
          marginRight: 45,
        }}
      >
        Page{page}
      </Typography>
    </Grid>
  );
};

export default function PDF({
  d1,
  d2,
  d3,
  d4,
  c1,
  c2,
  c3,
  c4,
  swim2,
  swim3,
  shoes1,
  shoes2,
  shoes3,
  name,
  men2,
}) {
  const d1Col = Math.ceil(d1?.length / 7);
  const d2Col = Math.ceil(d2?.length / 7);
  const d3Col = Math.ceil(d3?.length / 7);
  const d4Col = Math.ceil(d4?.length / 7);
  // const c1Col = Math.ceil(c1?.length / 7);
  // const c2Col = Math.ceil(c2?.length / 7);
  // const c3Col = Math.ceil(c3?.length / 7);
  const shoes1Col = Math.ceil(shoes1?.length / 7);
  const shoes2Col = Math.ceil(shoes2?.length / 7);
  const shoes3Col = Math.ceil(shoes3?.length / 7);
  const swim2Col = Math.ceil(swim2?.length / 7);
  const swim3Col = Math.ceil(swim3?.length / 7);
  const men2Col = Math.ceil(men2?.length / 7);

  const d1pages = Math.ceil(d1?.length / 14);
  const d2pages = Math.ceil(d2?.length / 14);
  const d3pages = Math.ceil(d3?.length / 14);
  // const c1pages = Math.ceil(c1?.length / 14);
  const c2pages = Math.ceil(c2?.length / 14);
  // const c3pages = Math.ceil(c3?.length / 14);
  const swim2pages = Math.ceil(swim2?.length / 14);
  const swim3pages = Math.ceil(swim3?.length / 14);
  const shoes1pages = Math.ceil(shoes1?.length / 14);
  const shoes2pages = Math.ceil(shoes2?.length / 14);

  return (
    <Grid container alignItems="center" style={{ padding: '0px 0px 0px 46px' }}>
      <Grid container>
        {d1.map((product, index) => (
          <>
            {index % 14 === 0 && <Header name={name} id={0} />}
            <Grid
              item
              md={5 / 12}
              key={product.style_code}
              style={{ marginBottom: 11, marginTop: 11 }}
            >
              <GridItem product={product} />
            </Grid>

            {index !== 0 &&
              ((index + 1) % 14 === 0 || index === d1?.length - 1) && (
                <Grid
                  style={{
                    marginTop:
                      d1?.length > 0 &&
                      d1Col % 2 === 1 &&
                      index === d1?.length - 1
                        ? 311.5
                        : 0,
                  }}
                  container
                >
                  <Footer page={Math.ceil(index / 14)} d1 />
                </Grid>
              )}
          </>
        ))}
      </Grid>
      <Grid container>
        {d2.map((product, index) => (
          <>
            {index % 14 === 0 && <Header name={name} id={1} />}
            <Grid
              item
              md={5 / 12}
              key={product.style_code}
              style={{ marginBottom: 11, marginTop: 11 }}
            >
              <GridItem product={product} />
            </Grid>
            {index !== 0 &&
              ((index + 1) % 14 === 0 || index === d2?.length - 1) && (
                <Grid
                  container
                  style={{
                    marginTop:
                      d2?.length > 0 &&
                      d2Col % 2 === 1 &&
                      index === d2?.length - 1
                        ? 311.5
                        : 0,
                  }}
                >
                  <Footer page={d1pages + Math.ceil(index / 14)} d2 />
                </Grid>
              )}
          </>
        ))}
      </Grid>
      <Grid container>
        {d3.map((product, index) => (
          <>
            {index % 14 === 0 && <Header name={name} id={2} />}
            <Grid
              item
              md={5 / 12}
              key={product.style_code}
              style={{ marginBottom: 11, marginTop: 11 }}
            >
              <GridItem product={product} />
            </Grid>
            {index !== 0 &&
              ((index + 1) % 14 === 0 || index === d3?.length - 1) && (
                <Grid
                  container
                  style={{
                    marginTop:
                      d3?.length > 0 &&
                      d3Col % 2 === 1 &&
                      index === d3?.length - 1
                        ? 311.5
                        : 0,
                  }}
                >
                  <Footer page={d1pages + d2pages + Math.ceil(index / 14)} d3 />
                </Grid>
              )}
          </>
        ))}
      </Grid>
      <Grid container>
        {d4.map((product, index) => (
          <>
            {index % 14 === 0 && <Header name={name} id={3} />}
            <Grid
              item
              md={5 / 12}
              key={product.style_code}
              style={{ marginBottom: 11, marginTop: 11 }}
            >
              <GridItem product={product} />
            </Grid>
            {index !== 0 &&
              ((index + 1) % 14 === 0 || index === d4?.length - 1) && (
                <Grid
                  container
                  style={{
                    marginTop:
                      d4?.length > 0 &&
                      d4Col % 2 === 1 &&
                      index === d4?.length - 1
                        ? 311.5
                        : 0,
                  }}
                >
                  <Footer
                    page={d1pages + d2pages + d3pages + Math.ceil(index / 14)}
                    d4
                  />
                </Grid>
              )}
          </>
        ))}
      </Grid>
      {/* <Grid container>
        {c1.map((product, index) => (
          <>
            {index % 14 === 0 && <Header name={name} id={3} />}
            <Grid
              item
              md={5 / 12}
              key={product.style_code}
              style={{ marginBottom: 11, marginTop: 11 }}
            >
              <GridItem product={product} />
            </Grid>
            {index !== 0 &&
              ((index + 1) % 14 === 0 || index === c1?.length - 1) && (
                <Grid
                  container
                  style={{
                    marginTop:
                      c1?.length > 0 &&
                      c1Col % 2 === 1 &&
                      index === c1?.length - 1
                        ? 311.5
                        : 0,
                  }}
                >
                  <Footer
                    page={d1pages + d2pages + d3pages + Math.ceil(index / 14)}
                    c1
                  />
                </Grid>
              )}
          </>
        ))}
      </Grid> */}
      {/* <Grid container>
        {c2.map((product, index) => (
          <>
            {index % 14 === 0 && <Header name={name} id={3} />}
            <Grid
              item
              md={5 / 12}
              key={product.style_code}
              style={{ marginBottom: 11, marginTop: 11 }}
            >
              <GridItem product={product} />
            </Grid>
            {index !== 0 &&
              ((index + 1) % 14 === 0 || index === c2?.length - 1) && (
                <Grid
                  container
                  style={{
                    marginTop:
                      c2?.length > 0 &&
                      c2Col % 2 === 1 &&
                      index === c2?.length - 1
                        ? 311.5
                        : 0,
                  }}
                >
                  <Footer
                    page={d1pages + d2pages + d3pages + Math.ceil(index / 14)}
                    c2
                  />
                </Grid>
              )}
          </>
        ))}
      </Grid> */}
      {/* <Grid container>
        {c3.map((product, index) => (
          <>
            {index % 14 === 0 && <Header name={name} id={5} />}
            <Grid
              item
              md={5 / 12}
              key={product.style_code}
              style={{ marginBottom: 11, marginTop: 11 }}
            >
              <GridItem product={product} />
            </Grid>
            {index !== 0 &&
              ((index + 1) % 14 === 0 || index === c3?.length - 1) && (
                <Grid
                  container
                  style={{
                    marginTop:
                      c3?.length > 0 &&
                      c3Col % 2 === 1 &&
                      index === c3?.length - 1
                        ? 311.5
                        : 0,
                  }}
                >
                  <Footer
                    page={
                      d1pages +
                      d2pages +
                      d3pages +
                      c1pages +
                      c2pages +
                      Math.ceil(index / 14)
                    }
                    c1
                  />
                </Grid>
              )}
          </>
        ))}
      </Grid> */}
      <Grid container>
        {swim2.map((product, index) => (
          <>
            {index % 14 === 0 && (
              <Header name={name} capsula="SWIMWEAR" id={1} />
            )}
            <Grid
              item
              md={5 / 12}
              key={product.style_code}
              style={{ marginBottom: 11, marginTop: 11 }}
            >
              <GridItem product={product} />
            </Grid>
            {index !== 0 &&
              ((index + 1) % 14 === 0 || index === swim2?.length - 1) && (
                <Grid
                  container
                  style={{
                    marginTop:
                      swim2?.length > 0 &&
                      swim2Col % 2 === 1 &&
                      index === swim2?.length - 1
                        ? 311.5
                        : 0,
                  }}
                >
                  <Footer
                    page={
                      d1pages +
                      d2pages +
                      d3pages +
                      c2pages +
                      Math.ceil(index / 14)
                    }
                  />
                </Grid>
              )}
          </>
        ))}
      </Grid>
      <Grid container>
        {swim3.map((product, index) => (
          <>
            {index % 14 === 0 && (
              <Header name={name} capsula="SWIMWEAR" id={2} />
            )}
            <Grid
              item
              md={5 / 12}
              key={product.style_code}
              style={{ marginBottom: 11, marginTop: 11 }}
            >
              <GridItem product={product} />
            </Grid>
            {index !== 0 &&
              ((index + 1) % 14 === 0 || index === swim3?.length - 1) && (
                <Grid
                  container
                  style={{
                    marginTop:
                      swim3?.length > 0 &&
                      swim3Col % 2 === 1 &&
                      index === swim3?.length - 1
                        ? 311.5
                        : 0,
                  }}
                >
                  <Footer
                    page={
                      d1pages +
                      d2pages +
                      d3pages +
                      c2pages +
                      swim2pages +
                      Math.ceil(index / 14)
                    }
                    c1
                  />
                </Grid>
              )}
          </>
        ))}
      </Grid>
      <Grid container>
        {shoes1.map((product, index) => (
          <>
            {index % 14 === 0 && (
              <Header name={name} capsula="FOOTWEAR" id={0} />
            )}
            <Grid
              item
              md={5 / 12}
              key={product.style_code}
              style={{ marginBottom: 11, marginTop: 11 }}
            >
              <GridItem product={product} />
            </Grid>
            {index !== 0 &&
              ((index + 1) % 14 === 0 || index === shoes1?.length - 1) && (
                <Grid
                  container
                  style={{
                    marginTop:
                      shoes1?.length > 0 &&
                      shoes1Col % 2 === 1 &&
                      index === shoes1?.length - 1
                        ? 311.5
                        : 0,
                  }}
                >
                  <Footer
                    page={
                      d1pages +
                      d2pages +
                      d3pages +
                      c2pages +
                      swim2pages +
                      swim3pages +
                      Math.ceil(index / 14)
                    }
                    c1
                  />
                </Grid>
              )}
          </>
        ))}
      </Grid>
      <Grid container>
        {shoes2.map((product, index) => (
          <>
            {index % 14 === 0 && (
              <Header name={name} capsula="FOOTWEAR" id={1} />
            )}
            <Grid
              item
              md={5 / 12}
              key={product.style_code}
              style={{ marginBottom: 11, marginTop: 11 }}
            >
              <GridItem product={product} />
            </Grid>
            {index !== 0 &&
              ((index + 1) % 14 === 0 || index === shoes2?.length - 1) && (
                <Grid
                  container
                  style={{
                    marginTop:
                      shoes2?.length > 0 &&
                      shoes2Col % 2 === 1 &&
                      index === shoes2?.length - 1
                        ? 311.5
                        : 0,
                  }}
                >
                  <Footer
                    page={
                      d1pages +
                      d2pages +
                      d3pages +
                      c2pages +
                      swim2pages +
                      swim3pages +
                      shoes1pages +
                      Math.ceil(index / 14)
                    }
                    c1
                  />
                </Grid>
              )}
          </>
        ))}
      </Grid>
      <Grid container>
        {shoes3.map((product, index) => (
          <>
            {index % 14 === 0 && (
              <Header name={name} capsula="FOOTWEAR" id={2} />
            )}
            <Grid
              item
              md={5 / 12}
              key={product.style_code}
              style={{ marginBottom: 11, marginTop: 11 }}
            >
              <GridItem product={product} />
            </Grid>
            {index !== 0 &&
              ((index + 1) % 14 === 0 || index === shoes3?.length - 1) && (
                <Grid
                  container
                  style={{
                    marginTop:
                      shoes3?.length > 0 &&
                      shoes3Col % 2 === 1 &&
                      index === shoes3?.length - 1
                        ? 311.5
                        : 0,
                  }}
                >
                  <Footer
                    page={
                      d1pages +
                      d2pages +
                      d3pages +
                      c2pages +
                      swim2pages +
                      swim3pages +
                      shoes1pages +
                      shoes2pages +
                      Math.ceil(index / 14)
                    }
                    c1
                  />
                </Grid>
              )}
          </>
        ))}
      </Grid>
      <Grid container>
        {men2.map((product, index) => (
          <>
            {index % 14 === 0 && (
              <Header name={name} capsula="MENSWEAR" id={2} />
            )}
            <Grid
              item
              md={5 / 12}
              key={product.style_code}
              style={{ marginBottom: 11, marginTop: 11 }}
            >
              <GridItem product={product} />
            </Grid>
            {index !== 0 &&
              ((index + 1) % 14 === 0 || index === men2?.length - 1) && (
                <Grid
                  container
                  style={{
                    marginTop:
                      men2?.length > 0 &&
                      men2Col % 2 === 1 &&
                      index === men2?.length - 1
                        ? 311.5
                        : 0,
                  }}
                >
                  <Footer
                    page={
                      d1pages +
                      d2pages +
                      d3pages +
                      c2pages +
                      swim2pages +
                      swim3pages +
                      shoes1pages +
                      shoes2pages +
                      Math.ceil(index / 14)
                    }
                    c1
                  />
                </Grid>
              )}
          </>
        ))}
      </Grid>
    </Grid>
  );
}
