import React from 'react';
import { Grid } from '@material-ui/core';

const MinusIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 8H4"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const PlusIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 3V13"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 8H3"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default function InputNumber({ setValue, value, year }) {
  const date = new Date();
  return (
    <div>
      <Grid
        container
        style={{
          borderBottom: '2px solid #000',
          paddingBottom: 8,
          alignItems: 'center',
        }}
      >
        <div
          onClick={() => {
            if (year && value === 1900) return;
            if (value === 1) return;
            setValue(value - 1);
          }}
          style={{ cursor: 'pointer' }}
        >
          <MinusIcon />
        </div>
        <input
          onChange={event => {
            if (
              isNaN(event.target.value) ||
              (!year && event.target.value.length > 2) ||
              event.target.value.length > 4
            )
              return;
            setValue(Number(event.target.value));
          }}
          style={{
            width: year ? 50 : 16,
            border: 'none',
            margin: '0px 12px',
            fontSize: 14,
            lineHeight: '16px',
            position: 'relative',
            bottom: 2,
          }}
          value={value}
        />
        <div
          style={{ position: 'relative', right: 5, cursor: 'pointer' }}
          onClick={() => {
            if (year && value === date.getFullYear()) return;
            setValue(value + 1);
          }}
        >
          <PlusIcon />
        </div>
      </Grid>
    </div>
  );
}
