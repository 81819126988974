import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Snackbar from '../../util/SnackBar';
import Button from '../../components/Button';
import api from '../../services/api';

const StyledDialog = withStyles({
  paper: {
    margin: 0,
    display: 'flex',
    justifyContent: 'center',
    borderRadius: 0,
  },
  paperFullScreen: {
    margin: 0,
    height: '100%',
    backgroundColor: 'white',
    borderRadius: 0,
  },
  paperWidthLg: {
    maxWidth: '100%',
  },
  scrollPaper: {
    alignItems: 'center',
    borderRadius: 0,
  },
  paperFullWidth: {
    width: '100%',
  },
  container: {
    height: 'auto',
    borderRadius: 0,
  },
})(Dialog);

const dictOption = {
  Available: 'green',
  'Sold Out': 'orange',
  Cancelled: 'red',
};

export default function ConfirmationDialogRaw(props) {
  const {
    onClose,
    value: valueProp,
    open,
    products,
    removeProduct,
    setLoading,
    cleanSelected,
    ...other
  } = props;

  const [newStatus, setNewStatus] = React.useState('');
  const [hasDifferentStatus, setHasDifferentStatus] = React.useState(false);
  const [isFinish, setIsFinish] = React.useState(false);

  React.useEffect(() => {
    let bool = false;
    products.forEach(product => {
      if (products[0] && products[0]?.status !== product?.status) bool = true;
    });
    setHasDifferentStatus(bool);
  }, [open, products]);

  const handleUpdate = async () => {
    if (!newStatus)
      return Snackbar.error('You have to choose one status to update');
    const body = products.map(product => ({
      ...product,
      status: newStatus,
    }));

    await api.put('/products', body);
    setIsFinish(true);
  };

  const OptionStatus = ({ option, isCurrent }) => {
    return (
      <Grid
        container
        alignItems="center"
        onClick={() => !isCurrent && setNewStatus(option)}
        style={{
          opacity: !isCurrent && newStatus !== option && 0.6,
          cursor: !isCurrent && 'pointer',
        }}
      >
        <div
          style={{
            width: 14,
            height: 14,
            background: dictOption[option],
            borderRadius: 7,
            marginRight: 8,
            marginTop: 5,
          }}
        />
        <span
          style={{
            fontWeight: !isCurrent && newStatus === option && 'bold',
          }}
        >
          {option}
        </span>
      </Grid>
    );
  };

  return (
    <StyledDialog
      aria-labelledby="confirmation-dialog-title"
      open={open}
      onClose={() => {
        onClose();
        if (isFinish) {
          setLoading(true);
          cleanSelected();
          setNewStatus('');
        }

        setIsFinish(false);
      }}
      hideBackdrop
      style={{
        position: 'fixed',
        // top: '34%',
        // left: '56%',
        // margin: '15% auto',
        marginTop: 35,
        marginLeft: 50,
        // transform: 'translate(-50%, -50%)',
        // width: 480,
        zIndex: 2000,
        borderRadius: 0,
      }}
      {...other}
    >
      <DialogTitle id="confirmation-dialog-title">
        <Grid container justifyContent="center">
          <Typography
            align="center"
            display="inline"
            style={{
              fontSize: 18,
              letterSpacing: '0.1em',
              maxWidth: 310,
            }}
          >
            {!isFinish
              ? 'CHANGE PRODUCT STATUS'
              : `THE FOLLOWING PRODUCTS ARE NOW`}{' '}
            {isFinish && (
              <Typography
                display="inline"
                style={{ fontWeight: 'bold', textTransform: 'uppercase' }}
              >
                {newStatus}
              </Typography>
            )}
          </Typography>
        </Grid>
        <Grid container justifyContent="center">
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: 16,
              borderBottom: '0.5px solid #000',
              width: 340,
              height: 2,
            }}
          />
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          justifyContent="center"
          style={{ width: 340, borderRadius: 0 }}
        >
          {!isFinish && (
            <Grid>
              <Typography
                align="center"
                style={{
                  fontSize: 18,
                  fontWeight: 'bold',
                  lineHeight: '21px',
                  height: 30,
                  marginBottom: 4,
                  marginTop: -8,
                }}
              >
                {products.length} product(s) selected
              </Typography>
            </Grid>
          )}
          <Grid
            container
            justifyContent="center"
            style={{
              height: 292,
              overflowY: 'scroll',
              width: 340,
              alignContent: 'flex-start',
            }}
          >
            {products.map((product, index) => (
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                style={{ height: 54 }}
              >
                <img
                  alt="product_image"
                  src={product?.mainly_image}
                  style={{ width: 34, height: 49 }}
                />
                <span style={{ fontSize: 14 }}>{product?.style_code}</span>
                <span
                  style={{
                    width: 144,
                    fontSize: 14,
                  }}
                >
                  {product?.description.length < 30
                    ? product?.description
                    : `${product?.description.substring(0, 25)}...`}
                </span>
                {!isFinish && (
                  <span onClick={() => removeProduct(index)}>X</span>
                )}
              </Grid>
            ))}
          </Grid>

          <div
            style={{
              borderTop: '0.5px solid #000',
              width: 340,
              height: 2,
              margin: '19px 0px',
            }}
          />
        </Grid>
        {!isFinish ? (
          !hasDifferentStatus ? (
            <Grid
              container
              justifyContent="space-between"
              style={{ width: 340, marginBottom: 38 }}
            >
              <Grid style={{ width: 120 }}>
                <Typography
                  style={{
                    fontSize: 12,
                    color: 'rgba(91,91,91, 1)',
                    marginBottom: 6,
                  }}
                >
                  Current Status
                </Typography>
                <OptionStatus isCurrent option={products[0]?.status} />
              </Grid>
              <Grid style={{ width: 220 }}>
                <Typography
                  style={{
                    fontSize: 12,
                    color: 'rgba(91,91,91, 1)',
                    marginBottom: 6,
                  }}
                >
                  New Status
                </Typography>
                <Grid container alignItems="center">
                  {products[0]?.status === 'Available' ? (
                    <>
                      <div style={{ marginRight: 24 }}>
                        <OptionStatus option="Sold Out" />
                      </div>
                      <div>
                        <OptionStatus option="Cancelled" />
                      </div>
                    </>
                  ) : products[0]?.status === 'Sold Out' ? (
                    <>
                      <div style={{ marginRight: 24 }}>
                        <OptionStatus option="Available" />
                      </div>
                      <div>
                        <OptionStatus option="Cancelled" />
                      </div>
                    </>
                  ) : (
                    <>
                      <div style={{ marginRight: 24 }}>
                        <OptionStatus option="Available" />
                      </div>
                      <div>
                        <OptionStatus option="Sold Out" />
                      </div>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              justifyContent="space-between"
              style={{ width: 340, marginBottom: 38 }}
            >
              <Grid style={{ width: 340 }}>
                <Typography
                  style={{
                    fontSize: 12,
                    color: 'rgba(91,91,91, 1)',
                    marginBottom: 6,
                  }}
                >
                  New Status
                </Typography>
                <Grid container alignItems="center">
                  <div style={{ marginRight: 24 }}>
                    <OptionStatus option="Available" />
                  </div>
                  <div style={{ marginRight: 24 }}>
                    <OptionStatus option="Sold Out" />
                  </div>
                  <div>
                    <OptionStatus option="Cancelled" />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          )
        ) : null}

        <Grid container justifyContent="center" style={{ paddingBottom: 30 }}>
          {!isFinish ? (
            <>
              <Grid
                onClick={() => {
                  onClose();
                  setIsFinish(false);
                  setNewStatus('');
                }}
                style={{ marginRight: 13 }}
              >
                <Button
                  text="Cancel"
                  textColor="#000"
                  color="#fff"
                  width={128}
                  height={40}
                  borderColor="1px solid #000"
                  justifyContent="center"
                />
              </Grid>
              <Grid style={{ marginLeft: 13 }}>
                <Button
                  text="Change"
                  textColor="#fff"
                  color="#000"
                  width={128}
                  height={40}
                  borderColor="1px solid #000"
                  justifyContent="center"
                  onClick={handleUpdate}
                />
              </Grid>
            </>
          ) : (
            <Button
              text="Close"
              textColor="#fff"
              color="#000"
              width={128}
              height={40}
              borderColor="1px solid #000"
              justifyContent="center"
              onClick={() => {
                onClose();
                setIsFinish(false);
                setLoading(true);
                cleanSelected();
                setNewStatus('');
              }}
            />
          )}
        </Grid>
      </DialogContent>
    </StyledDialog>
  );
}
