import { useCallback, useContext } from 'react';

import { EventsContext } from '../contexts/events';

export default function useEventDispatcher() {
  const [events] = useContext(EventsContext);

  const dispatchEvent = useCallback(
    (event, payload) => {
      if (event in events) {
        events[event].forEach(callback => callback(payload));
      }
    },
    [events]
  );

  return dispatchEvent;
}
