import styled from 'styled-components';

const SRButton = styled.div`
  width: 189px;
  height: 53px;
  background-color: ${props => props.color};
  border-radius: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s;

  border: 0px;
  &:hover {
    background-color: ${props => props.hover};
  }

  span {
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.08em;
    text-transform: uppercase;
  }
`;

export default SRButton;
