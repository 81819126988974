import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { BsGrid3X2GapFill } from 'react-icons/bs';
import { dictColor, events } from '../../consts';
import { useEventDispatcher } from '../../hooks';

function LeftBar({
  selectedColor,
  setSelectedColor,
  setGridSize,
  gridSize = 'Large',
  colorOptions,
}) {
  const dispatchEvent = useEventDispatcher();
  const gridOptions = ['Small', 'Medium', 'Large'];
  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      style={{
        left: 143,
        top: 218,
        position: 'fixed',
        width: 180,
        paddingRight: 25,
      }}
    >
      <Typography
        onClick={() => setSelectedColor({ value: 'none', label: null })}
        variant="h6"
        style={{
          width: '100%',
          fontWeight: selectedColor.value === 'none' ? 'bold' : '',

          marginBottom: 10,
          padding: '4px 6px',
          border:
            selectedColor.value === 'none'
              ? '1px solid #2a2a2a'
              : '1px solid transparent',
          cursor: 'pointer',
          fontSize: 10,
          color: '#000000',
        }}
      >
        ALL
      </Typography>

      <Grid
        container
        onClick={() => {
          dispatchEvent(events.gridColors);
        }}
        style={{
          overflow: 'auto',
          marginBottom: 35,
        }}
      >
        {colorOptions.map(color => (
          <Grid
            onClick={() => setSelectedColor(color)}
            container
            justifyContent="space-between"
            style={{
              // backgroundColor: 'yellow',
              width: 160,
              border:
                selectedColor.value === color.value
                  ? '1px solid #000'
                  : '1px solid transparent',
              cursor: 'pointer',
              // backgroundColor: 'red',
              // margin: '4px',
              // height: '100%',
              // backgroundColor: selectedColor.value,
              padding: '4px 6px',
              // borderBottom: '1px solid black',
            }}
          >
            <Typography
              style={{
                fontWeight: selectedColor.value === color.value ? 'bold' : '',
                fontSize: 10,
                color: 'black',
                lineHeight: '10px',
              }}
            >
              {color.label}
            </Typography>
            <div
              style={{
                width: 12,
                height: 12,
                backgroundColor: dictColor[color.label],
              }}
            />
          </Grid>
        ))}
      </Grid>
      <Grid container>
        {gridOptions.map(option => (
          <Typography
            onClick={() => {
              dispatchEvent(events.gridSize, {
                location: 'Prints',
              });
              setGridSize(option);
            }}
            variant="h6"
            style={{
              fontWeight: option === gridSize ? 'bold' : '',

              width: '100%',
              textAlign: 'center',
              padding: '4px 12px',
              border:
                option === gridSize
                  ? '1px solid #2a2a2a'
                  : '1px solid transparent',
              cursor: 'pointer',
              lineHeight: '10px',
              fontSize: 10,
              color: '#000000',
            }}
          >
            {option}
          </Typography>
          // </Grid>
        ))}
      </Grid>
      {/* SELECTED OPTION */}

      {/* GRID SIZE CONTAINER */}
      <Grid
        // container
        justifyContent="center"
        alignItems="center"
        style={{
          transform: 'rotate(-90deg)',
          position: 'absolute',
          top: 250,
          left: -100,
          // backgroundColor: 'red',
        }}
      >
        <Grid
          container
          alignItems="flex-end"
          justifyContent="flex-start"
          // style={{ height: 30 }}
        >
          <Typography
            variant="h6"
            style={{
              color: '#000',
              fontSize: 12,
              width: 60,
              // backgroundColor: 'green',
            }}
          >
            Grid size
          </Typography>
          <BsGrid3X2GapFill
            style={{
              fontSize: 12,
              color: 'black',
              // marginLeft: 4,
            }}
          />
          <Typography
            variant="h6"
            style={{ color: '#000', fontSize: 12, marginLeft: 60 }}
          >
            Color
          </Typography>
        </Grid>
      </Grid>
    </Grid>
    // </Grid>
  );
}

export default LeftBar;
