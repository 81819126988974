import React, { useEffect } from 'react';
import { events } from '../consts';
import useEventListener from '../hooks/useEventListener';

const {
  startNotes,
  submitOrder,
  addToCollection,
  gridColors,
  gridSize,
  next,
} = events;

window.dataLayer = window.dataLayer || [];

const { dataLayer } = window;

const dataLayerEvents = [
  {
    applicationEvent: startNotes,
    callback: () => {
      dataLayer.push({
        event: 'start-notes',
        category: 'Button',
        action: 'Start Notes Button',
      });
    },
  },
  {
    applicationEvent: submitOrder,
    callback: () => {
      dataLayer.push({
        event: 'submit-order',
        category: 'Button',
        action: 'Submit Order Button',
      });
    },
  },
  {
    applicationEvent: addToCollection,
    callback: ({ click, location }) => {
      dataLayer.push({
        event: 'add-to-collection',
        category: 'Button',
        action: ` ${
          click ? 'Added' : 'Removed'
        } Collection Button (${location})`,
      });
    },
  },
  {
    applicationEvent: gridColors,
    callback: () => {
      dataLayer.push({
        event: 'grid-colors',
        category: 'Button',
        action: 'Grid Collor Button',
      });
    },
  },
  {
    applicationEvent: gridSize,
    callback: ({ location }) => {
      dataLayer.push({
        event: 'grid-size',
        category: 'Button',
        action: `Grid Size Button (${location})`,
      });
    },
  },
  {
    applicationEvent: next,
    callback: () => {
      dataLayer.push({
        event: 'next',
        category: 'Button',
        action: 'Next Shipping Button',
      });
    },
  },
];

export default function GoogleTagManagerEvents() {
  const { subscribe, unsubscribe } = useEventListener();

  useEffect(() => {
    dataLayerEvents.forEach(({ applicationEvent, callback }) => {
      subscribe(applicationEvent, callback);
    });
    return () => {
      dataLayerEvents.forEach(({ applicationEvent, callback }) => {
        unsubscribe(applicationEvent, callback);
      });
    };
  }, [subscribe, unsubscribe]);

  return <></>;
}
