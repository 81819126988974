export const ROUTES = {
  LOGIN: '/',
  HOME: '/home',
};

export const country_list = [
  { name: 'Afghanistan', code: 'AF' },
  { name: 'Åland Islands', code: 'AX' },
  { name: 'Albania', code: 'AL' },
  { name: 'Algeria', code: 'DZ' },
  { name: 'American Samoa', code: 'AS' },
  { name: 'Andorra', code: 'AD' },
  { name: 'Angola', code: 'AO' },
  { name: 'Anguilla', code: 'AI' },
  { name: 'Antarctica', code: 'AQ' },
  { name: 'Antigua and Barbuda', code: 'AG' },
  { name: 'Argentina', code: 'AR' },
  { name: 'Armenia', code: 'AM' },
  { name: 'Aruba', code: 'AW' },
  { name: 'Australia', code: 'AU' },
  { name: 'Austria', code: 'AT' },
  { name: 'Azerbaijan', code: 'AZ' },
  { name: 'Bahamas', code: 'BS' },
  { name: 'Bahrain', code: 'BH' },
  { name: 'Bangladesh', code: 'BD' },
  { name: 'Barbados', code: 'BB' },
  { name: 'Belarus', code: 'BY' },
  { name: 'Belgium', code: 'BE' },
  { name: 'Belize', code: 'BZ' },
  { name: 'Benin', code: 'BJ' },
  { name: 'Bermuda', code: 'BM' },
  { name: 'Bhutan', code: 'BT' },
  { name: 'Bolivia', code: 'BO' },
  { name: 'Bosnia and Herzegovina', code: 'BA' },
  { name: 'Botswana', code: 'BW' },
  { name: 'Bouvet Island', code: 'BV' },
  { name: 'Brazil', code: 'BR' },
  { name: 'British Indian Ocean Territory', code: 'IO' },
  { name: 'Brunei Darussalam', code: 'BN' },
  { name: 'Bulgaria', code: 'BG' },
  { name: 'Burkina Faso', code: 'BF' },
  { name: 'Burundi', code: 'BI' },
  { name: 'Cambodia', code: 'KH' },
  { name: 'Cameroon', code: 'CM' },
  { name: 'Canada', code: 'CA' },
  { name: 'Cape Verde', code: 'CV' },
  { name: 'Cayman Islands', code: 'KY' },
  { name: 'Central African Republic', code: 'CF' },
  { name: 'Chad', code: 'TD' },
  { name: 'Chile', code: 'CL' },
  { name: 'China', code: 'CN' },
  { name: 'Christmas Island', code: 'CX' },
  { name: 'Cocos (Keeling) Islands', code: 'CC' },
  { name: 'Colombia', code: 'CO' },
  { name: 'Comoros', code: 'KM' },
  { name: 'Congo', code: 'CG' },
  { name: 'Congo, The Democratic Republic of the', code: 'CD' },
  { name: 'Cook Islands', code: 'CK' },
  { name: 'Costa Rica', code: 'CR' },
  { name: "Cote D'Ivoire", code: 'CI' },
  { name: 'Croatia', code: 'HR' },
  { name: 'Cuba', code: 'CU' },
  { name: 'Cyprus', code: 'CY' },
  { name: 'Czech Republic', code: 'CZ' },
  { name: 'Denmark', code: 'DK' },
  { name: 'Djibouti', code: 'DJ' },
  { name: 'Dominica', code: 'DM' },
  { name: 'Dominican Republic', code: 'DO' },
  { name: 'Ecuador', code: 'EC' },
  { name: 'Egypt', code: 'EG' },
  { name: 'El Salvador', code: 'SV' },
  { name: 'Equatorial Guinea', code: 'GQ' },
  { name: 'Eritrea', code: 'ER' },
  { name: 'Estonia', code: 'EE' },
  { name: 'Ethiopia', code: 'ET' },
  { name: 'Falkland Islands (Malvinas)', code: 'FK' },
  { name: 'Faroe Islands', code: 'FO' },
  { name: 'Fiji', code: 'FJ' },
  { name: 'Finland', code: 'FI' },
  { name: 'France', code: 'FR' },
  { name: 'French Guiana', code: 'GF' },
  { name: 'French Polynesia', code: 'PF' },
  { name: 'French Southern Territories', code: 'TF' },
  { name: 'Gabon', code: 'GA' },
  { name: 'Gambia', code: 'GM' },
  { name: 'Georgia', code: 'GE' },
  { name: 'Germany', code: 'DE' },
  { name: 'Ghana', code: 'GH' },
  { name: 'Gibraltar', code: 'GI' },
  { name: 'Greece', code: 'GR' },
  { name: 'Greenland', code: 'GL' },
  { name: 'Grenada', code: 'GD' },
  { name: 'Guadeloupe', code: 'GP' },
  { name: 'Guam', code: 'GU' },
  { name: 'Guatemala', code: 'GT' },
  { name: 'Guernsey', code: 'GG' },
  { name: 'Guinea', code: 'GN' },
  { name: 'Guinea-Bissau', code: 'GW' },
  { name: 'Guyana', code: 'GY' },
  { name: 'Haiti', code: 'HT' },
  { name: 'Heard Island and Mcdonald Islands', code: 'HM' },
  { name: 'Holy See (Vatican City State)', code: 'VA' },
  { name: 'Honduras', code: 'HN' },
  { name: 'Hong Kong', code: 'HK' },
  { name: 'Hungary', code: 'HU' },
  { name: 'Iceland', code: 'IS' },
  { name: 'India', code: 'IN' },
  { name: 'Indonesia', code: 'ID' },
  { name: 'Iran, Islamic Republic Of', code: 'IR' },
  { name: 'Iraq', code: 'IQ' },
  { name: 'Ireland', code: 'IE' },
  { name: 'Isle of Man', code: 'IM' },
  { name: 'Israel', code: 'IL' },
  { name: 'Italy', code: 'IT' },
  { name: 'Jamaica', code: 'JM' },
  { name: 'Japan', code: 'JP' },
  { name: 'Jersey', code: 'JE' },
  { name: 'Jordan', code: 'JO' },
  { name: 'Kazakhstan', code: 'KZ' },
  { name: 'Kenya', code: 'KE' },
  { name: 'Kiribati', code: 'KI' },
  { name: "Korea, Democratic People'S Republic of", code: 'KP' },
  { name: 'Korea, Republic of', code: 'KR' },
  { name: 'Kuwait', code: 'KW' },
  { name: 'Kyrgyzstan', code: 'KG' },
  { name: "Lao People'S Democratic Republic", code: 'LA' },
  { name: 'Latvia', code: 'LV' },
  { name: 'Lebanon', code: 'LB' },
  { name: 'Lesotho', code: 'LS' },
  { name: 'Liberia', code: 'LR' },
  { name: 'Libyan Arab Jamahiriya', code: 'LY' },
  { name: 'Liechtenstein', code: 'LI' },
  { name: 'Lithuania', code: 'LT' },
  { name: 'Luxembourg', code: 'LU' },
  { name: 'Macao', code: 'MO' },
  { name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
  { name: 'Madagascar', code: 'MG' },
  { name: 'Malawi', code: 'MW' },
  { name: 'Malaysia', code: 'MY' },
  { name: 'Maldives', code: 'MV' },
  { name: 'Mali', code: 'ML' },
  { name: 'Malta', code: 'MT' },
  { name: 'Marshall Islands', code: 'MH' },
  { name: 'Martinique', code: 'MQ' },
  { name: 'Mauritania', code: 'MR' },
  { name: 'Mauritius', code: 'MU' },
  { name: 'Mayotte', code: 'YT' },
  { name: 'Mexico', code: 'MX' },
  { name: 'Micronesia, Federated States of', code: 'FM' },
  { name: 'Moldova, Republic of', code: 'MD' },
  { name: 'Monaco', code: 'MC' },
  { name: 'Mongolia', code: 'MN' },
  { name: 'Montserrat', code: 'MS' },
  { name: 'Morocco', code: 'MA' },
  { name: 'Mozambique', code: 'MZ' },
  { name: 'Myanmar', code: 'MM' },
  { name: 'Namibia', code: 'NA' },
  { name: 'Nauru', code: 'NR' },
  { name: 'Nepal', code: 'NP' },
  { name: 'Netherlands', code: 'NL' },
  { name: 'Netherlands Antilles', code: 'AN' },
  { name: 'New Caledonia', code: 'NC' },
  { name: 'New Zealand', code: 'NZ' },
  { name: 'Nicaragua', code: 'NI' },
  { name: 'Niger', code: 'NE' },
  { name: 'Nigeria', code: 'NG' },
  { name: 'Niue', code: 'NU' },
  { name: 'Norfolk Island', code: 'NF' },
  { name: 'Northern Mariana Islands', code: 'MP' },
  { name: 'Norway', code: 'NO' },
  { name: 'Oman', code: 'OM' },
  { name: 'Pakistan', code: 'PK' },
  { name: 'Palau', code: 'PW' },
  { name: 'Palestinian Territory, Occupied', code: 'PS' },
  { name: 'Panama', code: 'PA' },
  { name: 'Papua New Guinea', code: 'PG' },
  { name: 'Paraguay', code: 'PY' },
  { name: 'Peru', code: 'PE' },
  { name: 'Philippines', code: 'PH' },
  { name: 'Pitcairn', code: 'PN' },
  { name: 'Poland', code: 'PL' },
  { name: 'Portugal', code: 'PT' },
  { name: 'Puerto Rico', code: 'PR' },
  { name: 'Qatar', code: 'QA' },
  { name: 'Reunion', code: 'RE' },
  { name: 'Romania', code: 'RO' },
  { name: 'Russian Federation', code: 'RU' },
  { name: 'RWANDA', code: 'RW' },
  { name: 'Saint Helena', code: 'SH' },
  { name: 'Saint Kitts and Nevis', code: 'KN' },
  { name: 'Saint Lucia', code: 'LC' },
  { name: 'Saint Pierre and Miquelon', code: 'PM' },
  { name: 'Saint Vincent and the Grenadines', code: 'VC' },
  { name: 'Samoa', code: 'WS' },
  { name: 'San Marino', code: 'SM' },
  { name: 'Sao Tome and Principe', code: 'ST' },
  { name: 'Saudi Arabia', code: 'SA' },
  { name: 'Senegal', code: 'SN' },
  { name: 'Serbia and Montenegro', code: 'CS' },
  { name: 'Seychelles', code: 'SC' },
  { name: 'Sierra Leone', code: 'SL' },
  { name: 'Singapore', code: 'SG' },
  { name: 'Slovakia', code: 'SK' },
  { name: 'Slovenia', code: 'SI' },
  { name: 'Solomon Islands', code: 'SB' },
  { name: 'Somalia', code: 'SO' },
  { name: 'South Africa', code: 'ZA' },
  { name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
  { name: 'Spain', code: 'ES' },
  { name: 'Sri Lanka', code: 'LK' },
  { name: 'Sudan', code: 'SD' },
  { name: 'Suriname', code: 'SR' },
  { name: 'Svalbard and Jan Mayen', code: 'SJ' },
  { name: 'Swaziland', code: 'SZ' },
  { name: 'Sweden', code: 'SE' },
  { name: 'Switzerland', code: 'CH' },
  { name: 'Syrian Arab Republic', code: 'SY' },
  { name: 'Taiwan, Province of China', code: 'TW' },
  { name: 'Tajikistan', code: 'TJ' },
  { name: 'Tanzania, United Republic of', code: 'TZ' },
  { name: 'Thailand', code: 'TH' },
  { name: 'Timor-Leste', code: 'TL' },
  { name: 'Togo', code: 'TG' },
  { name: 'Tokelau', code: 'TK' },
  { name: 'Tonga', code: 'TO' },
  { name: 'Trinidad and Tobago', code: 'TT' },
  { name: 'Tunisia', code: 'TN' },
  { name: 'Turkey', code: 'TR' },
  { name: 'Turkmenistan', code: 'TM' },
  { name: 'Turks and Caicos Islands', code: 'TC' },
  { name: 'Tuvalu', code: 'TV' },
  { name: 'Uganda', code: 'UG' },
  { name: 'Ukraine', code: 'UA' },
  { name: 'United Arab Emirates', code: 'AE' },
  { name: 'United Kingdom', code: 'GB' },
  { name: 'United States', code: 'US' },
  { name: 'United States Minor Outlying Islands', code: 'UM' },
  { name: 'Uruguay', code: 'UY' },
  { name: 'Uzbekistan', code: 'UZ' },
  { name: 'Vanuatu', code: 'VU' },
  { name: 'Venezuela', code: 'VE' },
  { name: 'Viet Nam', code: 'VN' },
  { name: 'Virgin Islands, British', code: 'VG' },
  { name: 'Virgin Islands, U.S.', code: 'VI' },
  { name: 'Wallis and Futuna', code: 'WF' },
  { name: 'Western Sahara', code: 'EH' },
  { name: 'Yemen', code: 'YE' },
  { name: 'Zambia', code: 'ZM' },
  { name: 'Zimbabwe', code: 'ZW' },
];

export const SHOP_NOW = [
  {
    url:
      'https://storage.googleapis.com/show-room-images/home_HS22/02_logada/desk/02_readytowear.jpg',
    title: 'ready to wear',
    id: 1,
  },
  {
    url:
      'https://storage.googleapis.com/show-room-images/home_HS22/02_logada/desk/02_swimwear.jpg',
    title: 'swimwear',
    id: 2,
  },
];

export const dictColor = {
  MIX: '#D1047D',
  BLACK: '#0C0A08',
  BEIGE: '#DFB08E',
  BLUE: '#0584C4',
  'BLUE + GREEN': '#0584C4',
  'GREEN+YELLOW+RED': '#2D6D4A',
  GREEN: '#2D6D4A',
  ARMY: '#2D6D4A',
  BRONZE: '#CD7F32',
  'CHEVRON FOREST BLACK': '#000',
  'CHEVRON FOREST ORANGE': '#FF8A00',
  'CHEVRON TOUCANS': '#86F151',
  'CHEVRON TOUCANS BLUE': '#0584C4',
  'COLORFUL FLOWERS': '#C81904',
  RAINBOW: '#FFFF00',
  'RAINBOW ISLAND': '#9400D3',
  'RAINBOW OXEN': '#9400D3',
  'RAINBOW OXEN BLACK': '#000',
  'SHINY TOUCANS TEAL': '#86F151',
  'BLUSH PINK': '#FAA19B',
  BLUSH: '#FAA19B',
  CARAMAEL: '#C07F45',
  'BRIGHT GREEN ': '#2D6D4A',
  'BRIGHT YELLOW': '#FAED27',
  BROWN: '#654321',
  'BEANIE BLACK MULTI': '#0C0A08',
  'BLACK + OFF-WHITE': '#000',
  'LIGHT BLUE': '#ADD8E6',
  'LIGHT DENIM': '#BAC9D8',
  'MULTI NEON': '#00FFFF',
  'NEON ORANGE': '#FF6700',
  'NEON PINK': '#FF00FF',
  'RED MULTI': '#C81904',
  OCHER: '#C81904',
  'NEON YELLOW': '#FAED27',
  SUN: '#FAED27',
  'CACHOS DE BANANA': '#FAED27',
  'GRAPHIC TOUCANS': '#86F151',
  'MIXED SCARVES': '#FEBC41',
  'LEOPARD CHEVRON FOREST BLACK': '#000',
  'LEOPARD HEARTS BLACK': '#000',
  'LEOPARD MUSHROOM BLACK': '#000',
  'LEOPARDS HEARTS BLACK': '#000',
  'MACAW FOREST BLACK': '#000',
  MUSTARD: '#e1ad01',
  'KIWI GREEN': '#86F151',
  'SUNSET TRAPESTY BEIGE': '#DFB08E',
  'PINEAPPLE FLOWERS NAVY BLUE': '#012F53',
  'DARK BLUE': '#00008B',
  SAND: '#C2B280',
  KHAKI: '#C2B280',
  'DARK FLOWER': '#000',
  'COLORFUL STARS': '#FEBC41',
  TANGERINE: '#F28500',
  'CERRADO SKY BLACK': '#000',
  'GREEN + LIGHT PINK': '#2D6D4A',
  'LIGHT PINK': '#FFBFB7',
  LILAC: '#8D5B8C',
  'LILAC AND ORANGE': '#8D5B8C',
  MULTICOLOR: '#4F3068',
  'MULTI ': '#4F3068',
  MULTI: '#4F3068',
  'NAVY BLUE': '#012F53',
  'NAVY BLUE + OFF-WHITE': '#012F53',
  'ORANGE ': '#FF8A00',
  NAVY: '#012F53',
  WHITE: '#FFF',
  'OFF WHITE': '#FEFBEC',
  'OFF-WHITE  ': '#FEFBEC',
  'OFF-WHITE AND RED': '#C81904',
  'OFF-WHITE': '#FEFBEC',
  'OFF-WHITE + BLUE': '#0584C4',
  'RED + ORANGE | MULTI': '#4F3068',
  ORANGE: '#FF8A00',
  'DEEP ORANGE': '#DD6E0F',
  'DEEP BLUE AND GREEN': '#056608',
  'BLACK AND WHITE': '#0C0A08',
  TEAL: '#012F53',
  'TEAL BLUE': '#012F53',
  'DENIM BLUE': '#53A8D2',
  DENIM: '#53A8D2',
  'WHITE DENIM': '#53A8D2',
  'BLLUE DENIM': '#53A8D2',
  'DENIM + CROCHET': '#53A8D2',
  'CROCHET PATCHWORK': '#53A8D2',
  PATCHWORK: '#53A8D2',
  'MYSTIC NIGHT': ' #0c1445',
  'NIGHT SKY BLACK': '#0c1430',
  'MYSTIC SCARF BLACK': '#000',
  NATURAL: '#E5D3BF',
  'WHITE MULTICOLOR': '#4F3068',
  'WILD SNAKES PINK': '#EAABD4',
  'PINK/YELLOW': '#EAABD4',
  'AINIKA TAPESTRY BLUE': '#0584C4',
  'ROMANTIC GARDEN': '#FAA19B',
  'LEAF GREEN': '#2D6D4A',
  'OFF WHITE + BLUE': '#0584C4',
  'BLUE AND GREEN': '#0584C4',
  'GREEN AND BLUE': '#2D6D4A',
  'BLUE AND PINK': '#EAABD4',
  'OFF WHITE + GREEN': '#2D6D4A',
  'OFF-WHITE + GREEN': '#2D6D4A',
  'MUSK GREEN': '#2D6D4A',
  RUST: '#FA8072',
  'FLORAL DREAM': '#FFC0CB',
  OCHRE: '#CC7722',
  'Black Bananica': '#000',
  BORDEAUX: '#4C0013',
  Antwerp: '#CF8846',
  CAMEL: '#CF8846',
  'VINTAGE FOREST SAND': '#ddbea9',
  SCARF: '#000',
  BURGUNDY: '#8D5B8C',
  CORAL: '#8D5B8C',
  'DARK PINK': '#4F3068',
  'DARK SAND': '#654321',
  'DARK SAND ': '#654321',
  'BURGUNDY + LIGHT BLUE': '#8D5B8C',
  'BURGUNDY + BLUE + LIGHT BLUE': '#8D5B8C',
  'FOLK PARTY': '#EAABD4',
  'FOLK PARTY LIGHT PINK': '#EAABD4',
  'FOREST ORANGE': '#FF8A00',
  'GOLD &SILVER': '#ffdf00',
  GOLDEN: '#ffdf00',
  'GRAPHIC SCARVES MULTICOLOR\n': '#4F3068',
  SILVER: '#DBE4EB',
  'SUNSET FOREST BROWN': '#654321',
  'SUNSET FOREST BROWN ': '#654321',
  'TOUCANS BUNCH': '#2D6D4A',
  'TROPICAL PARTY OFF WHITE': '#6c7a86',
  PINK: '#EAABD4',
  'FLOWERS GARDEN PINK': '#EAABD4',
  'PINK AND RED': '#EAABD4',
  RED: '#C81904',
  'WHITE MULTI': '#289168',
  'WHITE + BLUE': '#0584C4',
  'SKY BLUE': '#0584C4',
  'WHITE + MULTI': '#289168',
  YELLOW: '#FEBC41',
  CARAMEL: '#CF8846',
  'CARAMEL + PINK': '#CF8846',
  'CARAMEL + BLUE': '#CF8846',
  'WHITE AND BLUE': '#0584C4',
  'MEDIUM BLUE ': '#0584C4',
  'OFF-WHITE GUIPIRE': '#FEFBEC',
  'RED AND BLUE': '#C81904',
  'RED AND PINK': '#C81904',
  PURPLE: '#4F3068',
  'LIME GREEN': '#2d6d4a',
  LIME: '#2d6d4a',
  'LIME/PINK': '#2d6d4a',
  MINT: '#aaf0d1',
  PEACH: '#FFCBA4',
};

export const dictIdDelivery = {
  1: '5f34a6b5ed5ff21fbc9939c6',
  2: '5f34a7b6bc82e351349c76bf',
  3: '5f34a7c2bc82e351349c76c0',
  4: '63da89f9b2c36dc7bd9cf266',
};

export const shippingDate = {
  '5f34a6b5ed5ff21fbc9939c6': 'Shipping 1-30 APR',
  '5f34a7b6bc82e351349c76bf': 'Shipping 1-31 MAY',
  '5f34a7c2bc82e351349c76c0': 'Shipping 1-30 JUN',
};
export const dictIdDeliveryFormatted = {
  '5f34a6b5ed5ff21fbc9939c6': 'MAY',
  '5f34a7b6bc82e351349c76bf': 'JUN',
  '5f34a7c2bc82e351349c76c0': 'JUL',
};

export const dictIdDeliveryToNumber = {
  '5f34a6b5ed5ff21fbc9939c6': 1,
  '5f34a7b6bc82e351349c76bf': 2,
  '5f34a7c2bc82e351349c76c0': 3,
};

export const emailsAllowed = [
  'brittany.gerny@sundance.net',
  'nelson.camargo@farmrio.com.br',
  'thalita.cotrofe@farmrio.com.br',
  'harriet.jayne.graham@zalando.de',
];

export const events = {
  startNotes: 'start-notes',
  submitOrder: 'submit-order',
  addToCollection: 'add-to-collection',
  gridColors: 'grid-colors',
  gridSize: 'grid-size',
  next: 'next',
};
