/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';

import { Grid, IconButton } from '@material-ui/core';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import Card from '../CardDND';
import BoardContext from './context';

export default function Board({ data, handleSaveProducts }) {
  const [lists, setLists] = useState(data);
  const [factor, setFactor] = useState(1);

  const handleFactor = num => {
    if (factor + num > 1) return;
    if (factor + num <= 0.2) return;
    setFactor(factor + num);
  };

  function move(from, to) {
    const newArr = lists.map((item, index) =>
      index !== from && index !== to
        ? item
        : index === from
        ? lists[to]
        : lists[from]
    );
    setLists(newArr);
  }

  return (
    <>
      <Grid style={{ position: 'fixed', right: 50, bottom: 20 }}>
        <Grid container>
          <IconButton onClick={() => handleFactor(-0.2)}>
            <ZoomOutIcon />
          </IconButton>
          <IconButton onClick={() => handleFactor(+0.2)}>
            <ZoomInIcon />
          </IconButton>
        </Grid>
        <button type="submit" onClick={() => handleSaveProducts(lists)}>
          Save new order
        </button>
      </Grid>

      <BoardContext.Provider value={{ lists, move }}>
        {lists.map((card, index) => (
          <Card key={card.id} index={index} data={card} factor={factor} />
        ))}
      </BoardContext.Provider>
    </>
  );
}
