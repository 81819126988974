import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import ButtonFarm from '../../components/Button';

const useStyles = makeStyles({
  root: {
    '& .MuiDialog-scrollPaper': {
      alignItems: 'center',
    },

    '& .MuiDialogTitle-root': {
      padding: '16px 21px',
    },
  },

  grid: {
    display: 'flex',
  },
});

export default function DeleteAddressModal(props) {
  const classes = useStyles();
  const { onClose, open, addressName, handleDeleteAddress } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      className={classes.root}
    >
      <Grid
        style={{
          padding: 25,
        }}
      >
        <Typography>Are you sure you want to remove </Typography>
        <Typography>
          <span style={{ color: 'red' }}>{addressName}</span> from your address
          list?
        </Typography>

        <Grid className={classes.grid} style={{ marginTop: 25 }}>
          <ButtonFarm
            text="Cancel"
            textColor="#000"
            color="#fff"
            width={128}
            height={40}
            borderColor="1px solid #000"
            justifyContent="center"
            onClick={handleClose}
          />

          <Grid style={{ marginLeft: 10 }}>
            <ButtonFarm
              text="Confirm"
              width={128}
              height={40}
              textColor="#fff"
              color="#000"
              borderColor="1px solid #ebebeb"
              justifyContent="center"
              onClick={handleDeleteAddress}
            />
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
}
