import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ButtonFarm from '../../components/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles({
  root: {
    '& .MuiDialog-scrollPaper': {
      alignItems: 'center',
    },

    '& .MuiDialogTitle-root': {
      padding: '16px 21px',
    },
  },

  span: {
    height: 15,
    width: 15,
    borderRadius: '50%',
  },

  grid: {
    display: 'flex',
  },

  bold: {
    fontWeight: 'bold',
  },

  modalText: {
    textAlign: 'center',
    fontSize: 14,
  },
  fontSize12: {
    fontSize: 12,
  },
  fitContent: {
    maxWidth: 'fit-content',
  },
  marginTop30: {
    marginTop: 30,
  },
  subtitleGray: {
    color: '#616161',
    fontSize: 14,
  },
  noBorderRadius: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 'unset',
    },
  },
});
const roles = [
  {
    value: 'Buyer',
    label: 'Buyer',
  },
  {
    value: 'Assistant Buyer',
    label: 'Assistant Buyer',
  },
  {
    value: 'Merchandiser',
    label: 'Merchandiser',
  },
  {
    value: 'Store Manager',
    label: 'Store Manager',
  },
  {
    value: 'Owner',
    label: 'Owner',
  },
];

export default function AddUserToAccount(props) {
  const classes = useStyles();
  const { onClose, open, addNewUserToAccount } = props;
  const [newUserAttributes, setNewUserAttributes] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    role: 'Buyer',
    message: '',
  });

  const handleClose = () => {
    onClose();
  };

  const handleChange = e => {
    const name = e.target.name;
    let value = e.target.value;
    setNewUserAttributes({ ...newUserAttributes, [name]: value });
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      className={classes.root}
    >
      <Grid style={{ width: '80%', margin: '0px auto 20px ', padding: 12 }}>
        <DialogTitle id="simple-dialog-title">
          <Typography
            align="center"
            style={{
              fontSize: 18,
              letterSpacing: '0.1em',
              justifyContent: 'center',
            }}
          >
            ADD NEW USER TO ACCOUNT
          </Typography>
        </DialogTitle>
        <hr />
        <Grid className={classes.fitContent} style={{ marginTop: 24 }}>
          <Typography className={classes.fontSize12}>
            Additional users will need approval by the FARM RIO team, so please
            provide all the information requested below.
          </Typography>
        </Grid>

        <Grid className={classes.fitContent}>
          <TextField
            label="First Name"
            name="firstName"
            type="text"
            fullWidth
            inputProps={{ 'aria-label': 'First Name' }}
            style={{ marginTop: 15 }}
            value={newUserAttributes.firstName}
            onChange={handleChange}
          />
          <TextField
            label="Last Name"
            name="lastName"
            type="text"
            fullWidth
            className={classes.marginTop30}
            inputProps={{ 'aria-label': 'Last Name' }}
            value={newUserAttributes.lastName}
            onChange={handleChange}
          />
          <TextField
            label="Email"
            name="email"
            type="text"
            fullWidth
            className={classes.marginTop30}
            inputProps={{ 'aria-label': 'Email' }}
            value={newUserAttributes.email}
            onChange={handleChange}
          />
          <TextField
            label="Phone"
            name="phone"
            type="text"
            fullWidth
            className={classes.marginTop30}
            inputProps={{ 'aria-label': 'Phone' }}
            value={newUserAttributes.phone}
            onChange={handleChange}
          />

          <TextField
            select
            label="Role / Job Title"
            name="role"
            fullWidth
            className={classes.marginTop30}
            value={newUserAttributes.role}
            SelectProps={{
              native: true,
            }}
            onChange={handleChange}
          >
            {roles.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </TextField>
        </Grid>

        <Grid style={{ marginTop: 18 }}>
          <Typography className={classes.subtitleGray}>
            Tell us more about this user
          </Typography>

          <TextField
            style={{ marginTop: 8 }}
            className={`${classes.noBorderRadius}`}
            name="message"
            value={newUserAttributes.message}
            multiline
            fullWidth
            variant="outlined"
            onChange={handleChange}
          />
          <Typography className={classes.subtitleGray} style={{ fontSize: 10 }}>
            New users must be approved by the FARM RIO team
          </Typography>
        </Grid>

        <Grid className={classes.grid} style={{ marginTop: 25 }}>
          <ButtonFarm
            text="Cancel"
            textColor="#000"
            color="#fff"
            width={128}
            height={40}
            borderColor="1px solid #000"
            justify="center"
            onClick={handleClose}
          />

          <Grid style={{ marginLeft: 10 }}>
            <ButtonFarm
              text="Request"
              width={128}
              height={40}
              textColor={'#fff'}
              color={'#000'}
              borderColor="1px solid #ebebeb"
              justify="center"
              onClick={() => addNewUserToAccount(newUserAttributes)}
            />
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
}
