import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { Typography, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import Button from '../Button';

const StyledDialog = withStyles({
  paper: {
    width: 496,
    height: 227,
    padding: '32px 64px',
  },
  paperFullScreen: {
    backgroundColor: 'transparent',
  },
  paperWidthLg: {
    maxWidth: '100%',
  },
  scrollPaper: {
    alignItems: 'center',
  },
  paperFullWidth: {
    width: '100%',
  },
})(Dialog);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalWarningEmptyProducts({
  open,
  handleClose,
  quantity,
}) {
  return (
    <div>
      <StyledDialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
      >
        <Grid
          style={{ alignContent: 'flex-start' }}
          container
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            align="center"
            style={{
              fontSize: 18,
              lineHeight: '32px',
              paddingBottom: '24px',
            }}
          >
            You have{' '}
            <span style={{ color: '#E01919' }}>
              {' '}
              {quantity} styles with 0 items{' '}
            </span>{' '}
            on your order, please add quantities or remove these styles before
            submitting your order.
          </Typography>

          <Button
            textColor="#fff"
            color="#000"
            width={103}
            height={40}
            text="close"
            justifyContent="center"
            onClick={handleClose}
          />
        </Grid>
      </StyledDialog>
    </div>
  );
}
