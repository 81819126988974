/* eslint-disable no-underscore-dangle */
import React, {
  useState,
  useMemo,
  forwardRef,
  useCallback,
  useEffect,
} from 'react';

import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { MdKeyboardBackspace } from 'react-icons/md';

import { Typography, Grid, CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import useViewAsContext from '../../hooks/useViewAsContext';
import useUserContext from '../../hooks/useUserContext';

import Input from '../TextInput';
import Button from '../Button';

const StyledDialog = withStyles({
  paper: {
    width: 480,
    padding: '32px 67px',
  },
  paperFullScreen: {
    backgroundColor: 'transparent',
  },
  paperWidthLg: {
    maxWidth: '100%',
  },
  scrollPaper: {
    alignItems: 'center',
  },
  paperFullWidth: {
    width: '100%',
  },
})(Dialog);

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalVieweingAs() {
  const { userState } = useUserContext();

  const {
    viewAsState,
    hide: hideModal,
    setAccount,
    setUser,
    loadAccounts,
    loadAccountUsers,
  } = useViewAsContext();

  const { user: authenticatedUser } = userState;

  const {
    showModal: open,
    selectedUser,
    selectedAccount,
    accounts,
    users,
  } = viewAsState;

  const [selectUser, setSelectUser] = useState('');

  const accountOnChange = useCallback(
    e => {
      const account = accounts.find(({ name }) => name === e.target.value);
      setAccount(account);
      setSelectUser('');
    },
    [accounts, setAccount]
  );

  const userOnChange = useCallback(
    e => {
      setSelectUser(e.target.value);
    },
    [setSelectUser]
  );

  const onConfirm = useCallback(() => {
    const user = users.find(({ name }) => name === selectUser);
    setUser(user);
    hideModal();
  }, [hideModal, selectUser, setUser, users]);

  const onReset = useCallback(() => {
    setAccount(null);
    setUser(null);
    hideModal();
  }, [hideModal, setAccount, setUser]);

  const onCancel = useCallback(() => {
    const account = selectedUser?.account || false;
    if (account) setAccount(account);
    setSelectUser(selectedUser.name);
    hideModal();
  }, [hideModal, selectedUser, setAccount]);

  const accountValue = useMemo(() => selectedAccount?.name || '', [
    selectedAccount,
  ]);

  const accountOptionValues = useMemo(() => {
    if (!accounts) return [];
    return accounts.map(({ name }) => name).sort();
  }, [accounts]);

  const userOptionValues = useMemo(() => {
    if (!users) return [];
    return users.map(({ name }) => name).sort();
  }, [users]);

  const loading = accounts && accounts.length === 0;

  const userName = selectedUser?.name;
  const accountName = selectedUser?.account?.name;

  const setInitialState = useCallback(() => {
    if (accounts.length && userState.user.account) {
      const adminUserAccount = accounts.find(
        ({ _id }) => userState.user.account._id === _id
      );
      setSelectUser(userState.user.name);
      setUser(userState.user);
      setAccount(adminUserAccount);
    }
  }, [accounts, setAccount, setUser, userState.user]);

  useEffect(() => {
    if (!userState.token) return;
    if (accounts === null) {
      loadAccounts();
      return;
    }
    if (
      accounts !== null &&
      selectedAccount === null &&
      selectedUser === null
    ) {
      setInitialState();
    }
  }, [
    accounts,
    loadAccounts,
    selectedAccount,
    selectedUser,
    setInitialState,
    userState.token,
  ]);

  useEffect(() => {
    if (accounts !== null && selectedAccount !== null) {
      loadAccountUsers();
    }
  }, [selectedAccount, accounts, loadAccountUsers]);

  return (
    <div>
      <StyledDialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onCancel}
      >
        <Grid
          style={{ alignContent: 'flex-start' }}
          container
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            style={{
              fontSize: 14,
              lineHeight: '28px',
              paddingBottom: '16px',
              borderBottom: '1px solid #000',
              width: 346,
            }}
          >
            You are currently viweing the website as <br />{' '}
            <span style={{ fontWeight: 'bold', textTransform: 'capitalize' }}>
              {' '}
              {userName}{' '}
            </span>{' '}
            from{' '}
            <span style={{ fontWeight: 'bold', textTransform: 'capitalize' }}>
              {' '}
              {accountName}
            </span>
          </Typography>
          <Typography
            style={{ fontSize: 14, marginTop: 16, lineHeight: '28px' }}
          >
            Select an account and user to browse through the website and add
            products to their collections.
          </Typography>
          {loading ? (
            <Grid container justifyContent="center" style={{ marginTop: 40 }}>
              {' '}
              <CircularProgress />{' '}
            </Grid>
          ) : (
            <>
              <Input
                field="Account"
                width={346}
                options={accountOptionValues}
                value={accountValue}
                onChange={accountOnChange}
                select
                marginTop={50}
                background="#ebebeb"
              />
              {userOptionValues.length > 0 && (
                <Input
                  field="User"
                  width={346}
                  options={userOptionValues}
                  value={selectUser}
                  onChange={userOnChange}
                  select
                  marginTop={50}
                  background="#ebebeb"
                />
              )}
            </>
          )}
          <Grid
            container
            justifyContent="space-between"
            style={{ marginTop: 40 }}
          >
            <Grid>
              <Button
                text="Cancel"
                width={161}
                color="#fff"
                height={40}
                borderColor="1px solid #000"
                onClick={onCancel}
              />
            </Grid>
            <Grid>
              <Button
                text="Switch User"
                width={161}
                color={selectUser ? '#000' : '#d3d3d3'}
                height={40}
                textColor="#fff"
                onClick={selectUser ? onConfirm : null}
              />
            </Grid>
          </Grid>
          {selectedUser && (
            <Typography
              onClick={onReset}
              display="inline"
              style={{ fontSize: 14, marginTop: 20, cursor: 'pointer' }}
            >
              {' '}
              <MdKeyboardBackspace
                style={{ position: 'relative', top: 3, right: 2 }}
              />{' '}
              Reset to{' '}
              <span style={{ fontWeight: 'bold', textTransform: 'capitalize' }}>
                {authenticatedUser?.name?.toLowerCase()}
              </span>
            </Typography>
          )}
        </Grid>
      </StyledDialog>
    </div>
  );
}
