import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TableHead from '@material-ui/core/TableHead';
import { useDebounce } from 'use-lodash-debounce';
import ClearIcon from '@material-ui/icons/Clear';
import { AiOutlineDownload } from 'react-icons/ai';

import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TableSortLabel,
  TablePagination,
  CircularProgress,
} from '@material-ui/core';
import { BiEdit } from 'react-icons/bi';
import useConfigContext from '../../hooks/useConfigContext';
import ModalChangestatus from './ModalChangeProductStatus';
import TextInput from '../../components/TextInput';

import api from '../../services/api';

export const dictIdDelivery = {
  '5f34a6b5ed5ff21fbc9939c6': 'Delivery 1 FEB 15-28',
  '5f34a7b6bc82e351349c76bf': 'Delivery 2 MAR 15-30',
  '5f34a7c2bc82e351349c76c0': 'Delivery 3 APR 15-30',
  All: 'All',
};

const headCells = [
  {
    id: 'shopName',
    numeric: false,
    disablePadding: false,
    label: 'Account/Shop Name',
  },
  { id: 'num', numeric: false, disablePadding: true, label: 'Order' },
  { id: 'createdAt', numeric: false, label: 'Date Created' },

  { id: 'updatedAt', numeric: false, label: 'Last Edited' },

  { id: 'status', numeric: false, label: 'Status' },
  { id: 'infos', label: '' },
  { id: 'excel', label: '' },
];

const Chip = ({ label, handleDelete }) => {
  return (
    <div style={{ margin: 10 }} onClick={handleDelete}>
      <Typography display="inline">{label}</Typography>
      <img
        alt="icon"
        src="https://icons-for-free.com/iconfiles/png/512/close+cross+delete+remove+square+icon-1320195275754208861.png"
        style={{
          cursor: 'pointer',
          width: 16,
          height: 16,
          marginLeft: 5,
          position: 'relative',
          top: 3,
        }}
      />
    </div>
  );
};

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#fff',
    color: '#000',
    border: 0,
    borderBottom: '1px solid #000',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}
function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;

  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{ background: 'blue' }}>
      <TableRow>
        {/* <StyledTableCell padding="checkbox">
          <span />
        </StyledTableCell> */}
        {headCells.map(headCell => (
          <StyledTableCell
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              style={{ marginLeft: 30 }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: '0px 30px',
    maxHeight: '700px',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableRow: {
    '&$selected, &$selected:hover': {
      backgroundColor: 'purple',
    },
  },
  tableCell: {
    '$selected &': {
      color: 'yellow',
    },
  },
  selected: {},
}));

export default function ManageProducts({ orderSelected }) {
  const { configState } = useConfigContext();
  const seasonName = configState.season.name || '';
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('shopName');
  const [selected, setSelected] = React.useState([]);
  const [dense] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const [openChangeStatus, setOpenChangestatus] = React.useState(false);
  const [selectedInfo, setSelectedInfo] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [total, setTotal] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [num, setNum] = useState('');
  const [shopName, setShopName] = useState('All');
  const [shopNameOptions, setShopNameOptions] = useState(['All']);
  const [editedOptions] = useState(['All']);
  const [status, setStatus] = useState('All');
  const [loadingOrder, setLoadingOrder] = useState(false);
  const [downloadExcelURL, setDownloadExcelURL] = useState('');
  const [excelIndex, setExcelIndex] = useState(-1);
  const [created, setCreated] = useState({
    startDate: new Date(2020, 1, 1),
    endDate: new Date(),
    key: 'selection',
  });
  const tomorrow = new Date().setDate(new Date().getDate() + 1);
  const [edited, setEdited] = useState({
    startDate: new Date(2020, 1, 1),
    endDate: tomorrow,
    key: 'selection',
  });

  const handleExcel = async (orderId, index) => {
    const { data } = await api.get(`/order/${orderId}`);
    setExcelIndex(index);

    setLoadingOrder(true);
    const excelUrl = await api.post('/orderExcel', {
      arrayOfProducts: data.products,
      email: data.email,
    });
    setDownloadExcelURL(excelUrl.data);

    setLoadingOrder(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function formatDate(date) {
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const d = new Date(date);
    let month = `${monthNames[d.getMonth()]}`;
    let day = `${d.getDate()}`;
    const year = d.getFullYear();
    const hour = `${d.getHours()}:${(d.getMinutes() < 10 ? '0' : '') +
      d.getMinutes()}`;

    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;

    return [month, day, year, hour].join(' ');
  }

  const handleDelete = id => {
    setSelected(selected.filter(item => item !== id));
  };

  const getProducts = async () => {
    const { data } = await api.get(
      `/listOrders?limit=${rowsPerPage}&skip=${page *
        rowsPerPage}&num=${num}&shopName=${shopName}&status=${status}&createdAt_init=${new Date(
        created.startDate
      )}&createdAt_end=${new Date(created.endDate)}&updatedAt_end=${new Date(
        edited.endDate
      )}&updatedAt_init=${new Date(edited.startDate)}`
    );
    const arr = data.orders.map((item, index) => ({
      id: item?._id,
      createdAt: item?.createdAt,
      updatedAt: item?.updatedAt,
      num: item?.num,
      status: item?.status,
      shopName: item?.shopName,
    }));
    const statusResponseOptions = ['All'];
    const shopNameResponseOptions = ['All'];
    data.orders.forEach(item => {
      if (!statusResponseOptions.includes(item?.status))
        statusResponseOptions.push(item?.status);
      if (!shopNameResponseOptions.includes(item.shopName))
        shopNameResponseOptions.push(item.shopName);
    });
    setShopNameOptions(shopNameResponseOptions);
    setRows(arr);
    setTotal(data.count);
    // setLoading(false);
  };
  const debouncedSKU = useDebounce(num, 500);

  React.useEffect(() => {
    getProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    rowsPerPage,
    page,
    debouncedSKU,
    shopName,
    status,
    loading,
    edited,
    created,
  ]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.email);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const removeProduct = index => {
    setSelectedInfo(selectedInfo.filter((item, i) => index !== i));
    setSelected(selected.filter((item, i) => index !== i));
    if (selectedInfo?.length === 1) setOpenChangestatus(false);
  };

  const StyledTableRow = withStyles(theme => ({
    root: {
      border: '1px solid red',
      background: '#fff',
    },
    tableRow: {
      '&:hover': {
        backgroundColor: 'blue !important',
      },
    },
    tableCell: {
      '$selected &': {
        color: 'yellow',
      },
      borderBottom: 'none',
    },
    selected: {
      border: '1px solid #000',
    },
  }))(TableRow);

  return (
    <Grid
      container
      justifyContent="center"
      style={{ alignContent: 'flex-start' }}
    >
      <Grid container justifyContent="center">
        <Typography
          style={{
            fontFamily: 'Galano Grotesque Light',
            fontSize: 24,
            lineHeight: '28px',
            letterSpacing: '0.1em',
            marginBottom: 25,
          }}
        >
          EDIT ORDERS
        </Typography>
      </Grid>
      <Grid container justifyContent="center">
        <div style={{ marginBottom: 30 }}>
          <TextInput
            field="Find product by order"
            width={300}
            marginTop={20}
            // height={39}
            value={num}
            onChange={event => setNum(event.target.value)}
            // variant
          />
        </div>
      </Grid>
      <Grid
        container
        justifyContent="space-evenly"
        style={{ marginBottom: 20 }}
      >
        <TextInput
          select
          width={360}
          field="Account/Shop Name"
          options={shopNameOptions}
          value={shopName}
          onChange={event => {
            setPage(0);
            setShopName(event.target.value);
          }}
          height={29}
          background="#ebebeb"
        />
        <TextInput
          select
          field="Date Created"
          width={160}
          // options={createdOptions}
          value={created}
          options={[created]}
          onChange={event => {
            setCreated(event.selection);
          }}
          height={29}
          background="#ebebeb"
          date
        />
        <TextInput
          select
          field="Last Edited"
          width={160}
          options={editedOptions}
          value={edited}
          onChange={event => {
            setEdited(event.selection);
          }}
          height={29}
          background="#ebebeb"
          date
        />
        <TextInput
          select
          field="Status"
          width={160}
          value={status}
          options={['Pending', 'Notes', 'Approved', 'Cancelled']}
          onChange={event => {
            setPage(0);
            setStatus(event.target.value);
          }}
          height={29}
          background="#ebebeb"
        />
      </Grid>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <TableContainer style={{ height: window.innerHeight * 0.65 }}>
            <Table
              stickyHeader
              className={classes.table}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                style={{ background: 'blue' }}
              />
              <TableBody align>
                {stableSort(rows, getComparator(order, orderBy))
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <StyledTableRow
                        hover
                        // aria-checked={isItemSelected}
                        tabIndex={0}
                        key={row.description}
                        // selected={isItemSelected}
                        // classes={{ selected: classes.selected }}
                        // className={classes.tableRow}
                      >
                        {/* <TableCell /> */}
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          align="center"
                          style={{ borderBottom: 'none' }}
                        >
                          {row?.shopName}
                        </TableCell>
                        <TableCell
                          align="center"
                          onClick={() => window.open(`/register/${row.email}`)}
                          style={{ borderBottom: 'none' }}
                        >
                          {row?.num}
                        </TableCell>

                        <TableCell
                          style={{ borderBottom: 'none' }}
                          align="center"
                        >
                          {formatDate(row?.createdAt)}
                        </TableCell>
                        <TableCell
                          style={{ borderBottom: 'none' }}
                          align="center"
                        >
                          {formatDate(row?.updatedAt)}
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{ borderBottom: 'none' }}
                        >
                          <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                          >
                            <div
                              style={{
                                width: 14,
                                height: 14,
                                background:
                                  row.status === 'Notes'
                                    ? 'blue'
                                    : row.status === 'Submitted'
                                    ? 'orange'
                                    : row.status === 'Approved'
                                    ? 'green'
                                    : 'red',
                                borderRadius: 7,
                                marginRight: 8,
                              }}
                            />
                            {row.status}
                          </Grid>
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ borderBottom: 'none' }}
                        >
                          {loadingOrder && excelIndex === index ? (
                            <CircularProgress size={18} />
                          ) : (
                            <div
                              style={{
                                cursor: 'pointer',
                                display: 'flex',
                                flexDirection: 'row',
                              }}
                            >
                              <AiOutlineDownload
                                size={16}
                                color="black"
                                style={{ marginTop: 2, cursor: 'pointer' }}
                              />{' '}
                              {downloadExcelURL && excelIndex === index ? (
                                <span style={{ fontSize: 12 }}>
                                  {' '}
                                  <a
                                    href={downloadExcelURL}
                                    style={{
                                      textDecoration: 'none',
                                      color: 'black',
                                      cursor: 'pointer',
                                    }}
                                    download={`FARM US - ${seasonName}.xlsx`}
                                  >
                                    XLS READY{' '}
                                  </a>
                                </span>
                              ) : (
                                <Typography
                                  onClick={() => handleExcel(row?.id, index)}
                                  style={{ fontSize: 12 }}
                                >
                                  DOWNLOAD XLS
                                </Typography>
                              )}
                            </div>
                          )}
                        </TableCell>
                        <TableCell
                          onClick={() => orderSelected(row?.id)}
                          align="center"
                          style={{ borderBottom: 'none' }}
                        >
                          <div
                            style={{
                              cursor: 'pointer',
                            }}
                          >
                            <BiEdit size={24} />
                          </div>
                        </TableCell>
                      </StyledTableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ padding: '0px 50px' }}
      >
        <Grid>
          <Grid container>
            {selected.map(item => (
              <Chip label={item} handleDelete={() => handleDelete(item)} />
            ))}
          </Grid>
        </Grid>
        <Grid style={{ cursor: 'pointer' }} onClick={() => setSelected([])}>
          {selected.length > 0 && (
            <Grid container alignItems="center">
              Clear Selection
              <ClearIcon
                style={{ position: 'relative', top: 3 }}
                size="small"
              />
            </Grid>
          )}
        </Grid>
      </Grid>

      <ModalChangestatus
        open={openChangeStatus}
        onClose={() => {
          setOpenChangestatus(false);
        }}
        products={selectedInfo}
        removeProduct={removeProduct}
        setLoading={value => setLoading(value)}
        cleanSelected={() => setSelected([])}
      />
    </Grid>
  );
}
