import React, { useEffect, useState, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import { Typography, TextField } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ButtonComponent from '../../components/Button';
import Snackbar from '../../util/SnackBar';
import DeleteUsersModal from './RemoveUserFromAccountModal';
import AddUsersModal from './AddUserToAccount';
import { ReactComponent as FacebookLogo } from '../../assets/facebook-icon-size=default.svg';
import { ReactComponent as InstagramLogo } from '../../assets/instagram-icon-size=default.svg';
import { ReactComponent as Trash } from '../../assets/trash-size-default.svg';
import { ReactComponent as Add } from '../../assets/add-icon-size=small.svg';
import useUserContext from '../../hooks/useUserContext';
import { listAccountUsers} from '../../services/api'
const useStyles = makeStyles(() => ({
  containerDiv: {
    marginTop: 90,
  },
  marginTop: {
    marginTop: 40,
  },
  subtitleTextGray: {
    fontSize: 12,
    color: '#616161',
  },
  fontSize12: {
    fontSize: 12,
  },
  costumerNameText: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  span: {
    height: 15,
    width: 15,
    borderRadius: '50%',
  },
  marginTop30: {
    marginTop: 30,
  },
}));

const StyledButton = withStyles({
  root: {
    textTransform: 'none',
    padding: '0px',
    minWidth: 'auto',
  },
  contained: {
    boxShadow: 'none',
    backgroundColor: 'transparent',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: 'transparent',
    },
  },
  label: {
    alignItems: 'end',
  },
  endIcon: {
    '&.MuiButton-endIcon': {
      marginLeft: 0,
      marginRight: 0,
    },
  },
})(Button);

export default function Account() {
  const classes = useStyles();
  const [costumersByAccount, setCostumersByAccount] = useState([]);
  const account = useRef({});
  const {
    userState,
    updateAccountProperties,
    updateUserProperties,
    createNewUserForAnAccount
  } = useUserContext();
  const { user: profile } = userState;
  const [openModal, setOpenModal] = useState({
    removeUser: false,
    addUser: false,
  });
  const costumerToBeDeleted = useRef({});
  const [state, setState] = useState({
    accountName: '',
    website: '',
    facebook: '',
    instagram: '',
    accountId: '',
  });

  const handleChange = e => {
    const { name } = e.target;
    const { value } = e.target;
    setState({ ...state, [name]: value });
  };

  const handleSaveChanges = async () => {
    try {
      await updateAccountProperties({
        id: state.accountId,
        instagram: state.instagram,
        facebook: state.facebook,
        website: state.website,
      });
      return Snackbar.success('Account updated successfully ');
    } catch (error) {
      return Snackbar.error('Failed to update account');
    }
  };

  const handleDisapproveUser = async () => {
    try {
      await updateUserProperties({
        email: costumerToBeDeleted.current.email,
        isApproved: 'Disapproved',
      });
      setOpenModal({ ...openModal, removeUser: false });
      Snackbar.success('User status updated successfully');
    } catch (error) {
      Snackbar.error('Failed to update user status');
    }
  };

  const userStatusColor = status => {
    switch (status) {
      case 'Approved':
        return 'green';
      case 'Waiting':
        return 'orange';
      default:
        return 'red';
    }
  };

  const handleDeleteCostumerFromAccount = (costumerName, costumerEmail) => {
    costumerToBeDeleted.current = {
      name: costumerName,
      email: costumerEmail,
    };
  };

  const handleOpenModal = key => {
    setOpenModal({ ...openModal, [key]: true });
  };

  const handleCloseModal = key => {
    setOpenModal({ ...openModal, [key]: false });
    delete costumerToBeDeleted.current.name;
    delete costumerToBeDeleted.current.email;
  };

  const verifyUndefined = data => {
    // eslint-disable-next-line
    return data != undefined ? data : '';
  };

  const handleAddNewUserToAccount = async newUserAttributes => {
    try {
      await createNewUserForAnAccount({
        name: `${newUserAttributes.firstName + newUserAttributes.lastName}`,
        email: newUserAttributes.email,
        phone: newUserAttributes.phone,
        role: newUserAttributes.role,
        isInvited: true,
        message: newUserAttributes.message,
        account: account.current,
        accountId: account.current._id,
      });
      handleCloseModal('addUser');
      Snackbar.success('User added successfully');
    } catch (error) {
      Snackbar.error(error);
    }
  };

  useEffect(() => {
    (async () => {
      const data = profile;
      const users = await listAccountUsers(data?.account._id);
      setState({
        accountName: data.account.name,
        website: data.account.website,
        facebook: data.account.facebook != null ? data.account.facebook : '',
        instagram: data.account.instagram != null ? data.account.instagram : '',
        accountId: data?.account._id,
      });
      account.current = data.account;
      setCostumersByAccount(users.data.costumers);
    })();
  }, [profile]);

  return (
    <Grid
      item
      container
      xs={9}
      justify="space-between"
      style={{ marginTop: 33 }}
    >
      <Grid container>
        <Grid item xs={4}>
          <Typography>Account Information</Typography>
        </Grid>
        <Grid item xs={8}>
          <Grid item xs={8}>
            <TextField
              label="Account / Shop Name"
              name="accountName"
              type="text"
              fullWidth
              disabled
              inputProps={{ 'aria-label': 'Account / Shop Name' }}
              value={state.accountName}
              helperText={"Your account name can't be changed"}
            />
          </Grid>
          <Grid item xs={8} className={classes.marginTop}>
            <TextField
              label="Website"
              type="text"
              name="website"
              fullWidth
              value={state.website}
              onChange={e => {
                handleChange(e);
              }}
              inputProps={{ 'aria-label': 'Last Name' }}
            />
          </Grid>
          <Grid
            item
            xs={8}
            container
            alignItems="flex-end"
            className={classes.marginTop}
          >
            <Grid item>
              <FacebookLogo />
            </Grid>
            <Grid item style={{ flexGrow: 1 }}>
              <TextField
                label="Facebook"
                type="text"
                name="facebook"
                fullWidth
                value={state.facebook}
                onChange={e => {
                  handleChange(e);
                }}
                inputProps={{ 'aria-label': 'Facebook' }}
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={8}
            container
            alignItems="flex-end"
            className={classes.marginTop}
          >
            <Grid item>
              <InstagramLogo />
            </Grid>
            <Grid item style={{ flexGrow: 1 }}>
              <TextField
                label="Instagram"
                type="text"
                newUserAttributes
                name="instagram"
                fullWidth
                value={state.instagram}
                onChange={e => {
                  handleChange(e);
                }}
                inputProps={{ 'aria-label': 'Last Name' }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container className={classes.containerDiv}>
        <Grid item xs={4}>
          <Typography>Connected Users</Typography>
          {/* <Typography className={classes.subtitleTextGray}>
            Please confirm your password,
          </Typography>
          <Typography className={classes.subtitleTextGray}>
            then choose a new one
          </Typography> */}
        </Grid>
        <Grid item xs={8}>
          <Grid item xs={8}>
            {costumersByAccount?.map(costumer => (
              <Grid item key={costumer._id} className={classes.marginTop30}>
                <Grid
                  container
                  style={{
                    justifyContent: 'space-between',
                  }}
                >
                  <Grid style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <Typography className={classes.costumerNameText}>
                      {costumer.name}
                    </Typography>
                    <span
                      className={classes.span}
                      style={{
                        backgroundColor: userStatusColor(costumer.isApproved),
                        marginLeft: 8,
                        marginRight: 8,
                      }}
                    />

                    <Typography
                      style={{
                        fontSize: 12,
                      }}
                    >
                      {costumer.isApproved}
                    </Typography>
                  </Grid>

                  <Grid style={{ position: 'relative', top: 8 }}>
                    <StyledButton
                      endIcon={<Trash />}
                      onClick={() => {
                        handleDeleteCostumerFromAccount(
                          costumer.name,
                          costumer.email
                        );
                        handleOpenModal('removeUser');
                      }}
                    />
                  </Grid>
                </Grid>
                <Typography className={classes.fontSize12}>
                  {verifyUndefined(costumer.role)}
                </Typography>
                <Typography className={classes.fontSize12}>
                  {costumer.email}
                </Typography>
                <Typography className={classes.fontSize12}>
                  {verifyUndefined(costumer.account.countryCode) +
                    verifyUndefined(costumer.phone)}
                </Typography>
              </Grid>
            ))}

            <Grid
              container
              className={classes.marginTop30}
              style={{ alignItems: 'center' }}
            >
              <StyledButton
                endIcon={<Add />}
                onClick={() => handleOpenModal('addUser')}
              >
                <Typography
                  className={classes.fontSize12}
                  style={{ marginRight: 4 }}
                  // onClick={() => handleOpenModal('addUser')}
                >
                  Add User
                </Typography>
              </StyledButton>
            </Grid>

            <Grid container justify="center">
              <Grid
                style={{
                  marginBottom: 30,
                  marginTop: 60,
                  width: 'fit-content',
                }}
              >
                <ButtonComponent
                  width={180}
                  height={40}
                  textColor="#fff"
                  color="#000"
                  onClick={handleSaveChanges}
                  text="Save Changes"
                  justify="center"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <DeleteUsersModal
        open={openModal.removeUser}
        costumer={costumerToBeDeleted.current}
        onClose={() => handleCloseModal('removeUser')}
        handleDisapproveUser={handleDisapproveUser}
      />

      <AddUsersModal
        open={openModal.addUser}
        onClose={() => handleCloseModal('addUser')}
        addNewUserToAccount={handleAddNewUserToAccount}
      />
    </Grid>
  );
}
