/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { Dialog, Grid, Typography } from '@material-ui/core';
import Button from '../Button';
import Input from '../TextInput';
import api from '../../services/api';

export default function ModalMesage({ open, onClose, user }) {
  const [step, setStep] = useState(0);
  const [message, setMessage] = useState('');

  const handleMessage = async () => {
    await api.post('/message', { message, user });
    setStep(2);
  };

  const handleClose = () => {
    onClose();
    setStep(0);
    setMessage('');
  };

  return (
    <Dialog
      style={{
        width: '100%',
        maxWidth: '100vw',
        maxHeight: '100%',
        position: 'fixed',
        top: '30%',
        left: '0',
        transform: 'translate(0, -50%)',
        // overflowY: 'auto',
      }}
      open={open}
      BackdropProps={{ style: { height: window.innerHeight * 1.3 } }}
      onClose={handleClose}
    >
      <Grid container justifyContent="center" style={{ padding: '32px 64px' }}>
        <Grid>
          {step === 0 ? (
            <Typography
              style={{ width: 368, lineHeight: '32px', fontSize: 18 }}
              align="center"
            >
              Sorry, this account already exists. Please contact our support and
              we'll get back to you soon.
            </Typography>
          ) : step === 1 ? (
            <>
              <Typography
                align="center"
                style={{
                  fontSize: 18,
                  lineHeight: '18px',
                  letterSpacing: '0.1em',
                  width: 368,
                  paddingBottom: 16,
                  borderBottom: '1px solid #000',
                }}
              >
                CONTACT
              </Typography>
              <Input
                multiline
                limit={250}
                width={368}
                minHeight={108}
                value={message}
                onChange={e => {
                  if (e.target.value.length > 250) return;
                  setMessage(e.target.value);
                }}
                rows={5}
                field="Message"
                marginTop={50}
              />
            </>
          ) : (
            <Typography
              style={{ width: 368, lineHeight: '32px', fontSize: 18 }}
              align="center"
            >
              Your message has been sent and our support will get in touch with
              you soon.
            </Typography>
          )}
        </Grid>
        <Grid
          container
          justifyContent="center"
          style={{ marginTop: step === 1 ? 36 : 24 }}
        >
          <Grid>
            {step !== 2 ? (
              <Grid container>
                <Grid style={{ marginRight: 16 }}>
                  <Button
                    text={step === 0 ? 'Close' : 'Cancel'}
                    textColor="#000"
                    width={176}
                    height={40}
                    color="#fff"
                    borderColor="1px solid #000"
                    onClick={handleClose}
                  />
                </Grid>
                <Grid>
                  <Button
                    text={step === 0 ? 'Contact' : 'Send'}
                    textColor="#fff"
                    width={176}
                    height={40}
                    color="#000"
                    onClick={() => (step === 0 ? setStep(1) : handleMessage())}
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid container justifyContent="center">
                <Button
                  text="Close"
                  textColor="#fff"
                  width={368}
                  height={40}
                  color="#000"
                  onClick={handleClose}
                  justifyContent="center"
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
}
