import React, { useEffect, useRef, useState } from 'react';
import {
  Grid,
  FormControlLabel,
  Radio,
  Typography,
  RadioGroup,
} from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import useUserContext from '../../hooks/useUserContext';
import ButtonComponent from '../../components/Button';
import { ReactComponent as Edit } from '../../assets/edit-size-default.svg';
import { ReactComponent as Trash } from '../../assets/trash-size-default.svg';
import { ReactComponent as Add } from '../../assets/add.svg';
import CloseIcon from '../../components/xFilledIcon';
import Checkbox from '../../components/CheckLabel';
import ModalAddress from '../../components/ModalAddress';
import Snackbar from '../../util/SnackBar';
import DeleteAddressModal from './DeleteAddressModal';
import { ddiCountries } from './ddi';

const StyledButton = withStyles({
  root: {
    textTransform: 'none',
    padding: '0px',
    minWidth: 'auto',
  },
  contained: {
    boxShadow: 'none',
    backgroundColor: 'transparent',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: 'transparent',
    },
  },
  label: {
    alignItems: 'end',
  },
  endIcon: {
    '&.MuiButton-endIcon': {
      marginLeft: 0,
      marginRight: 0,
    },
  },
})(Button);

const useStyles = makeStyles(() => ({
  title: {
    fontSize: 17,
    lineHeight: '20px',
  },
  cardText: {
    fontSize: 12,
  },
  container: {
    backgroundColor: 'white',
    border: '1px solid white',
    '&:hover': {
      border: '1px solid black',
    },
  },
  fontBold: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  textFilterButton: {
    fontSize: 10,
  },
  checkboxItem: {
    marginRight: 18,
    marginBottom: 3,
  },
  endIcon: {
    '& .MuiButton-endIcon': {
      marginLeft: 3,
    },
  },
  textEndIcon: {
    height: 22,
    marginTop: 5,
    display: 'none',
  },
  gray: {
    color: '#616161',
  },
  fontSize14: {
    fontSize: 14,
  },
  inputField: {
    width: 385,
    marginTop: 30,
  },
}));

export default function AddressList() {
  const classes = useStyles();
  const { userState, updateAccountProperties } = useUserContext();
  const [selected, setSelected] = useState('All Addresses');
  const previousSelected = useRef('All Addresses');
  const allAddressesDiv = useRef();
  const [address, setAddress] = useState([]);
  const [editValues, setEditValues] = useState({
    modalAddress: '',
    modalCity: '',
    modalStreet: '',
    modalUnit: '',
    modalStoreProv: '',
    modalCountry: '',
    modalZipcode: '',
  });
  const [openModal, setOpenModal] = useState({
    addAddressModal: false,
    deleteAddressModal: false,
  });
  const isNewAddress = useRef(true);
  const accountId = useRef('');
  const indexAddressToBeEdited = useRef(0);
  const addressToBeDeleted = useRef();
  const [freight, setFreight] = useState({
    companyName: '',
    firstName: '',
    lastName: '',
    email: '',
    countryCode: '+',
    phone: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',
  });
  const [country, setCountry] = useState('');
  const [radio, setRadio] = useState('no');

  const handleClick = event => {
    if (event.target instanceof SVGElement) {
      previousSelected.current = 'All Addresses';
      event.currentTarget.childNodes[0].childNodes[0].style.fontWeight =
        'unset';
      allAddressesDiv.current.childNodes[0].childNodes[0].style.fontWeight =
        'bold';
      setSelected('All Addresses');
    } else if (
      event.target.textContent === previousSelected.current &&
      previousSelected.current !== 'All Addresses'
    ) {
      const divElement = document.getElementById(previousSelected.current);
      divElement.childNodes[0].childNodes[0].style.fontWeight = 'unset';
      divElement.childNodes[0].childNodes[1].style.display = 'none';
      previousSelected.current = 'All Addresses';
      allAddressesDiv.current.childNodes[0].childNodes[0].style.fontWeight =
        'bold';
      setSelected('All Addresses');
    } else if (event.target.textContent !== previousSelected.current) {
      document.getElementById(
        previousSelected.current
      ).childNodes[0].childNodes[0].style.fontWeight = 'unset';
      previousSelected.current = event.target.textContent;
      event.target.style.fontWeight = 'bold';
      setSelected(event.target.textContent);
    } else {
    }
  };

  const setAllAddressesFilterBold = () => {
    allAddressesDiv.current = document.getElementById('All Addresses');
    allAddressesDiv.current.childNodes[0].childNodes[0].style.fontWeight =
      'bold';
  };

  useEffect(() => {
    setAllAddressesFilterBold();
    const data = userState.user;
    accountId.current = data?.account._id;
    setAddress(data.account.addresses);

    if (Object.keys(data.account.freight).length !== 0) {
      setFreight(oldFreight => {
        oldFreight.companyName = data.account?.freight?.company;
        const name = data.account?.freight?.name.split(' ');
        oldFreight.firstName = name[0];
        name.shift();
        oldFreight.lastName =
          name.length >= 1
            ? name.reduce(
                (previousValue, currentValue) =>
                  `${previousValue} ${currentValue}`
              )
            : '';
        oldFreight.email = data.account?.freight?.email;
        oldFreight.address1 = data.account?.freight?.address?.street;
        oldFreight.address2 =
          data.account?.freight?.address?.building_unit_floor;
        oldFreight.city = data.account?.freight?.address?.city;
        oldFreight.country = data.account?.freight?.address?.country;
        oldFreight.state = data.account?.freight?.address?.state_province;
        oldFreight.zipCode = data.account?.freight?.address?.zipcode;
        oldFreight.countryCode = data.account?.freight?.ddi;
        oldFreight.phone = data.account?.freight?.phone;

        return oldFreight;
      });
    }
  }, [userState]);

  const handleRadioInput = event => {
    setRadio(event.target.value);
  };

  const handleCheckedAddress = (e, index) => {
    const addressCopy = address;
    if (e.target.value === 'shipping') {
      addressCopy[index].isShippingAddress = e.target.checked;
    } else {
      addressCopy[index].isBillingAddress = e.target.checked;
    }
    setAddress([...addressCopy]);
  };

  const handleSaveAddress = async inputAddresses => {
    if (isNewAddress.current === true) {
      try {
        await updateAccountProperties({
          id: accountId.current,
          addresses: [
            ...address,
            {
              name: inputAddresses.modalAddress,
              street: inputAddresses.modalStreet,
              building_unit_floor: inputAddresses.modalUnit,
              city: inputAddresses.modalCity,
              state_province: inputAddresses.modalStoreProv,
              zipcode: inputAddresses.modalZipcode,
              country: inputAddresses.modalCountry,
              isBillingAddress: false,
              isShippingAddress: false,
            },
          ],
        });

        handleCloseModal('addAddressModal');
        return Snackbar.success('Address created successfully');
      } catch (error) {
        return Snackbar.error('Failed to create an address');
      }
    } else {
      setAddress(oldAddress => {
        oldAddress[indexAddressToBeEdited.current] = {
          name: inputAddresses.modalAddress,
          street: inputAddresses.modalStreet,
          building_unit_floor: inputAddresses.modalUnit,
          city: inputAddresses.modalCity,
          state_province: inputAddresses.modalStoreProv,
          zipcode: inputAddresses.modalZipcode,
          country: inputAddresses.modalCountry,
          isBillingAddress: false,
          isShippingAddress: false,
        };
        return oldAddress;
      });
    }
    handleCloseModal('addAddressModal');
  };

  const handleSubmmit = async () => {
    try {
      if (radio === 'no')
        await updateAccountProperties({
          id: accountId.current,
          addresses: [...address],
        });
      else
        await updateAccountProperties({
          id: accountId.current,
          addresses: [...address],
          freight: {
            company: freight.companyName,
            name: `${`${freight.firstName} ${freight.lastName}`}`,
            email: freight.email,
            ddi: freight.countryCode,
            phone: freight.phone,
            address: {
              street: freight.address1,
              building_unit_floor: freight.address2,
              city: freight.city,
              state_province: freight.state,
              zipcode: freight.zipCode,
              country: freight.country,
            },
          },
        });

      return Snackbar.success('Address updated successfully');
    } catch (error) {
      return Snackbar.error('Failed to updated address');
    }
  };

  const handleOpenEdit = (ad, cit, stProv, zcode, ct, st, unit) => {
    isNewAddress.current = false;
    setEditValues({
      modalAddress: ad,
      modalCity: cit,
      modalStoreProv: stProv,
      modalZipcode: zcode,
      modalStreet: st,
      modalUnit: unit,
      modalCountry: ct,
    });
  };

  const handleCloseModal = key => {
    setOpenModal({ ...openModal, [key]: false });
  };

  const handleOpenModal = key => {
    setOpenModal({ ...openModal, [key]: true });
  };

  const setDeletedAddress = addressName => {
    addressToBeDeleted.current = addressName;
  };

  const handleDeleteAddress = async () => {
    try {
      const filteredAddress = address.filter(
        obj => obj.name !== addressToBeDeleted.current
      );

      await updateAccountProperties({
        id: accountId.current,
        addresses: filteredAddress,
      });
      handleCloseModal('deleteAddressModal');
      return Snackbar.success('Address deleted successfully');
    } catch (error) {
      return Snackbar.error('Failed to delete address');
    }
  };

  const selectControlVariable = addressObj => {
    switch (selected) {
      case 'All Addresses':
        return true;
      case 'Shipping':
        return addressObj.isShippingAddress;
      case 'Billing':
        return addressObj.isBillingAddress;
      default:
        return !addressObj.isShippingAddress && !addressObj.isBillingAddress;
    }
  };

  const handleChange = e => {
    const { name } = e.target;
    const { value } = e.target;

    if (name === 'countryCode' && value.length >= 3) countryByDdi(value);
    else if (name === 'countryCode' && value.length <= 1) {
      setFreight({ ...freight, [name]: '+' });
      return;
    } else if (name === 'countryCode') setCountry('');

    setFreight({ ...freight, [name]: value });
  };

  const countryByDdi = countryCode => {
    const countryDdi = ddiCountries.find(
      object => object.dial_code === countryCode
    );
    if (countryDdi !== undefined) setCountry(`(${countryDdi.code})`);
  };

  const verifyUndefined = data => {
    // eslint-disable-next-line
    return data != undefined ? data : '';
  };

  return (
    <Grid
      item
      container
      xs={9}
      justifyContent="space-between"
      style={{ marginTop: 33 }}
    >
      <Grid style={{ marginLeft: 5 }}>
        <Typography style={{ marginBottom: 8 }} className={classes.fontSize14}>
          Addresses
        </Typography>
        <Typography className={`${classes.cardText} ${classes.gray}`}>
          Here you can view and edit
        </Typography>
        <Typography className={`${classes.cardText} ${classes.gray}`}>
          all your addresses. You can
        </Typography>
        <Typography className={`${classes.cardText} ${classes.gray}`}>
          also set them as shipping or
        </Typography>
        <Typography className={`${classes.cardText} ${classes.gray}`}>
          billing addresses
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Grid item container xs={8} justifyContent="space-between">
          {['All Addresses', 'Shipping', 'Billing', 'Not Used'].map(string => (
            <StyledButton
              key={string}
              id={string}
              className={classes.endIcon}
              // classes={{
              //   endIcon: {marginLeft: 5}
              // }}
              endIcon={
                selected === string &&
                selected !== 'All Addresses' && <CloseIcon />
              }
              onClick={e => handleClick(e)}
            >
              <Typography className={classes.textFilterButton}>
                {string}
              </Typography>
            </StyledButton>
          ))}
        </Grid>

        <Grid
          container
          item
          xs={12}
          style={{ display: 'flex', marginTop: 24 }}
          spacing={3}
        >
          <Grid item xs={6}>
            <Grid
              className={classes.container}
              style={{ height: 154 }}
              onClick={() => {
                handleOpenModal('addAddressModal');
                isNewAddress.current = true;
              }}
            >
              <Grid style={{ padding: '16px 16px 8px 16px' }}>
                <Typography
                  className={classes.fontBold}
                  style={{ marginBottom: 42 }}
                >
                  Add New Address
                </Typography>
                <StyledButton
                  classes={{
                    endIcon: classes.endIcon,
                  }}
                  endIcon={<Add />}
                  onClick={() => {
                    handleOpenModal('addAddressModal');
                    isNewAddress.current = true;
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          {address?.map(
            (obj, index) =>
              selectControlVariable(obj) && (
                <Grid item xs={6} key={obj?.address}>
                  <Grid className={classes.container} style={{ height: 154 }}>
                    <Grid style={{ padding: '8px 8px 16px 16px' }}>
                      <Typography className={classes.fontBold}>
                        {obj.name || `Address ${index + 1}`}
                      </Typography>
                      <Grid style={{ position: 'relative' }}>
                        <Typography
                          className={classes.cardText}
                          style={{ marginTop: 14 }}
                        >
                          {`${verifyUndefined(obj.street)} ${verifyUndefined(
                            obj.building_unit_floor
                          )}`}
                        </Typography>
                        <Grid
                          style={{
                            position: 'absolute',
                            right: 0,
                            top: -30,
                          }}
                        >
                          <Grid>
                            <Grid container justify="flex-end">
                              <Typography
                                className={`${classes.cardText} ${classes.textEndIcon}`}
                              >
                                Remove
                              </Typography>
                              <StyledButton
                                endIcon={<Trash />}
                                onClick={() => {
                                  handleOpenModal('deleteAddressModal');
                                  setDeletedAddress(
                                    obj.name || `Address ${index + 1}`
                                  );
                                }}
                              />
                            </Grid>
                            <Grid container justify="flex-end">
                              <Typography
                                className={`${classes.cardText} ${classes.textEndIcon}`}
                              >
                                Edit
                              </Typography>
                              <StyledButton
                                endIcon={<Edit />}
                                onClick={() => {
                                  handleOpenModal('addAddressModal');
                                  handleOpenEdit(
                                    obj?.name,
                                    obj?.city,
                                    obj?.state_province,
                                    obj?.zipcode,
                                    obj?.country,
                                    obj?.street,
                                    obj?.building_unit_floor
                                  );
                                  indexAddressToBeEdited.current = index;
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Typography
                        className={classes.cardText}
                      >{`${verifyUndefined(obj.city)} ${verifyUndefined(
                        obj.state_province
                      )}`}</Typography>
                      <Typography
                        className={classes.cardText}
                      >{`${verifyUndefined(obj.zipcode)} ${verifyUndefined(
                        obj.country
                      )}`}</Typography>
                      <Grid
                        container
                        style={{ marginTop: 12 }}
                        alignItems="center"
                      >
                        <Checkbox
                          value="shipping"
                          marginRight={0}
                          checked={address[index].isShippingAddress}
                          onChange={e => handleCheckedAddress(e, index)}
                        />
                        <Typography
                          className={`${classes.cardText} ${classes.checkboxItem}`}
                        >
                          Shipping address
                        </Typography>
                        <Checkbox
                          value="billing"
                          marginRight={0}
                          checked={address[index].isBillingAddress}
                          onChange={e => handleCheckedAddress(e, index)}
                        />
                        <Typography
                          className={`${classes.cardText} ${classes.checkboxItem}`}
                        >
                          Billing address
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )
          )}
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between" style={{ marginTop: 50 }}>
        <Grid item xs={3}>
          <Typography
            className={classes.fontSize14}
            style={{ marginBottom: 8 }}
          >
            Freight Forwarder
          </Typography>
          <Typography className={`${classes.cardText} ${classes.gray}`}>
            If applicable
          </Typography>
        </Grid>
        <Grid container item xs={9}>
          <Typography className={classes.fontSize14}>
            Do you have a Freight Forwarder?
          </Typography>
          <Grid style={{ position: 'relative', bottom: 5, marginLeft: 20 }}>
            <RadioGroup
              aria-label="freight forwarder"
              value={radio}
              onChange={handleRadioInput}
              style={{ flexDirection: 'row' }}
            >
              <FormControlLabel
                value="no"
                control={<Radio size="small" />}
                label={
                  <Typography
                    style={{
                      fontSize: 12,
                      position: 'relative',
                      top: -2,
                      marginRight: 30,
                    }}
                  >
                    No
                  </Typography>
                }
              />
              <FormControlLabel
                value="yes"
                control={<Radio size="small" />}
                label={
                  <Typography
                    style={{
                      fontSize: 12,
                      position: 'relative',
                      top: -2,
                      marginRight: 30,
                    }}
                  >
                    Yes
                  </Typography>
                }
              />
            </RadioGroup>
          </Grid>
        </Grid>
      </Grid>

      {radio === 'yes' && (
        <Grid container justifyContent="space-between">
          <Grid item xs={3} />
          <Grid item xs={9}>
            <Grid>
              <TextField
                label="Company Name"
                type="text"
                className={classes.inputField}
                name="companyName"
                onChange={handleChange}
                value={freight.companyName}
                inputProps={{ 'aria-label': 'Company Name' }}
              />
            </Grid>

            <Grid>
              <TextField
                label="First Name"
                type="text"
                name="firstName"
                className={classes.inputField}
                onChange={handleChange}
                value={freight.firstName}
                inputProps={{ 'aria-label': 'Company Name' }}
              />
            </Grid>

            <Grid>
              <TextField
                label="Last Name"
                type="text"
                name="lastName"
                className={classes.inputField}
                onChange={handleChange}
                value={freight.lastName}
                inputProps={{ 'aria-label': 'Last Name' }}
              />
            </Grid>

            <Grid>
              <TextField
                label="Email"
                type="text"
                name="email"
                className={classes.inputField}
                onChange={handleChange}
                value={freight.email}
                inputProps={{ 'aria-label': 'Email' }}
              />
            </Grid>

            <Grid
              style={{
                marginTop: 30,
                display: 'flex',
                alignItems: 'baseline',
              }}
            >
              <TextField
                label="Country"
                type="text"
                name="countryCode"
                style={{ width: 50 }}
                onChange={handleChange}
                value={freight.countryCode}
                inputProps={{ 'aria-label': 'Email' }}
              />
              <Typography style={{ marginLeft: 15 }}>{country}</Typography>

              <TextField
                label="Phone"
                type="text"
                name="phone"
                style={{ marginLeft: 23, width: country ? 264 : 298 }}
                onChange={handleChange}
                value={freight.phone}
                inputProps={{ 'aria-label': 'Phone' }}
              />
            </Grid>

            <Grid>
              <TextField
                label="Address"
                type="text"
                name="address1"
                className={classes.inputField}
                onChange={handleChange}
                value={freight.address1}
                inputProps={{ 'aria-label': 'Phone' }}
              />
            </Grid>

            <Grid>
              <TextField
                label=" Building / Unit / Floor"
                type="text"
                name="address2"
                className={classes.inputField}
                onChange={handleChange}
                value={freight.address2}
                inputProps={{ 'aria-label': 'Phone' }}
              />
            </Grid>

            <Grid>
              <TextField
                label="City"
                type="text"
                name="city"
                className={classes.inputField}
                onChange={handleChange}
                value={freight.city}
                inputProps={{ 'aria-label': 'Phone' }}
              />
            </Grid>

            <Grid>
              <TextField
                label="State/Province"
                type="text"
                name="state"
                className={classes.inputField}
                onChange={handleChange}
                value={freight.state}
                inputProps={{ 'aria-label': 'Phone' }}
              />
            </Grid>

            <Grid>
              <TextField
                label="Zipcode"
                type="text"
                name="zipCode"
                className={classes.inputField}
                onChange={handleChange}
                value={freight.zipCode}
                inputProps={{ 'aria-label': 'Phone' }}
              />
            </Grid>

            <Grid style={{ marginBottom: 50 }}>
              <TextField
                label="Country"
                name="country"
                type="text"
                className={classes.inputField}
                onChange={handleChange}
                value={freight.country}
                inputProps={{ 'aria-label': 'Phone' }}
              />
            </Grid>
          </Grid>
        </Grid>
      )}

      <Grid container justify="center">
        <Grid
          style={{
            marginBottom: 30,
            marginTop: 60,
            width: 'fit-content',
          }}
        >
          <ButtonComponent
            width={180}
            height={40}
            textColor="#fff"
            color="#000"
            onClick={handleSubmmit}
            text="Save Changes"
            justify="center"
          />
        </Grid>
      </Grid>

      <ModalAddress
        open={openModal.addAddressModal}
        handleClose={() => handleCloseModal('addAddressModal')}
        handleSaveAddress={handleSaveAddress}
        editValues={!isNewAddress.current && editValues}
      />

      <DeleteAddressModal
        open={openModal.deleteAddressModal}
        addressName={addressToBeDeleted.current}
        onClose={() => handleCloseModal('deleteAddressModal')}
        handleDeleteAddress={handleDeleteAddress}
      />
    </Grid>
  );
}
