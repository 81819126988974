import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
  noBorderRadius: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 'unset',
    },
  },
  fontSize14: {
    fontSize: 14,
  },
  subtitleTextGray: {
    fontSize: 12,
    color: '#616161',
  },
  marginTop16: {
    marginTop: 16,
  },
}));

export default function TextArea({
  value,
  name,
  fullWidth,
  style,
  title,
  subtitle,
}) {
  const classes = useStyles();
  return (
    <>
      <Typography className={classes.fontSize14}>{title}</Typography>
      <TextField
        className={`${classes.noBorderRadius} ${classes.marginTop16}`}
        multiline
        fullWidth={fullWidth}
        variant="outlined"
        value={value}
        name={name}
        style={style}
      />
      <Typography className={classes.subtitleTextGray}>{subtitle}</Typography>
    </>
  );
}
