import React from 'react';

export default function Divider({ className = '' }) {
  return (
    <hr
      className={className}
      style={{
        background: 'black',
        margin: '21px 0',
        height: 1,
        marginTop: 13,
        marginBottom: 13,
        width: '100%',
      }}
    />
  );
}
