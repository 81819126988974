import React from 'react';
import { Grid } from '@material-ui/core';
import ManageOrderTable from './ManageOrderTable';

export default function ManageOrders() {
  return (
    <Grid
      container
      style={{ alignContent: 'flex-start', padding: '20px 30px 0px 35px' }}
    >
      <ManageOrderTable />
    </Grid>
  );
}
