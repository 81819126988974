import React, { useRef, useContext } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import Img from 'react-cool-img';
import { Grid, Typography } from '@material-ui/core';
import BoardContext from '../BoardDND/context';

import { Container } from './styles';

export default function Card({ data, index, factor }) {
  const ref = useRef();
  const { move } = useContext(BoardContext);

  const [{ isDragging }, dragRef] = useDrag({
    item: { type: 'CARD', index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, dropRef] = useDrop({
    accept: 'CARD',
    drop(item, monitor) {
      const draggedIndex = item.index;
      const targetIndex = index;

      if (draggedIndex === targetIndex) {
        return;
      }

      move(draggedIndex, targetIndex);

      item.index = targetIndex;
    },
  });

  dragRef(dropRef(ref));

  return (
    <Container ref={ref} isDragging={isDragging}>
      <Grid
        key={String(data.mainly_image)}
        md={data === 2 ? 12 : 3}
        style={{ position: 'relative' }}
        // onClick={async () => {
        //   history.replace({
        //     search: `?style_code=${data.style_code}`,
        //   });
        //   // setSelectedProduct(item);
        //   // setOpen(true);
        // }}
      >
        <Img
          style={{
            margin: '1px 2px',
            width: 202 * factor,
            height: 340 * factor,
            cursor: 'pointer',
          }}
          src={data.mainly_image}
          alt="a"
        />
        <Typography
          // style={}
          align="center"
          style={{
            padding: factor < 0.5 ? '5px 0px' : '14px 12px',
            backgroundColor: ' rgba(0, 0, 0, 0.05)',
            color: 'white',
            // lineHeight: 18 * factor,
            width: 202 * factor,
            fontSize: factor < 0.5 ? 10 : factor === 1 ? 22 : 18 * factor * 1.7,
            position: 'absolute',
            bottom: 0,
          }}
        >
          {/* {' '} */}
          {data?.style_code}
        </Typography>
      </Grid>
    </Container>
  );
}
