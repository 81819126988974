import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TableHead from '@material-ui/core/TableHead';
import { useDebounce } from 'use-lodash-debounce';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TableSortLabel,
  TablePagination,
} from '@material-ui/core';
import { BiEdit } from 'react-icons/bi';
import TextInput from '../TextInput';
import ModalChangestatus from '../../pages/Admin/ModalChangeProductStatus';
import api from '../../services/api';
import CreateUser from '../CreateUser';

const headCells = [
  { id: 'name', numeric: false, disablePadding: true, label: 'Name' },
  {
    id: 'shopName',
    numeric: false,
    disablePadding: false,
    label: 'Account/Shop Name',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'Email',
  },

  { id: 'phone', numeric: true, label: 'Phone' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
  { id: 'info', label: '' },
];

const Chip = ({ label, handleDelete }) => {
  return (
    <div style={{ margin: 10 }} onClick={handleDelete}>
      <Typography display="inline">{label}</Typography>
      <img
        alt="icon"
        src="https://icons-for-free.com/iconfiles/png/512/close+cross+delete+remove+square+icon-1320195275754208861.png"
        style={{
          cursor: 'pointer',
          width: 16,
          height: 16,
          marginLeft: 5,
          position: 'relative',
          top: 3,
        }}
      />
    </div>
  );
};

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#fff',
    color: '#000',
    border: 0,
    borderBottom: '1px solid #000',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}
function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{ background: 'blue' }}>
      <TableRow>
        {/* <StyledTableCell padding="checkbox">
          <span />
        </StyledTableCell> */}
        {headCells.map(headCell => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.id === 'status' ? 'left' : 'center'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              style={{ marginLeft: 30 }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: '0px 30px',
    maxHeight: '700px',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableRow: {
    '&$selected, &$selected:hover': {
      backgroundColor: 'purple',
    },
  },
  selected: {},
}));

export default function ManageProducts({ selectProduct }) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('shopName');
  const [selected, setSelected] = React.useState([]);
  const [dense] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [shopName, setShopName] = React.useState('All');
  const [country, setCountry] = React.useState('All');
  const [status, setStatus] = React.useState('All');
  const [shopNameOptions, setShopNameOptions] = React.useState([]);
  const [countryOptions, setCountryOptions] = React.useState([]);
  const [openChangeStatus, setOpenChangestatus] = React.useState(false);
  const [selectedInfo, setSelectedInfo] = React.useState([]);
  const [firstRequest, setIsFirstRequest] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [total, setTotal] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [word, setWord] = React.useState('');
  const debouncedSKU = useDebounce(word, 500);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDelete = id => {
    setSelected(selected.filter(item => item !== id));
  };

  const getCostumers = async () => {
    const { data } = await api.get(
      `/newCostumers?limit=${rowsPerPage}&skip=${page *
        rowsPerPage}&word=${word}&shopName=${shopName}&status=${status}&country=${country}`
    );
    const arr =
      data?.costumers?.map((item, index) => ({
        admin: item?.admin,
        name: item.name,
        email: item.email,
        shopName: item?.account?.name,
        phone: item.phone,
        country: item.bCountry,
        status: item.isApproved || 'Waiting',
        index: index + 1,
        id: item._id,
        accountId: item?.account?._id,
      })) || [];

    if (firstRequest) {
      setShopNameOptions(['All', ...data.options.shopNameArray.sort()]);
      setCountryOptions(['All', ...data.options.countryArray.sort()]);
      setIsFirstRequest(false);
    }

    setRows(arr);
    setTotal(data.count);
    setLoading(false);
  };

  React.useEffect(() => {
    getCostumers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSKU, shopName, page, rowsPerPage, country, status, loading]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.email);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, user) => {
    setSelected(user);
  };

  const removeProduct = index => {
    setSelectedInfo(selectedInfo.filter((item, i) => index !== i));
    setSelected(selected.filter((item, i) => index !== i));
    if (selectedInfo?.length === 1) setOpenChangestatus(false);
  };

  const StyledTableRow = withStyles(theme => ({
    root: {
      border: '1px solid red',
      background: '#fff',
      tableRow: {
        '&:hover': {
          backgroundColor: 'blue !important',
        },
      },

      selected: {
        border: '1px solid #000',
      },
    },
  }))(TableRow);

  return selected.length === 0 ? (
    <Grid
      container
      justifyContent="center"
      style={{ alignContent: 'flex-start' }}
    >
      <Grid container justifyContent="center">
        <Typography
          style={{
            padding: '20px 0px 0px',
            fontFamily: 'Galano Grotesque Light',
            fontSize: 24,
            lineHeight: '28px',
            letterSpacing: '0.1em',
          }}
        >
          EDIT USER INFO
        </Typography>
      </Grid>
      <Grid container justifyContent="center">
        <div style={{ marginBottom: 30 }}>
          <TextInput
            field="Search Here"
            width={300}
            marginTop={20}
            // height={39}
            value={word}
            onChange={event => setWord(event.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
      </Grid>
      <Grid
        container
        justifyContent="space-evenly"
        style={{ marginBottom: 20 }}
      >
        <TextInput
          select
          field="Account/Shop Name"
          width={window.innerWidth * 0.2}
          options={shopNameOptions}
          value={shopName}
          onChange={event => {
            setPage(0);
            setShopName(event.target.value);
          }}
          height={29}
          background="#ebebeb"
        />

        <TextInput
          select
          field="Country"
          width={window.innerWidth * 0.2}
          options={countryOptions}
          value={country}
          onChange={event => {
            setPage(0);
            setCountry(event.target.value);
          }}
          height={29}
          background="#ebebeb"
        />

        <TextInput
          select
          field="Status"
          width={window.innerWidth * 0.2}
          value={status}
          options={['All', 'Approved', 'Disapproved', 'Waiting']}
          onChange={event => {
            setPage(0);
            setStatus(event.target.value);
          }}
          height={29}
          background="#ebebeb"
        />
      </Grid>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <TableContainer style={{ height: window.innerHeight * 0.65 }}>
            <Table
              stickyHeader
              className={classes.table}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                style={{ background: 'blue' }}
              />
              <TableBody align="true">
                {stableSort(rows, getComparator(order, orderBy))
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <StyledTableRow
                        hover
                        // aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.email}
                        // selected={isItemSelected}
                        classes={{ selected: classes.root.selected }}
                        className={classes.root.tableRow}
                      >
                        {/* <TableCell /> */}

                        <TableCell
                          style={{ borderBottom: 'none' }}
                          align="center"
                        >
                          {row.name}
                        </TableCell>
                        <TableCell
                          style={{ borderBottom: 'none' }}
                          align="center"
                        >
                          {row.shopName}
                        </TableCell>
                        <TableCell
                          style={{ borderBottom: 'none' }}
                          align="center"
                        >
                          {row.email}
                        </TableCell>
                        <TableCell
                          style={{ borderBottom: 'none' }}
                          align="center"
                        >
                          {row.phone}
                        </TableCell>

                        <TableCell
                          style={{ borderBottom: 'none' }}
                          align="left"
                        >
                          <Grid container alignItems="center">
                            <div
                              style={{
                                width: 14,
                                height: 14,
                                background:
                                  row.status === 'Approved'
                                    ? 'green'
                                    : row.status === 'Disapproved'
                                    ? 'red'
                                    : 'orange',
                                borderRadius: 7,
                                marginRight: 8,
                              }}
                            />
                            {row.status}
                          </Grid>
                        </TableCell>
                        <TableCell
                          onClick={event => handleClick(event, row)}
                          align="center"
                          style={{ borderBottom: 'none' }}
                        >
                          <div
                            style={{
                              cursor: 'pointer',
                            }}
                          >
                            <BiEdit size={24} />
                          </div>
                        </TableCell>
                      </StyledTableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ padding: '0px 50px' }}
      >
        <Grid>
          <Grid container>
            {selected.map(item => (
              <Chip label={item} handleDelete={() => handleDelete(item)} />
            ))}
          </Grid>
        </Grid>
        <Grid style={{ cursor: 'pointer' }} onClick={() => setSelected([])}>
          {selected.length > 0 && (
            <Grid container alignItems="center">
              Clear Selection
              <ClearIcon
                style={{ position: 'relative', top: 3 }}
                size="small"
              />
            </Grid>
          )}
        </Grid>
      </Grid>

      <ModalChangestatus
        open={openChangeStatus}
        onClose={() => {
          setOpenChangestatus(false);
        }}
        products={selectedInfo}
        removeProduct={removeProduct}
        setLoading={value => setLoading(value)}
        cleanSelected={() => setSelected([])}
      />
    </Grid>
  ) : (
    <CreateUser
      initLoading={() => setLoading(true)}
      editedUser={selected}
      handleBack={() => setSelected([])}
    />
  );
}
