import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography, IconButton, Dialog } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import Input from '../../components/TextInput';
import Button from '../../components/Button';
import { country_list } from '../../consts';
import api from '../../services/api';

const StyledDialog = withStyles({
  paper: {
    margin: 0,
    display: 'flex',
    justifyContent: 'center',
    borderRadius: 10,
  },
  paperFullScreen: {
    margin: 0,
    height: '100%',
    backgroundColor: 'white',
    borderRadius: 10,
  },
  paperWidthLg: {
    maxWidth: '100%',
  },
  scrollPaper: {
    alignItems: 'center',
    borderRadius: 10,
  },
  paperFullWidth: {
    width: '100%',
  },
  container: {
    height: 'auto',
    borderRadius: 10,
  },
})(Dialog);

export default function ModalAddNewAddress({
  open,
  handleClose,
  email,
  currentAddress,
  handleAddNewAddress,
}) {
  const [modalAddress1, setModalAddress1] = useState('');
  const [modalAddress2, setModalAddress2] = useState('');
  const [modalCity, setModalCity] = useState('');
  const [modalStoreProv, setModalStoreProv] = useState('');
  const [modalCountry, setModalCountry] = useState('');
  const [modalZipcode, setModalZipcode] = useState('');
  const [errorbAddress1, setErrorbAddress1] = useState(null);
  const [errorbAddress2, setErrorbAddress2] = useState(null);
  const [errorbCity, setErrorbCity] = useState(null);
  const [errorbStoreProvince, setErrorbStoreProvince] = useState('');
  const [errorbZipcode, setErrorbZipcode] = useState('');

  const validationForm = () => {
    if (!modalAddress1) setErrorbAddress1('Required field');
    if (!modalCity) setErrorbCity('Required field');
    if (!modalStoreProv) setErrorbStoreProvince('Required field');
    if (!modalZipcode) setErrorbZipcode('Required field');
    if (
      modalAddress1 &&
      modalCity &&
      modalStoreProv &&
      modalZipcode &&
      modalCountry
    ) {
      return true;
    }
    return false;
  };

  const AddNewAddress = async () => {
    if (!validationForm()) return;
    try {
      const newAddress = {
        sLine1: modalAddress1,
        sLine2: modalAddress2,
        sLine3: modalCity,
        sStoreProvince: modalStoreProv,
        sZipcode: modalZipcode,
        sCountry: modalCountry,
      };
      const sAddress = [newAddress, ...currentAddress];
      await api.put('/newCostumers', { sAddress, email });
      handleAddNewAddress(newAddress);
      handleClose();
    } catch {}
  };

  return (
    <StyledDialog
      BackdropProps={{ style: { borderRadius: 15 } }}
      open={open}
      onClose={handleClose}
      style={{
        height: 716,
        width: 350,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 2000,
      }}
    >
      <Grid style={{ padding: '5%', width: 350 }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography>Add New Shipping Address</Typography>
          <IconButton onClick={handleClose}>
            <ClearIcon />
          </IconButton>
        </Grid>

        <Input
          field="Address 1"
          width={320}
          height={43}
          marginTop={20}
          value={modalAddress1}
          onChange={event => {
            setModalAddress1(event.target.value);
            setErrorbAddress1('');
          }}
          error={errorbAddress1}
          helperText={errorbAddress1}
        />
        <Input
          field="Address 2"
          width={320}
          height={43}
          marginTop={40}
          value={modalAddress2}
          onChange={event => {
            setModalAddress2(event.target.value);
            setErrorbAddress2('');
          }}
          error={errorbAddress2}
          helperText={errorbAddress2}
        />

        <Input
          field="City"
          width={320}
          height={43}
          marginTop={40}
          value={modalCity}
          onChange={event => {
            setModalCity(event.target.value);
            setErrorbCity('');
          }}
          error={errorbCity}
          helperText={errorbCity}
        />
        <Input
          field="Store/Province"
          width={320}
          height={43}
          marginTop={40}
          value={modalStoreProv}
          onChange={event => {
            setModalStoreProv(event.target.value);
            setErrorbStoreProvince('');
          }}
          error={errorbStoreProvince}
          helperText={errorbStoreProvince}
        />
        <Input
          field="Zipcode"
          width={320}
          height={43}
          marginTop={40}
          value={modalZipcode}
          onChange={event => {
            setModalZipcode(event.target.value);
            setErrorbZipcode('');
          }}
          error={errorbZipcode}
          helperText={errorbZipcode}
        />
        <Input
          field="Country"
          country
          background="#EBEBEB"
          width={320}
          height={43}
          marginTop={40}
          select
          onChange={event => setModalCountry(event.target.value)}
          value={modalCountry}
          options={country_list}
        />
        <Grid container justifyContent="center" style={{ paddingBottom: 44 }}>
          <Button
            justifyContent="center"
            width={128}
            height={40}
            color="#000000"
            textColor="#FFFFFF"
            text="save"
            marginTop={54}
            fontSize={16}
            onClick={AddNewAddress}
          />
        </Grid>
      </Grid>
    </StyledDialog>
  );
}
