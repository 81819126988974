/* eslint-disable no-nested-ternary */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Typography, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Input from '../../components/TextInput';
import { ReactComponent as InfoIcon } from '../../assets/info.svg';
// import { ReactComponent as HiddenEyeIcon } from '../../assets/Hidden-Eye-Icon-size=default.svg';

const useStyles = makeStyles(() => ({
  containerDiv: {
    marginTop: 90,
    position: 'relative',
  },
  marginTop: {
    marginTop: 80,
  },
  subtitleText: {
    fontSize: 12,
    color: '#616161',
  },
  steptTitle: {
    fontSize: 14,
    lineHeight: '18px',
  },
  stepTitleMobile: {
    fontSize: 18,
    lineHeight: '18px',
    paddingBottom: 16,
  },
  infoText: {
    fontSize: '10px',
    lineHeight: '12px',
    color: '#616161',
    width: 165,
    position: 'relative',
    left: 4,
  },
}));

export default function Profile({
  state,
  handleChange,
  error,
  country,
  width,
}) {
  const classes = useStyles();

  // const handleClickShowPassword = () => {
  //   setState({ ...state, showPassword: !state.showPassword });
  // };

  const wid =
    width < 1024
      ? width * 0.8
      : window.innerWidth > 1300
      ? 377
      : window.innerWidth > 1150
      ? 277
      : 210;

  return (
    <Grid
      item
      container
      justifyContent={width < 1024 ? 'center' : 'space-between'}
      style={{
        marginTop: width < 1024 ? 20 : 66,
        maxHeight:
          width < 1024 ? window.innerHeight - 248 : window.innerHeight - 350,
        overflowY: 'auto',
        overflowX: 'hidden',
        background: width < 1024 && '#fff',
        padding: width < 1024 && '5%',
        width: width < 1024 && width * 0.9,
      }}
    >
      <Grid container style={{}}>
        <Grid item xs={12} md={4}>
          <Typography
            className={
              width > 1024 ? classes.steptTitle : classes.stepTitleMobile
            }
          >
            Personal Information
          </Typography>
        </Grid>
        <Grid item>
          <Grid item>
            <TextField
              label="First Name"
              name="firstName"
              type="text"
              fullWidth
              autoComplete="off"
              onChange={e => {
                handleChange(e);
              }}
              inputProps={{
                'aria-label': 'First Name',
                autoComplete: 'off',
              }}
              value={state.firstName}
              error={error.firstName}
              helperText={error.firstName && 'Enter a Name'}
              style={{ width: wid }}
            />
          </Grid>
          <Grid item className={classes.marginTop}>
            <TextField
              label="Last Name"
              type="text"
              name="lastName"
              autoComplete="new-password"
              fullWidth
              value={state.lastName}
              onChange={e => {
                handleChange(e);
              }}
              inputProps={{ 'aria-label': 'Last Name' }}
              error={error.lastName}
              helperText={error.lastName && 'Enter a last name '}
              style={{ width: wid }}
            />
          </Grid>
          <Grid item className={classes.marginTop}>
            <Input
              select
              field="Role / Job Title"
              name="role"
              autoComplete="new-password"
              background="#EBEBEB"
              width={wid}
              height={36}
              options={[
                'Buyer',
                'Assistant buyer',
                'Merchandiser',
                'Store manager',
                'Owner',
              ]}
              value={state.role}
              onChange={e => handleChange(e)}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid container className={classes.containerDiv}>
        <Grid item xs={12} md={4}>
          <Typography
            className={
              width > 1024 ? classes.steptTitle : classes.stepTitleMobile
            }
          >
            Contact Information
          </Typography>
        </Grid>
        <Grid item>
          <Grid
            item
            container
            justifyContent="space-between"
            alignItems="baseline"
            style={{ width: wid }}
          >
            <TextField
              label="Country"
              type="text"
              autoComplete="new-password"
              name="countryCode"
              value={state.countryCode}
              style={{ width: '12%' }}
              onChange={e => {
                handleChange(e);
              }}
              inputProps={{ 'aria-label': 'Country Code' }}
              error={error.countryCode}
              helperText={
                error.countryCode ? 'Insert country code' : 'Country Code'
              }
            />

            <Typography>{country}</Typography>

            <TextField
              label="Phone"
              type="text"
              name="phone"
              autoComplete="new-password"
              style={{ width: '75%', marginTop: 16 }}
              onChange={e => {
                handleChange(e);
              }}
              value={state.phone}
              inputProps={{ 'aria-label': 'Phone' }}
              error={error.phone}
              helperText={error.phone && 'Numbers only'}
            />
          </Grid>
        </Grid>
        <Grid>
          <Grid
            container
            style={{ position: 'relative', left: wid > 1024 && '10%', top: 30 }}
          >
            <InfoIcon />
            <Typography className={classes.infoText}>
              Don't forget your area code, if applicable.
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid container className={classes.containerDiv}>
        <Grid item xs={12} md={4}>
          <Typography
            className={
              width > 1024 ? classes.steptTitle : classes.stepTitleMobile
            }
          >
            Login Information
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Grid item container>
            <TextField
              autoComplete="new-password"
              label="Email"
              name="email"
              type="text"
              fullWidth
              value={state.email}
              inputProps={{ 'aria-label': 'Email' }}
              onChange={e => {
                handleChange(e);
              }}
              error={error.email}
              helperText={error.email}
              style={{ width: wid }}
            />
            <Grid>
              <Grid
                container
                style={{
                  position: 'relative',
                  left: wid > 1024 && '10%',
                  top: 15,
                }}
              >
                <InfoIcon />
                <Typography className={classes.infoText}>
                  Please make sure your email is correct and don't use personal
                  emails. You won't be able to change this later.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container className={classes.containerDiv}>
        <Grid item xs={12} md={4}>
          <Typography
            className={
              width > 1024 ? classes.steptTitle : classes.stepTitleMobile
            }
          >
            Password
          </Typography>
        </Grid>
        <Grid item>
          <Grid item container alignItems="center">
            <TextField
              autoComplete="new-password"
              label="Password"
              name="password"
              type="password"
              fullWidth
              value={state.password}
              inputProps={{ 'aria-label': 'Password' }}
              style={{ width: wid }}
              onChange={e => {
                handleChange(e);
              }}
              error={error.password}
              helperText={
                error.password &&
                'Password must have at least: 1 uppercase letter, 1 lowercase letter, 1 number, 1 special symbol and 8 characters'
              }
            />
            <Grid>
              <Grid
                container
                style={{
                  position: 'relative',
                  left: wid > 1024 && '10%',
                  top: 15,
                }}
              >
                <InfoIcon />
                <Typography className={classes.infoText}>
                  Passwords must have at least: <br />
                  1 uppercase letter <br />
                  1 lowercase letter <br />
                  1 number <br />1 special symbol _ . * | /
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            container
            className={classes.marginTop}
            style={{ paddingBottom: 66 }}
          >
            <TextField
              autoComplete="new-password"
              label="Confirm Password"
              name="confirmPass"
              type="password"
              fullWidth
              value={state.confirmPass}
              inputProps={{ 'aria-label': 'Confirm Password' }}
              onChange={e => {
                handleChange(e);
              }}
              style={{ width: wid }}
              error={error.confirmPass}
              helperText={error.confirmPass && 'Passwords must match'}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
