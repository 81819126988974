import { useCallback, useContext, useEffect } from 'react';
import { UserContext, initialState } from '../contexts/user';
import api, {
  login,
  getUserInfo,
  resetPassword,
  updateAccount,
  updateUser,
  createUser,
} from '../services/api';

const noError = { status: 0, message: '' };

export default function useUserContext() {
  const [state, setState] = useContext(UserContext);

  const clearRequestErrors = useCallback(() => {
    setState(oldState => ({
      ...oldState,
      request: {
        ...oldState.request,
        error: noError,
      },
    }));
  }, [setState]);

  const signIn = useCallback(
    async ({ email, password }) => {
      setState(oldState => ({
        ...oldState,
        request: {
          error: noError,
          pending: true,
        },
      }));

      const response = await login({ email, password });

      if (response.status !== 200) {
        localStorage.removeItem('showroom-userToken');
        return setState(oldState => ({
          ...oldState,
          request: {
            error: {
              status: response.status,
              message: response.message,
            },
            pending: false,
          },
        }));
      }

      if (response.data.user.password !== false) {
        localStorage.setItem('showroom-userEmail', email.toLowerCase());
      }

      if (response.data.token) {
        localStorage.setItem('showroom-userToken', response.data.token);
        api.defaults.headers.Authorization = `Bearer ${response.data.token}`;
      }

      return setState(oldState => ({
        ...oldState,
        ...response.data,
        request: {
          error: noError,
          pending: false,
        },
      }));
    },
    [setState]
  );

  const logOut = useCallback(() => {
    localStorage.removeItem('showroom-userToken');
    window.location.reload(true);
  }, []);

  const reAuth = useCallback(async () => {
    const response = await getUserInfo({
      email: state.user.email,
      token: state.token,
    });

    if (response.status !== 200) {
      localStorage.removeItem('showroom-userToken');

      return setState(() => ({
        ...initialState,
        token: null,
      }));
    }

    return setState(oldState => ({
      ...oldState,
      user: {
        ...response.data,
      },
    }));
  }, [state, setState]);

  const resetPasswordHookMethod = async email => {
    setState(oldState => ({
      ...oldState,
      request: {
        error: noError,
        pending: true,
      },
    }));

    const response = await resetPassword({ email });

    if (response.status !== 204) {
      setState(oldState => ({
        ...oldState,
        request: {
          error: {
            status: response.status,
            message: response.message,
          },
          pending: false,
        },
      }));
      return false;
    }

    setState(oldState => ({
      ...oldState,
      request: {
        ...oldState.request,
        pending: false,
      },
    }));

    return true;
  };

  const skipFirstAccess = useCallback(() => {
    setState(oldState => ({
      ...oldState,
      user: {
        ...oldState.user,
        isFirstAccess: false,
      },
    }));
  }, [setState]);

  const finishUserRegister = useCallback(
    async data => {
      const response = await updateAccount(data);
      if (response.status === 200) {
        const user = response.data;
        setState(oldState => ({
          ...oldState,
          user,
        }));
      }
      return response;
    },
    [setState]
  );

  const updateAccountProperties = useCallback(
    async object => {
      const response = await updateAccount(object);
      if (response.status === 200)
        setState(oldState => ({
          ...oldState,
          user: response.data,
        }));
    },
    [setState]
  );

  const updateUserProperties = useCallback(
    async object => {
      const response = await updateUser(object);
      if (response.status === 200)
        setState(oldState => ({ ...oldState, user: response.data.user }));
    },
    [setState]
  );

  const createNewUserForAnAccount = useCallback(async object => {
    await createUser(object);
  }, []);

  useEffect(() => {
    api.defaults.headers.Authorization = state.token
      ? `Bearer ${state.token}`
      : null;
  }, [state.token]);

  return {
    userState: state,
    signIn,
    skipFirstAccess,
    clearRequestErrors,
    reAuth,
    logOut,
    resetPassword: resetPasswordHookMethod,
    finishUserRegister,
    updateAccountProperties,
    updateUserProperties,
    createNewUserForAnAccount,
  };
}
