import React, { useState, useEffect, useCallback } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { MdKeyboardBackspace } from 'react-icons/md';
import { useDebounce } from 'use-lodash-debounce';
import {
  IoMdHeartDislike,
  IoMdArrowDropdown,
  IoMdArrowDropup,
} from 'react-icons/io';
import { GrFormAdd } from 'react-icons/gr';
import Img from 'react-cool-img';
import useConfigContext from '../../hooks/useConfigContext';

import AddStyles from './AddStyles';

import api from '../../services/api';
import { dictIdDelivery, dictIdDeliveryFormatted } from '../../consts';

const dictOption = {
  Approved: 'green',
  Notes: 'blue',
  Cancelled: 'red',
  Pending: 'orange',
};

export default function MakeOrder({ orderSelected, handleBack }) {
  const { configState } = useConfigContext();
  const { deliveries } = configState;
  const [downloadExcelURL, setDownloadExcelURL] = useState('');
  const [open, setOpen] = useState(false);
  const [deliveryOpen, setDeliveryOpen] = useState(0);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState('');
  const [user, setUser] = useState('');
  const [d1, setD1] = useState([]);
  const [d2, setD2] = useState([]);
  const [d3, setD3] = useState([]);
  const [openD1, setOpenD1] = useState(false);
  const [openD2, setOpenD2] = useState(false);
  const [openD3, setOpenD3] = useState(false);
  const [totalD1, setTotald1] = useState(0);
  const [totalD2, setTotald2] = useState(0);
  const [totalD3, setTotald3] = useState(0);
  const [isUSD, setisUSD] = useState(true);
  const [myProducts, setMyProducts] = useState([]);

  const handleTotalD1 = num => {
    setTotald1(totalD1 + num);
  };

  const handleTotalD2 = num => {
    setTotald2(totalD2 + num);
  };
  const handleTotalD3 = num => {
    setTotald3(totalD3 + num);
  };

  const handleRemoveProductFromCollection = useCallback(
    async product => {
      try {
        await api.put(`/order/${orderSelected}`, {
          style_code: product.style_code,
        });
        setMyProducts(
          myProducts.filter(p => p.style_code !== product.style_code)
        );
      } catch (err) {
        console.log(err);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [myProducts]
  );
  const getOrder = async () => {
    const { data } = await api.get(`/order/${orderSelected}`);
    const response = await api.get(`/newCostumers/${data?.email}`);
    setUser(response?.data);
    setOrder(data);
    const d1Array = [];
    const d2Array = [];
    const d3Array = [];
    const ordenadedDelivery = data.products
      .map(product => {
        if (product.delivery_id === dictIdDelivery[1]) {
          d1Array.push(product);
        }
        if (product.delivery_id === dictIdDelivery[2]) {
          d2Array.push(product);
        }
        if (product.delivery_id === dictIdDelivery[3]) {
          d3Array.push(product);
        }
        return {
          ...product,
          formatted_delivery: dictIdDeliveryFormatted[product.delivery_id],
        };
      })
      .filter(i => !!i);

    setD1(d1Array);
    setD2(d2Array);
    setD3(d3Array);
    setMyProducts(ordenadedDelivery);
    setLoading(false);
  };

  useEffect(() => {
    if (loading && orderSelected) getOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderSelected, loading]);

  useEffect(() => {
    const d1Array = [];
    const d2Array = [];
    const d3Array = [];

    myProducts.forEach((product, index) => {
      if (product.delivery_id === dictIdDelivery[1]) {
        d1Array.push(product);
      }
      if (product.delivery_id === dictIdDelivery[2]) {
        d2Array.push(product);
      }
      if (product.delivery_id === dictIdDelivery[3]) {
        d3Array.push(product);
      }
      return {
        ...product,
        formatted_delivery: dictIdDeliveryFormatted[product.delivery_id],
      };
    });

    setD1(d1Array);
    setD2(d2Array);
    setD3(d3Array);
  }, [myProducts]);

  const Order = () => {
    return (
      <Grid style={{ alignContent: 'flex-start' }}>
        <Grid
          container
          justifyContent="space-between"
          style={{
            width: 848,
            borderBottom: '1px solid #000000',
            paddingBottom: 11,
            marginTop: 50,
          }}
        >
          <Grid onClick={() => setOpenD1(!openD1)}>
            <Typography
              display="inline"
              style={{ fontWeight: 'bold', fontSize: 16 }}
            >
              {`${deliveries[0].title} `}
            </Typography>
            <Typography display="inline" style={{ fontSize: 16 }}>
              {deliveries[0].subTitle1} | {deliveries[0].subTitle2}
            </Typography>
            <Grid style={{ marginTop: 2, marginLeft: 4, display: 'inline' }}>
              {openD1 ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}
            </Grid>
          </Grid>
          <Grid>
            <Typography
              display="inline"
              style={{ fontWeight: 'bold', fontSize: 16 }}
            />
            <Typography display="inline" style={{ fontSize: 14 }}>
              {d1.length} styles added
            </Typography>
            <Typography
              display="inline"
              style={{ fontSize: 14, marginLeft: 15, cursor: 'pointer ' }}
              onClick={() => {
                setOpen(true);
                setDeliveryOpen(0);
              }}
            >
              Add styles{' '}
              <GrFormAdd
                size={20}
                style={{ position: 'relative', top: 4, right: 5 }}
              />
            </Typography>
          </Grid>
        </Grid>
        <AddStyles
          open={open}
          onClose={() => setOpen(false)}
          email={user.email}
          delivery={deliveryOpen}
          orderId={orderSelected}
          handleLoading={() => setLoading(true)}
        />
        <Grid>
          {openD1 &&
            d1.map(product => (
              <Grid item key={product.style_code} style={{ marginBottom: 10 }}>
                <CartItem
                  handleRemoveProductFromCollection={
                    handleRemoveProductFromCollection
                  }
                  product={product}
                  isUSD={isUSD}
                  handleTotalPerDelivery={handleTotalD1}
                  delivery="d1"
                />
              </Grid>
            ))}
        </Grid>
        <Grid
          container
          justifyContent="space-between"
          style={{
            width: 848,
            borderBottom: '1px solid #000000',
            paddingBottom: 11,
            marginTop: 40,
          }}
        >
          <Grid onClick={() => setOpenD2(!openD2)}>
            <Typography
              display="inline"
              style={{ fontWeight: 'bold', fontSize: 16 }}
            >
              {`${deliveries[1].title} `}
            </Typography>
            <Typography display="inline" style={{ fontSize: 16 }}>
              {deliveries[1].subTitle1} | {deliveries[1].subTitle2}
            </Typography>
            <Grid style={{ marginTop: 2, marginLeft: 4, display: 'inline' }}>
              {openD2 ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}
            </Grid>
          </Grid>
          <Grid>
            <Typography display="inline" style={{ fontSize: 14 }}>
              {d2.length} styles added
            </Typography>
            <Typography
              display="inline"
              style={{ fontSize: 14, marginLeft: 15, cursor: 'pointer ' }}
              onClick={() => {
                setOpen(true);
                setDeliveryOpen(1);
              }}
            >
              Add styles{' '}
              <GrFormAdd
                size={20}
                style={{ position: 'relative', top: 4, right: 5 }}
              />
            </Typography>
          </Grid>
        </Grid>

        <Grid>
          {openD2 &&
            d2.map(product => (
              <Grid item key={product.style_code}>
                <CartItem
                  isUSD={isUSD}
                  handleRemoveProductFromCollection={
                    handleRemoveProductFromCollection
                  }
                  product={product}
                  handleTotalPerDelivery={handleTotalD2}
                  delivery="d2"
                />
              </Grid>
            ))}
        </Grid>

        <Grid
          container
          justifyContent="space-between"
          style={{
            width: 848,
            borderBottom: '1px solid #000000',
            paddingBottom: 11,
            marginTop: 40,
          }}
        >
          <Grid onClick={() => setOpenD3(!openD3)}>
            <Typography
              display="inline"
              style={{ fontWeight: 'bold', fontSize: 16 }}
            >
              {`${deliveries[2].title} `}
            </Typography>
            <Typography display="inline" style={{ fontSize: 16 }}>
              {deliveries[2].subTitle1} | {deliveries[2].subTitle2}
            </Typography>
            <Grid style={{ marginTop: 2, marginLeft: 4, display: 'inline' }}>
              {openD3 ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}
            </Grid>
          </Grid>
          <Grid>
            <Typography display="inline" style={{ fontSize: 14 }}>
              {d3.length} styles added
            </Typography>
            <Typography
              display="inline"
              style={{ fontSize: 14, marginLeft: 15, cursor: 'pointer ' }}
              onClick={() => {
                setOpen(true);
                setDeliveryOpen(2);
              }}
            >
              Add styles{' '}
              <GrFormAdd
                size={20}
                style={{ position: 'relative', top: 4, right: 5 }}
              />
            </Typography>
          </Grid>
        </Grid>
        <Grid>
          {openD3 &&
            d3.map(product => (
              <Grid item key={product.style_code}>
                <CartItem
                  isUSD={isUSD}
                  handleRemoveProductFromCollection={
                    handleRemoveProductFromCollection
                  }
                  product={product}
                  handleTotalPerDelivery={handleTotalD3}
                  delivery="d3"
                />
              </Grid>
            ))}
        </Grid>
        <Grid style={{ height: 100 }} />
      </Grid>
    );
  };

  const CartItem = ({
    product,
    handleRemoveProductFromCollection,
    isUSD,
    nav = 'place',
    delivery,
  }) => {
    const [qtd, setQtd] = useState(null);
    const [total, setTotal] = useState(null);
    const [cartInfo, setCartInfo] = useState(product.cartInfo);
    const [newQtd, setNewQtd] = useState(null);
    const [sc, setStyle_code] = useState(null);
    const [sz, setSize] = useState(null);
    const [newProduct, setNewProduct] = useState(null);

    const debouncedValue = useDebounce(newQtd, 300);

    const handleReqQtd = async () => {
      await api.post('/my/cart', {
        style_code: sc,
        size: sz,
        quantity: debouncedValue,
      });
    };

    useEffect(() => {
      if (debouncedValue) {
        handleReqQtd();
        // handleTotalPerDelivery(
        //   debouncedValue,
        //   product,
        //   d1 ? 'd1' : d2 ? 'd2' : 'd3'
        // );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedValue]);

    const handleChangeCartInfo = async (style_code, size, quantity) => {
      const newCartInfo = cartInfo.map(item => {
        const obj = { style_code, size, quantity };
        return size === item.size ? obj : item;
      });
      if (!newCartInfo.some(item => item.size === size)) {
        newCartInfo.push({ size, quantity });
      }

      setCartInfo(newCartInfo);
      setNewQtd(quantity);
      setStyle_code(style_code);
      setSize(size);
      const nProduct = product;
      nProduct.cartInfo = newCartInfo;
      setNewProduct(nProduct);
      if (downloadExcelURL) {
        setDownloadExcelURL('');
      }
    };

    React.useEffect(() => {
      let aux = 0;
      cartInfo.forEach(size => {
        aux += Number(size.quantity);
      });

      setQtd(aux + Number(debouncedValue));
      setTotal(
        (aux + Number(debouncedValue)) *
          (isUSD ? product.wholesale_usd : product.wholesale_eur)
      );
      if (debouncedValue) {
        if (delivery === 'd1') {
          setD1(
            d1.map(item =>
              item.style_code === newProduct.style_code ? newProduct : item
            )
          );
        }
        if (delivery === 'd2') {
          setD2(
            d2.map(item =>
              item.style_code === newProduct.style_code ? newProduct : item
            )
          );
        }
        if (delivery === 'd3') {
          setD3(
            d3.map(item =>
              item.style_code === newProduct.style_code ? newProduct : item
            )
          );
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedValue]);

    return (
      <>
        <Grid
          container
          justifyContent="space-between"
          style={{
            paddingLeft: 11,
            paddingRight: 17,
            width: 848,
            margin: '40px 0px',
            flexWrap: 'nowrap',
          }}
        >
          <Img
            onClick={() => handleRemoveProductFromCollection(product)}
            src={
              product.mainly_image ||
              'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAPFBMVEXu7u62trbx8fHPz8/t7e2zs7O8vLy5ubnl5eWrq6vc3Nzq6urZ2dnh4eHV1dW6urrHx8fBwcHKysqlpaXy5cTEAAAEF0lEQVR4nO3bjXKkKBQFYLj8CAIK5v3fdS/Y9mi3md1UZavFOt9UTTpgujwBQaEjBAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAEDH6N2nT+l3TfaNulNEGo18Y+KnT+sXUXkPKGW5USOGs4BShk+f1++JJ52Uu+l0m0akfN6GN+im66RA7jygNK7veYNcnBo1fJNQDmo9IrouQ2az+S4gt+ImdzjqlLMp0Pz5/6XGfvp8f4qm9xhmVimwNNmTys4GVv0+x5uStiGFKO1a+NGktq+EQtjXgGo3ZOpdG5tlaiPR0HvC+BJgu1U1C7eo6T+heQQkEYJ4vHzc6PD1F/pPaHI7fXKZi+2yzn60tLrBhdx/Qqlbmdpmi6WlCcN+pOk7YXvYpeU5fj4iqv2k0XVC48TLU/B6XYbbJGz3K/rQqi0PDXdJ2MYZd7iPMXW0OTxV9ZzQ1MuQjk/BrZvS/kLsOmEdV15uVNttKKm7tGEbOceTNlxuknBdrTiuRpn6PHi4Pe864VAnfMr7RmyhSd4loRlfG7HNkCLdZj5cb0t3U/464R+X4LpOKE1qiRw/2/M/Y9db7yTvk1DOayEllbNKdHZI5wkfj09itzx6HHm6T8gR9SEB6ZeA3SeUxnLn1FttuyRvlrA247aIz8/678uJN0hYB9FlinFa7NmacG8Jz/dE12X885re1kvP1rz/qrc17/pI/6OIZtb//p4XE6z5gdLh3hPfwEzqP5pSb1304eRTQt/49JkCAAAAAEBftHt/0D0rq9zFHhmfH1n7U0LHkrY749uC/lnZ/r3aMet+zjXoGEaVRN18UfG58hvVWBdGp6kGiDoqRy76aWxV9S8RjmXCRdLCjfwjiotDNOo6f66g/TBYHwUVX8y6cSaCNPwdTd5yDWkvZ+vHcfBz4apZSSv2ZbWfpi/H71Bo9Hnx0Q1+3Xe8BG0yUbGkfOCv87pzZohCCF+RKPog/EJkCyd1RLnwU70f92X1XWjO/DoJ7p2csvXSy3RTXU9GzWTr/svo2wb29gGM+pqb1yc+whJfc1w1lFI43q6s4t+EiIac59rC7Xmp69AkUROW0hK2Pe0tIX9DX9xe9YgtYR6Z25e1d+HIVnHCWKv1JROO3PXCY8NX8XmnxD1R6MwxazJOo/2oSZlAgct3Ze2CIyVr+8vMv5lIwkx0neuwtYbkPueNL4+yzK8XSsZ749r1RYvlq9Rz3FqV9b5snfoCD008lBqu5mEre3+dGbG1Qe2PIYXnVNdek3CtD9Zz1aFO7+552KGsvY1v00b9kdrFt+IrOblhOfvyTRlN5v85rasYzdfrh8FvJox9/m3QD9w9HwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAANzJP/MdJGqyWhqXAAAAAElFTkSuQmCC'
            }
            alt="farm"
            style={{
              width: 100,
              height: 'auto',
              marginRight: 22,
            }}
          />
          <Grid container>
            <Grid
              container
              alignItems="space-between"
              justifyContent="space-between"
              style={{
                paddingBottom: 5,
                borderBottom: '1px solid rgba(0, 0, 0, 0.5)',
              }}
            >
              <Grid>
                <Grid>
                  <Typography
                    style={{
                      fontSize: 12,
                      fontWeight: 'bold',
                      lineHeight: '21px',
                    }}
                  >
                    {product?.description}
                  </Typography>
                  <Typography style={{ fontSize: 12 }}>
                    {product?.style_code}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                style={{ marginTop: product.description.length < 28 && 10 }}
              >
                <Grid>
                  {' '}
                  <Typography
                    display="inline"
                    align="right"
                    style={{ fontWeight: 'bold', fontSize: 12, marginRight: 8 }}
                  >
                    Suggested Retail{' '}
                  </Typography>
                  <Typography
                    display="inline"
                    style={{ fontWeight: 'normal', fontSize: 12 }}
                  >
                    {isUSD ? (
                      <span>$ {Number(product?.msr_price_usd).toFixed(2)}</span>
                    ) : (
                      <span>
                        &euro; {Number(product?.msr_price_eur).toFixed(2)}
                      </span>
                    )}
                  </Typography>
                </Grid>

                <Grid
                  container
                  justifyContent="flex-end"
                  style={{
                    marginTop: 3,
                  }}
                >
                  {' '}
                  <Typography
                    display="inline"
                    align="right"
                    style={{ fontWeight: 'bold', fontSize: 12, marginRight: 9 }}
                  >
                    Wholesale{' '}
                  </Typography>
                  <Typography
                    display="inline"
                    style={{ fontWeight: 'normal', fontSize: 12 }}
                  >
                    {isUSD ? <span>$</span> : <span>&euro;</span>}{' '}
                    {(
                      Math.round(
                        (isUSD
                          ? product.wholesale_usd
                          : product.wholesale_eur) * 100
                      ) / 100
                    ).toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="space-between"
              style={{
                paddingBottom: 14,
                marginTop: 8,
                borderBottom: nav === 'place' && '1px solid rgba(0, 0, 0, 0.5)',
              }}
            >
              <Grid>
                <Grid container>
                  <Typography
                    display="inline"
                    style={{ fontWeight: 'bold', marginRight: 19 }}
                  >
                    Sizes
                  </Typography>
                  {product.sizes &&
                    Object.keys(product.sizes).map(size => (
                      <Grid style={{ marginRight: 8 }}>
                        <Typography
                          align="right"
                          style={{
                            marginBottom: 8,
                            fontSize: 12,
                          }}
                        >
                          {size}
                        </Typography>
                        {nav === 'place' ? (
                          <input
                            onChange={event => {
                              handleChangeCartInfo(
                                product.style_code,
                                size,
                                event.target.value
                              );
                            }}
                            style={{
                              border: '1px solid rgba(0, 0, 0, 0.5)',
                              width: 46,
                              height: 22,
                              fontSize: 12,
                              textAlign: 'right',
                              paddingRight: 4,
                            }}
                            value={
                              cartInfo?.find(item => item.size === size)
                                ?.quantity
                            }
                          />
                        ) : (
                          <Typography
                            align="right"
                            style={{ fontSize: 12, width: 46, height: 22 }}
                          >
                            {cartInfo?.find(item => item.size === size)
                              ?.quantity || 0}
                          </Typography>
                        )}
                      </Grid>
                    ))}
                </Grid>
              </Grid>
              <Grid>
                <Grid container>
                  <Grid style={{ marginRight: 21 }}>
                    <Typography
                      style={{
                        fontWeight: 'bold',
                        fontSize: 12,
                        marginBottom: 11,
                      }}
                    >
                      Quantity
                    </Typography>
                    <Typography align="right" style={{ fontSize: 12 }}>
                      {qtd}
                    </Typography>
                  </Grid>
                  <Grid>
                    <Typography
                      align="right"
                      style={{
                        fontWeight: 'bold',
                        fontSize: 12,
                        marginBottom: 11,
                      }}
                    >
                      Total
                    </Typography>
                    <Typography style={{ fontWeight: 'bold', fontSize: 12 }}>
                      {total ? (
                        isUSD ? (
                          <span>$</span>
                        ) : (
                          <span>&euro;</span>
                        )
                      ) : null}{' '}
                      {total}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              style={{
                cursor: 'pointer',
                margin: '6px 0px',
              }}
              container
              alignItems="center"
              onClick={() => handleRemoveProductFromCollection(product)}
            >
              <Typography style={{ fontSize: 12, marginRight: 10 }}>
                Remove from order
              </Typography>
              <IoMdHeartDislike
                style={{ position: 'relative', top: 1.5 }}
                size={12}
              />
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };
  return (
    <Grid
      container
      style={{ alignContent: 'flex-start', padding: '20px 80px 0px 35px' }}
    >
      <Grid container justifyContent="center">
        <Typography
          style={{
            fontFamily: 'Galano Grotesque Light',
            fontSize: 24,
            lineHeight: '28px',
            letterSpacing: '0.1em',
            marginBottom: 25,
          }}
        >
          {order && `EDIT ORDER #${order?.num}`}
        </Typography>
      </Grid>
      <Grid
        container
        alignItems="center"
        style={{ margin: '12px 0px 24px', cursor: 'pointer' }}
        onClick={handleBack}
      >
        <MdKeyboardBackspace />
        <Typography style={{ marginLeft: 8, position: 'relative', bottom: 2 }}>
          Back
        </Typography>
      </Grid>
      <Grid container justifyContent="space-between">
        <Grid>
          <Typography
            style={{ color: '#5b5b5b', fontSize: 12, lineHeight: '34px' }}
          >
            Account/Shop Name
          </Typography>
          <Typography>{user?.shopName}</Typography>
        </Grid>
        <Grid>
          <Typography
            style={{ color: '#5b5b5b', fontSize: 12, lineHeight: '34px' }}
          >
            Name
          </Typography>
          <Typography>{user?.name}</Typography>
        </Grid>
        <Grid>
          <Typography
            style={{ color: '#5b5b5b', fontSize: 12, lineHeight: '34px' }}
          >
            Email
          </Typography>
          <Typography>{user?.email}</Typography>
        </Grid>
        <Grid>
          <Typography
            style={{ color: '#5b5b5b', fontSize: 12, lineHeight: '34px' }}
          >
            Phone
          </Typography>
          <Typography>{user?.phone}</Typography>
        </Grid>
        <Grid>
          <Typography
            style={{ color: '#5b5b5b', fontSize: 12, lineHeight: '34px' }}
          >
            Status
          </Typography>
          <Grid container alignItems="center">
            <div
              style={{
                width: 14,
                height: 14,
                background: dictOption[order?.status],
                borderRadius: 7,
                marginRight: 8,
                marginTop: 5,
              }}
            />
            <span>{order?.status}</span>
          </Grid>
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between">
        <Grid>
          <Typography
            style={{ color: '#5b5b5b', fontSize: 12, lineHeight: '34px' }}
          >
            Shipping Address
          </Typography>
          <Typography>
            {order?.sAddress?.sLine1} {order?.sAddress?.sLine2}
          </Typography>
        </Grid>
        <Grid>
          <Typography
            style={{ color: '#5b5b5b', fontSize: 12, lineHeight: '34px' }}
          >
            City
          </Typography>
          <Typography>{order?.sAddress?.sLine3}</Typography>
        </Grid>
        <Grid>
          <Typography
            style={{ color: '#5b5b5b', fontSize: 12, lineHeight: '34px' }}
          >
            State/Province
          </Typography>
          <Typography>{order?.sAddress?.sStoreProvince}</Typography>
        </Grid>
        <Grid>
          <Typography
            style={{ color: '#5b5b5b', fontSize: 12, lineHeight: '34px' }}
          >
            Zipcode
          </Typography>
          <Typography>{order?.sAddress?.sZipcode}</Typography>
        </Grid>
        <Grid>
          <Typography
            style={{ color: '#5b5b5b', fontSize: 12, lineHeight: '34px' }}
          >
            Country
          </Typography>
          <Typography>{order?.sAddress?.sCountry}</Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="flex-end" style={{ marginTop: 30 }}>
        <Grid>
          <Typography
            display="inline"
            onClick={() => setisUSD(true)}
            style={{
              paddingBottom: 3,
              marginRight: 20,
              borderBottom: isUSD && '2px solid #000000',
              cursor: 'pointer',
              fontSize: 12,
              fontWeight: isUSD && 'bold',
            }}
          >
            USD
          </Typography>
          <Typography
            display="inline"
            onClick={() => setisUSD(false)}
            style={{
              paddingBottom: 3,
              marginRight: 20,
              borderBottom: !isUSD && '2px solid #000000',
              cursor: 'pointer',
              fontSize: 12,
              fontWeight: !isUSD && 'bold',
            }}
          >
            EUR
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <Order />
      </Grid>
    </Grid>
  );
}
