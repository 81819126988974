import React, { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ReactPlayer from 'react-player';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 400,
    flexGrow: 1,
    position: 'relative',
    top: '10%',
    margin: '0 auto'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing.unit * 4,
    backgroundColor: theme.palette.background.default,
  },
  img: {
    height: 'auto',
    display: 'block',
    overflow: 'hidden',
    width: '95%',
    margin: '0 auto'
  },
}));


export default function SwipeableTextMobileStepper({photos, handleClose, DetailProduct}){
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);

  const handleStepChange = activeStep => {
    setActiveStep(activeStep);
  };

  const handleSelect = (index) => {
    setActiveStep(index);
  };

  return (
    <div className={classes.root}>
      <Grid 
        container 
        style={{ width: '95%',
          position: 'relative',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          margin: '0 auto',
          zIndex: 999,
          backgroundColor:"#fff",
          padding:"4px",
          opacity: 0.85
        }}
      >
        <Grid item>
          {photos && photos.map((step, index) => (
              <IconButton style={{ padding: '7px' }} key={String(index + 1)} onClick={() => handleSelect(index)}>
                <Typography 
                  variant="body2"
                  style={{
                    fontSize: 14,
                    fontFamily: 'Galano Grotesque Light',
                    color: '#000',
                    borderBottom:
                      index === activeStep
                        ? '2px solid #000'
                        : null,
                    fontWeight:
                      index === activeStep
                        ? 'bold'
                        : '',
                  }}>
                    {String(index + 1).padStart(2, '0')}
                </Typography>
              </IconButton>
          ))}
        </Grid>
        <Grid item style={{ position: 'relative', padding: '7px' }}>
          <Typography
            onClick={handleClose}
            variant="body2"
            style={{
              color: '#000',
              cursor: 'pointer',
            }}
          >
            Close{' '}
            <AddIcon
              style={{
                marginBottom: -4,
                color: '#000',
                fontSize: 18,
                transform: 'rotate(135deg)',
              }}
            />
          </Typography>
        </Grid>
      </Grid>
      
      <SwipeableViews
        axis={'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {photos && photos.map((step, index) => (
          <div key={step}>
            {!step.includes('.mp4') ? (
              Math.abs(activeStep - index) <= 2 ? (
                <img className={classes.img} src={step} alt={step} />
              ) : null
            ) : (
              <ReactPlayer
                className={classes.img}
                loop
                url={step}
                height="auto"
                width="95%"
                playing
              />
            )
            }
          </div>
        ))}
      </SwipeableViews>
      <DetailProduct />
    </div>
  );
}

//     class SwipeableTextMobileStepper extends React.Component {
//       state = {
//         activeStep: 0,
//       };

//       handleNext = () => {
//         this.setState(prevState => ({
//           activeStep: prevState.activeStep + 1,
//         }));
//       };

//       handleBack = () => {
//         this.setState(prevState => ({
//           activeStep: prevState.activeStep - 1,
//         }));
//       };

//       handleStepChange = activeStep => {
//         this.setState({ activeStep });
//       };

//       handle = activeStep => {
//         this.setState({ activeStep: 4 });
//       };

//       render() {
//         const { classes, theme } = this.props;
//         const { activeStep } = this.state;
//         const maxSteps = tutorialSteps.length;
//     return (
//       <div className={classes.root}>
//         <Paper square elevation={0} className={classes.header}>
//           <Typography>{tutorialSteps[activeStep].label}</Typography>
//         </Paper>
//         <SwipeableViews
//           axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
//           index={activeStep}
//           onChangeIndex={this.handleStepChange}
//           enableMouseEvents
//         >
//           {tutorialSteps.map((step, index) => (
//             <div key={step.label}>
//               {Math.abs(activeStep - index) <= 2 ? (
//                 <img className={classes.img} src={step.imgPath} alt={step.label} />
//               ) : null}
//             </div>
//           ))}
//         </SwipeableViews>
//         { console.log(maxSteps, activeStep) }
//         {/* <MobileStepper
//           steps={maxSteps}
//           position="static"
//           activeStep={activeStep}
//           className={classes.mobileStepper}
//           nextButton={
//             <Button size="small" onClick={this.handleNext} disabled={activeStep === maxSteps - 1}>
//               Next
//               {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
//             </Button>
//           }
//           backButton={
//             <Button size="small" onClick={this.handleBack} disabled={activeStep === 0}>
//               {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
//               Back
//             </Button>
//           }
//         /> */}
//       </div>
//     );
//   }
// }
// SwipeableTextMobileStepper.propTypes = {
//   classes: PropTypes.object.isRequired,
//   theme: PropTypes.object.isRequired,
// };

// export default withStyles(styles, { withTheme: true })(SwipeableTextMobileStepper);