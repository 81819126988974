import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, IconButton, Dialog } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import Button from '../Button';
import api from '../../services/api';

const StyledDialog = withStyles({
  paper: {
    margin: 0,
    display: 'flex',
    justifyContent: 'center',
    // borderRadius: 10,
  },
  paperFullScreen: {
    margin: 0,
    // height: '100%',
    backgroundColor: 'white',
    // borderRadius: 10,
  },
  paperWidthLg: {
    maxWidth: '100%',
  },
  scrollPaper: {
    alignItems: 'center',
    // borderRadius: 10,
  },
  paperFullWidth: {
    width: '100%',
  },
  container: {
    height: 'auto',
    // borderRadius: 10,
  },
})(Dialog);

const useStyles = makeStyles(() => ({
  accordionDetails: {
    '&.MuiAccordionDetails-root': {
      display: 'block',
    },
    '&.MuiAccordionDetails-root .MuiPaper-elevation1': {
      boxShadow: 'none',
    },
  },
  accordion: {
    '&.MuiAccordion-root:before': {
      backgroundColor: 'unset',
    },
    '&.MuiAccordion-root.Mui-expanded': {
      margin: 'unset',
    },
  },
  gridItem: {
    display: 'flex',
  },
  span: {
    height: 15,
    width: 15,
    borderRadius: '50%',
  },
  summaryProduct: {
    textAlign: 'end',
  },
  summaryItem: {
    width: 95,
  },
  summaryStyle: {
    width: 87,
  },
  button: {
    '&.MuiButton-label': {
      alignItems: 'end',
    },
  },
  textAddresses: {
    fontSize: '10px',
  },
  titleAddresses: {
    fontSize: '12px',
  },
  bold: {
    fontWeight: 'bold',
    fontSize: 14,
  },
  downloadButton: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'flex-end',
  },
}));

const dictOption = {
  Approved: 'green',
  Notes: 'blue',
  Cancelled: 'red',
  Pending: 'orange',
};

export default function ModalAddNewAddress({
  open,
  handleClose,
  order,
  newOrder,
  editOrder,
}) {
  const classes = useStyles();

  const [user, setUser] = React.useState('');
  const [orderInfo, setOrderInfo] = React.useState({});

  const getUser = async () => {
    const response = await api.get(`/newCostumers/${order?.email}`);
    setUser(response.data);
  };

  React.useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  const Divider = ({ margin, width }) => {
    return (
      <Grid container justifyContent="center">
        <div
          style={{
            width: width || 340,
            height: 1,
            borderBottom: '1px solid #000',
            margin: margin || '24px 0px',
          }}
        />
      </Grid>
    );
  };

  const generateSummaryItems = React.useCallback(() => {
    let price = 0;
    let items = 0;
    const styles = order?.products.length;
    order.products.forEach(product => {
      product.cartInfo.forEach(upc => {
        // eslint-disable-next-line no-unused-expressions
        items += upc.quantity;
        price += upc.quantity * product.wholesale_usd;
      });
    });
    setOrderInfo({ price, items, styles });
  }, [order]);

  React.useEffect(() => {
    if (order) generateSummaryItems();
  }, [generateSummaryItems, order]);

  return (
    user && (
      <StyledDialog
        BackdropProps={{ style: { borderRadius: 6 } }}
        open={open}
        onClose={handleClose}
        style={{
          top: window.innerWidth > 1024 ? '50.9%' : '10%',
          left: window.innerWidth > 1024 && '50%',
          transform: window.innerWidth > 1024 && 'translate(-50%, -50%)',
          width: window.innerWidth > 1024 ? 480 : '100%',
          zIndex: 2000,
        }}
      >
        <Grid
          style={{
            padding: '5%',
            width: window.innerWidth > 1024 ? 480 : '100%',
          }}
        >
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid style={{ width: 40 }} />
            <Typography
              align="center"
              style={{
                fontSize: window.innerWidth > 1024 ? 16 : 14,
                letterSpacing: '0.1em',
              }}
            >
              YOU ALREADY HAVE AN OPEN <br /> ORDER TO THIS ADDRESS
            </Typography>
            <IconButton style={{ width: 40 }} onClick={handleClose}>
              <ClearIcon />
            </IconButton>
          </Grid>
          <Divider margin="16px 0px 40px" />
          <Grid container justifyContent="center">
            <Grid
              container
              justifyContent="space-between"
              style={{ width: 340 }}
            >
              <Typography style={{ fontSize: 14, lineHeight: '16px' }}>
                Order #{order?.num}
              </Typography>{' '}
              <Grid>
                <Grid container alignItems="center">
                  <div
                    style={{
                      width: 14,
                      height: 14,
                      background: dictOption[order?.status],
                      borderRadius: 7,
                      marginRight: 8,
                      marginTop: 5,
                    }}
                  />
                  <span style={{ fontSize: 12 }}>{order?.status}</span>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider margin="8px 0px" />

          <Grid container justifyContent="center">
            <Grid
              container
              justifyContent="space-between"
              style={{ width: 340 }}
            >
              <Grid>
                <img
                  src={order?.products[0]?.mainly_image}
                  style={{ width: 58, height: 80 }}
                  alt="first_product_mainly_image"
                />
              </Grid>
              <Grid style={{ marginLeft: 8, width: 274 }}>
                <Grid container justifyContent="space-between">
                  <Grid>
                    <Typography className={classes.bold}>
                      {orderInfo?.styles} styles
                    </Typography>
                  </Grid>
                  <Grid>
                    <Typography align="center" className={classes.bold}>
                      {orderInfo?.items} items
                    </Typography>
                  </Grid>
                  <Grid>
                    <Typography align="right" className={classes.bold}>
                      $ {orderInfo?.price}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider width="274px" margin="8px 0px" />
                <Grid>
                  <Typography style={{ fontSize: 12 }}>
                    <span style={{ fontWeight: 'bold' }}>
                      {order?.sAddress?.name}
                    </span>{' '}
                    <br />
                    {order?.sAddress?.street}
                    {order?.sAddress?.building_unit_floor && ', '}{' '}
                    {order?.sAddress?.building_unit_floor} <br />
                    {order?.sAddress?.city} - {order?.sAddress?.state_province}{' '}
                    <br />
                    {order?.sAddress?.zipcode} - {order?.sAddress?.country}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container justifyContent="center">
            <Typography
              style={{
                fontSize: 12,
                width: 368,
                lineHeight: '18px',
                marginTop: 32,
              }}
            >
              To create a new order to this address, you first have to submit or
              cancel the current order. Do you want to cancel and start a new
              one or keep editing your current order?
            </Typography>
          </Grid>

          <Grid container justifyContent="center" style={{ paddingBottom: 38 }}>
            <Grid>
              <Button
                justifyContent="center"
                width={176}
                height={40}
                textColor="#000000"
                color="#FFFFFF"
                text="Cancel Current Order"
                marginTop={32}
                fontSize={14}
                borderColor="1px solid #000"
                onClick={newOrder}
              />
            </Grid>
            <Grid style={{ marginLeft: 16 }}>
              <Button
                justifyContent="center"
                width={176}
                height={40}
                color="#000000"
                textColor="#FFFFFF"
                text="Edit Current Order"
                marginTop={32}
                fontSize={14}
                onClick={() => {
                  editOrder();
                  handleClose();
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </StyledDialog>
    )
  );
}
