/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import {
  Typography,
  FormControlLabel,
  RadioGroup,
  Radio,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckLabel from '../../components/CheckLabel';
import ModalAddress from '../../components/ModalAddresses';
import Input from '../../components/TextInput';

import { country_list } from '../../consts';

const RemoveIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 6C4 5.72386 3.77614 5.5 3.5 5.5C3.22386 5.5 3 5.72386 3 6H4ZM13 6C13 5.72386 12.7761 5.5 12.5 5.5C12.2239 5.5 12 5.72386 12 6H13ZM12 6V12.5H13V6H12ZM11.5 13H4.5V14H11.5V13ZM4 12.5V6H3V12.5H4ZM4.5 13C4.22386 13 4 12.7761 4 12.5H3C3 13.3284 3.67157 14 4.5 14V13ZM12 12.5C12 12.7761 11.7761 13 11.5 13V14C12.3284 14 13 13.3284 13 12.5H12Z"
        fill="black"
      />
      <path
        d="M6.5 7V11.5M9.5 7V11.5"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 3C9.5 3.27614 9.72386 3.5 10 3.5C10.2761 3.5 10.5 3.27614 10.5 3H9.5ZM5.5 3C5.5 3.27614 5.72386 3.5 6 3.5C6.27614 3.5 6.5 3.27614 6.5 3H5.5ZM7 2H9V1H7V2ZM9.5 2.5V3H10.5V2.5H9.5ZM6.5 3V2.5H5.5V3H6.5ZM9 2C9.27614 2 9.5 2.22386 9.5 2.5H10.5C10.5 1.67157 9.82843 1 9 1V2ZM7 1C6.17157 1 5.5 1.67157 5.5 2.5H6.5C6.5 2.22386 6.72386 2 7 2V1Z"
        fill="black"
      />
      <path d="M2 4.5L14 4.5" stroke="black" strokeLinecap="round" />
    </svg>
  );
};

const EditIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 2.5C5.27614 2.5 5.5 2.27614 5.5 2C5.5 1.72386 5.27614 1.5 5 1.5V2.5ZM10.5 7C10.5 6.72386 10.2761 6.5 10 6.5C9.72386 6.5 9.5 6.72386 9.5 7H10.5ZM9 10.5H2V11.5H9V10.5ZM1.5 10V3H0.5V10H1.5ZM2 2.5H5V1.5H2V2.5ZM9.5 7V10H10.5V7H9.5ZM2 10.5C1.72386 10.5 1.5 10.2761 1.5 10H0.5C0.5 10.8284 1.17157 11.5 2 11.5V10.5ZM9 11.5C9.82843 11.5 10.5 10.8284 10.5 10H9.5C9.5 10.2761 9.27614 10.5 9 10.5V11.5ZM1.5 3C1.5 2.72386 1.72386 2.5 2 2.5V1.5C1.17157 1.5 0.5 2.17157 0.5 3H1.5Z"
        fill="black"
      />
      <path
        d="M4 5.96098L3.65149 5.60246C3.55464 5.6966 3.5 5.82592 3.5 5.96098H4ZM6.02911 8V8.5C6.16404 8.5 6.29324 8.44547 6.38736 8.34879L6.02911 8ZM4 8H3.5C3.5 8.27614 3.72386 8.5 4 8.5V8ZM10.7015 1.76597L11.0551 1.41241V1.41241L10.7015 1.76597ZM10.7015 3.20076L10.3479 2.84718L10.3433 2.85197L10.7015 3.20076ZM8.79924 1.29584L9.14778 1.65439L9.15214 1.65004L8.79924 1.29584ZM10.2327 1.29716L9.87916 1.65071L9.87917 1.65071L10.2327 1.29716ZM4.34851 6.31951L9.14775 1.65437L8.45073 0.937312L3.65149 5.60246L4.34851 6.31951ZM9.87917 1.65071L10.348 2.11952L11.0551 1.41241L10.5863 0.943602L9.87917 1.65071ZM10.3433 2.85197L5.67085 7.65121L6.38736 8.34879L11.0598 3.54955L10.3433 2.85197ZM6.02911 7.5H4V8.5H6.02911V7.5ZM4.5 8V5.96098H3.5V8H4.5ZM10.348 2.11952C10.5489 2.32047 10.5489 2.64626 10.348 2.84721L11.0551 3.55432C11.6466 2.96284 11.6466 2.00388 11.0551 1.41241L10.348 2.11952ZM9.15214 1.65004C9.3532 1.44973 9.67848 1.45002 9.87916 1.65071L10.5863 0.943602C9.99557 0.352901 9.03813 0.352022 8.44634 0.941636L9.15214 1.65004Z"
        fill="black"
      />
      <path
        d="M7.70467 2.26615L9.60696 4.16844L10.3141 3.46133L8.41178 1.55904L7.70467 2.26615Z"
        fill="black"
      />
    </svg>
  );
};

const useStyles = makeStyles(() => ({
  containerDiv: {
    marginTop: 90,
  },
  marginTop: {
    marginTop: 48,
  },
  subtitleText: {
    fontSize: 12,
    color: '#616161',
  },
  steptTitle: {
    fontSize: 14,
    lineHeight: '18px',
  },
  stepSubtitle: {
    fontSize: 12,
    lineHeight: '18px',
    color: '#616161',
    marginTop: 12,
    width: 247,
  },
  stepTitleMobile: {
    fontSize: 18,
    lineHeight: '18px',
    paddingBottom: 16,
  },
  infoText: {
    fontSize: '10px',
    lineHeight: '12px',
    color: '#616161',
    width: 165,
    position: 'relative',
    left: 4,
  },
  divAddress: {
    backgroundColor: 'white',
    border: '1px solid white',
    '&:hover': {
      border: '1px solid black',
    },
  },
}));

const PlusIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 8V24"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24 16H8"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default function Account({
  state,
  handleChange,
  error,
  step,
  setState,
  country,
  width,
}) {
  const classes = useStyles();
  const [openAddress, setOpenAddress] = useState(false);
  const [id, setId] = useState(-1);
  const [editValues, setEditValues] = useState('');
  const [hasFreight, setHasFreight] = useState('No');

  const wid =
    width < 1024
      ? width * 0.8
      : window.innerWidth > 1300
      ? 377
      : window.innerWidth > 1150
      ? 277
      : 210;

  const handleOpenEdit = (ad, cit, adName, stProv, zcode, ct, i, st, unit) => {
    setOpenAddress(true);
    setEditValues({
      modalAddress: ad,
      modalCity: cit,
      modalStoreProv: stProv,
      modalZipcode: zcode,
      modalStreet: st,
      modalUnit: unit,
      modalCountry: ct,
    });

    setId(i);
  };

  const handleClose = () => {
    setOpenAddress(false);
    setId(-1);
  };

  const handleSaveAddress = address => {
    if (id === -1) {
      const newArr = state.doorsAddress.map(doorsAddress => doorsAddress);
      newArr.push(address);
      setState({ ...state, doorsAddress: newArr });
    } else {
      const newArr = state.doorsAddress.map((item, i) =>
        i !== id ? item : address
      );
      setState({ ...state, doorsAddress: newArr });
    }
    handleClose();
  };

  const handleDeleteDoorAddress = index => {
    setState({
      ...state,
      doorsAddress: state.doorsAddress.filter((item, i) => index !== i),
    });
  };

  const handleChangeBooleanAddress = (key, index) => {
    const addresses = state.doorsAddress.map((address, i) =>
      i !== index
        ? address
        : key === 'isShippingAddress'
        ? {
            ...address,
            isShippingAddress: !address.isShippingAddress,
          }
        : {
            ...address,
            isBillingAddress: !address.isBillingAddress,
          }
    );
    console.log('addresses', addresses);
    setState({
      ...state,
      doorsAddress: addresses,
    });
  };

  return (
    <Grid
      item
      container
      justifyContent={width < 1024 ? 'center' : 'space-between'}
      style={{
        marginTop: width < 1024 ? 20 : 66,
        maxHeight:
          width < 1024 ? window.innerHeight - 248 : window.innerHeight - 350,
        overflowY: 'auto',
        overflowX: 'hidden',
        background: width < 1024 && '#fff',
        padding: width < 1024 && '5%',
        width: width < 1024 && width * 0.9,
      }}
    >
      <Grid container>
        <Grid item xs={12} md={4}>
          <Typography
            className={
              width > 1024 ? classes.steptTitle : classes.stepTitleMobile
            }
          >
            {step === 3 ? 'Billing Addresses' : 'Shipping Addresses'}
          </Typography>
          <Typography
            style={{ marginTop: width < 1024 && 0 }}
            className={classes.stepSubtitle}
          >
            You can select one of your previously saved addresses or add a new
            one. If your account has multiple{' '}
            {step === 3 ? 'billing' : 'shipping'} addresses, select all that
            apply.{' '}
            {width > 1024 && (
              <>
                <br />
                <br />
                If you entered any details about an address incorrectly, you can
                edit them later in the{' '}
                <span style={{ fontWeight: 'bold' }}>Client Area.</span>
              </>
            )}
          </Typography>
        </Grid>
        <Grid item md={5}>
          <Grid container style={{ marginTop: 20 }}>
            <Grid
              item
              container
              className={classes.divAddress}
              style={{
                width: 190,
                height: 96,
                padding: '4px 4px 12px 12px',
                gap: '16px',
                cursor: 'pointer',
                alignItems: 'flex-start',
              }}
              onClick={() => {
                setOpenAddress(true);
              }}
            >
              <Typography
                style={{
                  fontSize: 12,
                  lineHeight: '16px',
                  fontWeight: 'bold',
                }}
              >
                Add New Address
              </Typography>
              <Grid container>
                <PlusIcon />
              </Grid>
            </Grid>
            {state.doorsAddress.map((item, index) => (
              <Grid item className={classes.divAddress}>
                <Grid
                  container
                  style={{
                    width: 200,
                    height: 154,
                    padding: '16px 16px 8px 16px',
                    gap: '16px',
                    alignItems: 'flex-start',
                  }}
                >
                  <Grid
                    container
                    justifyContent="space-between"
                    style={{ width: 134 }}
                  >
                    <Typography
                      style={{
                        fontSize: 12,
                        fontWeight: 'bold',
                        marginBottom: 12,
                      }}
                    >
                      {item.modalAddress}
                    </Typography>
                    <Grid
                      style={{ position: 'relative', bottom: 10, left: 15 }}
                    >
                      <div
                        onClick={() => {
                          setId(index);
                          handleOpenEdit(
                            item.modalAddress,
                            item.modalCity,
                            item.modalAddName,
                            item.modalStoreProv,
                            item.modalZipcode,
                            item.modalCountry,
                            index,
                            item.modalStreet,
                            item.modalUnit
                          );
                        }}
                      >
                        <EditIcon />
                      </div>
                      <div
                        onClick={() => handleDeleteDoorAddress(index)}
                        style={{ position: 'relative', right: 2 }}
                      >
                        <RemoveIcon />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="space-between"
                    style={{ position: 'relative', bottom: 22 }}
                  >
                    <Grid style={{ width: 134 }}>
                      <Typography style={{ fontSize: 10, lineHeight: '12px' }}>
                        {item.modalStreet}{' '}
                        {item?.modalUnit && ` ${item.modalUnit}`}
                      </Typography>
                      <Typography
                        style={{ fontSize: 10, lineHeight: '12px' }}
                      >{`${item.modalCity} - ${item.modalStoreProv}`}</Typography>
                      <Typography
                        style={{ fontSize: 10, lineHeight: '12px' }}
                      >{`${item.modalZipcode} - ${item.modalCountry}`}</Typography>
                      <Grid
                        container
                        style={{
                          position: 'relative',
                          left: 2,
                          top: 8,
                          width: 220,
                        }}
                      >
                        {step === 4 && (
                          <Grid>
                            <CheckLabel
                              onChange={() =>
                                handleChangeBooleanAddress(
                                  'isShippingAddress',
                                  index
                                )
                              }
                              checked={item.isShippingAddress}
                              label="Shipping"
                            />
                          </Grid>
                        )}
                        <Grid>
                          <CheckLabel
                            onChange={() =>
                              handleChangeBooleanAddress(
                                'isBillingAddress',
                                index
                              )
                            }
                            checked={item.isBillingAddress}
                            label="Billing"
                            disabled={step === 4}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* <Grid style={{ width: 46 }}>
                              <Typography
                                
                                align="right"
                                style={{
                                  fontSize: 12,

                                  cursor: 'pointer',
                                }}
                              >
                                Edit
                              </Typography>
                              <Typography
                                align="right"
                                style={{ fontSize: 12, cursor: 'pointer' }}
                                onClick={() => handleDeleteDoorAddress(index)}
                              >
                                Remove
                              </Typography>
                            </Grid> */}
                    <Grid />
                  </Grid>
                </Grid>
                <ModalAddress
                  open={openAddress}
                  handleClose={handleClose}
                  handleSaveAddress={handleSaveAddress}
                  editValues={editValues}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <Grid container style={{ padding: '66px 0px 30px' }}>
        {step === 3 ? (
          <>
            <Grid item xs={12} md={4}>
              <Typography
                className={
                  width > 1024 ? classes.steptTitle : classes.stepTitleMobile
                }
              >
                Accounts Payable
              </Typography>
            </Grid>
            <Grid>
              <Grid item>
                <TextField
                  label="First Name"
                  name="payableFirstName"
                  type="text"
                  fullWidth
                  autoComplete="off"
                  onChange={e => {
                    handleChange(e);
                  }}
                  inputProps={{
                    'aria-label': 'First Name',
                    autoComplete: 'off',
                  }}
                  value={state.payableFirstName}
                  style={{ width: wid }}
                />
              </Grid>
              <Grid item className={classes.marginTop}>
                <TextField
                  label="Last Name"
                  type="text"
                  name="payableLastName"
                  autoComplete="new-password"
                  fullWidth
                  value={state.payableLastName}
                  onChange={e => {
                    handleChange(e);
                  }}
                  inputProps={{ 'aria-label': 'Last Name' }}
                  style={{ width: wid }}
                />
              </Grid>
              <Grid item className={classes.marginTop}>
                <TextField
                  autoComplete="new-password"
                  label="Email"
                  name="payableEmail"
                  type="text"
                  fullWidth
                  value={state.payableEmail}
                  inputProps={{ 'aria-label': 'Email' }}
                  onChange={e => {
                    handleChange(e);
                  }}
                  style={{ width: wid }}
                />
              </Grid>
              <Grid item className={classes.marginTop}>
                <Grid
                  item
                  container
                  justifyContent="space-between"
                  alignItems="baseline"
                  style={{ width: wid }}
                >
                  <TextField
                    label="Phone"
                    type="text"
                    autoComplete="new-password"
                    name="payableDDI"
                    value={state.payableDDI}
                    style={{ width: '12%' }}
                    onChange={e => {
                      handleChange(e);
                    }}
                    inputProps={{ 'aria-label': 'Country Code' }}
                  />

                  <Typography>{country}</Typography>

                  <TextField
                    label=""
                    type="text"
                    name="payablePhone"
                    autoComplete="new-password"
                    style={{ width: '75%', marginTop: 16 }}
                    onChange={e => {
                      handleChange(e);
                    }}
                    value={state.payablePhone}
                    inputProps={{ 'aria-label': 'Phone' }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12} md={4}>
              <Typography
                className={
                  width > 1024 ? classes.steptTitle : classes.stepTitleMobile
                }
              >
                Freight Forwarder
              </Typography>
            </Grid>
            <Grid item md={6} container>
              <Grid>
                <Grid container alignItems="center">
                  <Typography
                    style={{
                      marginRight: 30,
                      fontSize: 14,
                      lineHeight: '16px',
                    }}
                  >
                    Do you have a Freight Forwarder?
                  </Typography>
                  <RadioGroup
                    row
                    aria-label="gender"
                    name="gender1"
                    value={hasFreight}
                    onChange={e => setHasFreight(e.target.value)}
                    style={{ postion: 'relative', top: 8 }}
                  >
                    <FormControlLabel
                      value="No"
                      control={<Radio size="small" />}
                      label={
                        <Typography
                          style={{
                            fontSize: 12,
                            position: 'relative',
                            top: -2,
                            marginRight: 30,
                          }}
                        >
                          No
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      value="Yes"
                      control={<Radio size="small" />}
                      label={
                        <Typography
                          style={{
                            fontSize: 12,
                            position: 'relative',
                            top: -2,
                            marginRight: 20,
                          }}
                        >
                          Yes
                        </Typography>
                      }
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
              {hasFreight === 'Yes' && (
                <Grid container>
                  <Grid item className={classes.marginTop}>
                    <TextField
                      label="Company Name"
                      name="freightCompany"
                      type="text"
                      fullWidth
                      autoComplete="off"
                      onChange={e => {
                        handleChange(e);
                      }}
                      inputProps={{
                        'aria-label': 'First Name',
                        autoComplete: 'off',
                      }}
                      value={state.freightCompany}
                      style={{ width: wid }}
                    />
                  </Grid>
                  <Grid item className={classes.marginTop}>
                    <TextField
                      label="First Name"
                      name="freightFirstName"
                      type="text"
                      fullWidth
                      autoComplete="off"
                      onChange={e => {
                        handleChange(e);
                      }}
                      inputProps={{
                        'aria-label': 'First Name',
                        autoComplete: 'off',
                      }}
                      value={state.freightFirstName}
                      style={{ width: wid }}
                    />
                  </Grid>
                  <Grid item className={classes.marginTop}>
                    <TextField
                      label="Last Name"
                      type="text"
                      name="freightLastName"
                      autoComplete="new-password"
                      fullWidth
                      value={state.freightLastName}
                      onChange={e => {
                        handleChange(e);
                      }}
                      inputProps={{ 'aria-label': 'Last Name' }}
                      style={{ width: wid }}
                    />
                  </Grid>
                  <Grid item className={classes.marginTop}>
                    <TextField
                      autoComplete="new-password"
                      label="Email"
                      name="freightEmail"
                      type="text"
                      fullWidth
                      value={state.freightEmail}
                      inputProps={{ 'aria-label': 'Email' }}
                      onChange={e => {
                        handleChange(e);
                      }}
                      style={{ width: wid }}
                    />
                  </Grid>
                  <Grid item className={classes.marginTop}>
                    <Grid
                      item
                      container
                      justifyContent="space-between"
                      alignItems="baseline"
                      style={{ width: wid }}
                    >
                      <TextField
                        label="Country"
                        type="text"
                        autoComplete="new-password"
                        name="freightDDI"
                        value={state.freightDDI}
                        style={{ width: '12%' }}
                        onChange={e => {
                          handleChange(e);
                        }}
                        inputProps={{ 'aria-label': 'Country Code' }}
                      />

                      <Typography>{country}</Typography>

                      <TextField
                        label="Phone"
                        type="text"
                        name="freightPhone"
                        autoComplete="new-password"
                        style={{ width: '75%', marginTop: 16 }}
                        onChange={e => {
                          handleChange(e);
                        }}
                        value={state.freightPhone}
                        inputProps={{ 'aria-label': 'Phone' }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item className={classes.marginTop}>
                    <TextField
                      label="Street Address"
                      name="freightStreet"
                      type="text"
                      fullWidth
                      autoComplete="off"
                      onChange={e => {
                        handleChange(e);
                      }}
                      inputProps={{ 'aria-label': 'Street Address' }}
                      value={state.freightStreet}
                      style={{ width: wid }}
                    />
                  </Grid>
                  <Grid item className={classes.marginTop}>
                    <TextField
                      label="Building, unit, floor etc"
                      type="text"
                      name="freightUnit"
                      autoComplete="new-password"
                      fullWidth
                      value={state.freightUnit}
                      onChange={e => {
                        handleChange(e);
                      }}
                      inputProps={{ 'aria-label': 'Building, unit, floor etc' }}
                      style={{ width: wid }}
                    />
                  </Grid>
                  <Grid item className={classes.marginTop}>
                    <TextField
                      autoComplete="new-password"
                      label="City"
                      name="freightCity"
                      type="text"
                      fullWidth
                      value={state.freightCity}
                      inputProps={{ 'aria-label': 'City' }}
                      onChange={e => {
                        handleChange(e);
                      }}
                      style={{ width: wid }}
                    />
                  </Grid>
                  <Grid item className={classes.marginTop}>
                    <TextField
                      label="State/Province"
                      type="text"
                      name="freightState"
                      autoComplete="new-password"
                      fullWidth
                      value={state.freightState}
                      onChange={e => {
                        handleChange(e);
                      }}
                      inputProps={{ 'aria-label': 'State/Province' }}
                      style={{ width: wid }}
                    />
                  </Grid>
                  <Grid item className={classes.marginTop}>
                    <TextField
                      autoComplete="new-password"
                      label="Zip / Postal code"
                      name="freightZip"
                      type="text"
                      fullWidth
                      value={state.freightZip}
                      inputProps={{ 'aria-label': 'Zip / Postal code' }}
                      onChange={e => {
                        handleChange(e);
                      }}
                      style={{ width: wid }}
                    />
                  </Grid>
                  <Grid container item className={classes.marginTop}>
                    <Input
                      field="Country"
                      country
                      name="freightCountry"
                      background="#EBEBEB"
                      width={wid}
                      height={43}
                      select
                      onChange={e => {
                        handleChange(e);
                      }}
                      value={state.freightCountry}
                      options={country_list}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
}
