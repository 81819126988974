import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Grid, DialogContent, Tooltip } from '@material-ui/core';
import { AiOutlineDownload } from 'react-icons/ai';
import ReactToPrint from 'react-to-print';
import useUserContext from '../../hooks/useUserContext';
import HeaderMobile from '../HeaderMobile';
import ProductCardCollection from './ProductCard';
import ModalProductMobile from '../ModalProductMobile';
import ModalCart from '../../components/ModalCart';
import useConfigContext from '../../hooks/useConfigContext';

import { dictIdDelivery } from '../../consts';

import api from '../../services/api';

import PDF from '../../components/PDF';

const StyledDialog = withStyles({
  paper: {
    margin: 0,
  },
  paperFullScreen: {
    margin: 0,
    height: '100%',
    backgroundColor: 'white',
  },
  paperWidthLg: {
    maxWidth: '100%',
  },
  scrollPaper: {
    alignItems: 'center',
  },
  paperFullWidth: {
    width: '100%',
  },
})(Dialog);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalCollection({ open, handleClose }) {
  const { configState } = useConfigContext();
  const seasonName = configState.season.name || '';
  const { deliveries } = configState;
  const [date] = useState(0);
  const [myProducts, setMyProducts] = useState([]);
  const [openCart, setOpenCart] = useState(false);
  const [d1] = useState([]);
  const [d2] = useState([]);
  const [d3] = useState([]);

  const { userState } = useUserContext();
  const { user: profile } = userState;
  // const { loadingExcel } = useSelector(state => state.collection);
  // const [selectedCapsulaIndex, setSelectedCapsulaIndex] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState(1);
  const [openModalProduct, setOpenModalProduct] = useState(false);
  const handleModalProductClose = () => {
    setOpenModalProduct(false);
  };
  const handlePrevProduct = useCallback(() => {
    const selectedProductIndex = myProducts.findIndex(
      p => p.style_code === selectedProduct.style_code
    );
    if (selectedProductIndex === 0) {
      // vou pro ultimo item
      const product = myProducts.find(
        (p, index) => index === myProducts.length - 1
      );
      setSelectedProduct(product);
    } else {
      // volto um item
      const product = myProducts.find(
        (p, index) => index === selectedProductIndex - 1
      );

      setSelectedProduct(product);
    }
  }, [selectedProduct, myProducts]);

  const handleNextProduct = useCallback(() => {
    const selectedProductIndex = myProducts.findIndex(
      p => p.style_code === selectedProduct.style_code
    );
    if (selectedProductIndex >= myProducts.length - 1) {
      setSelectedProduct(myProducts[0]);
    } else {
      const product = myProducts.find(
        (p, index) => index === selectedProductIndex + 1
      );
      setSelectedProduct(product);
    }
  }, [selectedProduct, myProducts]);
  const userNameFormatted = useMemo(() => {
    if (!profile) return '';
    return profile.name ? profile.name.toUpperCase() : '';
  }, [profile]);
  const [print, setPrint] = useState(false);
  const [downloadExcelURL, setExcelURL] = useState('');
  // const [loading, setLoading] = useState(true);
  const [selectedDelivery, setSelectedDelivery] = useState(1);

  function timeout(delay) {
    return new Promise(res => setTimeout(res, delay));
  }

  const componentRef = useRef();
  const loadExcel = useCallback(async () => {
    try {
      // const excel = await api.get('excel');
      // // , {
      // //   headers: {
      // //     'Content-Disposition': 'attachment; filename=template.xlsx',
      // //     'Content-Type':
      // //       'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      // //   },
      // //   responseType: 'arraybuffer',
      // // });
      // // const blob = new Blob([excel.data]);
      // // const excelUrl = URL.createObjectURL(blob);
      // setExcelURL(excel.data.url);
    } catch (err) {}
  }, []);

  useEffect(() => {
    async function Wrap() {
      if (!open) return;

      if (!profile) return;
      if (!downloadExcelURL) {
        await loadExcel();
      }
    }
    Wrap();
  }, [loadExcel, downloadExcelURL, profile, open]);

  const handleRemoveProductFromCollection = useCallback(
    async product => {
      try {
        await api.post('/collections', {
          style_code: product.style_code,
        });
        setMyProducts(
          myProducts.filter(p => p.style_code !== product.style_code)
        );
        setExcelURL('');
      } catch (err) {
        console.log(err);
      }
    },
    [myProducts]
  );

  useEffect(() => {
    async function loadMyProducts() {
      try {
        const response = await api.get('/collections', {
          params: {
            delivery_id: dictIdDelivery[selectedDelivery],
            categories: null,
          },
        });

        setMyProducts(response.data);
      } catch (err) {
        console.log(err);
      }
    }
    loadMyProducts();
  }, [date, selectedDelivery, open]);

  const productsByCollectionAndCapsula = useMemo(() => {
    const differentCapsulas = [
      'MAIN COLLECTION',
      'SWIMWEAR',
      'MENSWEAR',
      'SKI',
    ];
    myProducts.forEach(prod => {
      if (!differentCapsulas.includes(prod.capsula)) {
        differentCapsulas.push(prod.capsula);
      }
    });

    const value = {};
    differentCapsulas
      .filter(i => !!i)
      .forEach(capsula => {
        value[capsula] = myProducts.filter(p => p.capsula === capsula);
      });

    return value;
  }, [myProducts]);
  // const { capsulaOptions = [] } = useMemo(() => {
  //   const differentCapsulas = [];

  //   myProducts.forEach(prod => {
  //     const formmattedCapsula = `Delivery ${
  //       dictIdDeliveryToNumber[prod.delivery_id]
  //     } ${prod.capsula}`;
  //     if (!differentCapsulas.includes(formmattedCapsula)) {
  //       differentCapsulas.push(formmattedCapsula);
  //     }
  //   });

  //   const value = {};
  //   differentCapsulas
  //     .filter(i => !!i)
  //     .forEach(capsula => {
  //       value[capsula] = myProducts.filter(
  //         p =>
  //           `Delivery ${dictIdDeliveryToNumber[p.delivery_id]} ${p.capsula}` ===
  //           capsula
  //       );
  //     });

  //   return {
  //     productsByCollectionAndCapsula: value,
  //     capsulaOptions: differentCapsulas.filter(i => !!i),
  //   };
  // }, [myProducts]);

  // const productOfSelectedCapsula = useMemo(() => {
  //   return myProducts.filter(
  //     p =>
  //       `Delivery ${dictIdDeliveryToNumber[p.delivery_id]} ${p.capsula}` ===
  //       capsulaOptions[selectedCapsulaIndex]
  //   );
  // }, [selectedCapsulaIndex, myProducts, capsulaOptions]);

  // const RenderTitle = () => {
  //   const [delivery, deliveryNumber, ...capsulaName] = capsulaOptions[
  //     selectedCapsulaIndex
  //   ].split(' ');
  //   return (
  //     <Typography
  //       align="center"
  //       style={{
  //         fontSize: 14,
  //         marginTop: -10,
  //         letterSpacing: '0.1em',
  //       }}
  //     >
  //       <span style={{ fontWeight: 'bold' }}>
  //         {' '}
  //         {delivery} {deliveryNumber}{' '}
  //       </span>{' '}
  //       {capsulaName.join(' ')}
  //     </Typography>
  //   );
  // };

  if (!myProducts) return null;
  return (
    <div>
      <StyledDialog
        open={open}
        TransitionComponent={Transition}
        // keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullScreen
        // style={{ marginTop: 30 }}
      >
        <DialogContent style={{ padding: 0 }}>
          {print && (
            <Grid container ref={componentRef}>
              <PDF name={profile.name} d1={d1} d2={d2} d3={d3} />
            </Grid>
          )}
          <Grid style={{ width: '100%', height: '100%' }} id="modal">
            <>
              <Grid
                container
                style={{
                  position: 'fixed',
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: '#fff',
                  top: 0,
                  zIndex: 1,
                  padding: '10px',
                }}
              >
                <HeaderMobile
                // isTransparent={!!bg}
                />
                <Grid
                  container
                  alignItems="center"
                  direction="column"
                  style={{ padding: '10px' }}
                >
                  {/* <Grid
                    container
                    style={{
                      maxWidth: '95%',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}
                    align="flex-start"
                  >
                    <RiArrowLeftSLine
                      size={16}
                      color="#000"
                      onClick={() => {
                        if (selectedCapsulaIndex === 0) {
                          setSelectedCapsulaIndex(capsulaOptions.length - 1);
                        } else {
                          setSelectedCapsulaIndex(i => i - 1);
                        }
                      }}
                    />
                    <RenderTitle />
                    <Typography
                      align="center"
                      style={{
                        fontSize: 14,
                        marginTop: -10,
                        letterSpacing: '0.1em',
                      }}
                    >
                      <span style={{ fontWeight: 'bold' }}> </span>{' '}
                      {capsulaOptions[selectedCapsulaIndex]}
                    </Typography>
                    <RiArrowRightSLine
                      size={16}
                      color="#000"
                      onClick={() => {
                        if (
                          selectedCapsulaIndex ===
                          capsulaOptions.length - 1
                        ) {
                          setSelectedCapsulaIndex(0);
                        } else {
                          setSelectedCapsulaIndex(i => i + 1);
                        }
                      }}
                    />
                  </Grid> */}
                  <Typography
                    align="center"
                    style={{
                      fontSize: 18,
                      letterSpacing: '0.2em',
                    }}
                  >
                    {userNameFormatted}'s {seasonName} Collection
                  </Typography>
                </Grid>

                <Grid
                  container
                  justifyContent="space-between"
                  style={{ padding: '5px 2%' }}
                >
                  <Typography
                    onClick={() => setSelectedDelivery(1)}
                    style={{
                      fontSize: 12,
                      fontWeight: selectedDelivery === 1 && 'bold',
                      borderBottom:
                        selectedDelivery === 1 && '1px solid #000000',
                      padding: '2px 4px',
                    }}
                  >
                    Delivery 1
                  </Typography>
                  <Typography
                    onClick={() => setSelectedDelivery(2)}
                    style={{
                      fontSize: 12,
                      fontWeight: selectedDelivery === 2 && 'bold',
                      borderBottom:
                        selectedDelivery === 2 && '1px solid #000000',
                      padding: '2px 4px',
                    }}
                  >
                    Delivery 2
                  </Typography>
                  <Typography
                    onClick={() => setSelectedDelivery(3)}
                    style={{
                      fontSize: 12,
                      fontWeight: selectedDelivery === 3 && 'bold',
                      borderBottom:
                        selectedDelivery === 3 && '1px solid #000000',
                      padding: '2px 4px',
                    }}
                  >
                    Delivery 3
                  </Typography>
                </Grid>
              </Grid>
            </>
            <Grid
              container
              justifyContent="flex-start"
              alignItems="flex-start"
              className="print-container"
              style={{ paddingTop: 140 }}
            >
              <Grid
                container
                style={{ paddingBottom: 50, marginTop: 20 }}
                justifyContent="center"
              >
                {Object.keys(productsByCollectionAndCapsula).map(collection => {
                  if (!productsByCollectionAndCapsula[collection].length)
                    return null;
                  return (
                    <div style={{ padding: '0% 2%' }} key={collection.id}>
                      <div
                        style={{
                          display: 'flex',
                          flexGrow: 1,
                          justifyContent: 'space-between',
                          marginTop: 20,
                          borderBottom: '1px solid black',
                          paddingBottom: 10,
                          marginBottom: 5,
                        }}
                      >
                        <Grid container direction="column" align="flex-start">
                          <span style={{ fontWeight: 'bold', fontSize: 10 }}>
                            {collection}{' '}
                          </span>{' '}
                          <Typography style={{ fontSize: 10 }}>
                            {deliveries[+selectedDelivery - 1].subTitle1} |{' '}
                            {deliveries[+selectedDelivery - 1].subTitle2}{' '}
                          </Typography>
                          <span style={{ fontWeight: 'bold', fontSize: 10 }}>
                            {productsByCollectionAndCapsula[collection].length}{' '}
                            Styles added{' '}
                          </span>{' '}
                        </Grid>
                        {/* <Typography
                          style={{
                            minWidth: 100,
                            fontSize: 10,
                            alignSelf: 'flex-end',
                            // paddingBottom: 10,
                            // marginBottom: 10,
                            textAlign: 'right',
                          }}
                        >
                          Add styles +
                        </Typography> */}
                      </div>

                      <Grid
                        container
                        spacing={1}
                        key={collection}
                        style={{
                          overflowY: 'scroll',
                          maxHeight: window.innerHeight * 0.63,
                          marginTop: 5,
                          // border: 'solid 1px black',
                          // margin: '10px 0px',
                        }}
                      >
                        {productsByCollectionAndCapsula[collection].map(
                          product => (
                            <ProductCardCollection
                              onClick={() => {
                                setSelectedProduct(product);
                                setOpenModalProduct(true);
                              }}
                              key={product.style_code}
                              handleRemoveProductFromCollection={
                                handleRemoveProductFromCollection
                              }
                              print={print}
                              product={product}
                            />
                          )
                        )}
                      </Grid>
                    </div>
                  );
                })}
                {/* {productOfSelectedCapsula.map(product => (
                  <Grid
                    item
                    md={7 / 12}
                    style={{ padding: 2 }}
                    key={product.style_code}
                  >
                    <GridItem
                      handleRemoveProductFromCollection={
                        handleRemoveProductFromCollection
                      }
                      print={print}
                      product={product}
                      d3
                      cold1={cold1print}
                      cold2={cold2print}
                    />
                  </Grid>
                ))} */}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-around"
            style={{
              borderTop: '1px solid #000000',
              height: 44,
              position: 'fixed',
              bottom: 0,
              background: '#ffffff',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '0 10px',
            }}
          >
            <Grid
              container
              // justifyContent="space-between"
              style={{ width: '65%', alignItems: 'center' }}
            >
              <Typography style={{ fontSize: 12, marginRight: 5 }}>
                Download Collection
              </Typography>
              <Tooltip title="Use 'Save as PDF' mode">
                <Grid>
                  <ReactToPrint
                    trigger={() => (
                      <Grid
                        container
                        style={{
                          alignItems: 'flex-start',
                          cursor: 'pointer',
                          width: 41,
                          height: 19,
                          marginRight: 'auto',
                        }}
                        onClick={() => setPrint(true)}
                      >
                        <AiOutlineDownload
                          size={13}
                          style={{ marginTop: 3.5 }}
                          color="black"
                        />
                        <span style={{ fontSize: 12 }}>PDF</span>
                      </Grid>
                    )}
                    content={() => componentRef.current}
                    onAfterPrint={() => setPrint(false)}
                    onBeforeGetContent={async () => {
                      setPrint(true);
                      await timeout(1000);
                    }}
                  />
                </Grid>
              </Tooltip>
              {/* <Grid>
                <Grid
                  // style={{ }}
                  container
                  style={{
                    alignItems: 'center',
                    cursor: 'pointer',
                    width: 41,
                    height: 19,
                  }}
                  justifyContent="center"
                  alignItems="center"
                >
                  {loadingExcel ? (
                    <CircularProgress
                      size={10}
                      style={{ marginTop: 4, marginRight: 2 }}
                    />
                  ) : (
                    <AiOutlineDownload
                      size={16}
                      color="black"
                      style={{ marginTop: 4 }}
                    />
                  )}
                  {loadingExcel ? (
                    <div
                      style={{
                        textDecoration: 'none',
                        color: 'black',
                      }}
                    >
                      <span
                        style={{
                          fontSize: 12,
                        }}
                      >
                        XLS
                      </span>
                    </div>
                  ) : (
                    <a
                      href={downloadExcelURL}
                      style={{
                        textDecoration: 'none',
                        color: 'black',
                      }}
                      download="FARM US - FALL HIGH SUMMER 23.xlsx"
                    >
                      <span
                        style={{
                          fontSize: 12,
                        }}
                      >
                        XLS
                      </span>
                    </a>
                  )}
                </Grid>
              </Grid> */}
            </Grid>
            <Grid
              style={{
                // width: 400,
                display: 'flex',
                justifyContent: 'flex-end',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Grid
                onClick={() => {
                  setOpenCart(true);
                  // dispatchEvent(events.startNotes);
                }}
                style={{ width: 120, height: 24, background: '#000' }}
              >
                <Grid
                  style={{ height: 24 }}
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography style={{ fontSize: 10, color: '#fff' }}>
                    Start notes
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <ModalProductMobile
            products={myProducts}
            // setProducts={setProducts}
            handlePrevProduct={handlePrevProduct}
            handleNextProduct={handleNextProduct}
            open={openModalProduct}
            handleClose={handleModalProductClose}
            style_code={selectedProduct}
          />
          <ModalCart open={openCart} handleClose={() => setOpenCart(false)} />
        </DialogContent>
      </StyledDialog>
    </div>
  );
}
