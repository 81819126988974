import React, { useState } from 'react';
import { Typography, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { BsArrowDown } from 'react-icons/bs';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import Header from '../../components/HeaderView';

import style from './styles';

const styles = theme => style(theme);

function WhoWeAre() {
  const [wid, setWid] = useState(0);
  const [hgt, sethgt] = useState(0);

  React.useEffect(() => {
    function updateSize() {
      setWid(window.innerWidth);
      sethgt(window.innerHeight);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [wid, hgt]);

  const History = () => {
    return (
      <Grid
        contaienr
        justifyContent="center"
        alignItems="center"
        style={{
          width: wid,
          height: wid > 1024 ? 700 : 284,
          marginTop: 20,
          background:
            wid > 1024
              ? 'url("https://storage.googleapis.com/showroom-bucket-images/ALL/history.png") top center no-repeat  '
              : 'url("https://storage.googleapis.com/showroom-bucket-images/ALL/history_mobile.png") no-repeat  center',
        }}
      >
        <Grid
          style={{
            position: 'relative',
            left: window.innerWidth > 1024 ? '7%' : 0,
            marginLeft: window.innerWidth < 1024 && 25,
            top: window.innerWidth > 1024 ? '78%' : 250,
          }}
        >
          <Grid
            style={{
              width: wid > 1024 ? 480 : 256,
              height: wid > 1024 ? 350 : 160,
              background: 'rgba(255, 255, 255, 0.92)',
              padding: wid > 1024 ? '20px 30px' : '17px 24px 10px 21px',
            }}
          >
            <Typography
              style={{
                fontSize: wid > 1024 ? 32 : 18,
                marginBottom: 10,
              }}
            >
              Our History
            </Typography>
            <Typography
              style={{
                fontSize: wid > 1024 ? 14 : 10,
                fontWeight: 'bold',
              }}
            >
              Our story begins in 1997 at a marketplace in Rio de Janeiro.
              Founders Kátia Barros and Marcello Bastos introduced a collection
              of colorful garments capturing the vibrant spirit of Rio. Locals
              immediately fell in love with FARM Rio’s bold, authentic designs.
              <br />
              <br />
              Since our U.S. launch in March 2019, our global expansion
              continues through international shipping, delivering FARM Rio’s
              energy to locations around the world.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const Footer = () => {
    return (
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{
          width: wid,
          height: 102,
          background: '#000',
          paddingLeft: '7%',
        }}
      >
        <Typography
          style={{
            fontSize: wid > 1024 ? 32 : 22,
            lineHeight: '24px',
            color: '#fff',
          }}
        >
          Contact US
        </Typography>
        <Typography
          style={{
            fontSize: wid > 1024 ? 14 : 12,
            lineHeight: wid > 1024 ? '28px' : '18px',
            color: '#fff',
          }}
        >
          If you'd like to get in touch with our sales team, <br /> please email
          us at{' '}
          <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
            wholesale@farmrio.com{' '}
          </span>
        </Typography>
        <Grid style={{ width: 207 }} />
      </Grid>
    );
  };

  const GrupoSoma = () => {
    return wid > 1024 ? (
      <Grid
        container
        justifyContent="space-evenly"
        style={{ paddingBottom: '7%' }}
      >
        <Grid
          style={{
            width: 411,
          }}
        >
          <Typography
            style={{ fontSize: 32, lineHeight: '24px', marginBottom: 32 }}
          >
            Grupo Soma
          </Typography>
          <Typography style={{ fontSize: 14, lineHeight: '28px' }}>
            FARM Rio is proud to be a founding member of Soma Group, the largest
            fashion group in Brazil. AS modern collective with a human focus,
            Grupo Soma works to empower brands that generate passion and serve
            as an inspiration thourgh fashion, art and community.
            <br />
            <br />
            <br />
            Learn more at{' '}
            <a
              href="https://www.somagrupo.com.br/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: '#000', fontWeight: 'bold' }}
            >
              somagrupo.com.br
            </a>
          </Typography>
        </Grid>

        <img
          src="https://storage.googleapis.com/showroom-bucket-images/ALL/bg_video_still.png"
          alt="soma_img"
          style={{
            width: 536,
            height: 316,
          }}
        />
      </Grid>
    ) : (
      <Grid
        container
        justifyContent="center"
        style={{ padding: '7%', marginTop: 20 }}
      >
        <Grid
          style={{
            width: 257,
          }}
        >
          <Typography
            style={{ fontSize: 24, lineHeight: '24px', marginBottom: 13 }}
          >
            Grupo Soma
          </Typography>
          <Typography style={{ fontSize: 12, lineHeight: '18px' }}>
            FARM Rio is proud to be a founding member of Soma Group, the largest
            fashion group in Brazil. AS modern collective with a human focus,
            Grupo Soma works to empower brands that generate passion and serve
            as an inspiration thourgh fashion, art and community.
            <br />
            <br />
            Learn more at{' '}
            <a
              href="https://www.somagrupo.com.br/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: '#000', fontWeight: 'bold' }}
            >
              somagrupo.com.br
            </a>
          </Typography>
        </Grid>

        <img
          src="https://storage.googleapis.com/showroom-bucket-images/ALL/bg_video_still.png"
          alt="soma_img"
          style={{
            width: wid * 0.96,
            height: 216,
            marginTop: 23,
          }}
        />
      </Grid>
    );
  };

  const Stores = () => {
    return wid > 1024 ? (
      <Grid
        style={{ height: wid > 1024 ? 600 : hgt / 3, marginTop: 10 }}
        container
      >
        <Grid style={{ padding: '0px 7%' }}>
          <Typography
            style={{
              fontSize: wid > 1024 ? 32 : 18,
              margin: '41px 0px',
            }}
          >
            Our Stores
          </Typography>
          <Grid
            container
            justifyContent="space-between"
            style={{ width: wid * 0.8 }}
          >
            <Grid>
              <img
                src="https://storage.googleapis.com/showroom-bucket-images/ALL/farm_ny.png"
                style={{ width: 258, height: 166 }}
                alt="farm_ny"
              />
              <Typography
                style={{ fontSize: 14, lineHeight: '24px', marginTop: 31 }}
              >
                Farm Rio New York <br />
                113 Prince Street <br />
                New York, NY, 10012 <br />
                (646) 998-5023
                <br />
                <br />
                Monday-Saturday 11-7pm <br /> Sunday 12-6pm
              </Typography>
            </Grid>
            <Grid>
              <img
                src="https://storage.googleapis.com/showroom-bucket-images/ALL/farm_miami.png"
                style={{ width: 258, height: 166 }}
                alt="farm_ny"
              />
              <Typography
                style={{ fontSize: 14, lineHeight: '24px', marginTop: 31 }}
              >
                Farm Rio Miami <br />
                Aventura Mall <br />
                19501 Biscayne Blvd, Miami, <br />
                FL 33180 Ground Floor (305) 974-2828
                <br />
                <br />
                Sunday 12 - 7pm <br /> Monday-Thursday: 11am - 7pm <br />
                Friday-Saturday: 11am - 8pm
              </Typography>
            </Grid>
            <Grid>
              <img
                src="https://storage.googleapis.com/showroom-bucket-images/ALL/farm_venice.png"
                style={{ width: 258, height: 166 }}
                alt="farm_ny"
              />
              <Typography
                style={{ fontSize: 14, lineHeight: '24px', marginTop: 31 }}
              >
                Farm Rio Venice <br />
                1360 Abbot Kinney Blvd <br />
                Venice, CA 90291 <br />
                (310) 314 9618
                <br />
                <br />
                Monday-Sunday 11am - 7pm
              </Typography>
            </Grid>
            <Grid>
              <img
                src="https://storage.googleapis.com/showroom-bucket-images/ALL/farm_bon_marche.png"
                style={{ width: 258, height: 166 }}
                alt="farm_ny"
              />
              <Typography
                style={{ fontSize: 14, lineHeight: '24px', marginTop: 31 }}
              >
                Farm Rio Le bon Marché <br />
                24 Rue de Sèvres Paris, FR 75007 <br />
                New York, NY, 10012 <br />
                +33 1 44 39 80 00
                <br />
                <br />
                Monday-Saturday 10am - 7h45pm <br /> Sunday 11am - 7h45pm
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    ) : (
      <Grid container justifyContent="center">
        <Swiper spaceBetween={10} slidesPerView={1.2}>
          <SwiperSlide>
            <Grid container justifyContent="center">
              <img
                src="https://storage.googleapis.com/showroom-bucket-images/ALL/farm_ny.png"
                style={{ width: 258, height: 166 }}
                alt="farm_ny"
              />
              <Typography
                style={{ fontSize: 14, lineHeight: '24px', marginTop: 31 }}
              >
                Farm Rio New York <br />
                113 Prince Street <br />
                New York, NY, 10012 <br />
                (646) 998-5023
                <br />
                <br />
                Monday-Saturday 11-7pm <br /> Sunday 12-6pm
              </Typography>
            </Grid>
          </SwiperSlide>
          <SwiperSlide>
            <Grid container justifyContent="center">
              <img
                src="https://storage.googleapis.com/showroom-bucket-images/ALL/farm_miami.png"
                style={{ width: 258, height: 166 }}
                alt="farm_ny"
              />
              <Typography
                style={{ fontSize: 14, lineHeight: '24px', marginTop: 31 }}
              >
                Farm Rio Miami <br />
                Aventura Mall <br />
                19501 Biscayne Blvd, Miami, <br />
                FL 33180 Ground Floor (305) 974-2828
                <br />
                <br />
                Sunday 12 - 7pm <br /> Monday-Thursday: 11am - 7pm <br />
                Friday-Saturday: 11am - 8pm
              </Typography>
            </Grid>
          </SwiperSlide>
          <SwiperSlide>
            <Grid container justifyContent="center">
              <img
                src="https://storage.googleapis.com/showroom-bucket-images/ALL/farm_venice.png"
                style={{ width: 258, height: 166 }}
                alt="farm_ny"
              />
              <Typography
                style={{ fontSize: 14, lineHeight: '24px', marginTop: 31 }}
              >
                Farm Rio Venice <br />
                1360 Abbot Kinney Blvd <br />
                Venice, CA 90291 <br />
                (310) 314 9618
                <br />
                <br />
                Monday-Sunday 11am - 7pm
              </Typography>
            </Grid>
          </SwiperSlide>
          <SwiperSlide>
            <Grid container justifyContent="center">
              <img
                src="https://storage.googleapis.com/showroom-bucket-images/ALL/farm_bon_marche.png"
                style={{ width: 258, height: 166 }}
                alt="farm_ny"
              />
              <Typography
                style={{ fontSize: 14, lineHeight: '24px', marginTop: 31 }}
              >
                Farm Rio Le bon Marché <br />
                24 Rue de Sèvres Paris, FR 75007 <br />
                New York, NY, 10012 <br />
                +33 1 44 39 80 00
                <br />
                <br />
                Monday-Saturday 10am - 7h45pm <br /> Sunday 11am - 7h45pm
              </Typography>
            </Grid>
          </SwiperSlide>
          ...
        </Swiper>
      </Grid>
    );
  };

  const Borogodo = () => {
    return (
      <Grid
        contaienr
        justifyContent="center"
        alignItems="center"
        style={{
          width: wid,
          height: wid > 1024 ? 700 : 284,
          marginTop: 20,
          background:
            wid > 1024
              ? 'url("https://storage.googleapis.com/showroom-bucket-images/ALL/girls.png") no-repeat top center'
              : 'url("https://storage.googleapis.com/showroom-bucket-images/ALL/borogodo_mobile.png") no-repeat  center',
        }}
      >
        <Grid
          style={{
            position: 'relative',
            left: window.innerWidth > 1024 ? '7%' : 0,
            marginLeft: window.innerWidth < 1024 && 25,
            top: window.innerWidth > 1024 ? '79%' : 240,
          }}
        >
          <Grid
            style={{
              width: wid > 1024 ? 480 : 297,
              height: wid > 1024 ? 209 : 160,
              background: 'rgba(255, 255, 255, 0.92)',
              padding: wid > 1024 ? '20px 30px' : '5px 14px 10px 16px',
            }}
          >
            <Typography
              style={{
                fontSize: wid > 1024 ? 32 : 24,
                marginBottom: 10,
              }}
            >
              Our borogodó
            </Typography>
            <Typography
              style={{
                fontSize: wid > 1024 ? 14 : 12,
                width: 267,
              }}
            >
              Borogodó is an indescribable charm and intrigue that you can’t
              quite put your finger on, but it makes something (or someone) very
              special. This distinctly Brazilian way of interacting with other
              people, creating art and doing business sets FARM Rio apart.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const ExplainBorogodo = () => {
    return wid > 1024 ? (
      <Grid container style={{ padding: 32, marginTop: 100, zIndex: 2 }}>
        <Grid
          style={{
            width: 424,
            position: 'relative',
            left: '7%',
          }}
        >
          <Typography style={{ fontSize: 14, height: 505, lineHeight: '28px' }}>
            Our borogodó begins with process. We consider this customer journey
            from beginning to end, walking in her shoes and imagining how she
            would like to feel at each touchpoint. Our messaging and imagery
            support optimistic, healthy living and embracing happiness on a
            daily basis, so that interacting with us is always a positive
            experience. <br /> <br />
            It feels good to wear our clothes. Customers are more conscious than
            ever of fashion’s impact on the planet, and we care about our impact
            on the world creating clothes that are made in a responsible way and
            has a multi-layered effect: Through our commitment to
            sustainability, environmental initiatives, and social programs, we
            give back to nature and invest in a better future. <br /> <br /> The
            customer’s interaction with the brand is emotional, delightful, and
            priceless in value, because it creates a positive internal shift.
            This is the magic of FARM Rio.
          </Typography>
        </Grid>

        <img
          src="https://storage.googleapis.com/showroom-bucket-images/ALL/borogodo.png"
          alt="borogodo"
          style={{
            width: 506,
            height: 536,
            position: 'relative',
            left: '40%',
          }}
        />
      </Grid>
    ) : (
      <Grid container justifyContent="center" style={{ marginTop: 150 }}>
        <img
          src="https://storage.googleapis.com/showroom-bucket-images/ALL/explain_borogodo_mobile.png"
          alt="borogodo"
          style={{
            width: 360,
            height: 250,
          }}
        />
        <Typography
          style={{
            marginTop: 28,
            fontSize: 12,
            height: 545,
            lineHeight: '20px',
            width: 294,
          }}
        >
          Our borogodó begins with process. We consider this customer journey
          from beginning to end, walking in her shoes and imagining how she
          would like to feel at each touchpoint. Our messaging and imagery
          support optimistic, healthy living and embracing happiness on a daily
          basis, so that interacting with us is always a positive experience.{' '}
          <br /> <br />
          It feels good to wear our clothes. Customers are more conscious than
          ever of fashion’s impact on the planet, and we care about our impact
          on the world creating clothes that are made in a responsible way and
          has a multi-layered effect: Through our commitment to sustainability,
          environmental initiatives, and social programs, we give back to nature
          and invest in a better future. <br /> <br /> The customer’s
          interaction with the brand is emotional, delightful, and priceless in
          value, because it creates a positive internal shift. This is the magic
          of FARM Rio.
        </Typography>
      </Grid>
    );
  };

  const Essence = () => {
    return (
      <Grid
        style={{
          height: wid > 1024 ? 600 : 300,
          marginTop: wid > 1024 ? 70 : 240,
        }}
        container
        justifyContent="center"
        alignItems="center"
      >
        <Grid>
          <Typography
            style={{
              fontSize: wid > 1024 ? 32 : 18,
            }}
            align="center"
          >
            Our essence
          </Typography>
          <Typography
            align="center"
            style={{
              margin: '30px 0px 50px',
              fontSize: wid > 1024 ? 14 : 10,
              width: wid > 1024 ? 647 : 300,
            }}
          >
            Dressing in happiness is about more than personal style. We believe
            that clothes are tools of empowerment that can impact your mood.
            Statements inspired by the colors and shapes of nature can lift
            frequencies and emotions, creating a ripple effect of optimism.
            <br />
            <br />
            <br />
            Clothing inspired by the bold beauty of nature—and tied to a
            planet-loving, human-centric mission—can manifest happiness in
            bigger ways.
            <br />
            <br />
            <br />
            <span style={{ fontWeight: 'bold' }}>
              {' '}
              We are on a mission to make the world a brighter place
            </span>
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const WelcomeSS2022 = () => {
    return (
      <Grid
        contaienr
        justifyContent="center"
        alignItems="center"
        style={{
          width: wid,
          height: wid > 1024 ? hgt : 336,
          marginTop: 20,
          background:
            wid > 1024
              ? 'url("https://storage.googleapis.com/showroom-bucket-images/ALL/img_campaign2.png") no-repeat '
              : 'url("https://storage.googleapis.com/showroom-bucket-images/ALL/img_campaign_mobile.png") no-repeat center',
          alignItems: 'center',
        }}
      >
        <Typography
          align="center"
          style={{
            fontSize: wid > 1024 ? 32 : 24,
            lineHeight: 24,
            color: '#fff',
            letterSpacing: '0.1em',
          }}
        >
          Meet FARM Rio
          <Typography
            style={{
              color: '#fff',
              fontSize: 24,
              position: 'relative',
              bottom: wid < 1024 ? 150 : 360,
            }}
          >
            25 years of dress in happiness{' '}
          </Typography>
        </Typography>

        <Typography
          onClick={() => window.scrollTo({ behavior: 'smooth', top: hgt })}
          align="center"
          style={{
            fontSize: wid > 1024 ? 24 : 18,
            color: '#fff',
            marginBottom: wid > 1024 && 20,
            position: 'absolute',
            bottom: hgt * 0.05,
            left: '44.5%',
            cursor: 'pointer',
          }}
        >
          Our commitments
          <Grid container justifyContent="center">
            <BsArrowDown size={48} />
          </Grid>
        </Typography>
      </Grid>
    );
  };

  return (
    <div>
      <Header />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{
          width: wid,
          position: 'sticky',
          top: wid > 1024 ? 69 : 60,
          background: '#fff',
          zIndex: 1,
        }}
      >
        <Typography
          style={{
            fontSize: wid > 1024 ? 24 : 18,
            fontFamily: 'Galano Grotesque Light',
            letterSpacing: '0.1em',
          }}
        >
          WHO WE ARE
        </Typography>
      </Grid>
      <WelcomeSS2022 />
      <History />
      <Essence />
      <Borogodo />
      <ExplainBorogodo />
      <Stores />
      <GrupoSoma />
      <Footer />
    </div>
  );
}

const wrapperComponent = withStyles(styles)(WhoWeAre);

export default wrapperComponent;
