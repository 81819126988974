import React, { useState } from 'react';
import {
  Grid,
  TextField,
  Typography,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import validator from 'validator';

import history from '../../services/history';

import api from '../../services/api';

export default function Reset(props) {
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [tokenId] = useState(props.match.params.tokenId);
  const { enqueueSnackbar } = useSnackbar();

  const successSnack = (message, options = {}) => {
    enqueueSnackbar(message, { variant: 'success', ...options });
  };

  const isValidPassword = () => {
    if (password !== confirmPassword)
      return setPasswordError('Password does not match');
    if (password?.length < 8)
      return setPasswordError('Password must have at least 8 characters');
    if (validator.isLowercase(password))
      return setPasswordError('Password must have at least 1 uppercase letter');
    if (validator.isUppercase(password))
      return setPasswordError('Password must have at least 1 lowercase letter');
    if (validator.isAlphanumeric(password))
      return setPasswordError(
        'Password must have at least 1 special character'
      );
    return true;
  };

  const handleLogin = async () => {
    if (isValidPassword()) {
      setLoading(true);
      await api.put(
        '/newCostumers/updatePassword',
        {
          password,
        },
        {
          headers: { Authorization: `Bearer ${tokenId}` },
        }
      );
      successSnack('Password updated');
      history.push('/');
    }
  };

  function handleKeyPress(eve) {
    if (eve.key === 'Enter') {
      handleLogin();
    }
  }

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{
        width: window.innerWidth,
        height: window.innerHeight,
        background:
          window.innerWidth > 1024
            ? 'url("https://storage.googleapis.com/showroom-bucket-images/ALL/capa.jpeg") no-repeat '
            : 'url("https://storage.googleapis.com/showroom-bucket-images/ALL/welcome_mobile_h.jpg") no-repeat center',
        alignItems: 'center',
      }}
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{
          width: 340,
          height: 'auto',
          padding: '20px 0px',
          border: '1px solid #000',
          background: 'rgba(255, 255, 255, 0.9)',
        }}
      >
        {tokenId !== 'INVALID_TOKEN' ? (
          <>
            <Grid container justifyContent="center">
              <Typography
                align="center"
                style={{
                  fontSize: 18,
                  letterSpacing: '0.1em',
                  width: 270,
                  borderBottom: '1px solid #000',
                  paddingBottom: 20,
                }}
              >
                CREATE NEW PASSWORD
              </Typography>
            </Grid>
            <>
              <Grid container style={{ width: 270, margin: '20px 0px' }}>
                <Typography>Password must have at least:</Typography>
                <ul style={{ position: 'relative', left: 40, top: 10 }}>
                  <li
                    style={{
                      color: validator.isLowercase(password) ? 'red' : 'green',
                    }}
                  >
                    1 uppercase letter
                  </li>
                  <li
                    style={{
                      color: validator.isUppercase(password) ? 'red' : 'green',
                    }}
                  >
                    1 lowercase letter
                  </li>
                  <li
                    style={{
                      color: password && /\d/.test(password) ? 'green' : 'red',
                    }}
                  >
                    1 number
                  </li>
                  <li
                    style={{
                      color:
                        password && !validator.isAlphanumeric(password)
                          ? 'green'
                          : 'red',
                    }}
                  >
                    1 special symbol _.*+-
                  </li>
                  <li
                    style={{
                      color:
                        password && password === confirmPassword
                          ? 'green'
                          : 'red',
                    }}
                  >
                    match passwords
                  </li>
                </ul>
              </Grid>
              <TextField
                error={passwordError}
                style={{ width: 270, height: 40, margin: '20px 0px' }}
                variant="outlined"
                fullWidth
                autoFocus
                label="Password"
                type="password"
                value={password}
                onChange={e => {
                  setPasswordError('');
                  setPassword(e.target.value);
                }}
                onKeyUp={e => handleKeyPress(e)}
                // helperText={passwordError}
              />
              <div style={{ margin: '20px 0px' }}>
                <TextField
                  error={passwordError}
                  helperText={passwordError}
                  style={{ width: 270, height: 40 }}
                  variant="outlined"
                  fullWidth
                  label="Confirm password"
                  type="password"
                  value={confirmPassword}
                  onChange={e => {
                    setPasswordError('');
                    setConfirmPassword(e.target.value);
                  }}
                  onKeyUp={e => handleKeyPress(e)}
                  // helperText={passwordError}
                />
              </div>
            </>
            <Button
              disabled={
                !password ||
                password !== confirmPassword ||
                validator.isAlphanumeric(password) ||
                !/\d/.test(password) ||
                validator.isLowercase(password) ||
                validator.isUppercase(password)
              }
              variant="contained"
              fullWidth
              onClick={handleLogin}
              style={{
                width: 128,
                height: 40,
                background:
                  !password ||
                  password !== confirmPassword ||
                  validator.isAlphanumeric(password) ||
                  !/\d/.test(password) ||
                  validator.isLowercase(password) ||
                  validator.isUppercase(password)
                    ? '#dddddd'
                    : '#000000',
                borderRadius: 0,
                textTransform: 'none',
                marginTop: 50,
              }}
            >
              {loading  ? ( // eslint-disable-line
                <CircularProgress color="#ffffff" size={26} />
              ) : (
                <Typography style={{ color: '#ffffff' }}>Create</Typography>
              )}
            </Button>
          </>
        ) : (
          <Grid>
            <Typography
              align="center"
              style={{ fontSize: 32, fontWeight: 'bold', marginBottom: 30 }}
            >
              Token Error
            </Typography>
            <Typography align="center" style={{ fontSize: 14 }}>
              {' '}
              Your token is expired.
            </Typography>
            <Button
              variant="contained"
              fullWidth
              onClick={() => history.push('/')}
              style={{
                width: 200,
                height: 40,
                background: '#000000',
                borderRadius: 0,
                textTransform: 'none',
                marginTop: 50,
              }}
            >
              {loading  ? ( // eslint-disable-line
                <CircularProgress color="#ffffff" size={26} />
              ) : (
                <Typography style={{ color: '#ffffff' }}>
                  Go to Home Page
                </Typography>
              )}
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
