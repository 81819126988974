/* eslint-disable no-underscore-dangle */
import { useCallback, useContext } from 'react';

import { ViewAsContext } from '../contexts/viewAs';

import { listAccounts, listAccountUsers } from '../services/api';

export default function useViewAsContext() {
  const [state, setState] = useContext(ViewAsContext);

  const show = useCallback(() => {
    setState(oldState => ({
      ...oldState,
      showModal: true,
    }));
  }, [setState]);

  const hide = useCallback(() => {
    setState(oldState => ({
      ...oldState,
      showModal: false,
    }));
  }, [setState]);

  const setUser = useCallback(
    user => {
      if (user !== 'undefined' || user !== null)
        setState(oldState => ({
          ...oldState,
          selectedUser: user,
        }));
    },
    [setState]
  );

  const setAccount = useCallback(
    account => {
      if (account !== 'undefined' || account !== null)
        setState(oldState => ({
          ...oldState,
          selectedAccount: account,
        }));
    },
    [setState]
  );

  const loadAccounts = useCallback(async () => {
    const response = await listAccounts();

    if (response.status === 200) {
      const accounts = response.data.sort((a, b) => {
        if (a?.name > b?.name) {
          return 1;
        }
        if (a?.name < b?.name) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });

      setState(oldState => {
        return {
          ...oldState,
          accounts,
        };
      });
    }
  }, [setState]);

  const loadAccountUsers = useCallback(async () => {
    const response = await listAccountUsers(state.selectedAccount._id);
    if (response.status === 200) {
      setState(oldState => {
        return {
          ...oldState,
          users: response.data.costumers,
        };
      });
    } else {
      setState(oldState => {
        return {
          ...oldState,
          users: [],
        };
      });
    }
  }, [setState, state.selectedAccount]);

  return {
    viewAsState: state,
    loadAccounts,
    loadAccountUsers,
    setUser,
    setAccount,
    show,
    hide,
  };
}
