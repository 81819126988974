import React, {
  useCallback,
  useState,
  useMemo,
  useEffect,
  useRef,
} from 'react';
import { Grid, Fade, Typography } from '@material-ui/core';
import { useRouteMatch, useLocation } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Img from 'react-cool-img';
import { useDrag, useDrop } from 'react-dnd';
import CategoryItem from '../CategoryItem';
import useConfigContext from '../../hooks/useConfigContext';

import LeftBarCategory from './LeftBarCategory';
import api from '../../services/api';
import ModalProduct from '../ModalProduct';
import style from './styles';
import { ORDERCONFIG, categoryViewOptions } from '../../config/showroomConfig';
import BoardDND from '../BoardDND';

import { saveNewOrderCategories } from './api';

const styles = theme => style(theme);

const dictIdDelivery = {
  1: '5f34a6b5ed5ff21fbc9939c6',
  2: '5f34a7b6bc82e351349c76bf',
  3: '5f34a7c2bc82e351349c76c0',
  holiday: '5f34a6b5ed5ff21fbc9939c6',
  takeaway: '5f34a7b6bc82e351349c76bf',
  'pre-spring': '5f34a7c2bc82e351349c76c0',
};

const categoryObjectKeys = {
  delivery: 'categoriesOptions',
  swimwear: 'categoriesOptionsSwim',
  shoes: 'categoriesOptionsShoes',
  men: 'categoriesOptionsMen',
};

function CategoryView({
  classes,
  showDND,
  handleShowDND,
  handleIsCategoryDetail,
}) {
  const { params } = useRouteMatch();
  const { delivery_id } = params;
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const location = useLocation();
  const currentCollection = location.pathname.split('/')[1];
  const currentCategory = categoryViewOptions[currentCollection];
  const { configState } = useConfigContext();
  const seasonName = configState.season.name || '';

  useEffect(() => {
    handleIsCategoryDetail(selectedCategory);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory]);

  const handleClickOpen = product => {
    setOpen(true);
    setSelectedProduct(product);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedProduct(null);
  };

  const ref = useRef();
  const [, dragRef] = useDrag({
    item: { type: 'CARD', id: 'sou um card' },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, dropRef] = useDrop({
    accept: 'CARD',
    hover(item, monitor) {},
  });

  dragRef(dropRef(ref));

  const handlePrevProduct = useCallback(() => {
    const selectedProductIndex = products.findIndex(
      p => p.style_code === selectedProduct.style_code
    );
    if (selectedProductIndex === 0) {
      // vou pro ultimo item
      const product = products.find(
        (p, index) => index === products.length - 1
      );
      setSelectedProduct(product);
    } else {
      // volto um item
      const product = products.find(
        (p, index) => index === selectedProductIndex - 1
      );

      setSelectedProduct(product);
    }
  }, [products, selectedProduct]);

  const handleNextProduct = useCallback(() => {
    const selectedProductIndex = products.findIndex(
      p => p.style_code === selectedProduct.style_code
    );
    if (selectedProductIndex >= products.length - 1) {
      setSelectedProduct(products[0]);
    } else {
      const product = products.find(
        (p, index) => index === selectedProductIndex + 1
      );
      setSelectedProduct(product);
    }
  }, [products, selectedProduct]);

  const [gridSize, setGridSize] = useState('Large');

  const gridSizeValues = useMemo(() => {
    if (gridSize === 'Small') {
      return { height: 324, width: 205, fontSize: 10, lineHeight: '14px' };
    }
    if (gridSize === 'Large') {
      return { height: 600, width: 415, fontSize: 16, lineHeight: '18px' };
    }

    return { height: 440, width: 273, fontSize: 14, lineHeight: '17px' };
  }, [gridSize]);

  const handleSelectCategory = index => {
    window.scrollTo(0, 0);

    setSelectedCategory(index);
  };

  const loadProducts = useCallback(async () => {
    try {
      if (
        !ORDERCONFIG.CATEGORY[categoryObjectKeys[currentCollection]][
          selectedCategory
        ]
      )
        return;
      const { value } = ORDERCONFIG.CATEGORY[
        categoryObjectKeys[currentCollection]
      ][selectedCategory];

      const style_collection =
        currentCollection === 'delivery'
          ? 'MAIN'
          : currentCollection === 'shoes'
          ? 'FOOTWEAR'
          : 'SWIMWEAR';
      setLoading(true);

      const response = await api.get(
        `/products/${dictIdDelivery[delivery_id]}`,
        {
          params: {
            orderBy: 'category',
            category_name: value && value,
            style_collection,
          },
        }
      );

      setProducts(response.data);
    } finally {
      setLoading(false);
    }
  }, [currentCollection, delivery_id, selectedCategory]);

  useEffect(() => {
    loadProducts();
    window.scrollTo(0, 0);
  }, [selectedCategory, delivery_id, loadProducts]);

  const saveProductsWithNewOrder = useCallback(
    async p => {
      if (loading) return;
      setLoading(true);
      await saveNewOrderCategories({ products: p });
      await loadProducts();
      setLoading(false);

      handleShowDND();
    },
    [loadProducts, handleShowDND, loading]
  );

  const SelectCategoryView = useCallback(
    () => (
      <Fade in timeout={300}>
        <div>
          <Typography
            align="center"
            style={{
              letterSpacing: '0.1em',
              fontSize: 27,
              color: 'black',
              marginBottom: 12,
            }}
          >
            {seasonName}
          </Typography>
          <Typography
            align="center"
            style={{ color: 'black', letterSpacing: '0.1em' }}
          />
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              container
              justifyContent="space-around"
              alignItems="center"
              style={{
                width: 1044,
                height: '100%',
              }}
            >
              {currentCategory?.categoriesArray?.map(
                (categoryObject, index) => {
                  return (
                    categoryObject.activeDeliveries.includes(+delivery_id) && (
                      <CategoryItem
                        left={index % 2 !== 0}
                        top={index % 2 === 0}
                        key={`delivery${delivery_id}: ${categoryObject.categoryName}`}
                        img={
                          ORDERCONFIG.CATEGORY
                            .IMAGENS_CAPA_CATEGORIA_POR_DELIVERY[
                            categoryObject.categoryName
                          ][
                            currentCollection === 'swimwear' &&
                            (categoryObject.categoryName === 'BOTTOMS' ||
                              categoryObject.categoryName === 'OUTERWEAR' ||
                              categoryObject.categoryName === 'DRESSES' ||
                              categoryObject.categoryName === 'JUMPSUITS')
                              ? parseInt(delivery_id) + 3
                              : delivery_id
                          ]
                        }
                        txt={categoryObject.categoryName}
                        onClick={() => handleSelectCategory(index + 1)}
                      />
                    )
                  );
                }
              )}
            </Grid>
          </Grid>
        </div>
      </Fade>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [delivery_id]
  );

  const CategoryDetail = useCallback(
    () => (
      <Grid container justifyContent="center" alignItems="flex-start">
        {/* LEFT CONTAINER */}
        <LeftBarCategory
          gridSize={gridSize}
          setGridSize={setGridSize}
          setSelectedCategory={handleSelectCategory}
          selectedCategory={selectedCategory}
          delivery={delivery_id}
        />

        {/* RIGHT CONTAINER */}
        {/* <Fade in timeout={300}> */}
        <Grid
          container
          justifyContent="flex-start"
          alignItems="flex-start"
          style={{
            // position: 'absolute',
            marginTop: 112,
            width: '70%',
            height: '100%',
            marginRight: '5%',
          }}
        >
          {selectedProduct && (
            <ModalProduct
              handlePrevProduct={handlePrevProduct}
              handleNextProduct={handleNextProduct}
              open={open}
              handleClose={handleClose}
              product={selectedProduct}
            />
          )}

          {!loading && showDND ? (
            <BoardDND
              delivery_id={delivery_id}
              data={products}
              handleSaveProducts={saveProductsWithNewOrder}
            />
          ) : (
            products.map((product, index) => (
              <Grid
                onClick={() => handleClickOpen(product)}
                key={String(product.style_code)}
                style={{
                  // background: 'green',
                  position: 'relative',
                  // margin: '4px 6px',
                  display: 'inline-block',
                  cursor: 'pointer',
                }}
              >
                <Grid
                  className={classes.hover}
                  style={{
                    bottom: 0,
                    height: '100%',
                    marginLeft: -13,
                    // minWidth: gridSizeValues.width,
                    // maxWidth: gridSizeValues.width,
                    display: 'flex',
                    alignItems: 'flex-end',
                  }}
                >
                  <Grid
                    style={{
                      backgroundColor: ' rgba(255, 255, 255, 0.95)',
                    }}
                  >
                    <>
                      <Typography
                        style={{
                          color: 'black',
                          // paddingTop: 12,
                          paddingTop: 4,

                          lineHeight: gridSizeValues.lineHeight,
                          width:
                            gridSizeValues.width >= 415
                              ? gridSizeValues.width - 13
                              : gridSizeValues.width <= 205
                              ? gridSizeValues.width + 11
                              : gridSizeValues.width + 21,
                          paddingLeft: 12,
                          fontSize: gridSizeValues.fontSize,
                        }}
                      >
                        {product.style_code}
                      </Typography>
                      <Typography
                        style={{
                          // display: 'flex',
                          // alignItems: 'flex-end',
                          // top: 1,
                          lineHeight: gridSizeValues.lineHeight,
                          color: 'black',
                          fontSize: gridSizeValues.fontSize,
                          paddingBottom: 14,
                          paddingLeft: 12,

                          wordBreak: 'break-word',

                          // height: '100%',
                        }}
                      >
                        {/* {' '} */}
                        {product.description?.toUpperCase()}
                      </Typography>
                    </>
                  </Grid>
                </Grid>
                {product.status === 'Sold Out' && (
                  <Typography
                    align="center"
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      color: '#fff',
                      zIndex: 1000,
                      fontSize: gridSizeValues.height * 0.1,
                      letterSpacing: '0.1em',
                      width: '100%',
                    }}
                  >
                    SOLD OUT{' '}
                  </Typography>
                )}

                <Img
                  src={
                    product.mainly_image ||
                    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAPFBMVEXu7u62trbx8fHPz8/t7e2zs7O8vLy5ubnl5eWrq6vc3Nzq6urZ2dnh4eHV1dW6urrHx8fBwcHKysqlpaXy5cTEAAAEF0lEQVR4nO3bjXKkKBQFYLj8CAIK5v3fdS/Y9mi3md1UZavFOt9UTTpgujwBQaEjBAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAEDH6N2nT+l3TfaNulNEGo18Y+KnT+sXUXkPKGW5USOGs4BShk+f1++JJ52Uu+l0m0akfN6GN+im66RA7jygNK7veYNcnBo1fJNQDmo9IrouQ2az+S4gt+ImdzjqlLMp0Pz5/6XGfvp8f4qm9xhmVimwNNmTys4GVv0+x5uStiGFKO1a+NGktq+EQtjXgGo3ZOpdG5tlaiPR0HvC+BJgu1U1C7eo6T+heQQkEYJ4vHzc6PD1F/pPaHI7fXKZi+2yzn60tLrBhdx/Qqlbmdpmi6WlCcN+pOk7YXvYpeU5fj4iqv2k0XVC48TLU/B6XYbbJGz3K/rQqi0PDXdJ2MYZd7iPMXW0OTxV9ZzQ1MuQjk/BrZvS/kLsOmEdV15uVNttKKm7tGEbOceTNlxuknBdrTiuRpn6PHi4Pe864VAnfMr7RmyhSd4loRlfG7HNkCLdZj5cb0t3U/464R+X4LpOKE1qiRw/2/M/Y9db7yTvk1DOayEllbNKdHZI5wkfj09itzx6HHm6T8gR9SEB6ZeA3SeUxnLn1FttuyRvlrA247aIz8/678uJN0hYB9FlinFa7NmacG8Jz/dE12X885re1kvP1rz/qrc17/pI/6OIZtb//p4XE6z5gdLh3hPfwEzqP5pSb1304eRTQt/49JkCAAAAAEBftHt/0D0rq9zFHhmfH1n7U0LHkrY749uC/lnZ/r3aMet+zjXoGEaVRN18UfG58hvVWBdGp6kGiDoqRy76aWxV9S8RjmXCRdLCjfwjiotDNOo6f66g/TBYHwUVX8y6cSaCNPwdTd5yDWkvZ+vHcfBz4apZSSv2ZbWfpi/H71Bo9Hnx0Q1+3Xe8BG0yUbGkfOCv87pzZohCCF+RKPog/EJkCyd1RLnwU70f92X1XWjO/DoJ7p2csvXSy3RTXU9GzWTr/svo2wb29gGM+pqb1yc+whJfc1w1lFI43q6s4t+EiIac59rC7Xmp69AkUROW0hK2Pe0tIX9DX9xe9YgtYR6Z25e1d+HIVnHCWKv1JROO3PXCY8NX8XmnxD1R6MwxazJOo/2oSZlAgct3Ze2CIyVr+8vMv5lIwkx0neuwtYbkPueNL4+yzK8XSsZ749r1RYvlq9Rz3FqV9b5snfoCD008lBqu5mEre3+dGbG1Qe2PIYXnVNdek3CtD9Zz1aFO7+552KGsvY1v00b9kdrFt+IrOblhOfvyTRlN5v85rasYzdfrh8FvJox9/m3QD9w9HwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAANzJP/MdJGqyWhqXAAAAAElFTkSuQmCC'
                  }
                  alt="img"
                  style={{
                    width: 'auto',
                    height: gridSizeValues.height,
                    margin: '0px 2px 1px',
                    filter: product.status === 'Sold Out' && 'brightness(65%)',
                  }}
                />
                {product?.sustainableTags && (
                  <>
                    <Grid
                      container
                      style={{
                        position: 'absolute',
                        background: 'white',
                        padding: '0px 9px',
                        bottom:
                          gridSize === 'Large'
                            ? '11%'
                            : gridSize === 'Medium'
                            ? '14%'
                            : '16%',
                        width: 'fit-content',

                        opacity: 0.8,
                      }}
                    >
                      <Typography
                        style={{
                          position: 'relative',
                          bottom: 2,
                          fontFamily: 'GalanoGrotesque-Medium',
                          fontSize:
                            gridSize === 'Large'
                              ? 14
                              : gridSize === 'Medium'
                              ? 12
                              : 10,
                        }}
                      >
                        {product.sustainableTags[0]}
                      </Typography>
                    </Grid>
                    <Grid
                      container
                      style={{
                        position: 'absolute',
                        background: 'white',
                        bottom:
                          gridSize === 'Small'
                            ? '23%'
                            : gridSize === 'Large'
                            ? '16%'
                            : '19.5%',
                        width: 'fit-content',
                        padding: '0px 9px',

                        opacity: 0.8,
                      }}
                    >
                      <Typography
                        style={{
                          position: 'relative',
                          bottom: 2,
                          fontFamily: 'GalanoGrotesque-Medium',
                          fontSize:
                            gridSize === 'Large'
                              ? 14
                              : gridSize === 'Medium'
                              ? 12
                              : 10,
                        }}
                      >
                        {product.sustainableTags[1]}
                      </Typography>
                    </Grid>
                  </>
                )}
              </Grid>
            ))
          )}
        </Grid>
        {/* </Fade> */}
      </Grid>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      classes.hover,
      delivery_id,
      gridSize,
      gridSizeValues.fontSize,
      gridSizeValues.height,
      gridSizeValues.lineHeight,
      gridSizeValues.width,
      handleNextProduct,
      handlePrevProduct,
      handleShowDND,
      loading,
      open,
      products,
      saveProductsWithNewOrder,
      selectedCategory,
      selectedProduct,
      showDND,
    ]
  );

  // const Component = useCallback(() => {
  if (selectedCategory) {
    return <CategoryDetail />;
  }
  return <SelectCategoryView />;
  // }, [selectedCategory]);
}
const wrapperComponent = withStyles(styles)(CategoryView);
export default wrapperComponent;
