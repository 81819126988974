import React from 'react';
import { Typography, Grid } from '@material-ui/core';

function CategoryItem({ top, left, img, txt, onClick, isSwim }) {
  return (
    <Grid
      style={{ position: 'relative', margin: '2% 4%', cursor: 'pointer' }}
      onClick={() => onClick()}
    >
      {!isSwim ? (
        <img
          style={{
            width: 340,
            height: 480,
            position: 'relative',
            left: left ? '10%' : '-10%',
          }}
          src={img}
          alt="farm"
        />
      ) : (
        <Grid
          style={{
            width: 300,
            height: 300,
            // position: 'relative',
            // left: left ? '10%' : '-10%',
          }}
        />
      )}
      {txt !== 'TOPS AND BLOUSES' ? (
        <Typography
          variant="h3"
          style={{
            fontSize: 48,
            fontWeight: 'light',
            lineHeight:
              txt === 'BIKINI BOTTOMS' || txt === 'ONE PIECE'
                ? 'unset'
                : '35px',
            height:
              txt === 'BIKINI BOTTOMS' || txt === 'ONE PIECE' ? 'unset' : 50,
            color: 'black',
            position: 'absolute',
            top: top ? '10%' : '70%',
            left: left ? '-33%' : '66%',
            minWidth: 260,
            maxWidth: txt !== 'BIKINI BOTTOMS' && 315,
            display: 'flex',
            justifyContent: left ? 'flex-end' : 'flex-start',
            padding: '0px 5px',
            background: '#fff',
          }}
        >
          {txt}
        </Typography>
      ) : (
        <Grid>
          <Typography
            variant="h3"
            style={{
              fontSize: 48,
              fontWeight: 'light',
              lineHeight: '35px',
              height: 50,
              color: 'black',
              position: 'absolute',
              top: top ? '10%' : '70%',
              left: left ? '-33%' : '66%',
              minWidth: 225,
              maxWidth: 300,
              display: 'flex',
              justifyContent: left ? 'flex-end' : 'flex-start',
              padding: '0px 5px',
              background: '#fff',
            }}
          >
            TOPS &amp;
          </Typography>
          <Typography
            variant="h3"
            style={{
              fontSize: 48,
              fontWeight: 'light',
              lineHeight: '35px',
              height: 50,
              color: 'black',
              position: 'absolute',
              top: top ? '22%' : '80%',
              left: left ? '-19%' : '66%',
              minWidth: 225,
              maxWidth: 300,
              display: 'flex',
              justifyContent: left ? 'flex-end' : 'flex-start',
              padding: '0px 5px',
              background: '#fff',
            }}
          >
            BLOUSES
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}

export default CategoryItem;
