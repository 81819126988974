import React, { useRef, useState, useLayoutEffect, useCallback } from 'react';
import { Typography, CircularProgress } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Img from 'react-cool-img';
import Skeleton from '@material-ui/lab/Skeleton';
import useUserContext from '../../hooks/useUserContext';
import api from '../../services/api';
import Cart from '../../components/ModalCart';
import { ReactComponent as DownloadLogo } from '../../assets/downloadSmall.svg';
import { ReactComponent as SmallArrow } from '../../assets/smallArrow.svg';

const useStyles = makeStyles(() => ({
  accordionDetails: {
    '&.MuiAccordionDetails-root': {
      display: 'block',
    },
    '&.MuiAccordionDetails-root .MuiPaper-elevation1': {
      boxShadow: 'none',
    },
  },
  accordion: {
    '&.MuiAccordion-root:before': {
      backgroundColor: 'unset',
    },
    '&.MuiAccordion-root.Mui-expanded': {
      margin: 'unset',
    },
  },
  gridItem: {
    display: 'flex',
  },
  span: {
    height: 15,
    width: 15,
    borderRadius: '50%',
  },
  summaryProduct: {
    textAlign: 'end',
  },
  summaryItem: {
    width: 95,
  },
  summaryStyle: {
    width: 87,
  },
  button: {
    '&.MuiButton-label': {
      alignItems: 'end',
    },
  },
  textAddresses: {
    fontSize: '10px',
  },
  titleAddresses: {
    fontSize: '12px',
  },
  bold: {
    fontWeight: 'bold',
  },
  downloadButton: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'flex-end',
  },
}));

const StyledAccordionSummary = withStyles({
  root: {
    padding: 0,
    minHeight: 'unset',
  },
  content: {
    justifyContent: 'space-between',
    margin: 'unset',
    height: 32,
  },
})(AccordionSummary);

const StyledButton = withStyles({
  root: {
    textTransform: 'none',
    padding: '0px',
    minWidth: 'auto',
  },
  contained: {
    boxShadow: 'none',
    backgroundColor: 'transparent',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: 'transparent',
    },
  },
  endIcon: {
    marginLeft: 3,
    marginRight: 0,
  },
  label: {
    alignItems: 'end',
  },
})(Button);

const removeDuplicate = years => [...new Set(years)];

const sortByKey = (toSort, key) => {
  toSort.sort((objA, objB) => {
    if (objA[key] > objB[key]) return 1;
    if (objA[key] < objB[key]) return -1;
    return 0;
  });

  if (key === 'year') toSort.reverse();
};

const convertIdToDelivery = id => {
  switch (id) {
    case '5f34a6b5ed5ff21fbc9939c6':
      return 'Delivery 1';
    case '5f34a7b6bc82e351349c76bf':
      return 'Delivery 2';
    default:
      return 'Delivery 3';
  }
};

const orderStatusColor = status => {
  switch (status) {
    case 'Notes':
      return 'blue';
    case 'Approved':
      return 'green';
    case 'Pending':
      return 'yellow';
    default:
      return 'red';
  }
};

const formateDate = dateString => {
  const months = [
    'jan',
    'feb',
    'mar',
    'apr',
    'may',
    'jun',
    'jul',
    'aug',
    'sep',
    'oct',
    'nov',
    'dec',
  ];
  const dateSpplited = dateString.split('-');
  const hoursArray = dateSpplited[2].split(':');

  return `${dateSpplited[2][0]}${dateSpplited[2][1]} ${
    months[parseInt(dateSpplited[1]) - 1]
  } ${dateSpplited[0]} ${`${hoursArray[0][3] + hoursArray[0][4]}:${
    hoursArray[1]
  }`}`;
};

const totalByKey = (key, summaryProducts) => {
  return summaryProducts.reduce(
    (previousDelivery, currentDelivery) =>
      previousDelivery + currentDelivery[key],
    0
  );
};

function Divider() {
  return <div style={{ borderBottom: '1px solid black', margin: '8px 0px' }} />;
}

const continueNotes = (setOpenCart, setMyProducts, order, setOrderSelected) => {
  setOpenCart(true);
  setMyProducts(order.products);
  setOrderSelected(order);
};

export default function OrderHistory() {
  const classes = useStyles();
  const { userState } = useUserContext();
  const { user: profile } = userState;
  const years = useRef([]);
  const summaryProducts = useRef([]);
  const [loading, setLoading] = useState(false);
  const orders = useRef([]);
  const [downloadExcelURL, setDownloadExcelURL] = useState('');
  const [loadingOrder, setLoadingOrder] = useState(false);
  const [openCart, setOpenCart] = useState(false);
  const [myProducts, setMyProducts] = useState([]);
  const [excelReady, setExcelReady] = useState([]);
  const [orderSelected, setOrderSelected] = useState(null);

  // Agrupa as keys de produtos (order.products) por delivery
  const generateSummaryItems = useCallback(() => {
    summaryProducts.current = orders.current.map(order => {
      return Object.values(
        order.products.reduce((previous, current) => {
          const key = current.delivery;

          if (current.price === 0 || current.style === 0 || current.items === 0)
            return previous;

          if (!previous[key])
            previous[key] = {
              delivery: key,
              style: 0,
              items: 0,
              totalPrice: 0,
            };

          current.items = current?.items.reduce(
            (previous, current) => previous + current,
            0
          );
          current.totalPrice = current.items * current.price;
          delete current.price;

          previous[key].style += current.style;
          previous[key].items += current.items;
          previous[key].totalPrice += current.totalPrice;

          return previous;
        }, {})
      );
    });
  }, []);

  const handleExcel = async (products, index) => {
    setLoadingOrder(true);

    const excelUrl = await api.post('/orderExcel', {
      arrayOfProducts: products,

      email: profile.email,
    });

    setDownloadExcelURL(excelUrl.data);

    setLoadingOrder(false);
    setExcelReady([...excelReady, index]);
  };

  const handleOrdersData = useCallback(async () => {
    try {
      const userData = await api.get(
        `newCostumers/${localStorage.getItem('showroom-userEmail')}`
      );

      const { data } = await api.get(`listOrders/${userData.data.account._id}`);

      const filteredOrderKeys = data.map(order => ({
        ...order,
        year: order?.createdAt.split('-')[0],
        products: order?.products.map(product => ({
          ...product,
          delivery: convertIdToDelivery(product.delivery_id),
          style: product.cartInfo.length,
          items: product?.cartInfo?.map(cart => cart?.quantity),
          price: product.wholesale_usd,
          print_name: product?.print_name || [],
        })),
      }));

      years.current = removeDuplicate(
        filteredOrderKeys.map(order => order.year)
      );

      // ordenação de chaves de objetos
      filteredOrderKeys.map(order => sortByKey(order?.products, 'delivery'));
      years.current.sort().reverse();
      sortByKey(filteredOrderKeys, 'year');
      orders.current = filteredOrderKeys;
      generateSummaryItems();
      setLoading(true);
    } catch (error) {
      console.log(error);
    }
  }, [generateSummaryItems]);

  useLayoutEffect(() => {
    handleOrdersData();
  }, [handleOrdersData]);

  return (
    <Grid
      item
      container
      xs={9}
      justifyContent="space-between"
      style={{ marginTop: 33 }}
    >
      <Grid item xs={3}>
        <Typography style={{ marginLeft: 15 }}>Your Orders</Typography>
      </Grid>
      {loading ? (
        <Grid item xs={9}>
          {years.current.map((year, index) => (
            <Accordion key={index}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>{year}</Typography>
              </AccordionSummary>
              {orders.current.map(
                (order, index) =>
                  order.year === year && (
                    <AccordionDetails
                      className={classes.accordionDetails}
                      key={index}
                      style={{ marginTop: 15 }}
                    >
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        style={{ marginBottom: 14 }}
                      >
                        <Typography style={{ fontSize: 18 }}>
                          {order.num}
                        </Typography>
                        <Grid item className={classes.gridItem}>
                          <Grid
                            container
                            wrap="nowrap"
                            style={{ width: 'auto' }}
                            alignItems="center"
                          >
                            <span
                              className={classes.span}
                              style={{
                                backgroundColor: orderStatusColor(order.status),
                                marginTop: 5,
                              }}
                            />
                            <Typography
                              className={classes.bold}
                              style={{ marginLeft: 8 }}
                            >
                              {order.status}
                            </Typography>
                          </Grid>

                          <StyledButton
                            variant="contained"
                            style={{ margin: '0px 15px' }}
                          >
                            {!excelReady.includes(index) ? (
                              <Grid
                                container
                                justifyContent="center"
                                onClick={() =>
                                  !loadingOrder &&
                                  handleExcel(order.products, index)
                                }
                                style={{ alignItems: 'flex-end' }}
                              >
                                {!loadingOrder ? (
                                  <span
                                    href={downloadExcelURL}
                                    className={classes.downloadButton}
                                    download="FARM US - RESORT 23.xlsx"
                                  >
                                    <Typography style={{ marginRight: 3 }}>
                                      Download Order
                                    </Typography>
                                    <DownloadLogo />
                                  </span>
                                ) : (
                                  <>
                                    <Typography
                                      display="inline"
                                      style={{ marginRight: 3 }}
                                    >
                                      Preparing order...
                                    </Typography>
                                    <CircularProgress
                                      size={12}
                                      style={{ marginLeft: 5 }}
                                    />
                                  </>
                                )}
                              </Grid>
                            ) : (
                              <Grid
                                container
                                justifyContent="center"
                                style={{ alignItems: 'flex-end' }}
                              >
                                <a
                                  href={downloadExcelURL}
                                  className={classes.downloadButton}
                                  download="FARM US - RESORT 23.xlsx"
                                >
                                  <Typography style={{ marginRight: 3 }}>
                                    Excel Ready
                                  </Typography>
                                  <DownloadLogo />
                                </a>
                              </Grid>
                            )}
                          </StyledButton>
                          <StyledButton
                            variant="contained"
                            endIcon={<SmallArrow />}
                            onClick={() => {
                              continueNotes(
                                setOpenCart,
                                setMyProducts,
                                order,
                                setOrderSelected
                              );
                            }}
                          >
                            <Typography>
                              {order.status === 'Notes'
                                ? 'Continue Notes'
                                : 'Review Order'}
                            </Typography>
                          </StyledButton>
                        </Grid>
                      </Grid>
                      <Divider />
                      <Accordion className={classes.accordion}>
                        <StyledAccordionSummary aria-controls="panel1a-content">
                          <StyledButton
                            variant="contained"
                            endIcon={<ExpandMoreIcon />}
                            style={{ alignItems: 'baseline' }}
                          >
                            <Typography>Order Summary</Typography>
                          </StyledButton>

                          <Grid
                            className={`${classes.summaryProduct} + ${classes.summaryStyle}`}
                          >
                            <Typography className={classes.bold}>
                              {totalByKey(
                                'style',
                                summaryProducts.current[index]
                              )}{' '}
                              styles
                            </Typography>
                          </Grid>
                          <Grid
                            className={`${classes.summaryProduct} + ${classes.summaryItem}`}
                          >
                            <Typography className={classes.bold}>
                              {totalByKey(
                                'items',
                                summaryProducts.current[index]
                              )}{' '}
                              items
                            </Typography>
                          </Grid>
                          <Grid
                            className={`${classes.summaryProduct} + ${classes.summaryItem}`}
                          >
                            <Typography className={classes.bold}>
                              ${' '}
                              {totalByKey(
                                'totalPrice',
                                summaryProducts.current[index]
                              )}
                            </Typography>
                          </Grid>
                        </StyledAccordionSummary>
                        <AccordionDetails
                          className={classes.accordionDetails}
                          style={{ padding: 0 }}
                        >
                          <Grid container>
                            {summaryProducts.current[index].map(object => (
                              <Grid
                                container
                                justifyContent="space-between"
                                key={`${object.delivery} ${object.style} ${object.items}`}
                              >
                                <Grid style={{ width: 148 }}>
                                  <Typography>{object.delivery}</Typography>
                                </Grid>
                                <Grid
                                  className={`${classes.summaryProduct} + ${classes.summaryStyle}`}
                                >
                                  <Typography>{object.style} styles</Typography>
                                </Grid>
                                <Grid
                                  className={`${classes.summaryProduct} + ${classes.summaryItem}`}
                                >
                                  <Typography>{object.items} items</Typography>
                                </Grid>
                                <Grid
                                  className={`${classes.summaryProduct} + ${classes.summaryItem}`}
                                >
                                  <Typography>${object.totalPrice}</Typography>
                                </Grid>
                              </Grid>
                            ))}
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                      <Divider />

                      <Grid container style={{ marginTop: 8 }}>
                        <Grid item xs={3} style={{ margin: '18px 0px' }}>
                          <Img
                            style={{
                              margin: '1px 2px',
                              width: 127,
                              height: 176,
                            }}
                            src={
                              order.products[0]?.mainly_image ||
                              'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAPFBMVEXu7u62trbx8fHPz8/t7e2zs7O8vLy5ubnl5eWrq6vc3Nzq6urZ2dnh4eHV1dW6urrHx8fBwcHKysqlpaXy5cTEAAAEF0lEQVR4nO3bjXKkKBQFYLj8CAIK5v3fdS/Y9mi3md1UZavFOt9UTTpgujwBQaEjBAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAEDH6N2nT+l3TfaNulNEGo18Y+KnT+sXUXkPKGW5USOGs4BShk+f1++JJ52Uu+l0m0akfN6GN+im66RA7jygNK7veYNcnBo1fJNQDmo9IrouQ2az+S4gt+ImdzjqlLMp0Pz5/6XGfvp8f4qm9xhmVimwNNmTys4GVv0+x5uStiGFKO1a+NGktq+EQtjXgGo3ZOpdG5tlaiPR0HvC+BJgu1U1C7eo6T+heQQkEYJ4vHzc6PD1F/pPaHI7fXKZi+2yzn60tLrBhdx/Qqlbmdpmi6WlCcN+pOk7YXvYpeU5fj4iqv2k0XVC48TLU/B6XYbbJGz3K/rQqi0PDXdJ2MYZd7iPMXW0OTxV9ZzQ1MuQjk/BrZvS/kLsOmEdV15uVNttKKm7tGEbOceTNlxuknBdrTiuRpn6PHi4Pe864VAnfMr7RmyhSd4loRlfG7HNkCLdZj5cb0t3U/464R+X4LpOKE1qiRw/2/M/Y9db7yTvk1DOayEllbNKdHZI5wkfj09itzx6HHm6T8gR9SEB6ZeA3SeUxnLn1FttuyRvlrA247aIz8/678uJN0hYB9FlinFa7NmacG8Jz/dE12X885re1kvP1rz/qrc17/pI/6OIZtb//p4XE6z5gdLh3hPfwEzqP5pSb1304eRTQt/49JkCAAAAAEBftHt/0D0rq9zFHhmfH1n7U0LHkrY749uC/lnZ/r3aMet+zjXoGEaVRN18UfG58hvVWBdGp6kGiDoqRy76aWxV9S8RjmXCRdLCjfwjiotDNOo6f66g/TBYHwUVX8y6cSaCNPwdTd5yDWkvZ+vHcfBz4apZSSv2ZbWfpi/H71Bo9Hnx0Q1+3Xe8BG0yUbGkfOCv87pzZohCCF+RKPog/EJkCyd1RLnwU70f92X1XWjO/DoJ7p2csvXSy3RTXU9GzWTr/svo2wb29gGM+pqb1yc+whJfc1w1lFI43q6s4t+EiIac59rC7Xmp69AkUROW0hK2Pe0tIX9DX9xe9YgtYR6Z25e1d+HIVnHCWKv1JROO3PXCY8NX8XmnxD1R6MwxazJOo/2oSZlAgct3Ze2CIyVr+8vMv5lIwkx0neuwtYbkPueNL4+yzK8XSsZ749r1RYvlq9Rz3FqV9b5snfoCD008lBqu5mEre3+dGbG1Qe2PIYXnVNdek3CtD9Zz1aFO7+552KGsvY1v00b9kdrFt+IrOblhOfvyTRlN5v85rasYzdfrh8FvJox9/m3QD9w9HwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAANzJP/MdJGqyWhqXAAAAAElFTkSuQmCC'
                            }
                            alt="a"
                          />
                        </Grid>

                        <Grid
                          className={classes.gridItem}
                          item
                          xs={9}
                          style={{ marginTop: 8 }}
                        >
                          <Grid item xs={6} key={index}>
                            <Grid style={{ margin: '4px 4px 12px 12px' }}>
                              <Typography
                                className={classes.titleAddresses}
                                style={{
                                  marginTop: 8,
                                  marginBottom: 20,
                                  marginLeft: -10,
                                }}
                              >
                                Shipping Address
                              </Typography>
                              <Grid
                                className={classes.gridItem}
                                style={{
                                  alignItems: 'baseline',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <Typography
                                  className={`${classes.titleAddresses} ${classes.bold}`}
                                  style={{
                                    marginBottom: 20,
                                  }}
                                >
                                  {order.sAddress?.name ||
                                    `Address ${index + 1}`}
                                </Typography>
                              </Grid>

                              <Typography className={classes.textAddresses}>
                                {order.sAddress.street ||
                                  order.sAddress?.sLine1}
                              </Typography>
                              <Typography className={classes.textAddresses}>
                                {`${order.sAddress?.building_unit_floor ||
                                  order.sAddress?.sLine2} ${order.sAddress
                                  ?.city || order.sAddress?.sLine3}`}
                              </Typography>
                              <Typography className={classes.textAddresses}>
                                {`${order.sAddress?.zipcode ||
                                  order.sAddress?.sZipcode} ${order.sAddress
                                  ?.country || order.sAddress?.sCountry}`}
                              </Typography>
                            </Grid>
                            <Typography className={classes.titleAddresses}>
                              Date created
                            </Typography>
                            <Typography className={classes.textAddresses}>
                              {`${formateDate(order.createdAt)} by ${
                                order.email
                              }`}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Divider />
                    </AccordionDetails>
                  )
              )}
            </Accordion>
          ))}
        </Grid>
      ) : (
        <Grid item xs={9}>
          <Skeleton
            variant="rect"
            style={{ height: window.innerHeight * 0.5 }}
          />
        </Grid>
      )}

      <Cart
        open={openCart}
        handleClose={() => setOpenCart(false)}
        orderProducts={myProducts}
        orderSelected={orderSelected}
      />
    </Grid>
  );
}
