import React, { useCallback, useState, useEffect } from 'react';
import { Grid, Fade, Typography } from '@material-ui/core';
import { useRouteMatch, useLocation } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Img from 'react-cool-img';
import CategoryItem from '../CategoryItemMobile';

import api from '../../services/api';
import ModalProduct from '../ModalProductMobile';
import style from './styles';
import { ORDERCONFIG, categoryViewOptions } from '../../config/showroomConfig';

const styles = theme => style(theme);

const dictIdDelivery = {
  1: '5f34a6b5ed5ff21fbc9939c6',
  2: '5f34a7b6bc82e351349c76bf',
  3: '5f34a7c2bc82e351349c76c0',
  smiley: '5f34a7b6bc82e351349c76bf',
};

const categoryObjectKeys = {
  delivery: 'categoriesOptions',
  swimwear: 'categoriesOptionsSwim',
  shoes: 'categoriesOptionsShoes',
};

function CategoryView() {
  const { params } = useRouteMatch();
  const { delivery_id } = params;
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const location = useLocation();
  const currentCollection = location.pathname.split('/')[1];
  const currentCategory = categoryViewOptions[currentCollection];

  const stateMachineFoward = {
    delivery: {
      1: 2, // DRESSES -> TOPS & BLOUSES
      2: 3, // TOPS & BLOUSES -> BOTTOMS
      3: 4, // BOTTOMS -> JUMPSUITS
      4: 5, // JUMPSUITS -> KNITWEAR
      5: delivery_id === '3' ? 7 : 6, // KNITWEAR -> ACCESSORIES (se entrega 3), senão OUTERWEAR
      6: 7, // OUTERWEAR -> ACCESSORIES
      7: 1, // ACCESSORIES -> DRESSES (reinicia)
    },
    swimwear: {
      1: 2, // BIKINI BOTTOMS -> BIKINI TOPS
      2: 3, // BIKINI TOPS -> ONE PIECE
      3: 4, // ONE PIECE -> SARONGS
      4: 5, // SARONGS -> TOPS
      5: 6, // TOPS -> BOTTOMS
      6: 7, // BOTTOMS -> DRESSES
      7: 1, // DRESSES -> BIKINI BOTTOMS (reinicia)
    },
    shoes: {
      1: 2, // FLAT SANDALS
      2: 3, // FLATS
      3: delivery_id === '2' ? 5 : 4, // HEELED SANDALS vai para BAGS na entrega 1, e para CLOGS AND MULES na entrega 2
      4: 1, // CLOGS AND MULES volta ao início na entrega 2
      5: 1, // BAGS volta ao início na entrega 1
    },
    men: {
      1: 2,
      2: 1,
    },
  };

  const stateMachineBackward = {
    delivery: {
      1: 7, // DRESSES <- ACCESSORIES
      2: 1, // TOPS & BLOUSES <- DRESSES
      3: 2, // BOTTOMS <- TOPS & BLOUSES
      4: 3, // JUMPSUITS <- BOTTOMS
      5: 4, // KNITWEAR <- JUMPSUITS
      6: 5, // OUTERWEAR <- KNITWEAR (somente entregas 1 e 2)
      7: delivery_id === '3' ? 5 : 6, // ACCESSORIES <- KNITWEAR (entrega 3) ou OUTERWEAR (entregas 1 e 2)
    },
    swimwear: {
      1: 7, // BIKINI BOTTOMS <- DRESSES
      2: 1, // BIKINI TOPS <- BIKINI BOTTOMS
      3: 2, // ONE PIECE <- BIKINI TOPS
      4: 3, // SARONGS <- ONE PIECE
      5: 4, // TOPS <- SARONGS
      6: 5, // BOTTOMS <- TOPS
      7: 6, // DRESSES <- BOTTOMS
    },
    shoes: {
      1: delivery_id === '2' ? 5 : 4, // FLAT SANDALS volta para BAGS na entrega 1, e para CLOGS AND MULES na entrega 2
      2: 1, // FLATS volta para FLAT SANDALS
      3: 2, // HEELED SANDALS volta para FLATS
      4: 3, // CLOGS AND MULES volta para HEELED SANDALS na entrega 2
      5: 3, // BAGS volta para HEELED SANDALS na entrega 1
    },
    men: {
      1: 2,
      2: 1,
    },
  };

  const handleClickOpen = product => {
    setOpen(true);
    setSelectedProduct(product);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedProduct(null);
  };

  const handlePrevProduct = useCallback(() => {
    const selectedProductIndex = products.findIndex(
      p => p.style_code === selectedProduct.style_code
    );
    if (selectedProductIndex === 0) {
      // vou pro ultimo item
      const product = products.find(
        (p, index) => index === products.length - 1
      );
      setSelectedProduct(product);
    } else {
      // volto um item
      const product = products.find(
        (p, index) => index === selectedProductIndex - 1
      );

      setSelectedProduct(product);
    }
  }, [products, selectedProduct]);

  const handleNextProduct = useCallback(() => {
    const selectedProductIndex = products.findIndex(
      p => p.style_code === selectedProduct.style_code
    );
    if (selectedProductIndex >= products.length - 1) {
      setSelectedProduct(products[0]);
    } else {
      const product = products.find(
        (p, index) => index === selectedProductIndex + 1
      );
      setSelectedProduct(product);
    }
  }, [products, selectedProduct]);

  const handleSelectCategory = index => {
    window.scrollTo(0, 0);

    setSelectedCategory(index);
  };
  useEffect(() => {
    async function loadProducts() {
      try {
        if (
          !ORDERCONFIG.CATEGORY[categoryObjectKeys[currentCollection]][
            selectedCategory
          ]
        )
          return;
        const style_collection =
          currentCollection === 'delivery'
            ? 'MAIN'
            : currentCollection === 'shoes'
            ? 'FOOTWEAR'
            : 'SWIMWEAR';

        const { value } = ORDERCONFIG.CATEGORY[
          categoryObjectKeys[currentCollection]
        ][selectedCategory];
        setLoading(true);
        const response = await api.get(
          `/products/${dictIdDelivery[delivery_id]}`,
          {
            params: {
              orderBy: 'category',
              category_name: value && value,
              style_collection,
            },
          }
        );

        setProducts(response.data);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    }
    loadProducts();
  }, [selectedCategory, delivery_id, currentCollection]);

  const SelectCategoryView = useCallback(
    () => (
      <Fade in timeout={300}>
        <div>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              style={{
                marginTop: 75,
              }}
            >
              {currentCategory?.categoriesArray?.map(
                (categoryObject, index) =>
                  !(
                    delivery_id === '2' &&
                    categoryObject.categoryName === 'JUMPSUITS'
                  ) &&
                  categoryObject.activeDeliveries.includes(+delivery_id) && (
                    <CategoryItem
                      left={index % 2 !== 0}
                      top={index % 2 === 0}
                      key={`delivery${delivery_id}: ${categoryObject.categoryName}`}
                      img={
                        ORDERCONFIG.CATEGORY
                          .IMAGENS_CAPA_CATEGORIA_POR_DELIVERY[
                          categoryObject.categoryName
                        ][delivery_id]
                      }
                      txt={categoryObject.categoryName}
                      onClick={() => handleSelectCategory(index + 1)}
                    />
                  )
              )}
            </Grid>
          </Grid>
        </div>
      </Fade>
    ),
    [delivery_id, currentCategory.categoriesArray]
  );

  const CategoryDetail = useCallback(
    () => (
      <Grid container justifyContent="center" alignItems="flex-start">
        <Fade in timeout={300}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{
              // position: 'absolute',
              marginTop: 112,
            }}
          >
            <Typography
              style={{
                left: window.innerWidth / 12,
                top: 160,
                position: 'fixed',
                zIndex: 1000,
                background: '#fafafa',
                fontSize: 26,
                maxWidth: 130,
              }}
            >
              {
                ORDERCONFIG.CATEGORY[categoryObjectKeys[currentCollection]][
                  selectedCategory
                ]?.name
              }
            </Typography>
            {selectedProduct && (
              <ModalProduct
                handlePrevProduct={handlePrevProduct}
                handleNextProduct={handleNextProduct}
                open={open}
                handleClose={handleClose}
                product={selectedProduct}
              />
            )}
            {!loading &&
              products.map((product, index) => (
                <Grid
                  onClick={() => handleClickOpen(product)}
                  key={String(product.style_code)}
                  style={{
                    // background: 'green',
                    // position: 'relative',
                    // margin: '4px 6px',

                    cursor: 'pointer',
                  }}
                >
                  <Img
                    src={
                      product.mainly_image ||
                      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAPFBMVEXu7u62trbx8fHPz8/t7e2zs7O8vLy5ubnl5eWrq6vc3Nzq6urZ2dnh4eHV1dW6urrHx8fBwcHKysqlpaXy5cTEAAAEF0lEQVR4nO3bjXKkKBQFYLj8CAIK5v3fdS/Y9mi3md1UZavFOt9UTTpgujwBQaEjBAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAEDH6N2nT+l3TfaNulNEGo18Y+KnT+sXUXkPKGW5USOGs4BShk+f1++JJ52Uu+l0m0akfN6GN+im66RA7jygNK7veYNcnBo1fJNQDmo9IrouQ2az+S4gt+ImdzjqlLMp0Pz5/6XGfvp8f4qm9xhmVimwNNmTys4GVv0+x5uStiGFKO1a+NGktq+EQtjXgGo3ZOpdG5tlaiPR0HvC+BJgu1U1C7eo6T+heQQkEYJ4vHzc6PD1F/pPaHI7fXKZi+2yzn60tLrBhdx/Qqlbmdpmi6WlCcN+pOk7YXvYpeU5fj4iqv2k0XVC48TLU/B6XYbbJGz3K/rQqi0PDXdJ2MYZd7iPMXW0OTxV9ZzQ1MuQjk/BrZvS/kLsOmEdV15uVNttKKm7tGEbOceTNlxuknBdrTiuRpn6PHi4Pe864VAnfMr7RmyhSd4loRlfG7HNkCLdZj5cb0t3U/464R+X4LpOKE1qiRw/2/M/Y9db7yTvk1DOayEllbNKdHZI5wkfj09itzx6HHm6T8gR9SEB6ZeA3SeUxnLn1FttuyRvlrA247aIz8/678uJN0hYB9FlinFa7NmacG8Jz/dE12X885re1kvP1rz/qrc17/pI/6OIZtb//p4XE6z5gdLh3hPfwEzqP5pSb1304eRTQt/49JkCAAAAAEBftHt/0D0rq9zFHhmfH1n7U0LHkrY749uC/lnZ/r3aMet+zjXoGEaVRN18UfG58hvVWBdGp6kGiDoqRy76aWxV9S8RjmXCRdLCjfwjiotDNOo6f66g/TBYHwUVX8y6cSaCNPwdTd5yDWkvZ+vHcfBz4apZSSv2ZbWfpi/H71Bo9Hnx0Q1+3Xe8BG0yUbGkfOCv87pzZohCCF+RKPog/EJkCyd1RLnwU70f92X1XWjO/DoJ7p2csvXSy3RTXU9GzWTr/svo2wb29gGM+pqb1yc+whJfc1w1lFI43q6s4t+EiIac59rC7Xmp69AkUROW0hK2Pe0tIX9DX9xe9YgtYR6Z25e1d+HIVnHCWKv1JROO3PXCY8NX8XmnxD1R6MwxazJOo/2oSZlAgct3Ze2CIyVr+8vMv5lIwkx0neuwtYbkPueNL4+yzK8XSsZ749r1RYvlq9Rz3FqV9b5snfoCD008lBqu5mEre3+dGbG1Qe2PIYXnVNdek3CtD9Zz1aFO7+552KGsvY1v00b9kdrFt+IrOblhOfvyTRlN5v85rasYzdfrh8FvJox9/m3QD9w9HwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAANzJP/MdJGqyWhqXAAAAAElFTkSuQmCC'
                    }
                    alt="img"
                    style={{
                      width: window.innerWidth * 0.47,
                      height: 'auto',
                      margin: '3px 3px',
                    }}
                  />
                </Grid>
              ))}
          </Grid>
        </Fade>
        <Grid
          container
          justifyContent="space-around"
          style={{
            background: '#ffffff',
            bottom: window.innerWidth > 760 ? 60 : 40,
            width: '100%',
            height: window.innerWidth > 760 ? 60 : 40,
            position: 'fixed',
            zIndex: 1000,
            borderBottom: '1px solid #000000',
            alignItems: 'center',
          }}
        >
          <Typography
            onClick={() => {
              return setSelectedCategory(
                stateMachineBackward[currentCollection][selectedCategory]
              );
            }}
            style={{
              fontSize: window.innerWidth > 760 ? 20 : 12,
              width: window.innerWidth > 760 ? 200 : 130,
            }}
            align="left"
          >
            {'< previous category'}
          </Typography>
          <Typography
            onClick={() => {
              setSelectedCategory(0);
              window.scrollTo(0, 0);
            }}
            style={{
              fontSize: window.innerWidth > 760 ? 20 : 12,
              width: window.innerWidth > 760 ? 150 : 80,
            }}
            align="center"
          >
            all categories
          </Typography>
          <Typography
            onClick={() => {
              return setSelectedCategory(
                stateMachineFoward[currentCollection][selectedCategory]
              );
            }}
            style={{
              fontSize: window.innerWidth > 760 ? 20 : 12,
              width: window.innerWidth > 760 ? 200 : 130,
            }}
            align="right"
          >
            {'next category >'}
          </Typography>
        </Grid>
      </Grid>
    ),
    [
      currentCollection,
      handleNextProduct,
      handlePrevProduct,
      loading,
      open,
      products,
      selectedCategory,
      selectedProduct,
      stateMachineBackward,
      stateMachineFoward,
    ]
  );

  if (selectedCategory) {
    return <CategoryDetail />;
  }
  return <SelectCategoryView />;
}

const wrapperComponent = withStyles(styles)(CategoryView);

export default wrapperComponent;
