import React from 'react';
import HTML5Backend from 'react-dnd-html5-backend';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import { Router } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { DndProvider } from 'react-dnd';

import ModalLogin from './components/ModalLogin';

import Plugins from './plugins';

import EventsContextProvider from './contexts/events';
import UserContextProvider from './contexts/user';
import ModalLoginContextProvider from './contexts/modalLogin';
import ViewAsContextProvider from './contexts/viewAs';
import ConfigContextProvider from './contexts/config';

import Routes from './routes';
import history from './services/history';
import { SnackbarUtilsConfigurator } from './util/SnackBar';

import GlobalStyle from './styles/global';

const theme = createTheme({
  palette: {
    // type: 'dark',
    primary: {
      light: '#000',
      main: '#000',
      dark: '#000',
      // contrastText: '#fafafa',
    },
    secondary: {
      main: '#000',
    },
  },
  overrides: {
    MuiTypography: {
      root: { color: '#000' },
    },
    MuiOutlinedInput: {
      input: {
        '&:-webkit-autofill': {
          '-webkit-box-shadow': '0 0 0 100px transparent inset',
          transitionDelay: '9999s',
          transitionProperty: 'background-color, color',
        },
      },
    },
    MuiDialog: {
      paper: {
        margin: 0,
      },
      paperFullScreen: {
        backgroundColor: 'transparent',
      },
      paperWidthLg: {
        maxWidth: '100%',
      },
      scrollPaper: {
        alignItems: 'flex-end',
      },
      paperFullWidth: {
        width: '100%',
      },
    },

    MuiSelect: {
      icon: {
        color: '#000',
      },
    },
  },
});
function App() {
  React.useEffect(() => {
    if (
      document.location.href.includes('farmrio-showroom') &&
      !document.location.href.includes('staging')
    ) {
      window.location = 'https://farmriowholesale.com';
    }
  }, []);

  return (
    <>
      <ConfigContextProvider>
        <EventsContextProvider>
          <UserContextProvider>
            <ModalLoginContextProvider>
              <ViewAsContextProvider>
                <DndProvider backend={HTML5Backend}>
                  <SnackbarProvider
                    maxSnack={3}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    autoHideDuration={3500}
                  >
                    <SnackbarUtilsConfigurator />
                    <ThemeProvider theme={theme}>
                      <ModalLogin />
                      <GlobalStyle />
                      <Plugins />
                      <Router history={history}>
                        <Routes />
                      </Router>
                    </ThemeProvider>
                  </SnackbarProvider>
                </DndProvider>
              </ViewAsContextProvider>
            </ModalLoginContextProvider>
          </UserContextProvider>
        </EventsContextProvider>
      </ConfigContextProvider>
    </>
  );
}

export default App;
