import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import logo from '../../assets/logo_farmRio_white.svg';
import CreateUser from '../../components/CreateUser';
import EditUser from '../../components/EditUser';
import ManageProducts from './ManageProducts';
import history from '../../services/history';
import EditProduct from './EditProduct';
import EditOrder from './EditOrder';
import AddOrder from './AddOrder';
import ManageOrders from './ManageOrders';
import ManageUsers from './ManageUsersTable';

const Divider = () => {
  return (
    <div
      style={{
        borderBottom: '1px solid #fff',
        height: 1,
        width: 205,
        margin: '22px 0px',
      }}
    />
  );
};

export default function Admin(props) {
  const userEmail = props.location?.search.split('?email=')[1] || null;

  const [selected, setSelected] = React.useState(
    userEmail ? 'Manage Users' : 'Add User'
  );
  const [width, setWidth] = useState(null);

  useEffect(() => {
    function updateSize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [width]);

  const Option = ({ text }) => {
    return (
      <Typography
        onClick={() => setSelected(text)}
        style={{
          margin: '12px 0px',
          fontSize: 14,
          lineheight: '18px',
          color: '#fff',
          fontWeight: selected === text && 'bold',
          cursor: 'pointer',
        }}
      >
        {text}
      </Typography>
    );
  };
  return (
    <Grid container justifyContent="space-between">
      {/* ------------------- MENU NAVIGATION -------------------  */}
      <Grid
        style={{
          width: 280,
          height: window.innerHeight,
          background: '#000',
          padding: '21px 23px 30px 34px',
        }}
      >
        <Grid container alignItems="center" onClick={() => history.push('/')}>
          <ArrowBackIcon
            style={{
              fontSize: 14,
              position: 'relative',
              top: 2,
              color: '#fff',
              cursor: 'pointer',
            }}
          />
          <Typography
            display="inline"
            style={{
              fontSize: 14,
              cursor: 'pointer',
              marginLeft: 5,
              color: '#fff',
            }}
          >
            {' '}
            Back to Browsing
          </Typography>
        </Grid>
        <Grid container justifyContent="center" style={{ padding: '40px 0px' }}>
          <Grid>
            <img
              style={{
                width: 'auto',
                height: 20,
              }}
              src={logo}
              alt="Farm Rio"
            />
            <Typography
              style={{
                fontSize: 12,
                letterSpacing: '0.25em',
                paddingBottom: 8,
                color: '#fff',
              }}
              align="center"
            >
              WHOLESALE
            </Typography>
          </Grid>
        </Grid>
        <Typography
          style={{
            fontSize: 18,
            letterSpacing: '0.1em',
            // fontFamily: 'Galano Grotesque Light',
            lineHeight: '28px',
            color: '#fff',
            marginBottom: 15,
          }}
        >
          ADMIN DASHBOARD
        </Typography>
        <Divider />
        <Option text="Add User" />
        <Option text="Edit User Info" />
        <Option text="Manage Users" />
        <Divider />
        {/* <Option text="Add Product" /> */}
        <Option text="Edit Product Info" />
        <Option text="Manage Products" />
        <Divider />
        <Option text="Add Order" />
        <Option text="Edit Order" />
        <Option text="Manage Orders" />
      </Grid>

      <Grid
        container
        justifyContent="center"
        style={{
          width: `${Math.floor(100 - (100 * 280) / width - 1)}%`,
          maxHeight: window.innerHeight,
          overflowY: 'auto',
        }}
      >
        {selected === 'Add User' && <CreateUser />}
        {selected === 'Edit User Info' && <EditUser />}
        {selected === 'Manage Users' && <ManageUsers userEmail={userEmail} />}
        {selected === 'Manage Products' && <ManageProducts />}
        {selected === 'Edit Product Info' && <EditProduct />}
        {selected === 'Add Order' && <AddOrder />}
        {selected === 'Edit Order' && <EditOrder />}
        {selected === 'Manage Orders' && <ManageOrders />}
      </Grid>
    </Grid>
  );
}
