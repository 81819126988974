import React, { createContext, useReducer } from 'react';

const EventsContext = createContext();

export { EventsContext };

export default function EventsContextProvider({ children }) {
  const observer = useReducer(
    (state, action) => {
      const { type, event } = action;
      switch (type) {
        case 'subscribe': {
          const { callback } = action;
          if (event in state) {
            if (state[event].includes(callback)) {
              return state;
            }
            return { ...state, [event]: [...state[event], callback] };
          }
          return { ...state, [event]: [callback] };
        }

        case 'unsubscribe': {
          const { callback } = action;
          if (event in state && state[event].includes(callback)) {
            return {
              ...state,
              [event]: [...state[event].filter(cb => cb !== callback)],
            };
          }
          return state;
        }

        default:
          throw new Error();
      }
    },
    {},
    () => ({})
  );

  return (
    <EventsContext.Provider value={observer}>{children}</EventsContext.Provider>
  );
}
