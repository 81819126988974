import api from '../../services/api';

export const submitOrder = async ({ cartInfo, userInfo, status }) => {
  try {
    const response = await api.post(`/order`, {
      cartInfo,
      userInfo,
      status,
    });
    return response.data;
  } catch (err) {
    return null;
  }
};

export const checkOrder = async ({ userInfo }) => {
  try {
    const response = await api.post(`checkOrder`, {
      userInfo,
    });
    return response.data;
  } catch (err) {
    return null;
  }
};

export const updateOrder = async ({ cartInfo, orderId }) => {
  await api.put(`/order/${orderId}`, {
    products: cartInfo,
  });
};
