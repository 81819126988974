import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { Typography, TextField } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import { ddiCountries } from './ddi';
import Input from '../../components/TextInput';
import StyledButton from '../../components/Button';
import { ReactComponent as InfoIcon } from '../../assets/info.svg';
import api from '../../services/api';

const StyledBtn = withStyles({
  root: {
    textTransform: 'none',
    padding: '0px',
    minWidth: 'auto',
  },
  contained: {
    boxShadow: 'none',
    backgroundColor: 'transparent',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: 'transparent',
    },
  },
  label: {
    alignItems: 'end',
  },
  endIcon: {
    '&.MuiButton-endIcon': {
      marginLeft: 0,
      marginRight: 0,
    },
  },
})(Button);

const validatePhoneField = state => {
  const regexPhone = /^[0-9]+$/; // accept at least 1 number
  return regexPhone.test(state.phone);
};

const validateCountryCodeField = state => {
  const regexCountryCode = /^\+[\d]+$/;
  return regexCountryCode.test(state.countryCode);
};

const validateEmail = state => {
  const regexemail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  return regexemail.test(state.email.toLowerCase());
};

const validateEmptyField = field => {
  return field.length === 0;
};

const useStyles = makeStyles(() => ({
  containerDiv: {
    marginTop: 66,
  },
  marginTop: {
    marginTop: 40,
  },
  subtitleTextGray: {
    fontSize: 12,
    color: '#616161',
  },
  fontSize12: {
    fontSize: 12,
  },
  costumerNameText: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  span: {
    height: 15,
    width: 15,
    borderRadius: '50%',
  },
  marginTop30: {
    marginTop: 30,
  },
  subtitleText: {
    fontSize: 12,
    color: '#616161',
  },
  steptTitle: {
    fontSize: 14,
    lineHeight: '18px',
  },
  stepTitleMobile: {
    fontSize: 18,
    lineHeight: '18px',
    paddingBottom: 16,
  },
  stepSubtitle: {
    fontSize: 12,
    lineHeight: '18px',
    color: '#616161',
    marginTop: 12,
    width: 247,
  },
  infoText: {
    fontSize: '10px',
    lineHeight: '12px',
    color: '#616161',
    width: 165,
    position: 'relative',
    left: 4,
  },
}));

const PlusIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 8V24"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24 16H8"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default function Account({ state, handleAddUser, width }) {
  const classes = useStyles();
  const [user, setUser] = React.useState({
    firstName: '',
    lastName: '',
    email: '',
    countryCode: '+',
    phone: '',
    role: 'Buyer',
    informations: '',
  });

  const [country, setCountry] = useState('');
  const [open, setOpen] = useState(false);

  const [error, setError] = useState({
    countryCode: false,
    phone: false,
    firstName: false,
    lastName: false,
    email: false,
  });

  const countryByDdi = countryCode => {
    const countryDdi = ddiCountries.find(
      object => object.dial_code === countryCode
    );
    if (countryDdi !== undefined) setCountry(`(${countryDdi.code})`);
  };

  const wid =
    width < 1024
      ? width * 0.8
      : window.innerWidth > 1300
      ? 377
      : window.innerWidth > 1150
      ? 277
      : 210;

  const userStatusColor = status => {
    switch (status) {
      case 'Approved':
        return 'green';
      case 'Waiting':
        return 'orange';
      default:
        return 'red';
    }
  };

  const handleClose = () => {
    setUser({
      firstName: '',
      lastName: '',
      email: '',
      countryCode: '+',
      phone: '',
      role: '',
      informations: '',
    });
    setError({
      countryCode: false,
      phone: false,
      firstName: false,
      lastName: false,
      email: false,
    });
    setOpen(false);
  };

  const validateProfileInput = async () => {
    const objectError = {
      countryCode: !validateCountryCodeField(user),
      phone: !validatePhoneField(user),
      firstName: validateEmptyField(user.firstName),
      lastName: validateEmptyField(user.lastName),
      email: !validateEmail(user),
      // currentPassword: validateEmptyField(state.currentPassword),
    };

    if (
      objectError.countryCode ||
      objectError.phone ||
      objectError.firstName ||
      objectError.lastName ||
      objectError.email
    )
      return setError({
        countryCode: objectError.countryCode,
        phone: objectError.phone,

        firstName: objectError.firstName,
        lastName: objectError.lastName,
        email: objectError.email && 'Invalid email format',

        // currentPassword: validateEmptyField(state.currentPassword),
      });
    const response = await api.get(`/checkCustomerEmail/${user.email}`);
    console.log('response', response);
    if (response.data) {
      return setError({ ...error, email: 'Email is already in use' });
    }

    const newUser = {
      name: `${user.firstName} ${user.lastName}`,
      email: user.email.toLowerCase(),
      phone: user.phone,
      ddi: user.countryCode,
      role: user.role,
      isApproved: 'Waiting',
    };
    handleAddUser(newUser);
    return handleClose();
  };

  const handleChange = e => {
    const { name } = e.target;
    const { value } = e.target;

    if (name === 'countryCode' && value.length >= 3) countryByDdi(value);
    else if (name === 'countryCode' && value.length <= 1) {
      setUser({ ...user, [name]: '+' });
      setError({ ...error, [name]: false });
      return;
    } else if (name === 'countryCode') setCountry('');

    setUser({ ...user, [name]: value });
    setError({ ...error, [name]: false });

    if (error.name) setError({ ...error, [name]: false });
  };

  return (
    <Grid
      item
      container
      className={classes.containerDiv}
      justifyContent={width < 1024 ? 'center' : 'space-between'}
      style={{
        marginTop: width < 1024 ? 20 : 66,
        maxHeight:
          width < 1024 ? window.innerHeight - 248 : window.innerHeight - 350,
        overflowY: 'auto',
        overflowX: 'hidden',
        background: width < 1024 && '#fff',
        padding: width < 1024 && '5%',
        width: width < 1024 && width * 0.9,
      }}
    >
      <Grid item xs={12} md={4}>
        <Typography
          className={
            width > 1024 ? classes.steptTitle : classes.stepTitleMobile
          }
        >
          Connected Users
        </Typography>
        <Typography className={classes.stepSubtitle}>
          If you have a team and wish to grant them access to the FARM RIO
          Wholesale site, you can add them to your account.
          {width > 1024 && (
            <>
              <br />
              <br />
            </>
          )}{' '}
          Additional users will need approval by the FARM RIO team, so please
          provide all the information requested. <br /> <br />
          If you don't just click the
          <span style={{ fontWeight: 'bold' }}> Finish </span> button.
        </Typography>
      </Grid>
      <Grid style={{ marginTop: width < 1024 && 12 }} item xs={12} md={8}>
        <Grid item xs={12} md={8}>
          {state.users?.map((costumer, index) => (
            <Grid
              item
              key={costumer.email}
              style={{ marginTop: index === 0 ? 12 : 42 }}
            >
              <Grid
                container
                style={{
                  justifyContent: 'space-between',
                }}
              >
                <Grid
                  style={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    marginBottom: 1,
                  }}
                >
                  <Typography className={classes.costumerNameText}>
                    {costumer.name}
                  </Typography>
                  <span
                    className={classes.span}
                    style={{
                      backgroundColor: userStatusColor(costumer.isApproved),
                      marginLeft: 8,
                      marginRight: 8,
                    }}
                  />

                  <Typography
                    style={{
                      fontSize: 12,
                    }}
                  >
                    {costumer.isApproved}
                  </Typography>
                </Grid>
              </Grid>
              <Typography
                className={classes.fontSize12}
                style={{ marginBottom: 1 }}
              >
                {costumer.role}
              </Typography>

              <Typography
                className={classes.fontSize12}
                style={{ marginBottom: 1 }}
              >
                {costumer.email}
              </Typography>
              <Typography className={classes.fontSize12}>
                {`${costumer?.ddi != null ? costumer.ddi : ''} ${
                  costumer.phone
                }`}
              </Typography>
            </Grid>
          ))}

          {!open ? (
            <Grid
              container
              className={classes.marginTop30}
              style={{ alignItems: 'center' }}
              onClick={() => setOpen(true)}
            >
              <StyledBtn endIcon={<PlusIcon />}>
                <Typography
                  className={classes.fontSize12}
                  style={{ marginRight: 4 }}
                >
                  Add User
                </Typography>
              </StyledBtn>
            </Grid>
          ) : (
            <>
              <Grid item>
                <Grid item className={classes.marginTop}>
                  <TextField
                    label="First Name"
                    name="firstName"
                    type="text"
                    fullWidth
                    autoComplete="off"
                    onChange={e => {
                      handleChange(e);
                    }}
                    inputProps={{
                      'aria-label': 'First Name',
                      autoComplete: 'off',
                    }}
                    value={user.firstName}
                    error={error.firstName}
                    helperText={error.firstName && 'Enter a Name'}
                    style={{ width: wid }}
                  />
                </Grid>
                <Grid item className={classes.marginTop}>
                  <TextField
                    label="Last Name"
                    type="text"
                    name="lastName"
                    autoComplete="new-password"
                    fullWidth
                    value={user.lastName}
                    onChange={e => {
                      handleChange(e);
                    }}
                    inputProps={{ 'aria-label': 'Last Name' }}
                    error={error.lastName}
                    helperText={error.lastName && 'Enter a last name '}
                    style={{ width: wid }}
                  />
                </Grid>
                <Grid item container className={classes.marginTop}>
                  <TextField
                    autoComplete="new-password"
                    label="Email"
                    name="email"
                    type="text"
                    fullWidth
                    value={user.email}
                    inputProps={{ 'aria-label': 'Email' }}
                    onChange={e => {
                      handleChange(e);
                    }}
                    error={error.email}
                    helperText={error.email}
                    style={{ width: wid }}
                  />
                  <Grid>
                    <Grid
                      container
                      style={{ position: 'relative', left: '10%', top: 15 }}
                    >
                      <InfoIcon />
                      <Typography className={classes.infoText}>
                        Please make sure your email is correct and don't use
                        personal emails. You won't be able to change this later.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item className={classes.marginTop}>
                <Grid
                  item
                  container
                  justifyContent="space-between"
                  alignItems="baseline"
                  style={{ width: wid }}
                >
                  <TextField
                    label="Phone"
                    type="text"
                    autoComplete="new-password"
                    name="countryCode"
                    value={user.countryCode}
                    style={{ width: '12%' }}
                    onChange={e => {
                      handleChange(e);
                    }}
                    inputProps={{ 'aria-label': 'Country Code' }}
                    error={error.countryCode}
                    helperText={
                      error.countryCode ? 'Insert country code' : 'Country Code'
                    }
                  />

                  <Typography>{country}</Typography>

                  <TextField
                    label=""
                    type="text"
                    name="phone"
                    autoComplete="new-password"
                    style={{ width: '75%', marginTop: 16 }}
                    onChange={e => {
                      handleChange(e);
                    }}
                    value={user.phone}
                    inputProps={{ 'aria-label': 'Phone' }}
                    error={error.phone}
                    helperText={error.phone && 'Numbers only'}
                  />
                </Grid>
              </Grid>
              <Grid item style={{ marginTop: 24 }}>
                <Input
                  select
                  field="Role / Job Title"
                  name="role"
                  autoComplete="new-password"
                  background="#EBEBEB"
                  width={wid}
                  height={36}
                  options={[
                    'Buyer',
                    'Assistant buyer',
                    'Merchandiser',
                    'Store manager',
                    'Owner',
                  ]}
                  value={user.role}
                  onChange={e => handleChange(e)}
                />
              </Grid>
              <Grid container style={{ marginTop: 24 }}>
                <Input
                  field="Tell us more about this person"
                  placeholder="Tell us about this person's role in your account"
                  width={wid}
                  minHeight={34}
                  name="informations"
                  value={user.informations}
                  onChange={e => handleChange(e)}
                  multiline
                  rows={0}
                />
              </Grid>
              <Grid
                container
                alignItems="center"
                style={{
                  position: 'relative',
                  top: 20,
                  left: 3,
                  paddingBottom: 64,
                }}
              >
                <Grid style={{ marginRight: 24 }}>
                  <StyledButton
                    color="#fff"
                    borderColor="1px solid #000"
                    textColor="#000"
                    text="Cancel"
                    width={113}
                    height={40}
                    onClick={handleClose}
                  />
                </Grid>
                <Grid>
                  <StyledButton
                    color="#000"
                    borderColor="1px solid #fff"
                    textColor="#fff"
                    text="Add User"
                    width={127}
                    height={42}
                    onClick={validateProfileInput}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
