import React from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import FarmLogo from '../../assets/logo_farmRio.svg';
import FarmLogoWhite from '../../assets/logo_farmRio_white.svg';
import ModalCollection from '../ModalCollectionMobile';
import MenuDrawer from '../../components/ModalCollection/MenuDrawer';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: isTransparent => ({
    background: isTransparent ? 'rgba(0,0,0,0)' : '#fff',
    height: '60px',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: 'none',
  }),
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: isTransparent => ({
    color: isTransparent ? '#fff' : '#000',
  }),
  hide: {
    display: 'none',
  },
  drawerPaper: {
    width: '100%',
  },
  drawerHeader: isTransparent => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    minHeight: isTransparent ? '0px' : '50px',
    justifyContent: 'flex-start',
  }),
  content: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: '100%',
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  contentLogIn: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
    padding: '15px 5px 15px 15px',
  },
  contentSecondary: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    margin: '10px 0',
    paddingLeft: '15px',
  },
  typographySecondary: {
    color: '#000000',
    cursor: 'pointer',
    fontSize: 14,
    width: '100%',
    height: 'auto',
  },
}));

export default function PersistentDrawerLeft({
  children,
  login,
  isAdmin,
  isTransparent = false,
  rightIcon,
  bg,
}) {
  const classes = useStyles(isTransparent);
  const [open] = React.useState(false);
  const [openCollection, setOpenCollection] = React.useState(false);
  const history = useHistory();

  const handleCloseCollection = () => {
    setOpenCollection(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar
          style={{
            height: '60px',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <MenuDrawer
            open={open}
            mobile
            color={isTransparent ? ' #fff' : '#000'}
          />

          <Grid style={{ position: 'relative' }}>
            <Grid
              container
              justifyContent="center"
              onClick={() => {
                if (history.location.pathname.includes('europe')) {
                  history.push(`/europe`);
                } else {
                  history.push(`/`);
                }
              }}
            >
              <img
                style={{
                  width: 120,
                }}
                src={isTransparent ? FarmLogoWhite : FarmLogo}
                alt="Logo Farm"
              />
            </Grid>
          </Grid>
          <div style={{ width: 48 }} />
        </Toolbar>
      </AppBar>

      <ModalCollection
        open={openCollection}
        handleClose={handleCloseCollection}
      />

      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        {children}
      </main>
    </div>
  );
}
