import React, { useState } from 'react';
import { Typography, Grid } from '@material-ui/core';
import { IoMdHeartDislike } from 'react-icons/io';
import Img from 'react-cool-img';

export default function ProductCardCollection({
  product,
  handleRemoveProductFromCollection,
  print,
  onClick,
}) {
  const [removeProductView, setRemoveProductView] = useState(false);

  const [hover, setHover] = useState(false);
  const handleMouseHover = () => {
    setHover(!hover);
  };

  const ProductView = () => {
    return (
      <Grid>
        <Grid
          style={{
            width: window.innerWidth * 0.29,
            minHeight: 76.3,
            display: 'inline-grid',
            alignItems: 'center',
            position: 'relative',
            top: print ? 0 : -24,
          }}
        >
          <Typography style={{ fontSize: 12 }}>{product.style_code}</Typography>
          <Typography
            style={{
              fontSize: 10,
              fontWeight: 600,
              textAlign: 'left',
              marginTop: 5,
              height: 36,
            }}
          >
            {product.description}
          </Typography>
          <Typography
            style={{
              marginTop: 10,
              fontSize: 11,
              fontWeight: 600,
              textAlign: 'left',
            }}
          >
            Wholesale
          </Typography>
          <Typography style={{ fontSize: 11, marginTop: 5 }}>
            $ {product.wholesale_usd} / &euro;{product.wholesale_eur}{' '}
          </Typography>
          <Typography
            style={{
              marginTop: 5,
              fontSize: 11,
              fontWeight: 600,
              textAlign: 'left',
            }}
          >
            Suggested Retail
          </Typography>
          <Typography style={{ fontSize: 11, marginTop: 5 }}>
            $ {product.msr_price_usd} / &euro;{product.msr_price_eur}{' '}
          </Typography>
          <Grid
            onClick={() => setRemoveProductView(true)}
            style={{
              border: '1px solid #000',
              padding: 5,
              marginTop: 5,
              cursor: 'pointer',
            }}
          >
            <Typography style={{ fontSize: 6.5, textAlign: 'center' }}>
              Remove from collection{' '}
              <IoMdHeartDislike
                size={7}
                color="black"
                style={{ marginLeft: 8, marginBottom: 0 }}
              />
            </Typography>
          </Grid>
        </Grid>
        {/* <Grid
          onClick={downloadFiles}
          style={{ marginTop: -5 }}
          container
          alignItems="center"
        >
          <Typography style={{ fontSize: 10, width: 64 }}>Download</Typography>
          {!loading ? (
            <RiDownloadLine
              style={{ position: 'relative', top: 2 }}
              size={12}
            />
          ) : (
            <CircularProgress size={12} />
          )}
        </Grid> */}
      </Grid>
    );
  };

  const DeleteProductView = () => {
    return (
      <Grid>
        <Grid
          style={{
            width: window.innerWidth * 0.29,
            minHeight: 70,
            display: 'inline-grid',
            alignItems: 'center',
            position: 'relative',
            top: print ? 0 : -24,
          }}
        >
          <Typography style={{ fontSize: 12 }}>{product.style_code}</Typography>
          <Typography
            style={{
              fontSize: 10,
              fontWeight: 600,
              textAlign: 'left',
              marginTop: 5,
              height: 41,
              marginBottom: 15,
            }}
          >
            {product.description}
          </Typography>
          <Typography
            style={{
              paddingTop: 10,
              marginBottom: 19,
              fontSize: 13,
              borderTop: 'solid 1px black',
            }}
          >
            Remove this style from your collection?
          </Typography>
          <Grid container justifyContent="space-between" style={{ height: 10 }}>
            <div //eslint-disable-line
              onClick={() => setRemoveProductView(false)}
              style={{
                padding: 5,
                border: '0.5px solid black',
                cursor: 'pointer',
                width: '45%',
                textAlign: 'center',
              }}
            >
              <Typography style={{ fontSize: 11 }}>No </Typography>
            </div>
            <div //eslint-disable-line
              onClick={() => handleRemoveProductFromCollection(product)}
              style={{
                padding: 5,
                border: '0.5px solid black',
                cursor: 'pointer',
                width: '45%',
                textAlign: 'center',
              }}
            >
              <Typography style={{ fontSize: 11 }}>Yes </Typography>
            </div>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid
      onMouseEnter={handleMouseHover}
      onMouseLeave={handleMouseHover}
      item
      style={{ marginBottom: -20 }}
      // {...rest}
    >
      <Img // eslint-disable-line
        src={
          product.mainly_image ||
          'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAPFBMVEXu7u62trbx8fHPz8/t7e2zs7O8vLy5ubnl5eWrq6vc3Nzq6urZ2dnh4eHV1dW6urrHx8fBwcHKysqlpaXy5cTEAAAEF0lEQVR4nO3bjXKkKBQFYLj8CAIK5v3fdS/Y9mi3md1UZavFOt9UTTpgujwBQaEjBAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAEDH6N2nT+l3TfaNulNEGo18Y+KnT+sXUXkPKGW5USOGs4BShk+f1++JJ52Uu+l0m0akfN6GN+im66RA7jygNK7veYNcnBo1fJNQDmo9IrouQ2az+S4gt+ImdzjqlLMp0Pz5/6XGfvp8f4qm9xhmVimwNNmTys4GVv0+x5uStiGFKO1a+NGktq+EQtjXgGo3ZOpdG5tlaiPR0HvC+BJgu1U1C7eo6T+heQQkEYJ4vHzc6PD1F/pPaHI7fXKZi+2yzn60tLrBhdx/Qqlbmdpmi6WlCcN+pOk7YXvYpeU5fj4iqv2k0XVC48TLU/B6XYbbJGz3K/rQqi0PDXdJ2MYZd7iPMXW0OTxV9ZzQ1MuQjk/BrZvS/kLsOmEdV15uVNttKKm7tGEbOceTNlxuknBdrTiuRpn6PHi4Pe864VAnfMr7RmyhSd4loRlfG7HNkCLdZj5cb0t3U/464R+X4LpOKE1qiRw/2/M/Y9db7yTvk1DOayEllbNKdHZI5wkfj09itzx6HHm6T8gR9SEB6ZeA3SeUxnLn1FttuyRvlrA247aIz8/678uJN0hYB9FlinFa7NmacG8Jz/dE12X885re1kvP1rz/qrc17/pI/6OIZtb//p4XE6z5gdLh3hPfwEzqP5pSb1304eRTQt/49JkCAAAAAEBftHt/0D0rq9zFHhmfH1n7U0LHkrY749uC/lnZ/r3aMet+zjXoGEaVRN18UfG58hvVWBdGp6kGiDoqRy76aWxV9S8RjmXCRdLCjfwjiotDNOo6f66g/TBYHwUVX8y6cSaCNPwdTd5yDWkvZ+vHcfBz4apZSSv2ZbWfpi/H71Bo9Hnx0Q1+3Xe8BG0yUbGkfOCv87pzZohCCF+RKPog/EJkCyd1RLnwU70f92X1XWjO/DoJ7p2csvXSy3RTXU9GzWTr/svo2wb29gGM+pqb1yc+whJfc1w1lFI43q6s4t+EiIac59rC7Xmp69AkUROW0hK2Pe0tIX9DX9xe9YgtYR6Z25e1d+HIVnHCWKv1JROO3PXCY8NX8XmnxD1R6MwxazJOo/2oSZlAgct3Ze2CIyVr+8vMv5lIwkx0neuwtYbkPueNL4+yzK8XSsZ749r1RYvlq9Rz3FqV9b5snfoCD008lBqu5mEre3+dGbG1Qe2PIYXnVNdek3CtD9Zz1aFO7+552KGsvY1v00b9kdrFt+IrOblhOfvyTRlN5v85rasYzdfrh8FvJox9/m3QD9w9HwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAANzJP/MdJGqyWhqXAAAAAElFTkSuQmCC'
        }
        alt="farm"
        style={{
          width: window.innerWidth * 0.29,
          height: 'auto',
          cursor: 'pointer',
        }}
        onClick={onClick}
      />

      <Grid
        style={{
          position: 'relative',
          top: -30,
          height: 20,
        }}
        container
        justifyContent="flex-end"
        alignItems="center"
      />

      {removeProductView ? <DeleteProductView /> : <ProductView />}
    </Grid>
  );
}
