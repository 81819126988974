import React, { useState, useCallback } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {
  Grid,
  Typography,
  FormControlLabel,
  RadioGroup,
  Radio,
  TextField,
  Container,
} from '@material-ui/core';
import { MdKeyboardBackspace } from 'react-icons/md';
import Snackbar from '../../util/SnackBar';
import {
  createAccount,
  createUser,
  updateUser,
  findAccountByName,
} from '../../services/api';
import style from './styles';

const styles = theme => style(theme);

function Login({ editedUser, handleBack, initLoading }) {
  const [userEmail, setUserEmail] = useState('');
  const [shopName, setShopName] = useState('');
  const [userFirstName, setUserFirstName] = useState('');
  const [userLastName, setUserLastName] = useState('');
  const [userPhone, setUserPhone] = useState('');
  const [errorUserFirstName, setErrorUserFirstName] = useState('');
  const [errorUserLastName, setErrorUserLastName] = useState('');
  const [errorUserEmail, setErrorUserEmail] = useState('');
  const [errorUserPhone, setErrorUserPhone] = useState('');
  const [errorUserShopName, setErrorUserShopName] = useState('');
  const [userAdmin, setUserAdmin] = useState(false);

  const clearInputs = useCallback(async () => {
    setUserEmail('');
    setShopName('');
    setUserFirstName('');
    setUserLastName('');
    setUserPhone('');
  }, []);

  React.useEffect(() => {
    setUserFirstName(editedUser?.name?.split(' ')[0]);
    setUserLastName(
      editedUser?.name?.split(' ')[editedUser?.name?.split?.length - 1]
    );
    setUserEmail(editedUser?.email);
    setUserPhone(editedUser?.phone);
    setShopName(editedUser?.shopName);
    setUserAdmin(editedUser?.admin || false);
  }, [editedUser]);

  const validatePhoneField = e => {
    const regexPhone = /[\d\s+()-]+/;
    if (e.target.value.length === 0) return setUserPhone('');
    if (!regexPhone.test(e.target.value[e.target.value.length - 1])) {
      setErrorUserPhone('Insert a valid phone');
      return;
    }
    setUserPhone(e.target.value);
    setErrorUserPhone('');
  };

  const validateEmailField = e => {
    const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regexEmail.test(e.target.value)) {
      setErrorUserEmail('Insert a valid email');
      setUserEmail(e.target.value);
      return;
    }
    setUserEmail(e.target.value);
    setErrorUserEmail('');
  };

  const validateForm = () => {
    if (!userFirstName) setErrorUserFirstName('Insert a First Name');
    if (!userLastName) setErrorUserLastName('Insert a Last Name');
    if (!userEmail) setErrorUserEmail('Insert an Email');
    if (!shopName) setErrorUserShopName('Insert a Shop Name');

    if (!userFirstName || !userLastName || !userEmail || !shopName)
      return Snackbar.error('Failed to submit');

    if (errorUserEmail !== '' || errorUserPhone !== '')
      return Snackbar.error('Failed to submit');

    handleSubmit();
  };

  const handleSubmit = useCallback(async () => {
    let account = await findAccountByName(shopName.toUpperCase());
    if (!account.data) {
      account = await createAccount({
        name: shopName.toUpperCase(),
      });
    }
    if (editedUser) {
      try {
        await updateUser({
          email: userEmail,
          name: `${userFirstName} ${userLastName}`,
          phone: userPhone,
          admin: userAdmin,
          accountId: account ? account.data._id : editedUser.accountId,
        });
        initLoading();
        return Snackbar.success('User updated');
      } catch (err) {
        return Snackbar.error(err);
      }
    }
    try {
      await createUser({
        email: userEmail,
        name: `${userFirstName} ${userLastName}`,
        phone: userPhone,
        shopName,
        createByAdmin: true,
        isApproved: 'Approved',
        accountId: account.data._id,
      });
      clearInputs();
      initLoading();
      return Snackbar.success('User created');
    } catch (err) {
      return Snackbar.error(err);
    }
  }, [
    editedUser,
    userEmail,
    userFirstName,
    userLastName,
    shopName,
    userPhone,
    userAdmin,
    clearInputs,
    initLoading,
  ]);

  const css = {
    grid: {
      marginTop: 30,
    },
  };

  return (
    <>
      <Container
        style={{
          height: '100vh',
          paddingTop: 20,
          width: '36vw',
        }}
      >
        <Typography
          style={{
            fontFamily: 'Galano Grotesque Light',
            fontSize: 24,
            lineHeight: '28px',
            letterSpacing: '0.1em',
            marginBottom: 50,
            textAlign: 'center',
          }}
        >
          {!editedUser ? 'ADD USER' : 'EDIT USER INFO'}
        </Typography>
        {editedUser && (
          <Grid
            container
            alignItems="center"
            style={{ margin: '12px 0px 24px', cursor: 'pointer' }}
            onClick={handleBack}
          >
            <MdKeyboardBackspace />
          </Grid>
        )}
        <Grid container justifyContent="space-between" style={css.grid}>
          <Grid style={{ width: '47%' }}>
            <TextField
              label="First Name"
              type="text"
              onChange={e => {
                setUserFirstName(e.target.value);
                setErrorUserFirstName('');
              }}
              value={userFirstName}
              inputProps={{
                'aria-label': 'description',
              }}
              error={errorUserFirstName.length !== 0}
              helperText={errorUserFirstName}
              fullWidth
            />
          </Grid>
          <Grid style={{ width: '49%' }}>
            <TextField
              label="Last Name"
              type="text"
              onChange={e => {
                setUserLastName(e.target.value);
                setErrorUserLastName('');
              }}
              value={userLastName}
              inputProps={{ 'aria-label': 'description' }}
              error={errorUserLastName.length !== 0}
              helperText={errorUserLastName}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container style={css.grid}>
          <TextField
            label="Email"
            type="email"
            fullWidth
            onChange={e => {
              validateEmailField(e);
            }}
            value={userEmail}
            inputProps={{ 'aria-label': 'description' }}
            error={errorUserEmail.length !== 0}
            helperText={errorUserEmail}
            disabled={editedUser}
          />
        </Grid>
        <Grid container justifyContent="flex-start" style={css.grid}>
          <TextField
            label="Phone"
            type="tel"
            style={{ width: '50%' }}
            onChange={e => {
              validatePhoneField(e);
            }}
            value={userPhone}
            inputProps={{ 'aria-label': 'description' }}
            error={errorUserPhone.length !== 0}
            helperText={errorUserPhone}
          />
        </Grid>

        <Grid container justifyContent="flex-start" style={css.grid}>
          <TextField
            label="Account/Shop Name"
            type="text"
            fullWidth
            onChange={e => {
              setShopName(e.target.value);
              setErrorUserShopName('');
            }}
            value={shopName}
            inputProps={{ 'aria-label': 'description' }}
            error={errorUserShopName.length !== 0}
            helperText={errorUserShopName}
          />
        </Grid>

        {editedUser && (
          <Grid container style={{ marginTop: 53 }}>
            <Typography style={{ fontSize: 14, marginBottom: 18 }}>
              Admin
            </Typography>
            <Grid container>
              <RadioGroup
                row
                aria-label="gender"
                name="gender1"
                value={userAdmin}
                onChange={event => {
                  setUserAdmin(event.target.value === 'true');
                }}
              >
                <FormControlLabel
                  value
                  control={<Radio size="small" />}
                  label={
                    <Typography
                      style={{
                        fontSize: 12,
                        position: 'relative',
                        top: -2,
                        marginRight: 30,
                      }}
                    >
                      Yes
                    </Typography>
                  }
                />
                <FormControlLabel
                  value={false}
                  control={<Radio size="small" />}
                  label={
                    <Typography
                      style={{
                        fontSize: 12,
                        position: 'relative',
                        top: -2,
                        marginRight: 20,
                      }}
                    >
                      No
                    </Typography>
                  }
                />
              </RadioGroup>
            </Grid>
          </Grid>
        )}

        <Grid
          container
          justifyContent="center"
          style={{ marginTop: 30, paddingBottom: 30 }}
        >
          <Button
            onClick={validateForm}
            variant="contained"
            color="primary"
            style={{ marginTop: 15, padding: 5, width: 200 }}
          >
            {!editedUser ? 'ADD USER' : 'EDIT USER'}
          </Button>
        </Grid>
      </Container>
    </>
  );
}

const wrapperComponent = withStyles(styles)(Login);

export default wrapperComponent;
