import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Snackbar from '../../util/SnackBar';
import Button from '../../components/Button';

const StyledDialog = withStyles({
  paper: {
    margin: 0,
    display: 'flex',
    justifyContent: 'center',
    borderRadius: 0,
  },
  paperFullScreen: {
    margin: 0,
    height: '100%',
    backgroundColor: 'white',
    borderRadius: 0,
  },
  paperWidthLg: {
    maxWidth: '100%',
  },
  scrollPaper: {
    alignItems: 'center',
    borderRadius: 0,
  },
  paperFullWidth: {
    width: '100%',
  },
  container: {
    height: 'auto',
    borderRadius: 0,
  },
})(Dialog);

const dictOption = {
  Submitted: 'orange',
  Notes: 'blue',
  Pending: 'orange',
  Approved: 'green',
};

export default function ConfirmationDialogRaw(props) {
  const {
    onClose,
    value: valueProp,
    open,
    orders,
    removeOrder,
    handleLoading,
    cleanSelected,
    handleUpdateOrder,
    ...other
  } = props;

  const [newStatus, setNewStatus] = React.useState('');
  const [hasDifferentStatus, setHasDifferentStatus] = React.useState(false);
  const [isFinish, setIsFinish] = React.useState(false);

  function formatDate(date) {
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const d = new Date(date);
    let month = `${monthNames[d.getMonth()]}`;
    let day = `${d.getDate()}`;
    const year = d.getFullYear();
    const hour = `${d.getHours()}:${(d.getMinutes() < 10 ? '0' : '') +
      d.getMinutes()}`;

    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;

    return [month, day, year, hour].join(' ');
  }
  React.useEffect(() => {
    let bool = false;
    orders.forEach(order => {
      if (orders && orders?.status !== order?.status) bool = true;
    });
    setHasDifferentStatus(bool);
  }, [open, orders]);

  const handleUpdate = async () => {
    if (!newStatus)
      return Snackbar.error('You have to choose one status to update');

    handleUpdateOrder(newStatus);

    setIsFinish(true);
  };

  const OptionStatus = ({ option, isCurrent }) => {
    return (
      <Grid
        container
        alignItems="center"
        onClick={() => !isCurrent && setNewStatus(option)}
        style={{
          opacity: !isCurrent && newStatus !== option && 0.6,
          cursor: !isCurrent && 'pointer',
        }}
      >
        <div
          style={{
            width: 14,
            height: 14,
            background: dictOption[option],
            borderRadius: 7,
            marginRight: 8,
            marginTop: 5,
          }}
        />
        <span
          style={{
            fontWeight: !isCurrent && newStatus === option && 'bold',
          }}
        >
          {option}
        </span>
      </Grid>
    );
  };

  return (
    <StyledDialog
      aria-labelledby="confirmation-dialog-title"
      open={open}
      onClose={() => {
        onClose();
        if (isFinish) {
          cleanSelected();
          setNewStatus('');
        }

        setIsFinish(false);
      }}
      hideBackdrop
      style={{
        position: 'fixed',
        top: '40%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 480,
        zIndex: 2000,
        borderRadius: 0,
      }}
      {...other}
    >
      <DialogTitle id="confirmation-dialog-title">
        <Grid container justifyContent="center">
          <Typography
            align="center"
            display="inline"
            style={{
              fontSize: 18,
              letterSpacing: '0.1em',
              maxWidth: 310,
            }}
          >
            {!isFinish
              ? 'CHANGE PRODUCT STATUS'
              : `THE FOLLOWING orders ARE NOW`}{' '}
            {isFinish && (
              <Typography
                display="inline"
                style={{ fontWeight: 'bold', textTransform: 'uppercase' }}
              >
                {newStatus}
              </Typography>
            )}
          </Typography>
        </Grid>
        <Grid container justifyContent="center">
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: 16,
              borderBottom: '0.5px solid #000',
              width: 480,
              height: 2,
            }}
          />
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          justifyContent="center"
          style={{ width: 500, borderRadius: 0 }}
        >
          {!isFinish && (
            <Grid>
              <Typography
                align="center"
                style={{
                  fontSize: 18,
                  fontWeight: 'bold',
                  lineHeight: '21px',
                  height: 30,
                  marginBottom: 8,
                  marginTop: -8,
                }}
              >
                {orders?.length} order(s) selected
              </Typography>
            </Grid>
          )}
          <Grid
            container
            justifyContent="center"
            style={{
              height: 292,
              overflowY: 'scroll',
              width: 500,
              alignContent: 'flex-start',
            }}
          >
            {orders?.map((order, index) => (
              <Grid
                container
                alignItems="center"
                style={{ height: 130, alignContent: 'flex-start' }}
              >
                <Typography
                  style={{
                    fontSize: 14,
                    lineHeight: '18px',
                    fontWeight: 'bold',
                  }}
                >
                  Order #{order?.num}
                </Typography>
                <Grid container>
                  <Typography style={{ fontWeight: 'bold', width: 100 }}>
                    {' '}
                    Shop
                  </Typography>{' '}
                  <Typography>
                    {' '}
                    {order?.shopName?.length < 35
                      ? order?.shopName
                      : `${order?.shopName?.substring(0, 30)}...`}
                  </Typography>
                </Grid>
                <Grid container>
                  <Typography style={{ fontWeight: 'bold', width: 100 }}>
                    {' '}
                    Email
                  </Typography>{' '}
                  <Typography>{order?.email}</Typography>
                </Grid>
                <Grid container>
                  <Typography style={{ fontWeight: 'bold', width: 100 }}>
                    {' '}
                    Date
                  </Typography>{' '}
                  <Typography>{formatDate(order?.createdAt)}</Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>

          <div
            style={{
              borderTop: '0.5px solid #000',
              width: 480,
              height: 2,
              margin: '19px 0px',
            }}
          />
        </Grid>
        {!isFinish ? (
          !hasDifferentStatus ? (
            <Grid
              container
              justifyContent="space-between"
              style={{ width: 340, marginBottom: 38 }}
            >
              <Grid style={{ width: 120 }}>
                <Typography
                  style={{
                    fontSize: 12,
                    color: 'rgba(91,91,91, 1)',
                    marginBottom: 6,
                  }}
                >
                  Current Status
                </Typography>
                <OptionStatus isCurrent option={orders[0]?.status} />
              </Grid>
              <Grid style={{ width: 220 }}>
                <Typography
                  style={{
                    fontSize: 12,
                    color: 'rgba(91,91,91, 1)',
                    marginBottom: 6,
                  }}
                >
                  New Status
                </Typography>
                <Grid container alignItems="center">
                  {orders[0]?.status === 'Notes' ? (
                    <>
                      <div style={{ marginRight: 24 }}>
                        <OptionStatus option="Submitted" />
                      </div>
                      <div>
                        <OptionStatus option="Approved" />
                      </div>
                    </>
                  ) : (
                    <>
                      <div>
                        <OptionStatus option="Notes" />
                      </div>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              justifyContent="space-between"
              style={{ width: 340, marginBottom: 38 }}
            >
              <Grid style={{ width: 480 }}>
                <Typography
                  style={{
                    fontSize: 12,
                    color: 'rgba(91,91,91, 1)',
                    marginBottom: 6,
                  }}
                >
                  New Status
                </Typography>
                <Grid container alignItems="center">
                  <div style={{ marginRight: 24 }}>
                    <OptionStatus option="Approved" />
                  </div>
                  <div style={{ marginRight: 24 }}>
                    <OptionStatus option="Submitted" />
                  </div>
                  <div>
                    <OptionStatus option="Notes" />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          )
        ) : null}

        <Grid container justifyContent="center" style={{ paddingBottom: 30 }}>
          {!isFinish ? (
            <>
              <Grid
                onClick={() => {
                  onClose();
                  setIsFinish(false);
                  setNewStatus('');
                }}
                style={{ marginRight: 13 }}
              >
                <Button
                  text="Cancel"
                  textColor="#000"
                  color="#fff"
                  width={128}
                  height={40}
                  borderColor="1px solid #000"
                  justifyContent="center"
                />
              </Grid>
              <Grid style={{ marginLeft: 13 }}>
                <Button
                  text="Change"
                  textColor="#fff"
                  color="#000"
                  width={128}
                  height={40}
                  borderColor="1px solid #000"
                  justifyContent="center"
                  onClick={handleUpdate}
                />
              </Grid>
            </>
          ) : (
            <Button
              text="Close"
              textColor="#fff"
              color="#000"
              width={128}
              height={40}
              borderColor="1px solid #000"
              justifyContent="center"
              onClick={() => {
                onClose();
                setIsFinish(false);
                cleanSelected();
                setNewStatus('');
              }}
            />
          )}
        </Grid>
      </DialogContent>
    </StyledDialog>
  );
}
