import React from 'react';
import { ReactComponent as Remove } from '../../assets/removeFilledBlack.svg';

export default function Xicon() {
  return (
    <div
      style={{
        width: 12,
        height: 12,
        backgroundColor: 'black',
        display: 'flex',
      }}
    >
      <Remove />
    </div>
  );
}
