import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography, IconButton, Dialog } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import Button from '../../components/Button';
import api from '../../services/api';

const StyledDialog = withStyles({
  paper: {
    margin: 0,
    display: 'flex',
    justifyContent: 'center',
    borderRadius: 10,
  },
  paperFullScreen: {
    margin: 0,
    height: '100%',
    backgroundColor: 'white',
    borderRadius: 10,
  },
  paperWidthLg: {
    maxWidth: '100%',
  },
  scrollPaper: {
    alignItems: 'center',
    borderRadius: 10,
  },
  paperFullWidth: {
    width: '100%',
  },
  container: {
    height: 'auto',
    borderRadius: 10,
  },
})(Dialog);

export default function ModalAddNewAddress({
  open,
  handleClose,
  order,
  newOrder,
  editOrder,
}) {
  const [user, setUser] = React.useState('');

  const getUser = async () => {
    const response = await api.get(`/newCostumers/${order?.email}`);
    setUser(response.data);
  };

  React.useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  const Divider = () => {
    return (
      <Grid container justifyContent="center">
        <div
          style={{
            width: 340,
            height: 1,
            borderBottom: '1px solid #000',
            margin: '24px 0px',
          }}
        />
      </Grid>
    );
  };
  function formatDate(date) {
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const d = new Date(date);
    let month = `${monthNames[d.getMonth()]}`;
    let day = `${d.getDate()}`;
    const year = d.getFullYear();
    const hour = `${d.getHours()}:${(d.getMinutes() < 10 ? '0' : '') +
      d.getMinutes()}`;

    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;

    return [month, day, year, hour].join(' ');
  }

  return (
    user && (
      <StyledDialog
        BackdropProps={{ style: { borderRadius: 15 } }}
        open={open}
        onClose={handleClose}
        style={{
          height: 558,
          width: 480,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 2000,
        }}
      >
        <Grid style={{ padding: '5%' }}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid style={{ width: 50 }} />
            <Typography>ADD NEW ORDER</Typography>
            <IconButton style={{ width: 50 }} onClick={handleClose}>
              <ClearIcon />
            </IconButton>
          </Grid>
          <Divider />
          <Grid container justifyContent="center">
            <Typography
              style={{ width: 120, fontWeight: 'bold', fontSize: 14 }}
            >
              Account/Shop
            </Typography>{' '}
            <Typography style={{ fontSize: 14, width: 200 }}>
              {user?.shopName}
            </Typography>
          </Grid>
          <Divider />

          <Grid container justifyContent="center">
            <Typography
              align="center"
              style={{ fontSize: 14, width: 300, marginBottom: 30 }}
            >
              The user you selected already has an order in progress for this
              shipping address
            </Typography>

            <Grid container justifyContent="center">
              <Typography
                style={{ width: 120, fontWeight: 'bold', fontSize: 14 }}
              >
                Status
              </Typography>{' '}
              <Typography style={{ fontSize: 14, width: 200 }}>
                {order?.status}
              </Typography>
            </Grid>
            <Grid container justifyContent="center">
              <Typography
                style={{ width: 120, fontWeight: 'bold', fontSize: 14 }}
              >
                Created by
              </Typography>{' '}
              <Typography style={{ fontSize: 14, width: 200 }}>
                {user?.name}
              </Typography>
            </Grid>
            <Grid container justifyContent="center">
              <Typography
                style={{ width: 120, fontWeight: 'bold', fontSize: 14 }}
              >
                Date Created
              </Typography>{' '}
              <Typography style={{ fontSize: 14, width: 200 }}>
                {formatDate(order?.createdAt)}
              </Typography>
            </Grid>
            <Grid container justifyContent="center">
              <Typography
                style={{
                  width: 120,
                  fontWeight: 'bold',
                  fontSize: 14,
                }}
              >
                Date Last Edit
              </Typography>{' '}
              <Typography style={{ fontSize: 14, width: 200 }}>
                {formatDate(order?.updatedAt)}
              </Typography>
            </Grid>
          </Grid>
          <Divider />
          <Grid container justifyContent="center">
            <Typography align="center" style={{ fontSize: 14, width: 300 }}>
              Do you want to replace the current order with a new one?
            </Typography>
          </Grid>

          <Grid container justifyContent="center" style={{ paddingBottom: 33 }}>
            <Grid>
              <Button
                justifyContent="center"
                width={198}
                height={40}
                textColor="#000000"
                color="#FFFFFF"
                text="Edit Current Order"
                marginTop={54}
                fontSize={16}
                borderColor="1px solid #000"
                onClick={() => editOrder()}
              />
            </Grid>
            <Grid style={{ marginLeft: 16 }}>
              <Button
                justifyContent="center"
                width={198}
                height={40}
                color="#000000"
                textColor="#FFFFFF"
                text="Add New Order"
                marginTop={54}
                fontSize={16}
                onClick={() => newOrder()}
              />
            </Grid>
          </Grid>
        </Grid>
      </StyledDialog>
    )
  );
}
