import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { Typography, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useUserContext from '../../hooks/useUserContext';
import ButtonComponent from '../../components/Button';
import Snackbar from '../../util/SnackBar';
import { ddiCountries } from './ddi';

const useStyles = makeStyles(() => ({
  containerDiv: {
    marginTop: 90,
  },
  marginTop: {
    marginTop: 40,
  },
  subtitleText: {
    fontSize: 12,
    color: '#616161',
  },
}));

const validatePhoneField = state => {
  const regexPhone = /^[0-9]+$/; // accept at least 1 number
  return regexPhone.test(state.phone);
};

const validateCountryCodeField = state => {
  const regexCountryCode = /^\+[\d]+$/;
  return regexCountryCode.test(state.countryCode);
};

const validateEmptyField = field => {
  return field.length === 0;
};

export default function Profile() {
  const classes = useStyles();
  const { userState, updateUserProperties } = useUserContext();
  const [country, setCountry] = useState('');
  const [error, setError] = useState({
    countryCode: false,
    phone: false,
    firstName: false,
    lastName: false,
  });
  const [state, setState] = useState({
    firstName: '',
    lastName: '',
    email: '',
    countryCode: '',
    phone: '',
  });

  const handleChange = e => {
    const { name } = e.target;
    const { value } = e.target;

    if (name === 'countryCode' && value.length >= 3) countryByDdi(value);
    else if (name === 'countryCode' && value.length <= 1) {
      setState({ ...state, [name]: '+' });
      return;
    } else if (name === 'countryCode') setCountry('');

    setState({ ...state, [name]: value });
    if (error.name) setError({ ...error, [name]: false });
  };

  const handleSaveChanges = async () => {
    try {
      await updateUserProperties({
        name: `${state.firstName} ${state.lastName}`,
        countryCode: state.countryCode,
        phone: state.phone,
        email: state.email,
      });
      return Snackbar.success('User updated successfully ');
    } catch (error) {
      Snackbar.error('failed to update profile');
    }
  };

  const countryByDdi = countryCode => {
    const countryDdi = ddiCountries.find(
      object => object.dial_code === countryCode
    );
    if (countryDdi !== undefined) setCountry(`(${countryDdi.code})`);
  };

  const validateInputs = () => {
    const objectError = {
      countryCode: !validateCountryCodeField(state),
      phone: !validatePhoneField(state),
      firstName: validateEmptyField(state.firstName),
      lastName: validateEmptyField(state.lastName),
    };

    if (
      objectError.countryCode ||
      objectError.phone ||
      objectError.firstName ||
      objectError.lastName
    )
      setError({
        countryCode: objectError.countryCode,
        phone: objectError.phone,

        firstName: objectError.firstName,
        lastName: objectError.lastName,
      });
    else handleSaveChanges();
  };

  useEffect(() => {
    if (!userState.user) return;
    const name = userState.user.name.split(' ');
    const firstName = name[0];
    name.shift();
    setState({
      firstName: firstName || '',
      lastName: name.reduce(
        (previousValue, currentValue) => previousValue + ' ' + currentValue
      ),
      email: userState.user?.email || '',
      countryCode: userState.user?.countryCode || '',
      phone: userState.user?.phone || '',
    });
    if (userState.user.countryCode) countryByDdi(userState.user.countryCode);
  }, [userState]);

  return (
    <Grid
      item
      container
      xs={9}
      justifyContent="space-between"
      style={{ marginTop: 33 }}
    >
      <Grid container>
        <Grid item xs={4}>
          <Typography>Personal Information</Typography>
        </Grid>
        <Grid item xs={8}>
          <Grid item xs={8}>
            <TextField
              label="First Name"
              name="firstName"
              type="text"
              fullWidth
              onChange={e => {
                handleChange(e);
              }}
              inputProps={{ 'aria-label': 'First Name' }}
              value={state.firstName}
              error={error.firstName}
              helperText={error.firstName && 'Enter a Name'}
            />
          </Grid>
          <Grid item xs={8} className={classes.marginTop}>
            <TextField
              label="Last Name"
              type="text"
              name="lastName"
              fullWidth
              value={state.lastName}
              onChange={e => {
                handleChange(e);
              }}
              inputProps={{ 'aria-label': 'Last Name' }}
              error={error.lastName}
              helperText={error.lastName && 'Enter a last name '}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid container className={classes.containerDiv}>
        <Grid item xs={4}>
          <Typography>Contact Information</Typography>
        </Grid>
        <Grid item xs={8}>
          <Grid
            item
            container
            xs={8}
            justifyContent="space-between"
            alignItems="baseline"
          >
            <TextField
              label="Phone"
              type="text"
              name="countryCode"
              value={state.countryCode}
              style={{ width: '12%' }}
              onChange={e => {
                handleChange(e);
              }}
              inputProps={{ 'aria-label': 'Country Code' }}
              error={error.countryCode}
              helperText={
                error.countryCode ? 'Insert +DDD format' : 'Country Code'
              }
            />

            <Typography>{country}</Typography>

            <TextField
              label=""
              type="text"
              name="phone"
              style={{ width: '75%', marginTop: 16 }}
              onChange={e => {
                handleChange(e);
              }}
              value={state.phone}
              inputProps={{ 'aria-label': 'Phone' }}
              error={error.phone}
              helperText={error.phone && 'Numbers only'}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid container className={classes.containerDiv}>
        <Grid item xs={4}>
          <Typography>Login Information</Typography>
        </Grid>
        <Grid item xs={8}>
          <Grid item container xs={8}>
            <TextField
              label="Email"
              type="text"
              fullWidth
              disabled
              value={state.email}
              inputProps={{ 'aria-label': 'Email' }}
              helperText={"Your email can't be changed"}
            />
            <Grid container justify="center">
              <Grid
                style={{
                  marginBottom: 30,
                  marginTop: 60,
                  width: 'fit-content',
                }}
              >
                <ButtonComponent
                  width={180}
                  height={40}
                  textColor="#fff"
                  color="#000"
                  onClick={validateInputs}
                  text="Save Changes"
                  justify="center"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
