/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */

import React, { useState, useEffect, useCallback, useRef } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { withStyles } from '@material-ui/core/styles';

import {
  Typography,
  Grid,
  DialogContent,
  IconButton,
  Tooltip,
  CircularProgress,
} from '@material-ui/core';
import Img from 'react-cool-img';

import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import { IoMdHeartDislike } from 'react-icons/io';
import { AiOutlineDownload } from 'react-icons/ai';
import ReactToPrint from 'react-to-print';
import { useDebounce } from 'use-lodash-debounce';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClearIcon from '@material-ui/icons/Clear';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Snackbar from '../../util/SnackBar';
import api from '../../services/api';
import PDF from '../PDF';
import Button from '../Button';
import ModalWarningEmptyProducts from './ModalWarningEmptyProducts';
import logo from '../../assets/logo_farmRio.svg';
import Input from '../TextInput';
import MenuDrawer from '../ModalCollection/MenuDrawer';
import { submitOrder, checkOrder } from './api';
import ModalAddress from '../ModalAddresses';
import ModalAlreadyHaveAnOrder from './ModalAlreadyHaveAnOrder';

import { dictIdDelivery, dictIdDeliveryFormatted, events } from '../../consts';
import { useEventDispatcher } from '../../hooks';
import useUserContext from '../../hooks/useUserContext';
import useConfigContext from '../../hooks/useConfigContext';

// import {
//   excelRequest,
//   excelFailure,
//   excelSuccess,
// } from '../../store/modules/collection/actions';

const WarningIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ position: 'relative', top: 4, right: 2 }}
    >
      <circle r="6" transform="matrix(1 0 0 -1 8 8)" stroke="#E01919" />
      <circle r="1" transform="matrix(1 0 0 -1 8 11)" fill="#E01919" />
      <path
        d="M7.5 5C7.5 4.72386 7.72386 4.5 8 4.5C8.27614 4.5 8.5 4.72386 8.5 5L7.5 5ZM8.5 8C8.5 8.27614 8.27614 8.5 8 8.5C7.72386 8.5 7.5 8.27614 7.5 8L8.5 8ZM8.5 5L8.5 8L7.5 8L7.5 5L8.5 5Z"
        fill="#E01919"
      />
    </svg>
  );
};

const StyledDialog = withStyles({
  paper: {
    margin: 0,
  },
  paperFullScreen: {
    margin: 0,
    height: '100%',
    backgroundColor: 'white',
  },
  paperWidthLg: {
    maxWidth: '100%',
  },
  scrollPaper: {
    alignItems: 'center',
  },
  paperFullWidth: {
    width: '100%',
  },
})(Dialog);

const StyledDialogEdit = withStyles({
  paper: {
    margin: 0,
    display: 'flex',
    justifyContent: 'center',
  },
  paperFullScreen: {
    margin: 0,
    height: '100%',
    backgroundColor: 'white',
  },
  paperWidthLg: {
    maxWidth: '100%',
  },
  scrollPaper: {
    alignItems: 'center',
  },
  paperFullWidth: {
    width: '100%',
  },
  container: {
    height: 'auto',
  },
})(Dialog);

const Accordion = withStyles({
  root: {
    margin: '0',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 0,
      padding: 0,
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionDetails = withStyles(theme => ({
  root: {
    padding: 0,
  },
}))(MuiAccordionDetails);

const AccordionSummary = withStyles({
  root: {
    // marginBottom: -1,
    height: 40,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0px 0px',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ordenateDelivery(products) {
  const d1Array = [];
  const d2Array = [];
  const d3Array = [];
  const d4Array = [];

  products.forEach(product => {
    if (product.delivery_id === dictIdDelivery[1]) {
      d1Array.push(product);
    }
    if (product.delivery_id === dictIdDelivery[2]) {
      d2Array.push(product);
    }
    if (product.delivery_id === dictIdDelivery[3]) {
      d3Array.push(product);
    }
    if (product.delivery_id === dictIdDelivery[4]) {
      d4Array.push(product);
    }
  });
  return [d1Array, d2Array, d3Array, d4Array];
}

export default function ModalCart({
  open,
  handleClose,
  orderProducts,
  orderSelected,
}) {
  const { configState } = useConfigContext();
  const seasonName = configState.season.name || '';
  const DELIVERIES = configState.deliveries;
  const [date] = useState(0);
  const [categories] = useState([]);
  const dispatchEvent = useEventDispatcher();

  const [myProducts, setMyProducts] = useState(
    orderProducts?.length > 0 ? orderProducts : []
  );
  const [d1, setD1] = useState([]);
  const [d2, setD2] = useState([]);
  const [d3, setD3] = useState([]);
  const [d4, setD4] = useState([]);
  const [isUSD, setisUSD] = useState(true);
  const { userState } = useUserContext();
  const { user: profile } = userState;
  const [print, setPrint] = useState(false);
  // const [downloadExcelURL, setExcelURL] = useState('');
  const [nav, setNav] = useState(orderProducts ? 'review' : 'ship');
  const [wid, setWid] = useState(null);
  const [totalD1, setTotald1] = useState(0);
  const [totalD2, setTotald2] = useState(0);
  const [totalD3, setTotald3] = useState(0);
  const [totalD4, setTotalD4] = useState(0);
  const [loading, setLoading] = useState(true);
  const [shipAdd, setShipAdd] = useState([]);
  const [user, setUser] = useState(null);
  const [selectedShipAddress, setSelectedShipAddress] = useState(0);
  const [selectedBillingAddress] = useState(0);
  const [show, setShow] = useState(false);
  const [loadingOrder, setLoadingOrder] = useState(false);
  const [downloadExcelURL, setDownloadExcelURL] = useState('');
  const [emptyProducts, setEmptyProducts] = useState([]);
  const [billingAddresses, setBillingAddresses] = useState([]);
  const [isShip, setIsShip] = useState(true);
  const handleChangeAddress = () => {
    if (isShip) return shipAdd;
    return billingAddresses;
  };
  const [openWarningEmptyProducts, setOpenWarningEmptyProducts] = useState(
    false
  );
  const [openOrderAlreadyExist, setOpenOrderAlreadyExist] = useState(false);
  const [currentOrder, setCurrentOrder] = useState(null);
  const handleCloseOrderAlreadyExist = () => {
    setOpenOrderAlreadyExist(false);
    setCurrentOrder(null);
  };

  const updateExistingOrder = async () => {
    await submitOrder({
      cartInfo: myProducts,
      userInfo: {
        email: user.email,
        sAddress: shipAdd[selectedShipAddress],
      },
    });
    setNav('place');
    return handleCloseOrderAlreadyExist();
  };

  useEffect(() => {
    if (orderProducts !== 'undefined' && orderProducts?.length > 0)
      return setNav('review');
  }, [orderProducts]);

  const handleSortSizes = arr => {
    if (arr.length === 11 && arr[0] === '5') {
      return [
        '5',
        '6',
        '6 1/2',
        '7',
        '7 1/2',
        '8',
        '8 1/2',
        '9',
        '9 1/2',
        '10',
        '11',
      ];
    }
    return arr;
  };

  const isMobile = wid < 1024;

  const getUser = async () => {
    const { data } = await api.get(`/newCostumers/${profile.email}`);
    setUser(data);
    const bAddress = [];
    const sAddress = [];
    const addresses = data?.account?.addresses?.map(address => {
      if (address.isBillingAddress) bAddress.push(address);
      if (address.isShippingAddress) sAddress.push(address);
    });
    setBillingAddresses(bAddress);
    setShipAdd(sAddress);
    setLoading(false);
    return addresses;
  };

  useEffect(() => {
    if (profile && loading) {
      getUser();
    }
  }, [profile, loading]);

  useEffect(() => {
    function updateSize() {
      setWid(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [wid]);

  const handleTotalD1 = num => {
    setTotald1(totalD1 + num);
  };

  const handleTotalD2 = num => {
    setTotald2(totalD2 + num);
  };
  const handleTotalD3 = num => {
    setTotald3(totalD3 + num);
  };

  const handleTotald4 = num => {
    setTotald3(totalD3 + num);
  };

  const componentRef = useRef();

  const handleRemoveProductFromCollection = useCallback(
    async product => {
      try {
        await api.post('/collections', {
          style_code: product.style_code,
        });
        setMyProducts(
          myProducts.filter(p => p.style_code !== product.style_code)
        );
      } catch (err) {
        console.log(err);
      }
    },
    [myProducts]
  );

  useEffect(() => {
    async function loadMyProducts() {
      try {
        let response = '';

        if (orderProducts !== 'undefined' && orderProducts?.length > 0) {
          setMyProducts(orderProducts);

          const [d1Array, d2Array, d3Array, d4Array] = ordenateDelivery(
            orderProducts
          );

          setD1(d1Array);
          setD2(d2Array);
          setD3(d3Array);
          setD4(d4Array);
        } else {
          response = await api.get('/my/cart', {
            params: {
              delivery_id: date !== 0 ? dictIdDelivery[date] : null,
              categories:
                categories.length >= 1 ? JSON.stringify(categories) : null,
            },
          });
          setMyProducts(response.data);

          const [d1Array, d2Array, d3Array, d4Array] = ordenateDelivery(
            response.data
          );

          setD1(d1Array);
          setD2(d2Array);
          setD3(d3Array);
          setD4(d4Array);
        }

        if (!user) {
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
      }
    }
    loadMyProducts();
  }, [open, orderProducts]);

  useEffect(() => {
    const d1Array = [];
    const d2Array = [];
    const d3Array = [];

    myProducts.forEach(product => {
      if (product.delivery_id === dictIdDelivery[1]) {
        d1Array.push(product);
      }
      if (product.delivery_id === dictIdDelivery[2]) {
        d2Array.push(product);
      }
      // if (product.delivery_id === dictIdDelivery[3]) {
      //   d3Array.push(product);
      // }
      return {
        ...product,
        formatted_delivery: dictIdDeliveryFormatted[product.delivery_id],
      };
    });

    setD1(d1Array);
    setD2(d2Array);
    setD3(d3Array);
  }, [myProducts]);

  if (!myProducts) return null;

  const Finish = () => {
    function timeout(delay) {
      return new Promise(res => setTimeout(res, delay));
    }
    return (
      <Grid container justifyContent="center">
        <Typography
          display="inline"
          align="center"
          style={{
            fontFamily: 'Galano Grotesque Light',
            fontSize: isMobile ? 18 : 24,
            marginTop: '5%',
          }}
        >
          Your order has been submitted and is now{' '}
          <span style={{ fontWeight: 'bold' }}>pending</span> approval.{' '}
        </Typography>
        <Grid container justifyContent="center">
          <Typography
            align="center"
            style={{ margin: isMobile ? '20% 0' : '7% 0px' }}
          >
            Thank you for submitting your order with FARM Rio
          </Typography>
        </Grid>
        <Grid container justifyContent="center">
          <Typography align="center">
            Sales will reach out if there any questions and if your order is
            confirmed
          </Typography>
        </Grid>
        <Grid container justifyContent="center">
          <Typography align="center" style={{ margin: '15px 0px' }}>
            In the meantime, If you have any questions please fell free to reach
            out to
          </Typography>
        </Grid>
        <Grid container justifyContent="center">
          <Typography align="center" display="inline">
            Gabriele -{' '}
            <span style={{ fontWeight: 'bold' }}>
              gabriele.petrauskaite@farmrio.com
            </span>
          </Typography>
        </Grid>

        <Grid
          container
          justifyContent="center"
          style={{ margin: isMobile ? '20% 0 15%' : '4% 0 4%' }}
        >
          <Grid
            style={{
              display: isMobile && 'flex',
              justify: 'center',
              margin: isMobile && '10px 0px',
              width: isMobile && 400,
            }}
          >
            <Grid
              container
              justifyContent="center"
              style={{ marginRight: !isMobile && 20 }}
            >
              <Typography variant="body2">Download Collection</Typography>
              <Tooltip
                title="Use 'Save as PDF' mode"
                style={{ marginLeft: 10 }}
              >
                <Grid>
                  <ReactToPrint
                    trigger={() => (
                      <Grid
                        container
                        style={{
                          alignItems: 'center',
                          cursor: 'pointer',
                          height: 19,
                        }}
                        onClick={() => setPrint(true)}
                      >
                        <AiOutlineDownload
                          size={16}
                          color="black"
                          style={{ marginTop: 2 }}
                        />{' '}
                        <span style={{ fontSize: 12 }}>PDF</span>
                      </Grid>
                    )}
                    content={() => componentRef.current}
                    onAfterPrint={() => setPrint(false)}
                    onBeforeGetContent={async () => {
                      setPrint(true);
                      await timeout(1000);
                    }}
                  />
                </Grid>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid
            style={{
              display: isMobile && 'flex',
              justify: 'center',
              margin: isMobile && '10px 0px',
            }}
          >
            <Grid
              container
              justifyContent="center"
              style={{ marginLeft: !isMobile && 20 }}
            >
              {!downloadExcelURL ? (
                <Grid
                  container
                  justifyContent="center"
                  onClick={() => !loadingOrder && handleExcel()}
                  style={{
                    position: 'relative',
                    bottom: 55,
                    width: wid,
                    paddingRight: 30,
                  }}
                >
                  {!loadingOrder ? (
                    <>
                      <Typography style={{ marginRight: 10 }} variant="body2">
                        Download Order
                      </Typography>
                      <AiOutlineDownload
                        size={16}
                        color="black"
                        style={{ marginTop: 2, cursor: 'pointer' }}
                      />
                      <span style={{ fontSize: 12 }}>
                        {' '}
                        <span
                          href={downloadExcelURL}
                          style={{
                            textDecoration: 'none',
                            color: 'black',
                            cursor: 'pointer',
                          }}
                          download={`FARM US - ${seasonName}.xlsx`}
                        >
                          XLS{' '}
                        </span>
                      </span>
                    </>
                  ) : (
                    <Typography
                      display="inline"
                      style={{ marginRight: 10 }}
                      variant="body2"
                    >
                      Preparing order...{' '}
                      <CircularProgress size={12} style={{ marginLeft: 5 }} />
                    </Typography>
                  )}
                </Grid>
              ) : (
                <Grid
                  container
                  justifyContent="center"
                  // onClick={handleExcel}
                  style={{
                    marginLeft: !isMobile && 20,
                    position: 'relative',
                  }}
                >
                  <Typography style={{ marginRight: 10 }} variant="body2">
                    Excel Ready
                  </Typography>
                  <AiOutlineDownload
                    size={16}
                    color="black"
                    style={{ marginTop: 2, cursor: 'pointer' }}
                  />
                  <span style={{ fontSize: 12 }}>
                    {' '}
                    <a
                      href={downloadExcelURL}
                      style={{
                        textDecoration: 'none',
                        color: 'black',
                        cursor: 'pointer',
                      }}
                      download={`FARM US - ${seasonName}.xlsx`}
                    >
                      XLS{' '}
                    </a>
                  </span>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid
          style={{
            cursor: 'pointer',
            backgroundColor: 'black',
            padding: '8px 65px',
          }}
          onClick={() => {
            handleClose();
            setNav('ship');
          }}
        >
          <Typography style={{ color: '#FFFFFF' }}>
            Go back to your collection
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const Order = () => {
    return (
      <Grid>
        {d1.length > 0 && (
          <Grid
            container
            justifyContent="space-between"
            style={{
              width: isMobile ? '95%' : '75%',
              borderBottom: '1px solid #000000',
              paddingBottom: 11,
              marginTop: 44,
            }}
          >
            <Grid>
              <Typography
                display="inline"
                style={{ fontWeight: 'bold', fontSize: 16 }}
              >
                {`${DELIVERIES[0].title} `}
              </Typography>
              <Typography display="inline" style={{ fontSize: 16 }}>
                {DELIVERIES[0].subTitle1} | {DELIVERIES[0].subTitle2}
              </Typography>
            </Grid>
            <Grid>
              <Typography
                display="inline"
                style={{ fontWeight: 'bold', fontSize: 16 }}
              />
              <Typography display="inline" style={{ fontSize: 14 }}>
                {d1.length} styles added
              </Typography>
            </Grid>
          </Grid>
        )}
        <Grid>
          {d1.map(product => (
            <Grid item key={product.style_code} style={{ marginBottom: 10 }}>
              <CartItem
                handleRemoveProductFromCollection={
                  handleRemoveProductFromCollection
                }
                emptyProducts={emptyProducts}
                product={product}
                isUSD={isUSD}
                handleTotalPerDelivery={handleTotalD1}
                setEmptyProducts={setEmptyProducts}
                nav={nav}
                delivery="d1"
              />
            </Grid>
          ))}
        </Grid>
        {d2?.length > 0 && (
          <Grid
            container
            justifyContent="space-between"
            emptyProducts={emptyProducts}
            style={{
              width: isMobile ? '95%' : '75%',
              borderBottom: '1px solid #000000',
              paddingBottom: 11,
              marginTop: 80,
            }}
          >
            <Grid>
              <Typography
                display="inline"
                style={{ fontWeight: 'bold', fontSize: 16 }}
              >
                {`${DELIVERIES[1].title} `}
              </Typography>
              <Typography display="inline" style={{ fontSize: 16 }}>
                {DELIVERIES[1].subTitle1} | {DELIVERIES[1].subTitle2}
              </Typography>
            </Grid>
            <Grid>
              <Typography display="inline" style={{ fontSize: 14 }}>
                {d2.length} styles added
              </Typography>
            </Grid>
          </Grid>
        )}

        <Grid>
          {d2.map(product => (
            <Grid item key={product.style_code}>
              <CartItem
                isUSD={isUSD}
                handleRemoveProductFromCollection={
                  handleRemoveProductFromCollection
                }
                emptyProducts={emptyProducts}
                product={product}
                handleTotalPerDelivery={handleTotalD2}
                nav={nav}
                delivery="d2"
                setEmptyProducts={setEmptyProducts}
              />
            </Grid>
          ))}
        </Grid>
        {d3?.length > 0 && (
          <Grid
            container
            justifyContent="space-between"
            style={{
              width: isMobile ? '95%' : '75%',
              borderBottom: '1px solid #000000',
              paddingBottom: 11,
              marginTop: 80,
            }}
          >
            <Grid>
              <Typography
                display="inline"
                style={{ fontWeight: 'bold', fontSize: 16 }}
              >
                {`${DELIVERIES[2].title} `}
              </Typography>
              <Typography display="inline" style={{ fontSize: 16 }}>
                {DELIVERIES[2].subTitle1} | {DELIVERIES[2].subTitle2}
              </Typography>
            </Grid>
            <Grid>
              <Typography display="inline" style={{ fontSize: 14 }}>
                {d3.length} styles added
              </Typography>
            </Grid>
          </Grid>
        )}
        <Grid>
          {d3.map(product => (
            <Grid item key={product.style_code}>
              <CartItem
                isUSD={isUSD}
                handleRemoveProductFromCollection={
                  handleRemoveProductFromCollection
                }
                product={product}
                emptyProducts={emptyProducts}
                handleTotalPerDelivery={handleTotalD3}
                nav={nav}
                delivery="d3"
                setEmptyProducts={setEmptyProducts}
              />
            </Grid>
          ))}
        </Grid>
        {d4?.length > 0 && (
          <Grid
            container
            justifyContent="space-between"
            style={{
              width: isMobile ? '95%' : '75%',
              borderBottom: '1px solid #000000',
              paddingBottom: 11,
              marginTop: 80,
            }}
          >
            {/* <Grid>
              <Typography
                display="inline"
                style={{ fontWeight: 'bold', fontSize: 16 }}
              >
                {`${DELIVERIES[3].title} `}
              </Typography>
              <Typography display="inline" style={{ fontSize: 16 }}>
                {DELIVERIES[3].subTitle1} | {DELIVERIES[3].subTitle2}
              </Typography>
            </Grid> */}
            <Grid>
              <Typography display="inline" style={{ fontSize: 14 }}>
                {d4.length} styles added
              </Typography>
            </Grid>
          </Grid>
        )}
        <Grid>
          {d4.map(product => (
            <Grid item key={product.style_code}>
              <CartItem
                isUSD={isUSD}
                handleRemoveProductFromCollection={
                  handleRemoveProductFromCollection
                }
                product={product}
                emptyProducts={emptyProducts}
                handleTotalPerDelivery={handleTotald4}
                nav={nav}
                delivery="d4"
                setEmptyProducts={setEmptyProducts}
              />
            </Grid>
          ))}
        </Grid>
        <Grid style={{ height: 100 }} />
      </Grid>
    );
  };

  const Shipping = () => {
    const [, setIsBilling] = useState(false);
    const [openShip, setOpenShip] = useState(false);
    const [openAddress, setOpenAddress] = useState(false);
    const [id, setId] = useState(-1);
    const [editValues, setEditValues] = useState([]);

    const handleCloseModalAddress = () => {
      setOpenAddress(false);
      setId(-1);
    };

    const updateSAddress = async add => {
      const data = new FormData();
      const sAddress = [];
      const sCity = [];
      const sStoreProvince = [];
      const sZipcode = [];
      const sCountry = [];
      add.forEach(item => {
        sAddress.push(item.sLine1);
        sCity.push(item.sLine2);
        sStoreProvince.push(item.sLine3);
        sZipcode.push(item.sZipcode);
        sCountry.push(item.sCountry);
      });

      let sLineFormatted = '';
      sAddress.forEach((item, index) =>
        index === 0
          ? (sLineFormatted += item)
          : (sLineFormatted += `*-*${item}`)
      );
      let sCityFormatted = '';
      sCity.forEach((item, index) =>
        index === 0
          ? (sCityFormatted += item)
          : (sCityFormatted += `*-*${item}`)
      );
      let sStoreProvinceFormatted = '';
      sStoreProvince.forEach((item, index) =>
        index === 0
          ? (sStoreProvinceFormatted += item)
          : (sStoreProvinceFormatted += `*-*${item}`)
      );
      let sZipcodeFormatted = '';
      sZipcode.forEach((item, index) =>
        index === 0
          ? (sZipcodeFormatted += item)
          : (sZipcodeFormatted += `*-*${item}`)
      );
      let sCountryFormatted = '';
      sCountry.forEach((item, index) =>
        index === 0
          ? (sCountryFormatted += item)
          : (sCountryFormatted += `*-*${item}`)
      );

      data.append('sLine1', sLineFormatted);
      data.append('sLine2', sCityFormatted);
      data.append('sLine3', sStoreProvinceFormatted);
      data.append('sZipcode', sZipcodeFormatted);
      data.append('sCountry', sCountryFormatted);
      data.append('email', profile.email);

      await api.put('/newCostumers', data);
    };

    const handleDeleteAddress = index => {
      const newArr = shipAdd.filter((item, i) => index !== i);
      setShipAdd(newArr);
      updateSAddress(newArr);
    };

    const handleOpenEdit = (ad, cit, stProv, zcode, ct, i, st, unit) => {
      setOpenAddress(true);
      setEditValues({
        modalAddress: ad,
        modalCity: cit,
        modalStoreProv: stProv,
        modalZipcode: zcode,
        modalStreet: st,
        modalUnit: unit,
        modalCountry: ct,
      });

      setId(i);
    };

    const translateModalAddress = address => {
      return {
        name: address.modalAddress,
        street: address.modalStreet,
        building_unit_floor: address.modalUnit,
        city: address.modalCity,
        state_province: address.modalStoreProv,
        country: address.modalCountry,
        zipcode: address.modalZipcode,
        isBillingAddress: !isShip,
        isShippingAddress: isShip,
      };
    };

    const handleSaveAddress = address => {
      let newArr = [];
      let addresses = [];
      if (id === -1) {
        if (isShip) {
          setShipAdd([...shipAdd, translateModalAddress(address)]);
        } else {
          setBillingAddresses([
            ...billingAddresses,
            translateModalAddress(address),
          ]);
        }
        addresses = [
          ...shipAdd,
          ...billingAddresses,
          translateModalAddress(address),
        ];
      } else if (isShip) {
        newArr = shipAdd.map((item, i) =>
          i !== id ? item : translateModalAddress(address)
        );
        setShipAdd(newArr);
        addresses = [...newArr, ...billingAddresses];
      } else {
        newArr = billingAddresses.map((item, i) =>
          i !== id ? item : translateModalAddress(address)
        );
        setBillingAddresses(newArr);
        addresses = [...shipAdd, ...newArr];
      }
      api.put('/account', {
        id: profile.account,
        addresses,
      });
      handleCloseModalAddress();
    };

    return (
      <Grid container justify="center" alignItems={isMobile && 'center'}>
        <ModalAddress
          open={openAddress}
          handleClose={handleCloseModalAddress}
          handleSaveAddress={handleSaveAddress}
          editValues={editValues}
        />
        <StyledDialogEdit
          open={openShip}
          BackdropProps={{ style: { borderRadius: 5 } }}
          onClose={() => {
            setOpenShip(false);
            setOpenAddress(false);
          }}
          style={{
            minHeight: 715,
            width: 350,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 2000,
          }}
        >
          <Grid style={{ height: 715, padding: '5%', overflowX: 'hidden' }}>
            <Grid container justify="space-between" alignItems="center">
              <Typography>
                {isShip ? 'Shipping Address' : 'Billing Address'}
              </Typography>
              <IconButton
                onClick={() => {
                  setOpenShip(false);
                  setOpenAddress(false);
                }}
              >
                <ClearIcon />
              </IconButton>
            </Grid>
            <Grid style={{ width: 350 }}>
              {handleChangeAddress().map((address, index) => (
                <Grid
                  onClick={() =>
                    selectedShipAddress !== index &&
                    setSelectedShipAddress(index)
                  }
                  style={{
                    cursor: 'pointer',
                    padding: '11px 15px 4px',
                    width: 320,
                    height: 102,
                    border:
                      index === selectedShipAddress
                        ? '1px solid #000000'
                        : '1px solid #d3d3d3',
                    marginTop: 10,
                  }}
                >
                  <Typography
                    style={{
                      fontSize: 12,
                      fontWeight: 'bold',
                      marginBottom: 12,
                      color: index !== selectedShipAddress && '#d3d3d3',
                    }}
                  >
                    {address?.name}
                  </Typography>
                  <Grid container justifyContent="space-between">
                    <Grid style={{ width: 200 }}>
                      <Typography
                        style={{
                          fontSize: 12,
                          lineHeight: '10px',
                          color: index !== selectedShipAddress && '#d3d3d3',
                        }}
                      >{`${address?.street} - ${address?.building_unit_floor}`}</Typography>
                      <Typography
                        style={{
                          fontSize: 12,
                          lineHeight: '10px',
                          color: index !== selectedShipAddress && '#d3d3d3',
                        }}
                      >{`${address?.city} - ${address?.state_province}`}</Typography>
                      <Typography
                        style={{
                          fontSize: 12,
                          lineHeight: '10px',
                          color: index !== selectedShipAddress && '#d3d3d3',
                        }}
                      >{`${address?.zipcode} - ${address?.country}`}</Typography>
                    </Grid>
                    <Grid style={{ width: 46 }}>
                      <Typography
                        onClick={event => {
                          event.stopPropagation();
                          setIsBilling(false);
                          setId(index);
                          handleOpenEdit(
                            address.name,
                            address.city,
                            address.state_province,
                            address.zipcode,
                            address.country,
                            index,
                            address.street,
                            address.building_unit_floor
                          );
                        }}
                        align="right"
                        style={{
                          fontSize: 12,
                          color: index !== selectedShipAddress && '#d3d3d3',
                          cursor: 'pointer',
                          zIndex: 5000,
                        }}
                      >
                        Edit
                      </Typography>
                      {shipAdd.length > 1 && (
                        <Typography
                          align="right"
                          style={{
                            fontSize: 12,
                            cursor: 'pointer',
                            color: index !== selectedShipAddress && '#d3d3d3',
                          }}
                          onClick={event => {
                            event.stopPropagation();
                            handleDeleteAddress(index);
                          }}
                        >
                          Remove
                        </Typography>
                      )}
                    </Grid>
                    <Grid />
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Grid container justifyContent="center" style={{ marginTop: 20 }}>
              {/* {profile.doors > profile.sAddress?.length && ( */}
              <Grid
                container
                style={{
                  width: 216,
                  height: 36,
                  alignItems: 'center',
                  border: '1px solid #000000',
                  cursor: 'pointer',
                }}
                justifyContent="center"
                onClick={() => {
                  setOpenShip(false);
                  setOpenAddress(true);
                }}
              >
                <Typography style={{ fontSize: 12 }}>Add address +</Typography>
              </Grid>
              {/* )} */}
            </Grid>
          </Grid>
        </StyledDialogEdit>
        <Grid
          style={{
            width: isMobile ? '86%' : '60%',
            borderBottom: !isMobile && '1px solid #000000',
            padding: !isMobile && '36px 0px',
          }}
        >
          {!isMobile && (
            <Typography align="center">
              Please review your billing and shipping information for your order
            </Typography>
          )}
        </Grid>
        <Grid
          container
          justifyContent={isMobile ? 'center' : 'space-between'}
          style={{ width: isMobile ? '86%' : 1000, marginTop: 60 }}
        >
          <Grid>
            <Typography style={{ margin: isMobile && '20px 0px' }}>
              Billing address
            </Typography>
            <Grid
              style={{
                width: isMobile ? wid * 0.86 : 400,
                border: '1px solid #000000',
                padding: isMobile ? 12 : 25,
                margin: !isMobile && '30px 0px 40px',
              }}
            >
              <Typography
                style={{
                  fontWeight: 'bold',
                  fontSize: 14,
                  margin: '0px 0px 10px',
                }}
              >
                {billingAddresses[selectedBillingAddress]?.name}
              </Typography>

              <Typography style={{ fontSize: 14, margin: '10px 0px' }}>
                {billingAddresses[selectedBillingAddress]?.street} -{' '}
                {billingAddresses[selectedBillingAddress]
                  ?.building_unit_floor || ''}
              </Typography>
              <Typography style={{ fontSize: 14, margin: '10px 0px 0px' }}>
                {billingAddresses[selectedBillingAddress]?.city} -{' '}
                {billingAddresses[selectedBillingAddress]?.state_province}
              </Typography>
              <Typography style={{ fontSize: 14, margin: '10px 0px 0px' }}>
                {billingAddresses[selectedBillingAddress]?.zipcode} -{' '}
                {billingAddresses[selectedBillingAddress]?.country}
              </Typography>
            </Grid>
            <Grid
              container
              justifyContent="center"
              style={{ margin: isMobile && '20px 0px' }}
            >
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                onClick={() => {
                  setOpenShip(true);
                  setIsShip(false);
                }}
                style={{
                  width: 220,
                  height: 40,
                  border: '1px solid #000000',
                  cursor: 'pointer',
                }}
              >
                <Typography style={{ fontSize: 12 }}>Change address</Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid>
            <Typography style={{ margin: isMobile && '20px 0px' }}>
              Shipping address
            </Typography>

            <Grid
              style={{
                width: isMobile ? wid * 0.86 : 400,
                border: '1px solid #000000',
                padding: isMobile ? 12 : 25,
                margin: !isMobile && '30px 0px 40px',
              }}
            >
              <Typography
                style={{
                  fontWeight: 'bold',
                  fontSize: 14,
                  margin: '0px 0px 10px',
                }}
              >
                {shipAdd[selectedShipAddress]?.name}
              </Typography>

              <Typography style={{ fontSize: 14, margin: '10px 0px' }}>
                {shipAdd[selectedShipAddress]?.street} -{' '}
                {shipAdd[selectedShipAddress]?.building_unit_floor}
              </Typography>
              <Typography style={{ fontSize: 14, margin: '10px 0px 0px' }}>
                {shipAdd[selectedShipAddress]?.city} -{' '}
                {shipAdd[selectedShipAddress]?.state_province}
              </Typography>
              <Typography style={{ fontSize: 14, margin: '10px 0px 0px' }}>
                {shipAdd[selectedShipAddress]?.zipcode} -{' '}
                {shipAdd[selectedShipAddress]?.country}
              </Typography>
            </Grid>
            <Grid
              container
              justifyContent="center"
              style={{ margin: isMobile && '20px 0px' }}
            >
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{
                  width: 220,
                  height: 40,
                  border: '1px solid #000000',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setOpenShip(true);
                  setIsShip(true);
                }}
              >
                <Typography style={{ fontSize: 12 }}>Change address</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const FixedInfo = () => {
    const [expanded, setExpanded] = React.useState([]);
    const [comment, setComment] = React.useState([]);
    const [d1dresses, setD1dresses] = React.useState({ qtd: 0, price: 0 });
    const [d2dresses, setD2dresses] = React.useState({ qtd: 0, price: 0 });
    const [d3dresses, setD3dresses] = React.useState({ qtd: 0, price: 0 });
    const [d1tops, setD1tops] = React.useState({ qtd: 0, price: 0 });
    const [d2tops, setD2tops] = React.useState({ qtd: 0, price: 0 });
    const [d3tops, setD3tops] = React.useState({ qtd: 0, price: 0 });
    const [d1skirts, setD1skirts] = React.useState({ qtd: 0, price: 0 });
    const [d2skirts, setD2skirts] = React.useState({ qtd: 0, price: 0 });
    const [d3skirts, setD3skirts] = React.useState({ qtd: 0, price: 0 });
    const [d1bottoms, setD1bottoms] = React.useState({ qtd: 0, price: 0 });
    const [d2bottoms, setD2bottoms] = React.useState({ qtd: 0, price: 0 });
    const [d3bottoms, setD3bottoms] = React.useState({ qtd: 0, price: 0 });
    const [d1sweaters, setD1sweaters] = React.useState({ qtd: 0, price: 0 });
    const [d2sweaters, setD2sweaters] = React.useState({ qtd: 0, price: 0 });
    const [d3sweaters, setD3sweaters] = React.useState({ qtd: 0, price: 0 });
    const [d1outerwear, setD1outerwear] = React.useState({ qtd: 0, price: 0 });
    const [d2outerwear, setD2outerwear] = React.useState({ qtd: 0, price: 0 });
    const [d3outerwear, setD3outerwear] = React.useState({ qtd: 0, price: 0 });
    const [openUpdateModal, setOpenUpdateModal] = React.useState(false);
    const [isPayable, setIsPayable] = React.useState(false);
    const [modalName, setModalName] = useState('');
    const [modalShopName, setModalShopName] = useState('');
    const [modalPhone, setModalPhone] = useState('');
    const [modalEmail, setModalEmail] = useState('');
    const [errorName, setErrorName] = useState('');
    const [errorShopName, setErrorShopName] = useState('');
    const [errorEmail, setErrorEmail] = useState('');
    const [errorPhone, setErrorPhone] = useState('');

    React.useEffect(() => {
      setModalName(isPayable ? user?.payName : user?.name);
      setModalPhone(isPayable ? user?.payPhone : user?.phone);
      setModalEmail(isPayable ? user?.payEmail : user?.email);
      setModalShopName(user?.shopName);
    }, [isPayable]);

    const handleChange = panel => (event, newExpanded) => {
      if (expanded.includes(panel)) {
        return setExpanded(expanded.filter(item => item !== panel));
      }
      setExpanded([...expanded, panel]);
    };
    React.useEffect(() => {
      let aux = 0;
      let qtdDresses = 0;
      let priceDresses = 0;
      let qtdbottoms = 0;
      let pricebottoms = 0;
      let qtdtops = 0;
      let pricetops = 0;
      let qtdsweaters = 0;
      let pricesweaters = 0;
      let qtdouterwear = 0;
      let priceouterwear = 0;
      let qtdskirts = 0;
      let priceskirts = 0;

      d1.map(product => {
        product.cartInfo.forEach(size => {
          aux +=
            size.quantity *
            (isUSD ? product.wholesale_usd : product.wholesale_eur);

          if (product.category_name === 'DRESSES') {
            priceDresses +=
              size.quantity *
              (isUSD ? product.wholesale_usd : product.wholesale_eur);
          }
          if (product.category_name === 'BOTTOMS') {
            pricebottoms +=
              size.quantity *
              (isUSD ? product.wholesale_usd : product.wholesale_eur);
          }
          if (product.category_name === 'TOPS AND BLOUSES') {
            pricetops +=
              size.quantity *
              (isUSD ? product.wholesale_usd : product.wholesale_eur);
          }
          if (product.category_name === 'SKIRT') {
            priceskirts +=
              size.quantity *
              (isUSD ? product.wholesale_usd : product.wholesale_eur);
          }
          if (product.category_name === 'OUTERWEAR') {
            priceouterwear +=
              size.quantity *
              (isUSD ? product.wholesale_usd : product.wholesale_eur);
          }
          if (product.category_name === 'KNITWEAR') {
            pricesweaters +=
              size.quantity *
              (isUSD ? product.wholesale_usd : product.wholesale_eur);
          }
          return aux;
        });
        if (
          product.category_name === 'DRESSES' &&
          product.cartInfo.some(item => item.quantity > 0)
        ) {
          qtdDresses += 1;
        }
        if (product.category_name === 'BOTTOMS') {
          qtdbottoms += 1;
        }
        if (product.category_name === 'SKIRT') {
          qtdskirts += 1;
        }
        if (product.category_name === 'TOPS AND BLOUSES') {
          qtdtops += 1;
        }
        if (product.category_name === 'OUTERWEAR') {
          qtdouterwear += 1;
        }
        if (product.category_name === 'KNITWEAR') {
          qtdsweaters += 1;
        }
      });
      setD1dresses({ qtd: qtdDresses, price: priceDresses });
      setD1skirts({ qtd: qtdskirts, price: priceskirts });
      setD1bottoms({ qtd: qtdbottoms, price: pricebottoms });

      setD1tops({ qtd: qtdtops, price: pricetops });
      setD1sweaters({ qtd: qtdsweaters, price: pricesweaters });
      setD1outerwear({ qtd: qtdouterwear, price: priceouterwear });
      setTotald1(aux);
    }, [d1]);

    React.useEffect(() => {
      let aux = 0;

      d4.map(product => {
        product.cartInfo.forEach(size => {
          aux +=
            size.quantity *
            (isUSD ? product.wholesale_usd : product.wholesale_eur);

          return aux;
        });
      });

      setTotalD4(aux);
    }, [d4]);

    React.useEffect(() => {
      let aux = 0;
      let qtdDresses = 0;
      let priceDresses = 0;
      let qtdbottoms = 0;
      let pricebottoms = 0;
      let qtdtops = 0;
      let pricetops = 0;
      let qtdsweaters = 0;
      let pricesweaters = 0;
      let qtdouterwear = 0;
      let priceouterwear = 0;
      let qtdskirts = 0;
      let priceskirts = 0;
      d2.map(product => {
        product.cartInfo.forEach(size => {
          aux +=
            size.quantity *
            (isUSD ? product.wholesale_usd : product.wholesale_eur);

          if (product.category_name === 'DRESSES') {
            priceDresses +=
              size.quantity *
              (isUSD ? product.wholesale_usd : product.wholesale_eur);
          }
          if (product.category_name === 'BOTTOMS') {
            pricebottoms +=
              size.quantity *
              (isUSD ? product.wholesale_usd : product.wholesale_eur);
          }
          if (product.category_name === 'TOPS AND BLOUSES') {
            pricetops +=
              size.quantity *
              (isUSD ? product.wholesale_usd : product.wholesale_eur);
          }
          if (product.category_name === 'SKIRT') {
            priceskirts +=
              size.quantity *
              (isUSD ? product.wholesale_usd : product.wholesale_eur);
          }
          if (product.category_name === 'OUTERWEAR') {
            priceouterwear +=
              size.quantity *
              (isUSD ? product.wholesale_usd : product.wholesale_eur);
          }
          if (product.category_name === 'KNITWEAR') {
            pricesweaters +=
              size.quantity *
              (isUSD ? product.wholesale_usd : product.wholesale_eur);
          }
          return aux;
        });
        if (product.category_name === 'DRESSES') {
          qtdDresses += 1;
        }
        if (product.category_name === 'BOTTOMS') {
          qtdbottoms += 1;
        }
        if (product.category_name === 'SKIRT') {
          qtdskirts += 1;
        }
        if (product.category_name === 'TOPS AND BLOUSES') {
          qtdtops += 1;
        }
        if (product.category_name === 'OUTERWEAR') {
          qtdouterwear += 1;
        }
        if (product.category_name === 'KNITWEAR') {
          qtdsweaters += 1;
        }
      });
      setD2dresses({ qtd: qtdDresses, price: priceDresses });
      setD2skirts({ qtd: qtdskirts, price: priceskirts });
      setD2bottoms({ qtd: qtdbottoms, price: pricebottoms });

      setD2tops({ qtd: qtdtops, price: pricetops });
      setD2sweaters({ qtd: qtdsweaters, price: pricesweaters });
      setD2outerwear({ qtd: qtdouterwear, price: priceouterwear });
      setTotald2(aux);
    }, [d2]);

    React.useEffect(() => {
      let aux = 0;
      let qtdDresses = 0;
      let priceDresses = 0;
      let qtdbottoms = 0;
      let pricebottoms = 0;
      let qtdtops = 0;
      let pricetops = 0;
      let qtdsweaters = 0;
      let pricesweaters = 0;
      let qtdouterwear = 0;
      let priceouterwear = 0;
      let qtdskirts = 0;
      let priceskirts = 0;
      d3.map(product => {
        product.cartInfo.forEach(size => {
          aux +=
            size.quantity *
            (isUSD ? product.wholesale_usd : product.wholesale_eur);
          if (product.category_name === 'DRESSES') {
            priceDresses +=
              size.quantity *
              (isUSD ? product.wholesale_usd : product.wholesale_eur);
          }
          if (product.category_name === 'BOTTOMS') {
            pricebottoms +=
              size.quantity *
              (isUSD ? product.wholesale_usd : product.wholesale_eur);
          }
          if (product.category_name === 'TOPS AND BLOUSES') {
            pricetops +=
              size.quantity *
              (isUSD ? product.wholesale_usd : product.wholesale_eur);
          }
          if (product.category_name === 'SKIRT') {
            priceskirts +=
              size.quantity *
              (isUSD ? product.wholesale_usd : product.wholesale_eur);
          }
          if (product.category_name === 'OUTERWEAR') {
            priceouterwear +=
              size.quantity *
              (isUSD ? product.wholesale_usd : product.wholesale_eur);
          }
          if (product.category_name === 'KNITWEAR') {
            pricesweaters +=
              size.quantity *
              (isUSD ? product.wholesale_usd : product.wholesale_eur);
          }
          return aux;
        });
        if (product.category_name === 'DRESSES') {
          qtdDresses += 1;
        }
        if (product.category_name === 'BOTTOMS') {
          qtdbottoms += 1;
        }
        if (product.category_name === 'SKIRT') {
          qtdskirts += 1;
        }
        if (product.category_name === 'TOPS AND BLOUSES') {
          qtdtops += 1;
        }
        if (product.category_name === 'OUTERWEAR') {
          qtdouterwear += 1;
        }
        if (product.category_name === 'KNITWEAR') {
          qtdsweaters += 1;
        }
      });
      setD3dresses({ qtd: qtdDresses, price: priceDresses });
      setD3skirts({ qtd: qtdskirts, price: priceskirts });
      setD3bottoms({ qtd: qtdbottoms, price: pricebottoms });

      setD3tops({ qtd: qtdtops, price: pricetops });
      setD3sweaters({ qtd: qtdsweaters, price: pricesweaters });
      setD3outerwear({ qtd: qtdouterwear, price: priceouterwear });
      setTotald3(aux);
    }, [d3]);

    const handleUpdate = async () => {
      const data = new FormData();
      data.append('email', user?.email);
      if (isPayable) {
        data.append('payName', modalName);
        data.append('payPhone', modalPhone);
        data.append('payEmail', modalEmail);
      } else {
        if (modalEmail !== user?.email) {
          data.append('newEmail', modalEmail);
        }
        data.append('name', modalName);
        data.append('phone', modalPhone);
        data.append('shopName', modalShopName);
      }
      const response = await api.put('/newCostumers', data);
      if (response?.data?.msg) {
        return alert(response?.data?.msg);
      }

      setLoading(true);
    };

    return !show && isMobile ? null : (
      <Grid
        style={
          isMobile
            ? {
                position: 'fixed',
                right: '2%',
                bottom: 55,
                maxHeight: 550,
                zIndex: 5000,
                background: '#fff',
              }
            : {
                position: !isMobile && 'fixed',
                right: '2%',
                top: 250,
                maxHeight: 550,
                margin: isMobile && '20px 0px 0px',
                paddingBottom: isMobile && 50,
              }
        }
      >
        <Grid
          style={{
            width: isMobile ? wid * 0.96 : wid * 0.2,
            border: '2px solid #000000',
            padding: '21px 15px 17px',
          }}
        >
          <StyledDialogEdit
            BackdropProps={{ style: { borderRadius: 15 } }}
            open={openUpdateModal}
            onClose={() => {
              setOpenUpdateModal(false);
            }}
            style={{
              width: 350,
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 2000,
            }}
          >
            <Grid style={{ padding: '5%', width: 350 }}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography>
                  {!isPayable ? 'Account Information' : 'Account Payable'}
                </Typography>
                {(!orderSelected || orderSelected.status === 'Notes') && (
                  <IconButton onClick={() => setOpenUpdateModal(false)}>
                    <ClearIcon />
                  </IconButton>
                )}
              </Grid>
              {!isPayable && (
                <Input
                  field="Shop name"
                  width={320}
                  height={43}
                  marginTop={40}
                  value={modalShopName}
                  onChange={event => {
                    setModalShopName(event.target.value);
                    setErrorShopName('');
                  }}
                  error={errorShopName}
                  helperText={errorShopName}
                />
              )}

              <Input
                field="Name"
                width={320}
                height={43}
                marginTop={20}
                value={modalName}
                onChange={event => {
                  setModalName(event.target.value);
                  setErrorName('');
                }}
                error={errorName}
                helperText={errorName}
              />

              <Input
                field="Email"
                width={320}
                height={43}
                marginTop={40}
                value={modalEmail}
                onChange={event => {
                  setModalEmail(event.target.value);
                  setErrorEmail('');
                }}
                error={errorEmail}
                helperText={errorEmail}
              />
              <Input
                field="Phone"
                width={320}
                height={43}
                marginTop={40}
                value={modalPhone}
                onChange={event => {
                  setModalPhone(event.target.value);
                  setErrorPhone('');
                }}
                error={errorPhone}
                helperText={errorPhone}
              />

              <Grid
                container
                justifyContent="center"
                style={{ paddingBottom: 44 }}
              >
                <Button
                  justifyContent="center"
                  width={128}
                  height={40}
                  color="#000000"
                  textColor="#FFFFFF"
                  text="save"
                  marginTop={54}
                  fontSize={16}
                  onClick={handleUpdate}
                />
              </Grid>
            </Grid>
          </StyledDialogEdit>
          {nav === 'place' ? (
            <>
              {isMobile &&
                (!downloadExcelURL ? (
                  <Grid
                    container
                    justifyContent="center"
                    onClick={() => !loadingOrder && handleExcel()}
                    style={{
                      position: 'relative',
                      bottom: 55,
                      width: wid,
                      paddingRight: 30,
                    }}
                  >
                    {!loadingOrder ? (
                      <>
                        <Typography style={{ marginRight: 10 }} variant="body2">
                          Download Order
                        </Typography>
                        <AiOutlineDownload
                          size={16}
                          color="black"
                          style={{ marginTop: 2, cursor: 'pointer' }}
                        />
                        <span style={{ fontSize: 12 }}>
                          {' '}
                          <span
                            href={downloadExcelURL}
                            style={{
                              textDecoration: 'none',
                              color: 'black',
                              cursor: 'pointer',
                            }}
                            download={`FARM US - ${seasonName}.xlsx`}
                          >
                            XLS{' '}
                          </span>
                        </span>
                      </>
                    ) : (
                      <Typography
                        display="inline"
                        style={{ marginRight: 10 }}
                        variant="body2"
                      >
                        Preparing order...{' '}
                        <CircularProgress size={12} style={{ marginLeft: 5 }} />
                      </Typography>
                    )}
                  </Grid>
                ) : (
                  <Grid
                    container
                    justifyContent="center"
                    // onClick={handleExcel}
                    style={{
                      marginLeft: !isMobile && 20,
                      position: 'relative',
                    }}
                  >
                    <Typography style={{ marginRight: 10 }} variant="body2">
                      Excel Ready
                    </Typography>
                    <AiOutlineDownload
                      size={16}
                      color="black"
                      style={{ marginTop: 2, cursor: 'pointer' }}
                    />
                    <span style={{ fontSize: 12 }}>
                      {' '}
                      <a
                        href={downloadExcelURL}
                        style={{
                          textDecoration: 'none',
                          color: 'black',
                          cursor: 'pointer',
                        }}
                        download={`FARM US - ${seasonName}.xlsx`}
                      >
                        XLS{' '}
                      </a>
                    </span>
                  </Grid>
                ))}
              {window.innerHeight <= 750 &&
                !isMobile &&
                (!downloadExcelURL ? (
                  <Grid
                    container
                    justifyContent="center"
                    onClick={() => !loadingOrder && handleExcel()}
                    style={{
                      marginLeft: !isMobile && 20,
                      position: 'relative',
                      top: -55,
                      right: 23,
                      cursor: !loadingOrder && 'pointer',
                    }}
                  >
                    {!loadingOrder ? (
                      <>
                        <Typography style={{ marginRight: 10 }} variant="body2">
                          Download Order
                        </Typography>
                        <AiOutlineDownload
                          size={16}
                          color="black"
                          style={{ marginTop: 2, cursor: 'pointer' }}
                        />
                        <span style={{ fontSize: 12 }}>
                          {' '}
                          <span
                            href={downloadExcelURL}
                            style={{
                              textDecoration: 'none',
                              color: 'black',
                              cursor: 'pointer',
                            }}
                            download={`FARM US - ${seasonName}.xlsx`}
                          >
                            XLS{' '}
                          </span>
                        </span>
                      </>
                    ) : (
                      <Typography
                        display="inline"
                        style={{ marginRight: 10 }}
                        variant="body2"
                      >
                        Preparing order...{' '}
                        <CircularProgress size={12} style={{ marginLeft: 5 }} />
                      </Typography>
                    )}
                  </Grid>
                ) : (
                  <Grid
                    container
                    justifyContent="center"
                    // onClick={handleExcel}
                    style={{
                      marginLeft: !isMobile && 20,
                      position: 'relative',
                      top: 55,
                      right: 23,
                    }}
                  >
                    <Typography style={{ marginRight: 10 }} variant="body2">
                      Excel Ready
                    </Typography>
                    <AiOutlineDownload
                      size={16}
                      color="black"
                      style={{ marginTop: 2, cursor: 'pointer' }}
                    />
                    <span style={{ fontSize: 12 }}>
                      {' '}
                      <a
                        href={downloadExcelURL}
                        style={{
                          textDecoration: 'none',
                          color: 'black',
                          cursor: 'pointer',
                        }}
                        download={`FARM US - ${seasonName}.xlsx`}
                      >
                        XLS{' '}
                      </a>
                    </span>
                  </Grid>
                ))}

              <Grid container justifyContent="space-between">
                <Typography style={{ fontSize: 18 }}>Order Summary</Typography>
                <Grid>
                  <Typography
                    display="inline"
                    onClick={() => setisUSD(true)}
                    style={{
                      paddingBottom: 3,
                      marginRight: 20,
                      borderBottom: isUSD && '2px solid #000000',
                      cursor: 'pointer',
                      fontSize: 12,
                      fontWeight: isUSD && 'bold',
                    }}
                  >
                    USD
                  </Typography>
                  <Typography
                    display="inline"
                    onClick={() => setisUSD(false)}
                    style={{
                      paddingBottom: 3,
                      marginRight: 20,
                      borderBottom: !isUSD && '2px solid #000000',
                      cursor: 'pointer',
                      fontSize: 12,
                      fontWeight: !isUSD && 'bold',
                    }}
                  >
                    EUR
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                style={{
                  borderTop: '1px solid #000000',
                  borderBottom: '1px solid #000000',
                  marginTop: 18,
                }}
              >
                {d1.length > 0 && (
                  <Accordion
                    square
                    expanded={expanded.includes('panel1')}
                    onChange={handleChange('panel1')}
                  >
                    <AccordionSummary
                      aria-controls="panel1d-content"
                      id="panel1d-header"
                      style={{
                        boxShadow: 'null',
                        padding: 0,
                        border: 0,
                        height: 50,
                      }}
                    >
                      <Grid
                        container
                        justifyContent="space-between"
                        style={{ margin: '10px 0px' }}
                      >
                        <Grid>
                          <Grid
                            container
                            alignItems="center"
                            style={{ width: 100 }}
                          >
                            <Typography
                              style={{
                                fontSize: 12,
                                fontWeight: 'bold',
                              }}
                            >
                              Delivery 1
                            </Typography>
                            <Grid style={{ position: 'relative', top: 4 }}>
                              {expanded === 'panel1' ? (
                                <ArrowDropUpIcon />
                              ) : (
                                <ArrowDropDownIcon />
                              )}
                            </Grid>
                          </Grid>
                        </Grid>

                        <Typography style={{ fontSize: 12 }}>
                          {d1.length} Styles
                        </Typography>
                        <Typography
                          align="right"
                          style={{
                            fontSize: 12,
                            fontWeight: 'bold',
                            minWidth: 100,
                          }}
                        >
                          {isUSD ? <span>$</span> : <span>&euro;</span>}{' '}
                          {totalD1}
                        </Typography>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails
                      style={{
                        padding: 0,
                        marginLeft: 15,
                        position: 'relative',
                      }}
                    >
                      <Grid container>
                        {d1bottoms?.qtd > 0 && (
                          <Grid
                            container
                            justifyContent="space-between"
                            style={{ margin: '3px 0px' }}
                          >
                            <Grid>
                              <Grid container alignItems="center">
                                <Typography
                                  style={{
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                    width: 100,
                                  }}
                                >
                                  Bottoms
                                </Typography>
                              </Grid>
                            </Grid>

                            <Typography style={{ fontSize: 12 }}>
                              {d1bottoms.qtd} Styles
                            </Typography>
                            <Typography
                              align="right"
                              style={{
                                fontSize: 12,
                                fontWeight: 'bold',
                                minWidth: 100,
                              }}
                            >
                              {isUSD ? <span>$</span> : <span>&euro;</span>}{' '}
                              {d1bottoms?.price}
                            </Typography>
                          </Grid>
                        )}
                        {d1dresses?.qtd > 0 && (
                          <Grid
                            container
                            justifyContent="space-between"
                            style={{ margin: '3px 0px' }}
                          >
                            <Grid>
                              <Grid container alignItems="center">
                                <Typography
                                  style={{
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                    width: 100,
                                  }}
                                >
                                  Dresses
                                </Typography>
                              </Grid>
                            </Grid>

                            <Typography style={{ fontSize: 12 }}>
                              {d1dresses.qtd} Styles
                            </Typography>
                            <Typography
                              align="right"
                              style={{
                                fontSize: 12,
                                fontWeight: 'bold',
                                minWidth: 100,
                              }}
                            >
                              {isUSD ? <span>$</span> : <span>&euro;</span>}{' '}
                              {d1dresses?.price}
                            </Typography>
                          </Grid>
                        )}
                        {d1skirts?.qtd > 0 && (
                          <Grid
                            container
                            justifyContent="space-between"
                            style={{ margin: '3px 0px' }}
                          >
                            <Grid>
                              <Grid container alignItems="center">
                                <Typography
                                  style={{
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                    width: 100,
                                  }}
                                >
                                  Skirts
                                </Typography>
                              </Grid>
                            </Grid>

                            <Typography style={{ fontSize: 12 }}>
                              {d1skirts.qtd} Styles
                            </Typography>
                            <Typography
                              align="right"
                              style={{
                                fontSize: 12,
                                fontWeight: 'bold',
                                minWidth: 100,
                              }}
                            >
                              {isUSD ? <span>$</span> : <span>&euro;</span>}{' '}
                              {d1skirts?.price}
                            </Typography>
                          </Grid>
                        )}
                        {d1outerwear?.qtd > 0 && (
                          <Grid
                            container
                            justifyContent="space-between"
                            style={{ margin: '3px 0px' }}
                          >
                            <Grid>
                              <Grid container alignItems="center">
                                <Typography
                                  style={{
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                    width: 100,
                                  }}
                                >
                                  Outerwear
                                </Typography>
                              </Grid>
                            </Grid>

                            <Typography style={{ fontSize: 12 }}>
                              {d1outerwear.qtd} Styles
                            </Typography>
                            <Typography
                              align="right"
                              style={{
                                fontSize: 12,
                                fontWeight: 'bold',
                                minWidth: 100,
                              }}
                            >
                              {isUSD ? <span>$</span> : <span>&euro;</span>}{' '}
                              {d1outerwear?.price}
                            </Typography>
                          </Grid>
                        )}
                        {d1sweaters?.qtd > 0 && (
                          <Grid
                            container
                            justifyContent="space-between"
                            style={{ margin: '3px 0px' }}
                          >
                            <Grid>
                              <Grid container alignItems="center">
                                <Typography
                                  style={{
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                    width: 100,
                                  }}
                                >
                                  Sweaters
                                </Typography>
                              </Grid>
                            </Grid>

                            <Typography style={{ fontSize: 12 }}>
                              {d1sweaters.qtd} Styles
                            </Typography>
                            <Typography
                              align="right"
                              style={{
                                fontSize: 12,
                                fontWeight: 'bold',
                                minWidth: 100,
                              }}
                            >
                              {isUSD ? <span>$</span> : <span>&euro;</span>}{' '}
                              {d1sweaters?.price}
                            </Typography>
                          </Grid>
                        )}
                        {d1tops?.qtd > 0 && (
                          <Grid
                            container
                            justifyContent="space-between"
                            style={{ margin: '3px 0px' }}
                          >
                            <Grid>
                              <Grid container alignItems="center">
                                <Typography
                                  style={{
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                    width: 100,
                                  }}
                                >
                                  Tops & Blouses
                                </Typography>
                              </Grid>
                            </Grid>

                            <Typography style={{ fontSize: 12 }}>
                              {d1tops.qtd} Styles
                            </Typography>
                            <Typography
                              align="right"
                              style={{
                                fontSize: 12,
                                fontWeight: 'bold',
                                minWidth: 100,
                              }}
                            >
                              {isUSD ? <span>$</span> : <span>&euro;</span>}{' '}
                              {d1tops?.price}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                )}
                {d2.length > 0 && (
                  <Accordion
                    square
                    expanded={expanded.includes('panel2')}
                    onChange={handleChange('panel2')}
                    style={{ boxShadow: 'none' }}
                  >
                    <AccordionSummary
                      aria-controls="panel2d-content"
                      id="panel2d-header"
                      style={{ boxShadow: 'null', padding: 0 }}
                    >
                      <Grid
                        container
                        justifyContent="space-between"
                        style={{ margin: '10px 0px' }}
                      >
                        <Grid>
                          <Grid
                            container
                            alignItems="center"
                            style={{ width: 100 }}
                          >
                            <Typography
                              style={{
                                fontSize: 12,
                                fontWeight: 'bold',
                              }}
                            >
                              Delivery 2
                            </Typography>
                            <Grid style={{ position: 'relative', top: 4 }}>
                              {expanded === 'panel2' ? (
                                <ArrowDropUpIcon />
                              ) : (
                                <ArrowDropDownIcon />
                              )}
                            </Grid>
                          </Grid>
                        </Grid>

                        <Typography style={{ fontSize: 12 }}>
                          {d2.length} Styles
                        </Typography>
                        <Typography
                          align="right"
                          style={{
                            fontSize: 12,
                            fontWeight: 'bold',
                            minWidth: 100,
                          }}
                        >
                          {isUSD ? <span>$</span> : <span>&euro;</span>}{' '}
                          {totalD2}
                        </Typography>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails
                      style={{
                        padding: 0,
                        marginLeft: 15,
                        position: 'relative',
                      }}
                    >
                      <Grid container>
                        {d2bottoms?.qtd > 0 && (
                          <Grid
                            container
                            justifyContent="space-between"
                            style={{ margin: '3px 0px' }}
                          >
                            <Grid>
                              <Grid container alignItems="center">
                                <Typography
                                  style={{
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                    width: 100,
                                  }}
                                >
                                  Bottoms
                                </Typography>
                              </Grid>
                            </Grid>

                            <Typography style={{ fontSize: 12 }}>
                              {d2bottoms.qtd} Styles
                            </Typography>
                            <Typography
                              align="right"
                              style={{
                                fontSize: 12,
                                fontWeight: 'bold',
                                minWidth: 100,
                              }}
                            >
                              {isUSD ? <span>$</span> : <span>&euro;</span>}{' '}
                              {d2bottoms?.price}
                            </Typography>
                          </Grid>
                        )}
                        {d2dresses?.qtd > 0 && (
                          <Grid
                            container
                            justifyContent="space-between"
                            style={{ margin: '3px 0px' }}
                          >
                            <Grid>
                              <Grid container alignItems="center">
                                <Typography
                                  style={{
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                    width: 100,
                                  }}
                                >
                                  Dresses
                                </Typography>
                              </Grid>
                            </Grid>

                            <Typography style={{ fontSize: 12 }}>
                              {d2dresses.qtd} Styles
                            </Typography>
                            <Typography
                              align="right"
                              style={{
                                fontSize: 12,
                                fontWeight: 'bold',
                                minWidth: 100,
                              }}
                            >
                              {isUSD ? <span>$</span> : <span>&euro;</span>}{' '}
                              {d2dresses?.price}
                            </Typography>
                          </Grid>
                        )}
                        {d2skirts?.qtd > 0 && (
                          <Grid
                            container
                            justifyContent="space-between"
                            style={{ margin: '3px 0px' }}
                          >
                            <Grid>
                              <Grid container alignItems="center">
                                <Typography
                                  style={{
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                    width: 100,
                                  }}
                                >
                                  Skirts
                                </Typography>
                              </Grid>
                            </Grid>

                            <Typography style={{ fontSize: 12 }}>
                              {d2skirts.qtd} Styles
                            </Typography>
                            <Typography
                              align="right"
                              style={{
                                fontSize: 12,
                                fontWeight: 'bold',
                                minWidth: 100,
                              }}
                            >
                              {isUSD ? <span>$</span> : <span>&euro;</span>}{' '}
                              {d2skirts?.price}
                            </Typography>
                          </Grid>
                        )}
                        {d2outerwear?.qtd > 0 && (
                          <Grid
                            container
                            justifyContent="space-between"
                            style={{ margin: '3px 0px' }}
                          >
                            <Grid>
                              <Grid container alignItems="center">
                                <Typography
                                  style={{
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                    width: 100,
                                  }}
                                >
                                  Outerwear
                                </Typography>
                              </Grid>
                            </Grid>

                            <Typography style={{ fontSize: 12 }}>
                              {d2outerwear.qtd} Styles
                            </Typography>
                            <Typography
                              align="right"
                              style={{
                                fontSize: 12,
                                fontWeight: 'bold',
                                minWidth: 100,
                              }}
                            >
                              {isUSD ? <span>$</span> : <span>&euro;</span>}{' '}
                              {d2outerwear?.price}
                            </Typography>
                          </Grid>
                        )}
                        {d2sweaters?.qtd > 0 && (
                          <Grid
                            container
                            justifyContent="space-between"
                            style={{ margin: '3px 0px' }}
                          >
                            <Grid>
                              <Grid container alignItems="center">
                                <Typography
                                  style={{
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                    width: 100,
                                  }}
                                >
                                  Sweaters
                                </Typography>
                              </Grid>
                            </Grid>

                            <Typography style={{ fontSize: 12 }}>
                              {d2sweaters.qtd} Styles
                            </Typography>
                            <Typography
                              align="right"
                              style={{
                                fontSize: 12,
                                fontWeight: 'bold',
                                minWidth: 100,
                              }}
                            >
                              {isUSD ? <span>$</span> : <span>&euro;</span>}{' '}
                              {d2sweaters?.price}
                            </Typography>
                          </Grid>
                        )}
                        {d2tops?.qtd > 0 && (
                          <Grid
                            container
                            justifyContent="space-between"
                            style={{ margin: '3px 0px' }}
                          >
                            <Grid>
                              <Grid container alignItems="center">
                                <Typography
                                  style={{
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                    width: 100,
                                  }}
                                >
                                  Tops & Blouses
                                </Typography>
                              </Grid>
                            </Grid>

                            <Typography style={{ fontSize: 12 }}>
                              {d2tops.qtd} Styles
                            </Typography>
                            <Typography
                              align="right"
                              style={{
                                fontSize: 12,
                                fontWeight: 'bold',
                                minWidth: 100,
                              }}
                            >
                              {isUSD ? <span>$</span> : <span>&euro;</span>}{' '}
                              {d2tops?.price}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                )}
                {d3.length > 0 && (
                  <Accordion
                    square
                    expanded={expanded.includes('panel3')}
                    onChange={handleChange('panel3')}
                    style={{ boxShadow: 'none' }}
                  >
                    <AccordionSummary
                      aria-controls="panel3d-content"
                      id="panel3d-header"
                      style={{ boxShadow: 'null', padding: 0 }}
                    >
                      <Grid
                        container
                        justifyContent="space-between"
                        style={{ margin: '10px 0px' }}
                      >
                        <Grid>
                          <Grid
                            container
                            alignItems="center"
                            style={{ width: 100 }}
                          >
                            <Typography
                              style={{
                                fontSize: 12,
                                fontWeight: 'bold',
                              }}
                            >
                              Delivery 3
                            </Typography>
                            <Grid style={{ position: 'relative', top: 4 }}>
                              {expanded === 'panel3' ? (
                                <ArrowDropUpIcon />
                              ) : (
                                <ArrowDropDownIcon />
                              )}
                            </Grid>
                          </Grid>
                        </Grid>

                        <Typography style={{ fontSize: 12 }}>
                          {d3.length} Styles
                        </Typography>
                        <Typography
                          align="right"
                          style={{
                            fontSize: 12,
                            fontWeight: 'bold',
                            minWidth: 100,
                          }}
                        >
                          {isUSD ? <span>$</span> : <span>&euro;</span>}{' '}
                          {totalD3}
                        </Typography>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails
                      style={{
                        padding: 0,
                        marginLeft: 15,
                        position: 'relative',
                      }}
                    >
                      <Grid container>
                        {d3bottoms?.qtd > 0 && (
                          <Grid
                            container
                            justifyContent="space-between"
                            style={{ margin: '3px 0px' }}
                          >
                            <Grid>
                              <Grid container alignItems="center">
                                <Typography
                                  style={{
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                    width: 100,
                                  }}
                                >
                                  Bottoms
                                </Typography>
                              </Grid>
                            </Grid>

                            <Typography style={{ fontSize: 12 }}>
                              {d3bottoms.qtd} Styles
                            </Typography>
                            <Typography
                              align="right"
                              style={{
                                fontSize: 12,
                                fontWeight: 'bold',
                                minWidth: 100,
                              }}
                            >
                              {isUSD ? <span>$</span> : <span>&euro;</span>}{' '}
                              {d3bottoms?.price}
                            </Typography>
                          </Grid>
                        )}
                        {d3dresses?.qtd > 0 && (
                          <Grid
                            container
                            justifyContent="space-between"
                            style={{ margin: '3px 0px' }}
                          >
                            <Grid>
                              <Grid container alignItems="center">
                                <Typography
                                  style={{
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                    width: 100,
                                  }}
                                >
                                  Dresses
                                </Typography>
                              </Grid>
                            </Grid>

                            <Typography style={{ fontSize: 12 }}>
                              {d3dresses.qtd} Styles
                            </Typography>
                            <Typography
                              align="right"
                              style={{
                                fontSize: 12,
                                fontWeight: 'bold',
                                minWidth: 100,
                              }}
                            >
                              {isUSD ? <span>$</span> : <span>&euro;</span>}{' '}
                              {d3dresses?.price}
                            </Typography>
                          </Grid>
                        )}
                        {d3skirts?.qtd > 0 && (
                          <Grid
                            container
                            justifyContent="space-between"
                            style={{ margin: '3px 0px' }}
                          >
                            <Grid>
                              <Grid container alignItems="center">
                                <Typography
                                  style={{
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                    width: 100,
                                  }}
                                >
                                  Skirts
                                </Typography>
                              </Grid>
                            </Grid>

                            <Typography style={{ fontSize: 12 }}>
                              {d3skirts.qtd} Styles
                            </Typography>
                            <Typography
                              align="right"
                              style={{
                                fontSize: 12,
                                fontWeight: 'bold',
                                minWidth: 100,
                              }}
                            >
                              {isUSD ? <span>$</span> : <span>&euro;</span>}{' '}
                              {d3skirts?.price}
                            </Typography>
                          </Grid>
                        )}
                        {d3outerwear?.qtd > 0 && (
                          <Grid
                            container
                            justifyContent="space-between"
                            style={{ margin: '3px 0px' }}
                          >
                            <Grid>
                              <Grid container alignItems="center">
                                <Typography
                                  style={{
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                    width: 100,
                                  }}
                                >
                                  Outerwear
                                </Typography>
                              </Grid>
                            </Grid>

                            <Typography style={{ fontSize: 12 }}>
                              {d3outerwear.qtd} Styles
                            </Typography>
                            <Typography
                              align="right"
                              style={{
                                fontSize: 12,
                                fontWeight: 'bold',
                                minWidth: 100,
                              }}
                            >
                              {isUSD ? <span>$</span> : <span>&euro;</span>}{' '}
                              {d3outerwear?.price}
                            </Typography>
                          </Grid>
                        )}
                        {d3sweaters?.qtd > 0 && (
                          <Grid
                            container
                            justifyContent="space-between"
                            style={{ margin: '3px 0px' }}
                          >
                            <Grid>
                              <Grid container alignItems="center">
                                <Typography
                                  style={{
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                    width: 100,
                                  }}
                                >
                                  Sweaters
                                </Typography>
                              </Grid>
                            </Grid>

                            <Typography style={{ fontSize: 12 }}>
                              {d3sweaters.qtd} Styles
                            </Typography>
                            <Typography
                              align="right"
                              style={{
                                fontSize: 12,
                                fontWeight: 'bold',
                                minWidth: 100,
                              }}
                            >
                              {isUSD ? <span>$</span> : <span>&euro;</span>}{' '}
                              {d3sweaters?.price}
                            </Typography>
                          </Grid>
                        )}
                        {d3tops?.qtd > 0 && (
                          <Grid
                            container
                            justifyContent="space-between"
                            style={{ margin: '3px 0px 10px' }}
                          >
                            <Grid>
                              <Grid container alignItems="center">
                                <Typography
                                  style={{
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                    width: 100,
                                  }}
                                >
                                  Tops & Blouses
                                </Typography>
                              </Grid>
                            </Grid>

                            <Typography style={{ fontSize: 12 }}>
                              {d3tops.qtd} Styles
                            </Typography>
                            <Typography
                              align="right"
                              style={{
                                fontSize: 12,
                                fontWeight: 'bold',
                                minWidth: 100,
                              }}
                            >
                              {isUSD ? <span>$</span> : <span>&euro;</span>}{' '}
                              {d3tops?.price}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                )}
                {d4.length > 0 && (
                  <Grid
                    container
                    justifyContent="space-between"
                    style={{ margin: '10px 0px' }}
                  >
                    <Grid>
                      <Grid
                        container
                        alignItems="center"
                        style={{ width: 100 }}
                      >
                        <Typography
                          style={{
                            fontSize: 12,
                            fontWeight: 'bold',
                          }}
                        >
                          Ski Capsule
                        </Typography>
                      </Grid>
                    </Grid>

                    <Typography style={{ fontSize: 12 }}>
                      {d4.length} Styles
                    </Typography>
                    <Typography
                      align="right"
                      style={{
                        fontSize: 12,
                        fontWeight: 'bold',
                        minWidth: 100,
                      }}
                    >
                      {isUSD ? <span>$</span> : <span>&euro;</span>} {totalD1}
                    </Typography>
                  </Grid>
                )}
              </Grid>
              <Grid
                container
                justifyContent="space-between"
                style={{ marginTop: 15 }}
              >
                <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>
                  Total
                </Typography>
                <Typography style={{ fontSize: 14 }}>
                  {d1?.length + d2?.length + d3?.length + d4?.length} styles
                </Typography>
                <Typography
                  style={{
                    fontSize: 16,
                    fontWeight: 'bold',
                  }}
                >
                  {isUSD ? <span>$</span> : <span>&euro;</span>}{' '}
                  {totalD1 + totalD2 + totalD3 + totalD4}
                </Typography>
              </Grid>

              {window.innerHeight > 750 &&
                !isMobile &&
                (!downloadExcelURL ? (
                  <Grid
                    container
                    justifyContent="center"
                    onClick={() => !loadingOrder && handleExcel()}
                    style={{
                      marginLeft: !isMobile && 20,
                      position: 'relative',
                      top: 55,
                      right: 23,
                      cursor: !loadingOrder && 'pointer',
                    }}
                  >
                    {!loadingOrder ? (
                      <>
                        <Typography style={{ marginRight: 10 }} variant="body2">
                          Download Order
                        </Typography>
                        <AiOutlineDownload
                          size={16}
                          color="black"
                          style={{ marginTop: 2, cursor: 'pointer' }}
                        />
                        <span style={{ fontSize: 12 }}>
                          {' '}
                          <span
                            href={downloadExcelURL}
                            style={{
                              textDecoration: 'none',
                              color: 'black',
                              cursor: 'pointer',
                            }}
                            download={`FARM US - ${seasonName}.xlsx`}
                          >
                            XLS{' '}
                          </span>
                        </span>
                      </>
                    ) : (
                      <Typography
                        display="inline"
                        style={{ marginRight: 10 }}
                        variant="body2"
                      >
                        Preparing order...{' '}
                        <CircularProgress size={12} style={{ marginLeft: 5 }} />
                      </Typography>
                    )}
                  </Grid>
                ) : (
                  <Grid
                    container
                    justifyContent="center"
                    // onClick={handleExcel}
                    style={{
                      marginLeft: !isMobile && 20,
                      position: 'relative',
                      top: 55,
                      right: 23,
                    }}
                  >
                    <Typography style={{ marginRight: 10 }} variant="body2">
                      Excel Ready
                    </Typography>
                    <AiOutlineDownload
                      size={16}
                      color="black"
                      style={{ marginTop: 2, cursor: 'pointer' }}
                    />
                    <span style={{ fontSize: 12 }}>
                      {' '}
                      <a
                        href={downloadExcelURL}
                        style={{
                          textDecoration: 'none',
                          color: 'black',
                          cursor: 'pointer',
                        }}
                        download={`FARM US - ${seasonName}.xlsx`}
                      >
                        XLS{' '}
                      </a>
                    </span>
                  </Grid>
                ))}
            </>
          ) : (
            nav === 'review' && (
              <Grid style={{ maxHeight: 500, overflow: 'auto' }}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  style={{
                    paddingBottom: 12,
                    borderBottom: '1px solid #000',
                    marginBottom: 14,
                  }}
                >
                  <Typography style={{ fontSize: 18 }}>
                    Account Information
                  </Typography>
                  {(!orderSelected || orderSelected.status === 'Notes') && (
                    <Grid
                      onClick={() => {
                        setIsPayable(false);
                        setOpenUpdateModal(true);
                      }}
                    >
                      <Typography
                        align="right"
                        // onClick={() => setisUSD(true)}
                        style={{
                          cursor: 'pointer',
                          fontSize: 12,
                        }}
                      >
                        Edit
                      </Typography>
                    </Grid>
                  )}
                </Grid>
                <Typography style={{ fontSize: 12, lineHeight: 2 }}>
                  {user?.shopName}
                </Typography>
                <Typography style={{ fontSize: 12, lineHeight: 2 }}>
                  {user?.name}
                </Typography>
                <Typography style={{ fontSize: 12, lineHeight: 2 }}>
                  {user?.email}
                </Typography>
                <Typography style={{ fontSize: 12, lineHeight: 2 }}>
                  {user?.phone}
                </Typography>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  style={{
                    paddingBottom: 12,
                    borderBottom: '1px solid #000',
                    marginTop: 17,
                    marginBottom: 14,
                  }}
                  onClick={() => setNav('ship')}
                >
                  <Typography style={{ fontSize: 18 }}>
                    Shipping Address
                  </Typography>
                  {(!orderSelected || orderSelected.status === 'Notes') && (
                    <Grid>
                      <Typography
                        align="right"
                        // onClick={() => setisUSD(true)}
                        style={{
                          cursor: 'pointer',
                          fontSize: 12,
                        }}
                      >
                        Edit
                      </Typography>
                    </Grid>
                  )}
                </Grid>
                <Typography style={{ fontSize: 12, lineHeight: 2 }}>
                  {shipAdd[selectedShipAddress]?.sLine1}
                </Typography>
                <Typography style={{ fontSize: 12, lineHeight: 2 }}>
                  {shipAdd[selectedShipAddress]?.sLine2}
                  {' - '}
                  {shipAdd[selectedShipAddress]?.sLine3}
                </Typography>
                <Typography style={{ fontSize: 12, lineHeight: 2 }}>
                  {shipAdd[selectedShipAddress]?.sZipcode}
                  {' - '}
                  {shipAdd[selectedShipAddress]?.sCountry}
                </Typography>

                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  style={{
                    paddingBottom: 12,
                    borderBottom: '1px solid #000',
                    marginTop: 17,
                    marginBottom: 14,
                  }}
                  onClick={() => setNav('ship')}
                >
                  <Typography style={{ fontSize: 18 }}>
                    Billing Address
                  </Typography>
                  {(!orderSelected || orderSelected.status === 'Notes') && (
                    <Grid>
                      <Typography
                        align="right"
                        // onClick={() => setisUSD(true)}
                        style={{
                          cursor: 'pointer',
                          fontSize: 12,
                        }}
                      >
                        Edit
                      </Typography>
                    </Grid>
                  )}
                </Grid>
                <Typography style={{ fontSize: 12, lineHeight: 2 }}>
                  {user?.bAdress1}
                </Typography>
                <Typography style={{ fontSize: 12, lineHeight: 2 }}>
                  {user?.bAdress2}
                  {' - '} {user?.bAdress3}
                </Typography>
                <Typography style={{ fontSize: 12, lineHeight: 2 }}>
                  {user?.bZipcode}
                  {' - '} {user?.bCountry}
                </Typography>
                {(!orderSelected || orderSelected.status === 'Notes') && (
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    style={{
                      paddingBottom: 12,
                      borderBottom: '1px solid #000',
                      marginTop: 17,
                      marginBottom: 14,
                    }}
                  >
                    <Typography style={{ fontSize: 18 }}>
                      Accounts Payable
                    </Typography>
                    {(!orderSelected || orderSelected.status === 'Notes') && (
                      <Grid
                        onClick={() => {
                          setIsPayable(true);
                          setOpenUpdateModal(true);
                        }}
                      >
                        <Typography
                          align="right"
                          // onClick={() => setisUSD(true)}
                          style={{
                            cursor: 'pointer',
                            fontSize: 12,
                          }}
                        >
                          Edit
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                )}
                <Typography style={{ fontSize: 12, lineHeight: 2 }}>
                  {user?.payName}
                </Typography>
                <Typography style={{ fontSize: 12, lineHeight: 2 }}>
                  {user?.payEmail}
                </Typography>
                <Typography style={{ fontSize: 12, lineHeight: 2 }}>
                  {user?.payPhone}
                </Typography>
                <Input
                  field="Add comment"
                  width={isMobile ? wid * 0.85 : wid * 0.18}
                  height={127}
                  marginTop={20}
                  value={comment}
                  onChange={event => setComment(event.target.value)}
                  multiline
                  comment
                  rows={4}
                />
              </Grid>
            )
          )}
          {nav === 'review' && !isMobile && (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              style={{
                height: 40,
                width: wid * 0.18,
                marginTop: 22,
                background:
                  !orderSelected || orderSelected.status === 'Notes'
                    ? '#000'
                    : '#d3d3d3',
                cursor:
                  !orderSelected ||
                  (orderSelected.status === 'Notes' && 'pointer'),
              }}
              onClick={async () => {
                if (
                  loadingOrder ||
                  (orderSelected && orderSelected.status !== 'Notes')
                )
                  return;

                if (nav === 'review') {
                  if (shipAdd.length === 0)
                    return Snackbar.warning('Order must have shipping address');
                  setLoadingOrder(true);
                  if (!loadingOrder) {
                    await submitOrder({
                      cartInfo: myProducts,
                      userInfo: {
                        email: user.email,
                        sAddress: shipAdd[selectedShipAddress],
                      },
                    });
                    setLoadingOrder(false);
                  }
                  // setDownloadExcelURL(excelUrl);
                }
                setNav('finish');
              }}
            >
              <Typography style={{ fontSize: 14, color: '#fff' }}>
                {loadingOrder ? (
                  <CircularProgress size={20} style={{ color: '#fafafa' }} />
                ) : (
                  'Submit Order'
                )}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  };

  const handleExcel = async () => {
    setLoadingOrder(true);
    const excelUrl = await api.post('/orderExcel', {
      arrayOfProducts: myProducts,
      email: user.email,
    });
    setDownloadExcelURL(excelUrl.data);
    setLoadingOrder(false);
  };

  const handleCheckCartInfo = () => {
    const pos = [];
    myProducts.forEach(product => {
      if (
        product.cartInfo.length === 0 ||
        !product.cartInfo.find(obj => obj.quantity !== 0)
      ) {
        pos.push(product.style_code);
      }
    });
    return pos;
  };

  const Navbar = ({ setEmptyProducts }) => {
    const handleNext = async () => {
      if (loadingOrder) return;
      if (nav === 'ship') {
        if (shipAdd.length === 0)
          return Snackbar.warning('Order must have shipping address');
        setLoadingOrder(true);
        if (!loadingOrder) {
          const existingOrder = await checkOrder({
            userInfo: {
              email: user.email,
              sAddress: shipAdd[selectedShipAddress],
            },
          });
          if (existingOrder) {
            setCurrentOrder(existingOrder);
            setLoadingOrder(false);
            return setOpenOrderAlreadyExist(true);
          }
          await submitOrder({
            cartInfo: myProducts,
            userInfo: {
              email: user.email,
              sAddress: shipAdd[selectedShipAddress],
            },
          });
          // setDownloadExcelURL(excelUrl);
          setLoadingOrder(false);
          return setNav('place');
        }
      }
      if (nav === 'review') {
        const empty = handleCheckCartInfo();
        if (empty.length > 0) {
          setOpenWarningEmptyProducts(true);
          return setEmptyProducts(empty);
        }

        setLoadingOrder(true);
        if (!loadingOrder) {
          await submitOrder({
            cartInfo: myProducts,
            userInfo: {
              email: user.email,
              sAddress: shipAdd[selectedShipAddress],
            },
            status: 'Pending',
          });
          // setDownloadExcelURL(excelUrl);
          setLoadingOrder(false);
        }
      }
      setNav(nav === 'ship' ? 'place' : nav === 'place' ? 'review' : 'finish');
    };
    return (
      <>
        <Grid container justifyContent="space-between">
          <MenuDrawer />

          <Img
            src={logo}
            style={{
              width: isMobile ? 150 : 200,
              padding: '10px 0px 20px',
            }}
            alt="logoFarm"
          />
          <Grid style={{ width: 48 }} />
        </Grid>
        <Grid
          container
          justifyContent="center"
          style={{ margin: isMobile ? '-8px 0px 10px' : '-8px 0px 30px' }}
        >
          <Grid>
            <Typography
              align="center"
              style={{
                fontSize: isMobile ? 16 : 27,
                letterSpacing: '0.1em',
                fontFamily: 'Galano Grotesque Light',
              }}
            >
              {profile?.name &&
                `${profile?.name?.toUpperCase()?.split(' ')[0]}'S `}
              <Typography
                display="inline"
                style={{
                  fontWeight: 'bold',
                  fontSize: isMobile ? 16 : 27,
                  letterSpacing: '0.1em',
                  fontFamily: 'Galano Grotesque Light',
                }}
              >
                {seasonName}
              </Typography>{' '}
              ORDER
            </Typography>
          </Grid>
        </Grid>
        {nav !== 'finish' ? (
          isMobile ? (
            <>
              <Grid
                container
                justifyContent="space-between"
                style={{ padding: '0 5%' }}
              >
                <Typography
                  onClick={() =>
                    (!orderSelected || orderSelected.status === 'Notes') &&
                    setNav('ship')
                  }
                  style={{
                    fontSize: 12,
                    height: 26,
                    borderBottom: nav === 'ship' && '2px solid #000',
                    fontWeight: nav === 'ship' && 'bold',
                    cursor:
                      (!orderSelected || orderSelected?.status === 'Notes') &&
                      'pointer',
                    color:
                      orderSelected && orderSelected?.status !== 'Notes'
                        ? '#d3d3d3'
                        : '#000',
                  }}
                >
                  Shipping & Billing
                </Typography>
                <Typography
                  onClick={() =>
                    (!orderSelected || orderSelected?.status === 'Notes') &&
                    setNav('place')
                  }
                  style={{
                    fontSize: 12,
                    height: 26,
                    borderBottom: nav === 'place' && '2px solid #000',
                    fontWeight: nav === 'place' && 'bold',
                    cursor:
                      (!orderSelected || orderSelected?.status === 'Notes') &&
                      'pointer',
                    color:
                      orderSelected && orderSelected?.status !== 'Notes'
                        ? '#d3d3d3'
                        : '#000',
                  }}
                >
                  Place Order
                </Typography>
                <Typography
                  onClick={() =>
                    (!orderSelected || orderSelected.status === 'Notes') &&
                    setNav('place')
                  }
                  style={{
                    fontSize: 12,
                    height: 26,
                    borderBottom: nav === 'review' && '2px solid #000',
                    fontWeight: nav === 'review' && 'bold',

                    cursor:
                      (!orderSelected || orderSelected?.status === 'Notes') &&
                      'pointer',
                  }}
                >
                  Review Order
                </Typography>
              </Grid>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                style={{
                  padding: '0 5%',
                  position: 'fixed',
                  bottom: 0,
                  zIndex: 100,
                  background: '#fff',
                  height: 50,
                }}
              >
                <Grid>
                  <Grid
                    container
                    alignItems="center"
                    onClick={() => {
                      handleClose();
                      setNav('ship');
                    }}
                  >
                    <ArrowBackIcon
                      style={{ fontSize: 12, position: 'relative', top: 2 }}
                    />
                    <Typography
                      display="inline"
                      style={{ fontSize: 12, cursor: 'pointer', marginLeft: 5 }}
                    >
                      {' '}
                      Collection
                    </Typography>
                  </Grid>
                </Grid>
                {(nav === 'review' || nav === 'place') && (
                  <Grid>
                    <Typography
                      onClick={() => setShow(!show)}
                      style={{ fontSize: 12 }}
                    >
                      {nav === 'review' ? 'Account Info' : 'Order Summary'}
                    </Typography>
                  </Grid>
                )}

                <Grid>
                  <Button
                    width={nav === 'submit' ? 180 : 120}
                    height={25}
                    textColor="#fff"
                    color={
                      !orderSelected || orderSelected.status === 'Notes'
                        ? '#000'
                        : '#d3d3d3'
                    }
                    onClick={() =>
                      (!orderSelected || orderSelected.status === 'Notes') &&
                      handleNext()
                    }
                    fontSize={10}
                    loading={loadingOrder}
                    text={nav === 'review' ? 'Submit Order' : 'Next'}
                  />
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid container justifyContent="space-between">
              <Typography
                display="inline"
                style={{ fontSize: 14, cursor: 'pointer' }}
                onClick={() => {
                  handleClose();
                  setNav('ship');
                }}
              >
                <ArrowBackIcon
                  style={{ fontSize: 14, position: 'relative', top: 2 }}
                />{' '}
                Back to {orderSelected ? 'order history' : 'Collection'}
              </Typography>
              <Typography
                onClick={() =>
                  (!orderSelected || orderSelected.status === 'Notes') &&
                  setNav('ship')
                }
                style={{
                  fontSize: 14,
                  height: 26,
                  borderBottom: nav === 'ship' && '2px solid #000',
                  fontWeight: nav === 'ship' && 'bold',
                  cursor:
                    (!orderSelected || orderSelected?.status === 'Notes') &&
                    'pointer',
                  color:
                    orderSelected && orderSelected?.status !== 'Notes'
                      ? '#d3d3d3'
                      : '#000',
                }}
              >
                Shipping & Billing
              </Typography>
              <Typography
                onClick={() =>
                  (!orderSelected || orderSelected.status === 'Notes') &&
                  setNav('place')
                }
                style={{
                  fontSize: 14,
                  height: 26,
                  borderBottom: nav === 'place' && '2px solid #000',
                  fontWeight: nav === 'place' && 'bold',
                  cursor:
                    (!orderSelected || orderSelected?.status === 'Notes') &&
                    'pointer',
                  color:
                    orderSelected && orderSelected?.status !== 'Notes'
                      ? '#d3d3d3'
                      : '#000',
                }}
              >
                Place Order
              </Typography>
              <Typography
                onClick={() => setNav('review')}
                style={{
                  fontSize: 14,
                  height: 26,
                  borderBottom: nav === 'review' && '2px solid #000',
                  fontWeight: nav === 'review' && 'bold',

                  cursor: 'pointer',
                }}
              >
                Review Order
              </Typography>
              <Grid>
                <Button
                  width={nav === 'submit' ? 205 : 148}
                  height={40}
                  textColor="#fff"
                  color={
                    !orderSelected || orderSelected.status === 'Notes'
                      ? '#000'
                      : '#d3d3d3'
                  }
                  onClick={() => {
                    if (!orderSelected || orderSelected.status === 'Notes') {
                      handleNext();
                      const evt =
                        nav === 'review' ? events.submitOrder : events.next;
                      dispatchEvent(evt);
                    }
                  }}
                  loading={loadingOrder}
                  text={nav === 'review' ? 'Submit Order' : 'Next'}
                  disabled={orderSelected && orderSelected.status !== 'Notes'}
                />
              </Grid>
            </Grid>
          )
        ) : null}
      </>
    );
  };

  // const ModalShipAddres = ({ open, handleClose }) => {
  //   return (

  //   );
  // };

  const CartItem = ({
    product,
    handleRemoveProductFromCollection,
    isUSD,
    nav,
    delivery,
    emptyProducts,
    setEmptyProducts,
  }) => {
    const [qtd, setQtd] = useState(null);
    const [total, setTotal] = useState(null);
    const [cartInfo, setCartInfo] = useState(product.cartInfo);
    const [newQtd, setNewQtd] = useState(null);
    const [sc, setStyle_code] = useState(null);
    const [sz, setSize] = useState(null);
    const [newProduct, setNewProduct] = useState(null);

    const debouncedValue = useDebounce(newQtd, 300);

    const handleReqQtd = async () => {
      await api.post('/my/cart', {
        style_code: sc,
        size: sz,
        quantity: debouncedValue,
      });
      await submitOrder({
        cartInfo: myProducts,
        userInfo: {
          email: user.email,
          sAddress: shipAdd[selectedShipAddress],
        },
      });

      setEmptyProducts(emptyProducts.filter(code => code !== sc.toString()));
    };

    useEffect(() => {
      if (debouncedValue) {
        handleReqQtd();
        // handleTotalPerDelivery(
        //   debouncedValue,
        //   product,
        //   d1 ? 'd1' : d2 ? 'd2' : 'd3'
        // );
      }
    }, [debouncedValue]);

    const handleChangeCartInfo = async (style_code, size, quantity) => {
      const newCartInfo = cartInfo.map(item => {
        const obj = { style_code, size, quantity };
        return size === item.size ? obj : item;
      });
      if (!newCartInfo.some(item => item.size === size)) {
        newCartInfo.push({ size, quantity });
      }

      setCartInfo(newCartInfo);
      setNewQtd(quantity);
      setStyle_code(style_code);
      setSize(size);
      const nProduct = product;
      nProduct.cartInfo = newCartInfo;
      setNewProduct(nProduct);
      if (downloadExcelURL) {
        setDownloadExcelURL('');
      }
    };

    React.useEffect(() => {
      let aux = 0;
      cartInfo.forEach(size => {
        aux += Number(size.quantity);
      });

      setQtd(aux + Number(debouncedValue));
      setTotal(
        (aux + Number(debouncedValue)) *
          (isUSD ? product.wholesale_usd : product.wholesale_eur)
      );
      if (debouncedValue) {
        if (delivery === 'd1') {
          setD1(
            d1.map(item =>
              item.style_code === newProduct.style_code ? newProduct : item
            )
          );
        }
        if (delivery === 'd2') {
          setD2(
            d2.map(item =>
              item.style_code === newProduct.style_code ? newProduct : item
            )
          );
        }
        if (delivery === 'd3') {
          setD3(
            d3.map(item =>
              item.style_code === newProduct.style_code ? newProduct : item
            )
          );
        }
      }
    }, [debouncedValue]);

    return (
      <>
        <Grid
          container
          justifyContent="space-between"
          style={{
            paddingLeft: 11,
            paddingRight: 17,
            width: isMobile ? '95%' : '75%',
            margin: isMobile ? '20px 0px 10px' : '40px 0px',
            flexWrap: 'nowrap',
          }}
        >
          <Img
            onClick={() => handleRemoveProductFromCollection(product)}
            src={
              product.mainly_image ||
              'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAPFBMVEXu7u62trbx8fHPz8/t7e2zs7O8vLy5ubnl5eWrq6vc3Nzq6urZ2dnh4eHV1dW6urrHx8fBwcHKysqlpaXy5cTEAAAEF0lEQVR4nO3bjXKkKBQFYLj8CAIK5v3fdS/Y9mi3md1UZavFOt9UTTpgujwBQaEjBAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAEDH6N2nT+l3TfaNulNEGo18Y+KnT+sXUXkPKGW5USOGs4BShk+f1++JJ52Uu+l0m0akfN6GN+im66RA7jygNK7veYNcnBo1fJNQDmo9IrouQ2az+S4gt+ImdzjqlLMp0Pz5/6XGfvp8f4qm9xhmVimwNNmTys4GVv0+x5uStiGFKO1a+NGktq+EQtjXgGo3ZOpdG5tlaiPR0HvC+BJgu1U1C7eo6T+heQQkEYJ4vHzc6PD1F/pPaHI7fXKZi+2yzn60tLrBhdx/Qqlbmdpmi6WlCcN+pOk7YXvYpeU5fj4iqv2k0XVC48TLU/B6XYbbJGz3K/rQqi0PDXdJ2MYZd7iPMXW0OTxV9ZzQ1MuQjk/BrZvS/kLsOmEdV15uVNttKKm7tGEbOceTNlxuknBdrTiuRpn6PHi4Pe864VAnfMr7RmyhSd4loRlfG7HNkCLdZj5cb0t3U/464R+X4LpOKE1qiRw/2/M/Y9db7yTvk1DOayEllbNKdHZI5wkfj09itzx6HHm6T8gR9SEB6ZeA3SeUxnLn1FttuyRvlrA247aIz8/678uJN0hYB9FlinFa7NmacG8Jz/dE12X885re1kvP1rz/qrc17/pI/6OIZtb//p4XE6z5gdLh3hPfwEzqP5pSb1304eRTQt/49JkCAAAAAEBftHt/0D0rq9zFHhmfH1n7U0LHkrY749uC/lnZ/r3aMet+zjXoGEaVRN18UfG58hvVWBdGp6kGiDoqRy76aWxV9S8RjmXCRdLCjfwjiotDNOo6f66g/TBYHwUVX8y6cSaCNPwdTd5yDWkvZ+vHcfBz4apZSSv2ZbWfpi/H71Bo9Hnx0Q1+3Xe8BG0yUbGkfOCv87pzZohCCF+RKPog/EJkCyd1RLnwU70f92X1XWjO/DoJ7p2csvXSy3RTXU9GzWTr/svo2wb29gGM+pqb1yc+whJfc1w1lFI43q6s4t+EiIac59rC7Xmp69AkUROW0hK2Pe0tIX9DX9xe9YgtYR6Z25e1d+HIVnHCWKv1JROO3PXCY8NX8XmnxD1R6MwxazJOo/2oSZlAgct3Ze2CIyVr+8vMv5lIwkx0neuwtYbkPueNL4+yzK8XSsZ749r1RYvlq9Rz3FqV9b5snfoCD008lBqu5mEre3+dGbG1Qe2PIYXnVNdek3CtD9Zz1aFO7+552KGsvY1v00b9kdrFt+IrOblhOfvyTRlN5v85rasYzdfrh8FvJox9/m3QD9w9HwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAANzJP/MdJGqyWhqXAAAAAElFTkSuQmCC'
            }
            alt="farm"
            style={{
              width: isMobile ? 87 : 100,
              height: isMobile ? 120 : 'auto',
              marginRight: isMobile ? 10 : 22,
            }}
          />
          <Grid container>
            <Grid
              container
              alignItems="space-between"
              justifyContent="space-between"
              style={{
                paddingBottom: 5,
                borderBottom: '1px solid rgba(0, 0, 0, 0.5)',
              }}
            >
              <Grid style={{ display: isMobile && 'flex' }}>
                <Grid>
                  <Typography
                    style={{
                      fontSize: 12,
                      fontWeight: 'bold',
                      lineHeight: '21px',
                      color:
                        emptyProducts?.includes(product.style_code) &&
                        '#E01919',
                    }}
                  >
                    {product?.description}{' '}
                    {emptyProducts?.includes(product.style_code) && (
                      <span style={{ marginLeft: 8 }}>
                        <WarningIcon />
                        Please review this item
                      </span>
                    )}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: 12,
                      color:
                        emptyProducts?.includes(product.style_code) &&
                        '#E01919',
                    }}
                  >
                    {product?.style_code}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                style={{ marginTop: product.description.length < 28 && 10 }}
              >
                <Grid>
                  {' '}
                  <Typography
                    display="inline"
                    align="right"
                    style={{ fontWeight: 'bold', fontSize: 12, marginRight: 8 }}
                  >
                    Suggested Retail{' '}
                  </Typography>
                  <Typography
                    display="inline"
                    style={{ fontWeight: 'normal', fontSize: 12 }}
                  >
                    {isUSD ? (
                      <span>$ {Number(product?.msr_price_usd).toFixed(2)}</span>
                    ) : (
                      <span>
                        &euro; {Number(product?.msr_price_eur).toFixed(2)}
                      </span>
                    )}
                  </Typography>
                </Grid>

                <Grid
                  container
                  justifyContent={!isMobile && 'flex-end'}
                  style={{
                    marginTop: 3,
                  }}
                >
                  {' '}
                  <Typography
                    display="inline"
                    align="right"
                    style={{ fontWeight: 'bold', fontSize: 12, marginRight: 9 }}
                  >
                    Wholesale{' '}
                  </Typography>
                  <Typography
                    display="inline"
                    style={{ fontWeight: 'normal', fontSize: 12 }}
                  >
                    {isUSD ? <span>$</span> : <span>&euro;</span>}{' '}
                    {(
                      Math.round(
                        (isUSD
                          ? product.wholesale_usd
                          : product.wholesale_eur) * 100
                      ) / 100
                    ).toFixed(2)}
                  </Typography>
                </Grid>
                {isMobile && nav === 'place' && (
                  <Grid
                    style={{
                      cursor: 'pointer',
                    }}
                    container
                    alignItems="center"
                    onClick={() => handleRemoveProductFromCollection(product)}
                  >
                    <Typography
                      style={{
                        fontSize: 12,
                        marginRight: 10,
                      }}
                    >
                      Remove from collection
                    </Typography>
                    <IoMdHeartDislike
                      style={{ position: 'relative', top: 1.5 }}
                      size={12}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
            {!isMobile && (
              <Grid
                container
                justifyContent="space-between"
                style={{
                  paddingBottom: 14,
                  marginTop: 8,
                  borderBottom:
                    nav === 'place' && '1px solid rgba(0, 0, 0, 0.5)',
                }}
              >
                <Grid>
                  <Grid container>
                    <Typography
                      display="inline"
                      style={{ fontWeight: 'bold', marginRight: 19 }}
                    >
                      Sizes
                    </Typography>
                    {product.sizes &&
                      handleSortSizes(Object.keys(product.sizes)).map(size => (
                        <Grid style={{ marginRight: 8 }}>
                          <Typography
                            align="right"
                            style={{
                              marginBottom: 8,
                              fontSize: 12,
                            }}
                          >
                            {size}
                          </Typography>
                          {nav === 'place' ? (
                            <input
                              onChange={event => {
                                handleChangeCartInfo(
                                  product.style_code,
                                  size,
                                  event.target.value
                                );
                              }}
                              style={{
                                border: '1px solid rgba(0, 0, 0, 0.5)',
                                width: 46,
                                height: 22,
                                fontSize: 12,
                                textAlign: 'right',
                                paddingRight: 4,
                              }}
                              value={
                                cartInfo?.find(item => item.size === size)
                                  ?.quantity
                              }
                            />
                          ) : (
                            <Typography
                              align="right"
                              style={{ fontSize: 12, width: 46, height: 22 }}
                            >
                              {cartInfo?.find(item => item.size === size)
                                ?.quantity || 0}
                            </Typography>
                          )}
                        </Grid>
                      ))}
                  </Grid>
                </Grid>
                <Grid>
                  <Grid container>
                    <Grid style={{ marginRight: 21 }}>
                      <Typography
                        style={{
                          fontWeight: 'bold',
                          fontSize: 12,
                          marginBottom: 11,
                          color:
                            emptyProducts?.includes(product.style_code) &&
                            '#E01919',
                        }}
                      >
                        Quantity
                      </Typography>
                      <Typography
                        align="right"
                        style={{
                          fontSize: 12,
                          color:
                            emptyProducts?.includes(product.style_code) &&
                            '#E01919',
                        }}
                      >
                        {qtd}
                      </Typography>
                    </Grid>
                    <Grid>
                      <Typography
                        align="right"
                        style={{
                          fontWeight: 'bold',
                          fontSize: 12,
                          marginBottom: 11,
                          color:
                            emptyProducts?.includes(product.style_code) &&
                            '#E01919',
                        }}
                      >
                        Total
                      </Typography>
                      <Typography
                        style={{
                          fontWeight: 'bold',
                          fontSize: 12,
                          color:
                            emptyProducts?.includes(product.style_code) &&
                            '#E01919',
                        }}
                      >
                        {total ? (
                          isUSD ? (
                            <span>$</span>
                          ) : (
                            <span>&euro;</span>
                          )
                        ) : null}{' '}
                        {total}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {!isMobile && nav === 'place' && (
              <Grid
                style={{
                  cursor: 'pointer',
                  margin: '6px 0px',
                }}
                container
                alignItems="center"
                onClick={() => handleRemoveProductFromCollection(product)}
              >
                <Typography style={{ fontSize: 12, marginRight: 10 }}>
                  Remove from collection
                </Typography>
                <IoMdHeartDislike
                  style={{ position: 'relative', top: 1.5 }}
                  size={12}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        {isMobile && (
          <Grid
            container
            justifyContent="space-between"
            style={{
              paddingBottom: 14,
              paddingLeft: 11,
              paddingRight: 17,
              borderBottom: '1px solid rgba(0, 0, 0, 0.5)',
            }}
          >
            <Grid>
              <Grid container>
                <Grid>
                  <Typography
                    style={{
                      fontWeight: 'bold',
                      marginRight: 19,
                      fontSize: 12,
                    }}
                  >
                    Sizes
                  </Typography>
                </Grid>
                <Grid>
                  <Grid container>
                    {handleSortSizes(Object.keys(product.sizes)).map(
                      (size, index) => (
                        <Grid style={{ marginRight: 8 }}>
                          <Typography
                            align="right"
                            style={{
                              marginBottom: 8,
                              fontSize: 12,
                            }}
                          >
                            {size}
                          </Typography>
                          {nav === 'place' ? (
                            <input
                              onChange={event => {
                                handleChangeCartInfo(
                                  product.style_code,
                                  size,
                                  event.target.value
                                );
                              }}
                              style={{
                                border: '1px solid rgba(0, 0, 0, 0.5)',
                                width: 46,
                                height: 22,
                                fontSize: 12,
                                textAlign: 'right',
                                paddingRight: 4,
                              }}
                              value={
                                cartInfo?.find(item => item.size === size)
                                  ?.quantity
                              }
                            />
                          ) : (
                            <Typography
                              align="right"
                              style={{ fontSize: 12, width: 46, height: 22 }}
                            >
                              {cartInfo?.find(item => item.size === size)
                                ?.quantity || 0}
                            </Typography>
                          )}
                        </Grid>
                      )
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="space-between"
              style={{ marginTop: 5 }}
            >
              <Grid style={{ marginRight: 21 }}>
                <Typography
                  display="inline"
                  style={{
                    fontWeight: 'bold',
                    fontSize: 12,
                    marginRight: 10,
                    marginBottom: 11,
                  }}
                >
                  Quantity
                </Typography>
                <Typography
                  display="inline"
                  align="right"
                  style={{ fontSize: 12 }}
                >
                  {qtd}
                </Typography>
              </Grid>
              <Grid>
                <Typography
                  display="inline"
                  align="right"
                  style={{
                    fontWeight: 'bold',
                    fontSize: 12,
                    marginBottom: 11,
                    marginRight: 10,
                  }}
                >
                  Total
                </Typography>
                <Typography
                  display="inline"
                  style={{ fontWeight: 'bold', fontSize: 12 }}
                >
                  {total ? isUSD ? <span>$</span> : <span>&euro;</span> : null}{' '}
                  {total}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
      </>
    );
  };

  return (
    <div>
      <StyledDialog
        open={open}
        TransitionComponent={Transition}
        // keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullScreen
      >
        <DialogContent
          style={{
            margin: !isMobile && '0px 1% 50px',
            padding: isMobile && '2%',
          }}
        >
          {print ? (
            <Grid container ref={componentRef}>
              <PDF name={profile.name} d1={d1} d2={d2} d3={d3} />
            </Grid>
          ) : (
            <Grid style={{ width: '100%', height: '100%' }} id="modal">
              <Navbar setEmptyProducts={setEmptyProducts} />
              {!loading ? (
                nav !== 'ship' && nav !== 'finish' ? (
                  <>
                    <Order /> <FixedInfo />
                  </>
                ) : nav === 'ship' ? (
                  <Shipping />
                ) : (
                  <Finish />
                )
              ) : (
                <Grid container justifyContent="center" alignItems="center">
                  <CircularProgress style={{ marginTop: 100 }} />
                </Grid>
              )}
            </Grid>
          )}
          <ModalWarningEmptyProducts
            open={openWarningEmptyProducts}
            handleClose={() => setOpenWarningEmptyProducts(false)}
            quantity={emptyProducts.length}
          />
          <ModalAlreadyHaveAnOrder
            open={openOrderAlreadyExist}
            order={currentOrder}
            handleClose={handleCloseOrderAlreadyExist}
            editOrder={() => {
              setMyProducts(currentOrder?.products);
              setNav('place');
            }}
            newOrder={updateExistingOrder}
          />
        </DialogContent>
      </StyledDialog>
    </div>
  );
}
