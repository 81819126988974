import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import history from '../../services/history';
import { collectionViewsOptions } from '../../config/showroomConfig';

export default function RightLateralBar({ by, delivery, bg }) {
  const location = useLocation();
  const splittedLocation = location.pathname.split('/');
  const currentCollection = splittedLocation[1];

  return (
    <Grid
      container
      justifyContent="space-around"
      style={{
        width: '100%',
        position: 'fixed',
        bottom: 0,
        background: '#fff',
        height: window.innerWidth > 760 ? 60 : 40,
        alignItems: 'center',
        paddingLeft: 10,
        zIndex: 1,
        // justifyContent: 'space-between',
      }}
    >
      {collectionViewsOptions.map(
        viewString =>
          !(viewString === 'Category' && delivery === '4') && (
            <Grid
              onClick={() => {
                history.replace(
                  `/${currentCollection}/${delivery}/${viewString.toLocaleLowerCase()}`
                );
              }}
              style={{
                marginLeft: 3,
                cursor: 'pointer',
                justifyContent: 'center',
              }}
            >
              <Typography
                variant="h6"
                align="centr"
                style={{
                  padding: '4px 0px',
                  fontSize: window.innerWidth > 760 ? 20 : 12,
                  borderBottom:
                    by === viewString.toLocaleLowerCase()
                      ? bg && by === 'view'
                        ? '2px solid #FFFFFF'
                        : '2px solid #000000'
                      : '2px solid transparent',
                  color:
                    by === 'view' ? (bg ? '#ffffff' : '#000000') : '#000000',
                  fontFamily:
                    by === viewString.toLocaleLowerCase() &&
                    'GalanoGrotesque-Medium',
                  textAlign: 'center',
                }}
              >
                {viewString}
              </Typography>
            </Grid>
          )
      )}
    </Grid>
  );
}
