import React, { useCallback, useEffect } from 'react';
import { Grid, Fade, Typography } from '@material-ui/core';
import { useRouteMatch, useLocation, useHistory } from 'react-router-dom';
import Img from 'react-cool-img';
import ModalProduct from '../ModalProduct';
import { ORDERCONFIG } from '../../config/showroomConfig';
import useConfigContext from '../../hooks/useConfigContext';

function DeliveryView({ bg }) {
  const [open, setOpen] = React.useState(false);
  const location = useLocation();
  const { configState } = useConfigContext();
  const seasonName = configState.season.name || '';
  const history = useHistory();

  const [selectedProduct, setSelectedProduct] = React.useState(false);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };
  useEffect(() => {
    window.scrollTo(0, 0);
    const query = new URLSearchParams(location.search);
    const style_code = query.get('style_code');
    if (!style_code) return;
    setSelectedProduct({ style_code });
    setOpen(true);
  }, [location]);
  const handleClose = () => {
    setOpen(false);
  };
  const { params } = useRouteMatch();

  const { delivery_id } = params;

  const handlePrevProduct = useCallback(() => {
    const selectedProductIndex = ORDERCONFIG.TAKEAWAY.ORDENACAO[
      delivery_id
    ].findIndex(p => p.style_code === selectedProduct.style_code);
    if (selectedProductIndex === 0) {
      // vou pro ultimo item
      const product = ORDERCONFIG.TAKEAWAY.ORDENACAO[delivery_id].find(
        (p, index) =>
          index === ORDERCONFIG.TAKEAWAY.ORDENACAO[delivery_id].length - 1
      );
      // setSelectedProduct(product);
      history.replace({ search: `?style_code=${product.style_code}` });
    } else {
      // volto um item
      const product = ORDERCONFIG.TAKEAWAY.ORDENACAO[delivery_id].find(
        (p, index) => index === selectedProductIndex - 1
      );
      history.replace({ search: `?style_code=${product.style_code}` });

      // setSelectedProduct(product);
    }
  }, [selectedProduct, delivery_id, history]);

  const handleNextProduct = useCallback(() => {
    const selectedProductIndex = ORDERCONFIG.TAKEAWAY.ORDENACAO[
      delivery_id
    ].findIndex(p => p.style_code === selectedProduct.style_code);
    if (
      selectedProductIndex >=
      ORDERCONFIG.TAKEAWAY.ORDENACAO[delivery_id].length - 1
    ) {
      setSelectedProduct(ORDERCONFIG.TAKEAWAY.ORDENACAO[delivery_id][0]);
      history.replace({
        search: `?style_code=${ORDERCONFIG.TAKEAWAY.ORDENACAO[delivery_id][0].style_code}`,
      });
    } else {
      const product = ORDERCONFIG.TAKEAWAY.ORDENACAO[delivery_id].find(
        (p, index) => index === selectedProductIndex + 1
      );
      // setSelectedProduct(product);
      history.replace({ search: `?style_code=${product.style_code}` });
    }
  }, [selectedProduct, delivery_id, history]);

  return (
    <Fade in timeout={300}>
      <div>
        {bg ? (
          <Grid style={{ marginTop: window.innerHeight / 8 }}>
            <Typography
              align="center"
              style={{ fontSize: 48, color: '#ffffff', letterSpacing: '0.1em' }}
            >
              {seasonName}
            </Typography>

            <Typography
              align="center"
              style={{
                fontSize: 28,
                color: '#ffffff',
                letterSpacing: '0.1em',
              }}
            />
            <Typography
              align="center"
              style={{
                color: '#fff',
                marginTop: 45,
                fontSize: 24,
                letterSpacing: '0.1em',
              }}
            >
              DELIVERY {delivery_id}
            </Typography>
            {/* <Typography
            align="center"
            variant="h6"
            style={{
              color: '#fff',
              fontSize: 20,
              letterSpacing: '0.1em',
            }}
          >
            {dictIdDeliveryTitle[delivery_id]}
          </Typography> */}
          </Grid>
        ) : (
          <>
            <Typography
              align="center"
              style={{ fontSize: 32, letterSpacing: '0.2em', marginTop: 200 }}
            >
              {seasonName}
            </Typography>
            <Typography
              align="center"
              style={{ fontSize: 28, letterSpacing: '0.2em' }}
            >
              COLLECTION
            </Typography>
          </>
        )}
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid
            container
            justifyContent="flex-start"
            alignItems="center"
            style={{
              paddingLeft: 15,
              width: 1044,
              height: '100%',
              marginTop: window.innerHeight / 4,
            }}
          >
            {ORDERCONFIG.TAKEAWAY.ORDENACAO[2].map((item, index) => (
              <Grid
                key={String(item.overview_image)}
                md={item === 2 ? 12 : 3}
                style={{}}
                onClick={async () => {
                  history.replace({ search: `?style_code=${item.style_code}` });
                  // setSelectedProduct(item);
                  // setOpen(true);
                }}
              >
                <Img
                  style={{
                    margin: '1px 2px',
                    width: 252,
                    height: 380,
                    cursor: 'pointer',
                  }}
                  src={item.overview_image}
                  alt="a"
                />
              </Grid>
            ))}
          </Grid>
        </Grid>

        <ModalProduct
          products={ORDERCONFIG.TAKEAWAY.ORDENACAO[delivery_id]}
          // setProducts={setProducts}
          handlePrevProduct={handlePrevProduct}
          handleNextProduct={handleNextProduct}
          open={open}
          handleClose={handleClose}
          style_code={selectedProduct}
        />
      </div>
    </Fade>
  );
}

export default DeliveryView;
