import React, { useState, useEffect, useCallback, useMemo } from 'react';

import ModalCollection from '../../ModalCollection';
import ModalCollectionMobile from '../../../componentsMobile/ModalCollectionMobile';

export default function Collection() {
  const [vw, setVw] = useState(window.innerWidth);

  const updateViewportWidth = useCallback(() => {
    setVw(prevWidth => {
      const shouldUpdate =
        (prevWidth > 1024 && window.innerWidth < 1024) ||
        (prevWidth < 1024 && window.innerWidth > 1024);
      if (shouldUpdate) return window.innerWidth;
      return prevWidth;
    });
  }, [setVw]);

  useEffect(() => {
    window.addEventListener('resize', updateViewportWidth);
    return () => {
      window.removeEventListener('resize', updateViewportWidth);
    };
  }, [updateViewportWidth]);

  const isMobile = useMemo(() => {
    return vw < 1024;
  }, [vw]);

  return (
    <>
      {isMobile && (
        <ModalCollectionMobile open handleClose={() => window.history.back()} />
      )}
      {!isMobile && (
        <ModalCollection open handleClose={() => window.history.back()} />
      )}
    </>
  );
}
