import React, { useState, useEffect, useRef } from 'react';
import { Grid, Typography } from '@material-ui/core';
import ReactPlayer from 'react-player';
import { MdKeyboardBackspace } from 'react-icons/md';
import ButtonMui from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import TextInput from '../../components/TextInput';
import api from '../../services/api';
import Button from '../../components/Button';
import Snackbar from '../../util/SnackBar';
import ProductTable from './EditProductsTable';
import { ReactComponent as Check } from '../../assets/checkIcon.svg';

const ReadyToWearCategories = [
  'DRESSES',
  'BOTTOMS',
  'JUMPSUITS',
  'TOPS AND BLOUSES',
  'SKIRTS',
  'OUTERWEAR',
  'KNITWEAR',
];
const SwimCategories = [
  'BIKINI BOTTOMS',
  'BIKINI TOPS',
  'COVER UPS',
  'ONE PIECE',
  'SARONG',
];

const StyledButton = withStyles({
  root: {
    textTransform: 'none',
    padding: '0px',
    minWidth: 'auto',
  },
  contained: {
    boxShadow: 'none',
    // backgroundColor: 'transparent',
    border: '1px solid black',
    borderRadius: 'unset',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: 'transparent',
    },
  },
  endIcon: {
    marginLeft: 3,
    marginRight: 5,
    position: 'relative',
    bottom: 3.5,
  },
  label: {
    alignItems: 'end',
  },
})(ButtonMui);

export default function EditProduct() {
  const [selectedProduct, setSelectedProduct] = useState('');
  const [styleCode, setStyleCode] = useState('');
  const [productName, setProductName] = useState('');
  const [priceUSWholesale, setPriceUSWholesale] = useState('');
  const [priceUSMSRP, setPriceUSMSRP] = useState('');
  const [priceEUWholesale, setPriceEUWholesale] = useState('');
  const [priceEUMSRP, setPriceEUMSRP] = useState('');
  const [delivery, setDelivery] = useState('');
  const [capsula, setCapsula] = useState('');
  const [status, setStatus] = useState('');
  const [line, setLine] = useState('');
  const [category, setCategory] = useState('');
  const [print, setPrint] = useState('');
  const [composition, setComposition] = useState('');
  const [productImage, setProductImage] = useState('');
  const [printOptions, setPrintOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [product, setProduct] = useState('');
  const [loading, setLoading] = useState(false);
  const count = useRef(0);
  const [tagsArray, setTagsArray] = useState([
    { tagName: 'Sustainable 1', disabled: false, isSelected: false },
    { tagName: 'Sustainable 2', disabled: false, isSelected: false },
    { tagName: 'Sustainable 3', disabled: false, isSelected: false },
    { tagName: 'Sustainable 4', disabled: false, isSelected: false },
    { tagName: 'Sustainable 5', disabled: false, isSelected: false },
  ]);

  const incrementSelectedTag = () => {
    count.current += 1;
    if (count.current === 2) {
      disableTagButtons();
    }
  };

  const decrementSelectedTag = () => {
    count.current -= 1;
  };

  const disableTagButtons = () => {
    setTagsArray(oldState =>
      oldState.map(tagObj => {
        if (!tagObj.isSelected) {
          tagObj.disabled = true;
        }
        return tagObj;
      })
    );
  };

  const generateTagsArray = () => {
    return tagsArray
      .map(tagItem => {
        if (tagItem.isSelected) return tagItem.tagName;
        return '';
      })
      .filter(item => item);
  };

  const changeIsSelectedTagPropertyToTrue = tagLabel => {
    setTagsArray(oldState =>
      oldState.map(tagObj => {
        if (tagLabel === tagObj.tagName) tagObj.isSelected = true;
        return tagObj;
      })
    );
  };

  const enableTagButtons = tagLabel => {
    setTagsArray(oldState =>
      oldState.map(tagObj => {
        if (tagObj.disabled) tagObj.disabled = false;
        else if (tagLabel === tagObj.tagName) {
          tagObj.isSelected = false;
        }
        return tagObj;
      })
    );
  };

  const handleTagClick = (tagLabel, isTagSelected) => {
    if (!isTagSelected && count.current <= 2) {
      changeIsSelectedTagPropertyToTrue(tagLabel);
      incrementSelectedTag();
    } else {
      decrementSelectedTag();
      enableTagButtons(tagLabel);
    }
  };

  const handleUpdate = async () => {
    const body = [
      {
        ...product,
        description: productName,
        msr_price_eur: priceEUMSRP,
        msr_price_usd: priceUSMSRP,
        wholesale_eur: priceEUWholesale,
        wholesale_usd: priceUSWholesale,
        style_collection: line,
        delivery_id: delivery,
        print_name: [print],
        category_name: category,
        composition,
        capsula,
        sustainableTags: generateTagsArray(),
      },
    ];
    setLoading(true);
    await api.put('/products', body);
    setLoading(false);
    Snackbar.success('Product updated');
  };

  const getProduct = async () => {
    setLoading(true);
    const { data } = await api.get(`/product/${selectedProduct}`);
    setProduct(data);
    setStyleCode(data?.style_code);
    setProductName(data?.description);
    setPriceUSWholesale(data?.wholesale_usd);
    setPriceUSMSRP(data?.msr_price_usd);
    setPriceEUWholesale(data?.wholesale_eur);
    setPriceEUMSRP(data?.msr_price_eur);
    setDelivery(data?.delivery_id);
    setCapsula(data?.capsula);
    setStatus(data?.status);
    setLine(data?.style_collection);
    setCategory(data?.category_name);
    setComposition(data?.composition);
    setProductImage(data?.mainly_image);
    setPrint(data?.print_name[0]);
    setLoading(false);
  };

  const getProducts = async () => {
    const { data } = await api.get(`/products/${delivery}?options=true`);
    const array = line === 'MAIN' ? ReadyToWearCategories : SwimCategories;
    const categoriesFiltered = data?.categories.filter(item =>
      array.includes(item)
    );
    setCategoryOptions(categoriesFiltered);
    setPrintOptions(data?.prints);
  };

  const handleChangePrice = (str, value, func) => {
    return func(value.split(str)[1] || '');
  };

  useEffect(() => {
    if (selectedProduct) getProduct();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProduct]);

  useEffect(() => {
    if (delivery && line) getProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delivery, line]);

  const OptionStatus = ({ option }) => {
    const dictOption = {
      Available: 'green',
      'Sold Out': 'orange',
      Cancelled: 'red',
    };
    return (
      <>
        <Typography
          style={{ color: '#5b5b5b', fontSize: 12, lineHeight: '34px' }}
        >
          Status
        </Typography>
        <Grid container alignItems="center">
          <div
            style={{
              width: 14,
              height: 14,
              background: dictOption[option],
              borderRadius: 7,
              marginRight: 8,
              marginTop: 5,
            }}
          />
          <span>{option}</span>
        </Grid>
      </>
    );
  };

  return !selectedProduct ? (
    <ProductTable selectProduct={id => setSelectedProduct(id)} />
  ) : loading ? null : (
    <Grid
      container
      style={{ alignContent: 'flex-start', padding: '20px 5px 10px' }}
    >
      <Grid container justifyContent="center">
        <Typography
          style={{
            fontFamily: 'Galano Grotesque Light',
            fontSize: 24,
            lineHeight: '28px',
            letterSpacing: '0.1em',
            marginBottom: 25,
          }}
        >
          EDIT PRODUCT INFO
        </Typography>
      </Grid>
      <Grid
        container
        alignItems="center"
        style={{ margin: '12px 0px 24px', cursor: 'pointer' }}
        onClick={() => setSelectedProduct('')}
      >
        <MdKeyboardBackspace />
        <Typography style={{ marginLeft: 8, position: 'relative', bottom: 2 }}>
          Back
        </Typography>
      </Grid>
      <Grid container justifyContent="center" wrap="nowrap">
        <Grid style={{ width: '20%' }}>
          {product && (
            <img
              alt="product_image"
              src={productImage}
              style={{ width: 171, height: 245, marginBottom: 14 }}
            />
          )}
          <OptionStatus option={status} />
        </Grid>
        <Grid style={{ marginLeft: 20, width: '80%' }}>
          <Grid container>
            <TextInput
              field="SKU"
              value={styleCode}
              // onChange={event => setStyleCode(event.target.value)}
              height={43}
              width={170}
              marginRight={30}
              disabled
            />
            <TextInput
              field="Product Name"
              value={productName}
              onChange={event => setProductName(event.target.value)}
              height={43}
              width={570}
            />
          </Grid>
          <Grid container style={{ marginTop: 30 }}>
            <TextInput
              field="Price US - Wholesale"
              value={`$ ${priceUSWholesale}`}
              onChange={event =>
                handleChangePrice('$ ', event.target.value, setPriceUSWholesale)
              }
              width={170}
              marginRight={30}
              height={40}
            />
            <TextInput
              field="Price US - MSRP"
              value={`$ ${priceUSMSRP}`}
              onChange={event =>
                handleChangePrice('$ ', event.target.value, setPriceUSMSRP)
              }
              width={170}
              height={40}
              marginRight={30}
            />
            <TextInput
              field="Price EU - Wholesale"
              value={`€ ${priceEUWholesale}`}
              onChange={event =>
                handleChangePrice('€ ', event.target.value, setPriceEUWholesale)
              }
              width={170}
              marginRight={30}
              height={40}
            />
            <TextInput
              field="Price EU - MSRP"
              value={`€ ${priceEUMSRP}`}
              onChange={event =>
                handleChangePrice('€ ', event.target.value, setPriceEUMSRP)
              }
              width={170}
              height={40}
            />
          </Grid>
          <Grid container style={{ marginTop: 30 }}>
            <TextInput
              select
              width={370}
              field="Shipping Date"
              options={
                capsula === 'PRE FALL'
                  ? ['5f34a6b5ed5ff21fbc9939c6', '5f34a7b6bc82e351349c76bf']
                  : [
                      '5f34a6b5ed5ff21fbc9939c6',
                      '5f34a7b6bc82e351349c76bf',
                      '5f34a7c2bc82e351349c76c0',
                    ]
              }
              value={delivery}
              onChange={event => setDelivery(event.target.value)}
              height={40}
              marginRight={30}
              delivery
              background="#ebebeb"
            />{' '}
            <TextInput
              field="Capsule"
              width={220}
              select
              height={40}
              options={['MAIN COLLECTION', 'PRE FALL']}
              value={capsula}
              onChange={event => setCapsula(event.target.value)}
              background="#ebebeb"
              marginRight={18}
              disabled={line === 'SWIMWEAR'}
            />
            <TextInput
              field="line"
              width={132}
              height={40}
              value={line === 'MAIN' ? 'Ready to Wear' : line}
              disabled
            />
          </Grid>
          <Grid container style={{ marginTop: 30 }}>
            <TextInput
              select
              width={370}
              field="Category"
              options={categoryOptions}
              value={category}
              onChange={event => setCategory(event.target.value)}
              height={40}
              marginRight={30}
              background="#ebebeb"
            />{' '}
            <TextInput
              select
              width={370}
              field="Print"
              options={printOptions}
              value={print}
              onChange={event => setPrint(event.target.value)}
              height={40}
              background="#ebebeb"
            />{' '}
          </Grid>
          <Grid style={{ marginTop: 30 }}>
            <TextInput
              // width={770}
              field="Composition"
              value={composition}
              onChange={event => setComposition(event.target.value)}
              height={40}
            />{' '}
          </Grid>
          <Grid container style={{ marginTop: 32 }}>
            <Typography
              style={{
                color: '#5b5b5b',
                fontSize: 12,
                lineHeight: '34px',
                marginRight: 890,
              }}
            >
              Tags
            </Typography>
            <Grid container style={{ marginTop: 10 }}>
              {tagsArray.map((tagObj, index) => (
                <StyledButton
                  variant="contained"
                  style={{
                    margin: index % 2 !== 0 && '0px 15px',
                    background: tagObj.isSelected ? 'black' : 'white',
                    opacity: tagObj.disabled && 0.2,
                    // padding: '4px',
                    height: 28,
                    // background: 'green'
                  }}
                  endIcon={tagObj.isSelected && <Check />}
                  disabled={tagObj.disabled}
                  onClick={() =>
                    handleTagClick(tagObj.tagName, tagObj.isSelected)
                  }
                >
                  <Typography
                    style={{
                      position: 'relative',
                      bottom: 2,
                      color: tagObj.isSelected ? 'white' : 'black',
                      paddingLeft: 8,
                      paddingRight: 8,
                      paddingTop: 1,
                      paddingBottom: 1,
                      fontSize: 12,
                    }}
                  >
                    {tagObj.tagName}
                  </Typography>
                </StyledButton>
              ))}
            </Grid>
            {count.current === 2 && (
              <Typography
                style={{
                  fontSize: 12,
                  color: '#616161',
                  marginTop: 10,
                  marginLeft: 15,
                }}
              >
                2 tags maximum
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        justifyContent="center"
        style={{ margin: '33px 0px 13px' }}
      >
        <Typography
          style={{
            color: '#5b5b5b',
            fontSize: 12,
            lineHeight: '34px',
            marginRight: 890,
          }}
        >
          Photos
        </Typography>
        {product?.other_images?.at(-1)?.includes('mp4') ? (
          <Typography
            style={{ color: '#5b5b5b', fontSize: 12, lineHeight: '34px' }}
          >
            Video
          </Typography>
        ) : (
          <Grid style={{ marginRight: 30 }} />
        )}
      </Grid>
      <Grid container justifyContent="center">
        <Grid
          container
          justifyContent="space-between"
          style={{ width: 1120, position: 'relative', left: 78 }}
        >
          <Grid>
            <Grid container>
              {product && (
                <img
                  src={product?.mainly_image}
                  style={{ width: 120, height: 172, marginRight: 12 }}
                  alt=""
                />
              )}
              {product?.other_images?.map(
                item =>
                  !item?.includes('mp4') && (
                    <img
                      src={item}
                      style={{ width: 120, height: 172, marginRight: 12 }}
                      alt=""
                    />
                  )
              )}
            </Grid>
          </Grid>
          <Grid>
            {product?.other_images?.at(-1)?.includes('mp4') && (
              <Grid style={{ position: 'relative', right: 48 }}>
                <ReactPlayer
                  loop
                  url={product?.other_images.at(-1)}
                  height="172px"
                  width="172px"
                  playing
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid container style={{ marginTop: 30 }}>
        <Button
          text="Save Changes"
          color="#000"
          textColor="#fff"
          width={268}
          height={40}
          justifyContent="center"
          loading={loading}
          onClick={handleUpdate}
        />
      </Grid>
    </Grid>
  );
}
