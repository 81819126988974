import api from '../../services/api';

export const dictIdDelivery = {
  1: '5f34a6b5ed5ff21fbc9939c6',
  2: '5f34a7b6bc82e351349c76bf',
  3: '5f34a7c2bc82e351349c76c0',
};
export const loadProductsByCategory = async ({ delivery_id }) => {
  try {
    const response = await api.get(`products/${dictIdDelivery[delivery_id]}`, {
      params: { orderBy: 'category' },
    });
    return response.data;
  } catch (err) {
    return null;
  }
};

export const saveNewOrderCategories = async ({ products }) => {
  try {
    const response = await api.put(
      `products`,
      products.map((product, index) => ({
        style_code: product.style_code,
        order_by_category: index + 1,
      }))
    );
    return response.data;
  } catch (err) {
    return null;
  }
};
