import { useCallback, useContext } from 'react';

import { EventsContext } from '../contexts/events';

export default function useEventListener() {
  const [, dispatch] = useContext(EventsContext);

  const subscribe = useCallback(
    (event, callback) => {
      dispatch({ type: 'subscribe', event, callback });
    },
    [dispatch]
  );

  const unsubscribe = useCallback(
    (event, callback) => {
      dispatch({ type: 'unsubscribe', event, callback });
    },
    [dispatch]
  );

  return {
    subscribe,
    unsubscribe,
  };
}
