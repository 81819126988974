import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as SmallArrow } from '../../assets/smallArrow.svg';

const useStyles = makeStyles(() => ({
  title: {
    fontSize: 17,
    lineHeight: '20px',
  },
  textLink: {
    fontSize: 12,
    lineHeight: '14px',
  },
  container: {
    backgroundColor: 'white',
    border: '1px solid white',
    '&:hover': {
      border: '1px solid black',
    },
    maxWidth: '32.333%',
  },
}));

export default function TextCard({
  xs,
  title,
  logo,
  children,
  linkMessage,
  cardClick,
}) {
  const classes = useStyles();
  return (
    <Grid
      item
      xs={xs}
      className={classes.container}
      id={title}
      onClick={e => cardClick(e.currentTarget.id)}
    >
      <Grid
        container
        alignItems="center"
        style={{ padding: '16px 16px 8px 16px' }}
        wrap="nowrap"
      >
        {logo}
        <Typography className={classes.title}>{title}</Typography>
      </Grid>
      <hr style={{ margin: '0px 16px' }} />

      <Grid style={{ padding: 16 }}>
        {children}

        <Grid
          container
          alignItems="center"
          justifyContent="flex-end"
          wrap="nowrap"
          style={{ marginTop: 21 }}
        >
          <Typography className={classes.textLink}>{linkMessage}</Typography>
          <SmallArrow />
        </Grid>
      </Grid>
    </Grid>
  );
}
