import React, { useCallback, useEffect } from 'react';
// import PropTypes from 'prop-types';
// import { makeStyles, withStyles } from '@material-ui/core/styles';
// import TableHead from '@material-ui/core/TableHead';
import ClearIcon from '@material-ui/icons/Clear';
import AddIcon from '@material-ui/icons/Add';
import { Grid, Typography, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import Skeleton from '@material-ui/lab/Skeleton';
import styled from 'styled-components';
import Input from '../../components/TextInput';
import ModalAddNewAddress from './ModalAddNewAddress';
import MakeOrder from './MakeOrder';
import ModalOrderAlreadyExist from './ModalOrderAlreadyExist';

// import ModalChangestatus from './ModalChangeProductStatus';

import Button from '../../components/Button';
import {
  listAccountUsers,
  updateOrder,
  createOrder,
  findOrderByUserAndAddress,
  listAccounts,
} from '../../services/api';

const ScrollDiv = styled.div`
  overflow-y: auto;
  &::-webkit-scrollbar {
    height: 1px;
    width: 3px;
    border-radius: 8px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 8px;
    width: 3px;
    border: 0;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #000;
    border-radius: 8px;
    width: 3px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export default function AddOrder() {
  const [status, setStatus] = React.useState('Select Account');
  const [queryParams, setQueryParams] = React.useState('');
  const [queryUserParams, setQueryUserParams] = React.useState('');
  const [queryAddressParams, setQueryAddressParams] = React.useState('');
  const [shopNames, setShopNames] = React.useState([]);
  const [selectedShopName, setSelectedShopName] = React.useState('');
  const [selectedUser, setSelectedUser] = React.useState('');
  const [users, setUsers] = React.useState([]);
  const [shippingAddress, setShippingAddress] = React.useState([]);
  const [shippingAddressFilter, setShippingAddressFilter] = React.useState([]);
  const [selectedShipAddress, setSelectedShipAddress] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [order, setOrder] = React.useState('');
  const [currentOrder, setCurrentOrder] = React.useState(null);
  const [loadingOrder, setLoadingOrder] = React.useState(false);
  const [openOrderAlreadyExist, setOpenOrderAlreadyExist] = React.useState(
    false
  );

  const getShopNames = useCallback(async () => {
    const accounts = await listAccounts();
    setShopNames(
      accounts.data.sort(function(a, b) {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        // a must be equal to b
        return 0;
      })
    );
  }, []);

  const filteredAccounts = React.useMemo(() => {
    return shopNames.filter(shopName =>
      shopName?.name?.includes(queryParams?.toUpperCase())
    );
  }, [queryParams, shopNames]);

  const filteredUsers = React.useMemo(() => {
    return users.filter(user =>
      user?.name?.toUpperCase()?.includes(queryUserParams?.toUpperCase())
    );
  }, [queryUserParams, users]);

  const handleAddNewAddress = add => {
    setShippingAddress([add, ...shippingAddress]);
  };

  // const filtersAddress = useCallback(() => {
  //   setShippingAddress(
  //     shippingAddressFilter.filter(sAddress =>
  //       sAddress?.sLine1
  //         .toUpperCase()
  //         .includes(queryAddressParams.toUpperCase())
  //     )
  //   );
  // }, [queryAddressParams, shippingAddressFilter, setShippingAddress]);

  const filterUsersByAccount = useCallback(async () => {
    const usersByAccount = await listAccountUsers(selectedShopName._id);
    if (usersByAccount.status === 200)
      return setUsers(usersByAccount.data.costumers);
  }, [selectedShopName._id, setUsers]);

  useEffect(() => {
    if (shopNames.length > 0) return;
    getShopNames();
  }, [getShopNames, shopNames]);

  useEffect(() => {
    if (users.length > 0) return;
    if (selectedShopName) {
      filterUsersByAccount();
    }
  }, [filterUsersByAccount, selectedShopName, users]);

  const handleAddOrder = async () => {
    setLoadingOrder(true);
    const cartInfo = [];
    const userInfo = {
      email: selectedUser?.email,
      sAddress: {
        sLine1: selectedShipAddress?.sLine1,
        sLine2: selectedShipAddress?.sLine2,
        sLine3: selectedShipAddress?.sLine3,
        sZipcode: selectedShipAddress?.sZipcode,
        sStoreProvince: selectedShipAddress?.sStoreProvince,
        sCountry: selectedShipAddress?.sCountry,
      },
    };

    if (currentOrder) {
      await updateOrder(currentOrder?._id, { products: [] });
      if (updateOrder.status === 200)
        return setOrder({
          products: [],
          ...currentOrder,
        });
    } else {
      const { data } = await createOrder({
        cartInfo,
        userInfo,
        createdByAdmin: true,
      });
      setOrder(data?.newOrder);
    }

    setOpenOrderAlreadyExist(false);
    setLoadingOrder(false);
  };

  const handleCheckOrder = async () => {
    setLoadingOrder(true);
    const response = await findOrderByUserAndAddress({
      email: selectedUser?.email,
      sLine1: selectedShipAddress?.sLine1,
      sLine2: selectedShipAddress?.sLine2,
      sZipcode: selectedShipAddress?.sZipcode,
    });
    if (response.status === 200 && response.data.length > 0) {
      setCurrentOrder(response.data[0]);
      setLoadingOrder(false);
      return setOpenOrderAlreadyExist(true);
    }
    return handleAddOrder();
  };

  const Option = ({ text }) => {
    return (
      <Grid>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          style={{ width: 180 }}
        >
          <Typography
            align="center"
            onClick={() => {
              setStatus(text);
            }}
            style={{
              fontSize: 14,
              lineheight: '18px',
              fontWeight: status === text && 'bold',
              cursor: 'pointer',
              paddingBottom: 4,
              borderBottom: status === text && '2px solid #000',
              height: 26,
              color:
                text === 'Select Account'
                  ? '#000'
                  : text === 'Select User'
                  ? status === 'Select Account'
                    ? 'rgba(0, 0, 0, 0.5)'
                    : '#000'
                  : text === 'Select Shipping Address' && status === text
                  ? '#000'
                  : 'rgba(0, 0, 0, 0.5)',
            }}
          >
            {text}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container style={{ alignContent: 'flex-start' }}>
      {!order ? (
        <>
          <Grid container justifyContent="center">
            <Typography
              style={{
                padding: '20px 0px 0px',
                fontFamily: 'Galano Grotesque Light',
                fontSize: 24,
                lineHeight: '28px',
                letterSpacing: '0.1em',
              }}
            >
              ADD ORDER
            </Typography>
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            style={{ padding: '30px 10% 5%' }}
          >
            <Option text="Select Account" />
            <Option text="Select User" />
            <Option text="Select Shipping Address" />
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            style={{ alignContent: 'flex-start' }}
          >
            {/*  ------------- PARTE DO SELECT ACCOUNT -------------  */}

            <Grid style={{ width: window.innerWidth * 0.23 }}>
              {!selectedShopName ? (
                <Grid
                  style={{ position: 'relative', bottom: 40, marginBottom: 40 }}
                >
                  <Input
                    value={queryParams}
                    onChange={e => setQueryParams(e.target.value)}
                    placeholder="Search account"
                    width={304}
                    height={32}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon
                            style={{
                              fontSize: 20,
                              position: 'relative',
                              top: 4,
                            }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              ) : (
                <Grid container justifyContent="space-between">
                  <Typography
                    style={{
                      fontSize: 18,
                      fontWeight: 'bold',
                    }}
                  >
                    {selectedShopName?.name}
                  </Typography>{' '}
                  <ClearIcon
                    onClick={() => {
                      setSelectedShopName('');
                      setStatus('Select Account');
                      setUsers([]);
                      setSelectedUser('');
                      setShippingAddress([]);
                      setSelectedShipAddress('');
                      setQueryParams('');
                      setQueryAddressParams('');
                      setQueryUserParams('');
                    }}
                    style={{
                      position: 'relative',
                      top: 3,
                      cursor: 'pointer',
                      fontSize: 20,
                    }}
                  />
                </Grid>
              )}
              <Grid style={{ width: window.innerWidth * 0.23, marginTop: 50 }}>
                {0 === 1 ? (
                  <Grid>
                    <Skeleton
                      style={{ position: 'relative', bottom: 180 }}
                      height={600}
                      width={304}
                    />
                  </Grid>
                ) : (
                  <ScrollDiv
                    style={{
                      maxHeight: window.innerHeight * 0.5,
                      // width: window.innerWidth * 0.25,
                    }}
                  >
                    {filteredAccounts.map(shopName => (
                      <Grid container>
                        <Typography
                          onClick={() => {
                            if (!selectedShopName) {
                              setSelectedShopName(shopName);
                              setStatus('Select User');
                            }
                          }}
                          style={{
                            margin: '3px 0px',
                            fontSize: 14,
                            cursor: !selectedShopName && 'pointer',
                            color: selectedShopName
                              ? 'rgba(0, 0, 0, 0.35)'
                              : '#000',
                          }}
                        >
                          {shopName.name}
                        </Typography>
                      </Grid>
                    ))}
                  </ScrollDiv>
                )}
              </Grid>
            </Grid>

            {/*  ------------- PARTE DO SELECT USER -------------  */}

            <Grid style={{ width: window.innerWidth * 0.23 }}>
              {(status === 'Select User' || users.length > 0) && (
                <Grid>
                  {!selectedUser ? (
                    <Grid style={{ position: 'relative', bottom: 40 }}>
                      <Input
                        value={queryUserParams}
                        onChange={e => setQueryUserParams(e.target.value)}
                        placeholder="Search user"
                        width={304}
                        height={32}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon
                                style={{
                                  fontSize: 20,
                                  position: 'relative',
                                  top: 4,
                                }}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  ) : (
                    <Grid container justifyContent="space-between">
                      <Typography
                        style={{
                          fontSize: 18,
                          fontWeight: 'bold',
                        }}
                      >
                        {selectedUser.name}
                      </Typography>{' '}
                      <ClearIcon
                        onClick={() => {
                          setSelectedUser('');
                          setStatus('Select User');
                          setShippingAddress([]);
                          setSelectedShipAddress('');
                        }}
                        style={{
                          position: 'relative',
                          top: 3,
                          cursor: 'pointer',
                          fontSize: 20,
                        }}
                      />
                    </Grid>
                  )}
                  <ScrollDiv
                    style={{
                      maxHeight: window.innerHeight * 0.5,
                      marginTop: 50,
                      // width: window.innerWidth * 0.25,
                    }}
                  >
                    {filteredUsers.length > 0 &&
                      filteredUsers.map(user => (
                        <Grid container>
                          <Typography
                            onClick={() => {
                              if (!selectedUser) {
                                setShippingAddress(selectedShopName?.addresses);
                                setShippingAddressFilter(
                                  selectedShopName?.addresses || []
                                );
                                setSelectedUser(user);
                                setStatus('Select Shipping Address');
                              }
                            }}
                            style={{
                              margin: '3px 0px',
                              fontSize: 14,
                              cursor: !selectedUser && 'pointer',
                              color: selectedUser
                                ? 'rgba(0, 0, 0, 0.35)'
                                : '#000',
                            }}
                          >
                            {user?.name}
                          </Typography>
                        </Grid>
                      ))}
                  </ScrollDiv>
                </Grid>
              )}
            </Grid>

            {/*  ------------- PARTE DO SHIPPING ADDRESS -------------  */}

            <Grid style={{ width: window.innerWidth * 0.23 }}>
              {(shippingAddress?.length > 0 ||
                status === 'Select Shipping Address') && (
                <Grid style={{ marginBottom: 40 }}>
                  {!selectedShipAddress ? (
                    <Grid style={{ position: 'relative', bottom: 40 }}>
                      <Input
                        value={queryAddressParams}
                        onChange={e => setQueryAddressParams(e.target.value)}
                        placeholder="Search Address"
                        width={304}
                        height={32}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon
                                style={{
                                  fontSize: 20,
                                  position: 'relative',
                                  top: 4,
                                }}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                      <Typography
                        style={{
                          marginTop: 20,
                          fontSize: 14,
                          cursor: 'pointer',
                        }}
                        onClick={() => setOpen(true)}
                      >
                        <AddIcon style={{ position: 'relative', top: 7 }} /> New
                        Shipping Address
                      </Typography>
                    </Grid>
                  ) : (
                    <Grid container justifyContent="space-between">
                      <Grid>
                        <Typography
                          style={{
                            marginBottom: 4,
                            fontSize: 18,
                            fontWeight: 'bold',
                          }}
                        >
                          Address {selectedShipAddress?.name}
                        </Typography>
                        <Typography style={{ fontSize: 12 }}>
                          {selectedShipAddress?.street}{' '}
                          {selectedShipAddress?.building_unit_floor &&
                            `- ${selectedShipAddress?.building_unit_floor}`}{' '}
                        </Typography>
                        <Typography style={{ fontSize: 12 }}>
                          {selectedShipAddress?.city}{' '}
                          {selectedShipAddress?.store_province &&
                            `- ${selectedShipAddress?.store_province}`}
                        </Typography>
                        <Typography style={{ fontSize: 12 }}>
                          {selectedShipAddress?.zipcode}{' '}
                          {selectedShipAddress?.country &&
                            `- ${selectedShipAddress?.country}`}
                        </Typography>
                      </Grid>
                      <ClearIcon
                        onClick={() => {
                          setSelectedShipAddress('');
                        }}
                        style={{
                          position: 'relative',
                          top: 3,
                          cursor: 'pointer',
                          fontSize: 20,
                        }}
                      />
                    </Grid>
                  )}
                  <ScrollDiv
                    style={{
                      maxHeight: window.innerHeight * 0.5,
                      marginTop: 50,
                      marginRight: 10,
                      // width: window.innerWidth * 0.25,
                    }}
                  >
                    {shippingAddress?.map((address, index) => (
                      <Grid
                        container
                        style={{ marginBottom: 20 }}
                        onClick={() =>
                          setSelectedShipAddress({ ...address, index })
                        }
                      >
                        <Grid style={{ cursor: 'pointer' }}>
                          <Typography style={{ marginBottom: 4 }}>
                            {address?.name}
                          </Typography>
                          <Typography style={{ fontSize: 12 }}>
                            {address?.street}{' '}
                            {address?.building_unit_floor &&
                              `- ${address?.building_unit_floor}`}{' '}
                          </Typography>
                          <Typography style={{ fontSize: 12 }}>
                            {address?.city}{' '}
                            {address?.store_province &&
                              `- ${address?.store_province}`}
                          </Typography>
                          <Typography style={{ fontSize: 12 }}>
                            {address?.zipcode}{' '}
                            {address?.country && `- ${address?.country}`}
                          </Typography>
                        </Grid>
                      </Grid>
                    ))}
                  </ScrollDiv>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid
            container
            style={{ position: 'absolute', bottom: '3%', left: '9%' }}
          >
            <Button
              text="Add Order"
              width={268}
              height={40}
              color={selectedShipAddress ? '#000' : '#fff'}
              borderColor="1px solid rgba(0,0,0, 0.35)"
              textColor={selectedShipAddress ? '#fff' : 'rgba(0,0,0, 0.35)'}
              justifyContent="center"
              disabled={!selectedShipAddress}
              onClick={handleCheckOrder}
              loading={loadingOrder}
            />
          </Grid>
          <ModalAddNewAddress
            open={open}
            handleClose={() => setOpen(false)}
            email={selectedUser?.email}
            currentAddress={shippingAddressFilter}
            handleAddNewAddress={handleAddNewAddress}
          />
          <ModalOrderAlreadyExist
            open={openOrderAlreadyExist}
            handleClose={() => setOpenOrderAlreadyExist(false)}
            order={currentOrder}
            newOrder={handleAddOrder}
            editOrder={() => {
              setOpenOrderAlreadyExist(false);
              setOrder(currentOrder);
            }}
          />
        </>
      ) : (
        <MakeOrder
          orderSelected={order?._id}
          handleBack={() => {
            setOrder('');
            setCurrentOrder('');
          }}
        />
      )}
    </Grid>
  );
}
