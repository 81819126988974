import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    textTransform: 'none',
    padding: '0px',
    minWidth: 'auto',
    cursor: props => props.cursor,
  },
  contained: {
    boxShadow: 'none',
    backgroundColor: 'transparent',
    border: props => `1px solid ${props.color}`,
    borderRadius: 'unset',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: 'transparent',
    },
  },
  endIcon: {
    marginLeft: 3,
    marginRight: 5,
    position: 'relative',
    bottom: 3.5,
  },
  label: {
    alignItems: 'end',
  },
});

export default function SustainableTag({ tagName, style, endIcon }) {
  const classes = useStyles(style);

  return (
    <Button
      variant="contained"
      style={style}
      className={`${classes.root}  ${classes.contained} ${classes.label}`}
    >
      <Typography
        style={{
          position: 'relative',
          bottom: 2,
          color: style.color,
          padding: '4px 8px',
          fontSize: 10,
        }}
      >
        {tagName}
      </Typography>
    </Button>
  );
}
