import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Dashboard from './Dashboard';
import Header from '../../components/HeaderView';
import useUserContext from '../../hooks/useUserContext';
import OrderHistory from './OrderHistory';
import AddressList from './AddressList';
import Business from './Business';
import Account from './Account';
import Profile from './Profile';
import api from '../../services/api';
import useConfigContext from '../../hooks/useConfigContext';

import { ReactComponent as UserLogo } from '../../assets/user.svg';
import { ReactComponent as OrderLogo } from '../../assets/order.svg';
import { ReactComponent as AddressLogo } from '../../assets/address.svg';
import { ReactComponent as DashboardLogo } from '../../assets/dashboard.svg';
import { ReactComponent as UsersLogo } from '../../assets/users.svg';

const useStyles = makeStyles(() => ({
  dasboardMenu: {
    padding: 16,
    backgroundColor: 'white',
    maxWidth: '23%',
  },
  menu: {
    padding: 16,
    backgroundColor: 'white',
    maxWidth: '23%',
    position: 'relative',
    top: `-${window.innerHeight * 0.4 * 0.25}px`,
  },

  gridMenuItem: {
    marginTop: 12,
  },

  dashboardContent: {
    position: 'relative',
    top: `-${window.innerHeight * 0.4 * 0.2}px`,
    padding: '0px 20px',
    gridGap: 'clamp(8px, 10px, 13px)',
  },

  pagesContent: {
    padding: '0px 20px',
    gridGap: 'clamp(8px, 10px, 13px)',
  },
  typographyMenuItem: {
    cursor: 'pointer',
  },
  boldMenuItem: {
    fontFamily: 'GalanoGrotesque-Medium',
    fontWeight: 700,
    lineHeight: '14px',
    cursor: 'pointer',
  },
  menuTitle: {
    fontFamily: 'Galano Grotesque Light',
    fontWeight: 400,
    fontSize: 24,
    lineHeight: '32px',
  },
}));

export default function ClientArea() {
  const [selected, setSelected] = useState('Dashboard');
  const { configState } = useConfigContext();
  const seasonName = configState.season.name || '';

  const { userState } = useUserContext();
  const { user: profile } = userState;

  const classes = useStyles();
  const [accountName, setAccountName] = useState('');

  useEffect(() => {
    (async () => {
      const { data } = await api.get(
        `newCostumers/${localStorage.getItem('showroom-userEmail')}`
      );
      setAccountName(data.account.name.toUpperCase());
    })();
  }, []);

  function HeaderContent() {
    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        style={{
          background: `url("https://storage.googleapis.com/showroom-bucket-images/${seasonName}/Home/Logada/Desk/Who_We_Are.jpg") no-repeat   fixed center  `,
          backgroundSize: 'cover',
          height: window.innerHeight * 0.4,
        }}
      >
        <Header />

        <Grid style={{ margin: 'auto 0px', textAlign: 'center' }}>
          <Typography
            style={{
              fontSize: 32,
              lineHeight: '48px',
              color: 'white',
            }}
          >
            {textTitle()}
          </Typography>

          {selected === 'Dashboard' && (
            <Typography
              style={{
                fontSize: 24,
                lineHeight: '32px',
                color: 'white',
              }}
            >
              Welcome to your client area
            </Typography>
          )}
        </Grid>
      </Grid>
    );
  }

  function ClientMenu() {
    return (
      <Grid
        item
        xs={3}
        className={
          selected !== 'Dashboard' ? classes.menu : classes.dasboardMenu
        }
      >
        {selected !== 'Dashboard' && (
          <Grid style={{ padding: '16px 93px 16px 16px' }}>
            <Typography className={classes.menuTitle}>
              {profile?.name}
            </Typography>
            <Typography style={{ fontSize: 10, marginTop: 8 }}>
              {accountName}
            </Typography>
          </Grid>
        )}

        <Grid container wrap="nowrap" alignItems="center">
          <DashboardLogo />
          <Typography
            className={
              selected === 'Dashboard'
                ? classes.boldMenuItem
                : classes.typographyMenuItem
            }
            onClick={handleMenuClick}
          >
            Dashboard
          </Typography>
        </Grid>
        <Grid
          container
          alignItems="center"
          wrap="nowrap"
          className={classes.gridMenuItem}
        >
          <UserLogo />
          <Typography
            className={
              selected === 'Profile & Preferences'
                ? classes.boldMenuItem
                : classes.typographyMenuItem
            }
            onClick={handleMenuClick}
          >
            Profile & Preferences
          </Typography>
        </Grid>
        <Grid
          container
          alignItems="center"
          wrap="nowrap"
          className={classes.gridMenuItem}
        >
          <UsersLogo />
          <Typography
            className={
              selected === 'Account & Users'
                ? classes.boldMenuItem
                : classes.typographyMenuItem
            }
            onClick={handleMenuClick}
          >
            Account & Users
          </Typography>
        </Grid>

        <Grid
          container
          alignItems="center"
          wrap="nowrap"
          className={classes.gridMenuItem}
        >
          <AddressLogo />
          <Typography
            className={
              selected === 'Address List'
                ? classes.boldMenuItem
                : classes.typographyMenuItem
            }
            onClick={handleMenuClick}
          >
            Address List
          </Typography>
        </Grid>
        <Grid
          container
          alignItems="center"
          wrap="nowrap"
          className={classes.gridMenuItem}
        >
          <OrderLogo />
          <Typography
            className={
              selected === 'Order History'
                ? classes.boldMenuItem
                : classes.typographyMenuItem
            }
            onClick={handleMenuClick}
          >
            Order History
          </Typography>
        </Grid>
        {/* <Grid
          container
          alignItems="center"
          wrap="nowrap"
          className={classes.gridMenuItem}
        >
          <OfficeLogo />
          <Typography
            className={
              selected === 'Business Information'
                ? classes.boldMenuItem
                : classes.typographyMenuItem
            }
            onClick={handleMenuClick}
          >
            Business Information
          </Typography>
        </Grid>
        <Grid
          container
          alignItems="center"
          wrap="nowrap"
          className={classes.gridMenuItem}
        >
          <PaymentLogo />
          <Typography
            className={
              selected === 'Payment'
                ? classes.boldMenuItem
                : classes.typographyMenuItem
            }
            onClick={handleMenuClick}
          >
            Payment
          </Typography>
        </Grid> */}
      </Grid>
    );
  }

  const handleMenuClick = e => {
    setSelected(e.target.textContent);
  };

  const textTitle = () => {
    switch (selected) {
      case 'Dashboard':
        return `Hello, ${profile?.name}`;
      case 'Profile & Preferences':
        return 'Profile & Preferences';
      case 'Account & Users':
        return 'Account & Users';
      case 'Address List':
        return 'Address List';
      case 'Order History':
        return 'Order History';
      case 'Business Information':
        return 'Business Information';
      default:
        return 'Payment';
    }
  };
  return (
    <>
      <HeaderContent />
      {selected === 'Dashboard' ? (
        <Grid container className={classes.dashboardContent}>
          <ClientMenu />
          <Dashboard cardClick={setSelected} />
        </Grid>
      ) : (
        <Grid container className={classes.pagesContent}>
          <ClientMenu />

          {selected === 'Order History' && <OrderHistory />}
          {selected === 'Profile & Preferences' && <Profile />}
          {selected === 'Address List' && <AddressList />}
          {selected === 'Account & Users' && <Account />}
          {selected === 'Business Information' && <Business />}
        </Grid>
      )}
    </>
  );
}
