export const { format: formatPrice } = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const formatWithoutCent = num => {
  const monetary = formatPrice(num);
  return monetary.split('.')[0];
};

export const capitalize = s =>
  !s? "": s.toLowerCase().replace(/\b./g, a => {
    return a.toUpperCase();
  });
