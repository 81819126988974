import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';
import { useRouteMatch } from 'react-router-dom';
import { Typography, Grid, Paper } from '@material-ui/core';
import api from '../../services/api';
import Carousel from '../CarouselModalProduct';
import { dictColor } from '../../consts';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalProduct({
  open,
  product,
  handleClose,
  handleNextProduct,
  handlePrevProduct,
  setProducts,
  products = [],
  style_code,
}) {
  const { params } = useRouteMatch();
  const { delivery_id } = params;
  const [photos, setPhotos] = useState(null);
  const [isFavorite, setIsFavorite] = useState(false);
  const [productFetched, setProductFetched] = useState(false);

  async function handleAddFavorite() {
    try {
      setIsFavorite(!isFavorite);
      await api.post(`/collections`, { style_code: productFetched.style_code });
    } catch (err) {
      console.log(err);
      const response = await api.get(
        `/collections/${product.style_code}/check`
      );
      setIsFavorite(response.data);
    }
  }
  useEffect(() => {
    async function getDetailProducts() {
      const response = await api.get(`/product/${style_code.style_code}`);

      if (response.data && response.data.print_name) {
        response.data.print_name = response.data.print_name.join(' + ');
      }
      if (!response.data.msg) {
        setProductFetched(response.data);
      }
    }
    if (!product) {
      getDetailProducts();
    } else {
      setProductFetched(product);
    }
  }, [product, style_code]);

  useEffect(() => {
    if (productFetched.other_images && productFetched.mainly_image) {
      setPhotos([productFetched.mainly_image, ...productFetched.other_images]);
    } else if (productFetched.other_images) {
      setPhotos(productFetched.other_images);
    }
    // return setPhotos([
    //   'https://storage.googleapis.com/showroom-bucket-images/ZggLz9GA.jpeg',
    //   'https://storage.googleapis.com/showroom-bucket-images/ZggLz9GA.jpeg',
    // ]);
  }, [productFetched]);

  useEffect(() => {
    async function checkIsFavorite() {
      const response = await api.get(
        `/collections/${productFetched.style_code}/check`
      );
      setIsFavorite(response.data);
    }
    checkIsFavorite();
  }, [productFetched]);

  const DetailProduct = () => {
    return (
      <Paper
        style={{
          zIndex: 5000,
          width: '90%',
          margin: '0 auto',
          height: 'auto',
          marginBottom: '50px',
          borderRadius: 0,
          padding: '5px 25px',
          background: dictColor[productFetched.color || 'BLACK'],
          position: 'relative',
          top: '-3%',
        }}
      >
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography
            style={{
              width: 100,
              paddingTop: 9,
              color:
                productFetched.color === 'OFF-WHITE' ||
                productFetched.color === 'OFF WHITE' ||
                productFetched.color === 'OFF-WHITE ' ||
                productFetched.color === 'ORANGE' ||
                productFetched.color === 'RAINBOW'
                  ? '#000000'
                  : '#fff',
            }}
          >
            DELIVERY {delivery_id}{' '}
          </Typography>
          <Grid>
            <Grid
              container
              justifyContent="space-between"
              style={{ width: 125, marginTop: 5 }}
            >
              <Typography
                onClick={handlePrevProduct}
                variant="body2"
                style={{
                  color:
                    productFetched.color === 'OFF-WHITE' ||
                    productFetched.color === 'OFF WHITE' ||
                    productFetched.color === 'OFF-WHITE ' ||
                    productFetched.color === 'ORANGE' ||
                    productFetched.color === 'RAINBOW'
                      ? '#000000'
                      : '#fff',
                  cursor: 'pointer',
                }}
              >
                {' '}
                &lt; Previous
              </Typography>
              <Typography
                onClick={handleNextProduct}
                variant="body2"
                style={{
                  color:
                    productFetched.color === 'OFF-WHITE' ||
                    productFetched.color === 'OFF WHITE' ||
                    productFetched.color === 'OFF-WHITE ' ||
                    productFetched.color === 'ORANGE' ||
                    productFetched.color === 'RAINBOW'
                      ? '#000000'
                      : '#fff',
                  cursor: 'pointer',
                }}
              >
                Next &gt;
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container style={{ margin: '10px 0px' }}>
          <Grid style={{ position: 'relative' }}>
            <Typography
              style={{
                color:
                  productFetched.color === 'OFF-WHITE' ||
                  productFetched.color === 'OFF WHITE' ||
                  productFetched.color === 'OFF-WHITE ' ||
                  productFetched.color === 'ORANGE' ||
                  productFetched.color === 'RAINBOW'
                    ? '#000000'
                    : '#fff',
                fontSize: 23,
                lineHeight: 1.1,
                width: '100%',
              }}
            >
              {productFetched.description}
            </Typography>
          </Grid>
        </Grid>
        <Grid>
          <Typography
            variant="body2"
            style={{
              fontSize: 20,
              color:
                productFetched.color === 'OFF-WHITE' ||
                productFetched.color === 'OFF WHITE' ||
                productFetched.color === 'OFF-WHITE ' ||
                productFetched.color === 'ORANGE' ||
                productFetched.color === 'RAINBOW'
                  ? '#000000'
                  : '#fff',
            }}
          >
            {productFetched.style_code}
          </Typography>
        </Grid>

        <Grid style={{ margin: '20px 0px' }}>
          <Typography
            style={{
              color:
                productFetched.color === 'OFF-WHITE' ||
                productFetched.color === 'OFF WHITE' ||
                productFetched.color === 'OFF-WHITE ' ||
                productFetched.color === 'ORANGE' ||
                productFetched.color === 'RAINBOW'
                  ? '#000000'
                  : '#fff',
              fontSize: 13,
              width: '100%',
            }}
          >
            Composition
          </Typography>
          <Typography
            variant="body2"
            style={{
              color:
                productFetched.color === 'OFF-WHITE' ||
                productFetched.color === 'OFF WHITE' ||
                productFetched.color === 'OFF-WHITE ' ||
                productFetched.color === 'ORANGE' ||
                productFetched.color === 'RAINBOW'
                  ? '#000000'
                  : '#fff',
              textTransform: 'capitalize',
              width: '100%',
              fontSize: 16,
            }}
          >
            {productFetched.composition}
          </Typography>
          <Typography
            variant="body2"
            style={{
              color:
                productFetched.color === 'OFF-WHITE' ||
                productFetched.color === 'OFF WHITE' ||
                productFetched.color === 'OFF-WHITE ' ||
                productFetched.color === 'ORANGE' ||
                productFetched.color === 'RAINBOW'
                  ? '#000000'
                  : '#fff',
              textTransform: 'capitalize',
              width: '100%',
              fontSize: 16,
            }}
          >
            {productFetched.fabric_name}
          </Typography>
        </Grid>
        <Grid>
          <Typography
            style={{
              fontSize: 10,
              color:
                productFetched.color === 'OFF-WHITE' ||
                productFetched.color === 'OFF WHITE' ||
                productFetched.color === 'OFF-WHITE ' ||
                productFetched.color === 'ORANGE' ||
                productFetched.color === 'RAINBOW'
                  ? '#000000'
                  : '#fff',
              width: '100%',
            }}
          >
            Print
          </Typography>
          <Typography
            style={{
              width: '75%',
              color:
                productFetched.color === 'OFF-WHITE' ||
                productFetched.color === 'OFF WHITE' ||
                productFetched.color === 'OFF-WHITE ' ||
                productFetched.color === 'ORANGE' ||
                productFetched.color === 'RAINBOW'
                  ? '#000000'
                  : '#fff',
              textTransform: 'capitalize',
              fontSize:
                productFetched.print_name &&
                (productFetched.print_name.length > 100
                  ? 10
                  : productFetched.print_name.length > 50
                  ? 12
                  : 14),
            }}
          >
            {productFetched.print_name}
          </Typography>
        </Grid>
        <Grid style={{ margin: '20px 0px' }}>
          <Typography
            style={{
              fontSize: 10,
              color:
                productFetched.color === 'OFF-WHITE' ||
                productFetched.color === 'OFF WHITE' ||
                productFetched.color === 'OFF-WHITE ' ||
                productFetched.color === 'ORANGE' ||
                productFetched.color === 'RAINBOW'
                  ? '#000000'
                  : '#fff',
              width: '100%',
            }}
          >
            Price
          </Typography>
          <Typography
            variant="body2"
            style={{
              width: '100%',
              fontSize: 16,
              color:
                productFetched.color === 'OFF-WHITE' ||
                productFetched.color === 'OFF WHITE' ||
                productFetched.color === 'OFF-WHITE ' ||
                productFetched.color === 'ORANGE' ||
                productFetched.color === 'RAINBOW'
                  ? '#000000'
                  : '#fff',
            }}
          >
            {`Wholesale ${productFetched.wholesale_usd}$ / ${productFetched.wholesale_eur}€`}{' '}
            <br />
            {` MSRP ${productFetched.msr_price_usd}$ /  ${productFetched.msr_price_eur}€`}
          </Typography>
        </Grid>
        <Grid
          container
          alignItems="center"
          style={{ cursor: 'pointer', marginBottom: '20px' }}
          onClick={handleAddFavorite}
        >
          <Typography
            variant="body2"
            style={{
              fontSize: 16,
              color:
                productFetched.color === 'OFF-WHITE' ||
                productFetched.color === 'OFF WHITE' ||
                productFetched.color === 'OFF-WHITE ' ||
                productFetched.color === 'ORANGE' ||
                productFetched.color === 'RAINBOW'
                  ? '#000000'
                  : '#fff',
              marginRight: 4,
              // paddingBottom: 5,
            }}
          >
            {isFavorite ? 'Added' : 'Add'} to collection
          </Typography>
          {isFavorite ? (
            <AiFillHeart
              style={{
                color:
                  productFetched.color === 'OFF-WHITE' ||
                  productFetched.color === 'OFF WHITE' ||
                  productFetched.color === 'OFF-WHITE ' ||
                  productFetched.color === 'ORANGE' ||
                  productFetched.color === 'RAINBOW'
                    ? '#000000'
                    : '#fff',
                fontSize: 16,
                marginTop: 7,
              }}
            />
          ) : (
            <AiOutlineHeart
              style={{
                color:
                  productFetched.color === 'OFF-WHITE' ||
                  productFetched.color === 'OFF WHITE' ||
                  productFetched.color === 'OFF-WHITE ' ||
                  productFetched.color === 'ORANGE' ||
                  productFetched.color === 'RAINBOW'
                    ? '#000000'
                    : '#fff',
                fontSize: 14,
              }}
            />
          )}
        </Grid>
      </Paper>
    );
  };
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullScreen
        style={{ backgroundColor: 'rgba(256,256,256,0.7)' }}
      >
        <Carousel
          photos={photos}
          handleClose={handleClose}
          DetailProduct={DetailProduct}
        />
      </Dialog>
    </div>
  );
}
