import React, { createContext, useState } from 'react';

export const initialState = {
  token: localStorage.getItem('showroom-userToken'),
  user: {
    email: localStorage.getItem('showroom-userEmail'),
  },
  request: {
    error: {
      status: 0,
      message: '',
    },
    pending: false,
  },
};

const UserContext = createContext();

export { UserContext };

export default function UserContextProvider({ children }) {
  const state = useState(initialState);

  return <UserContext.Provider value={state}>{children}</UserContext.Provider>;
}
