import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import {
  Grid,
  FormControlLabel,
  Radio,
  Typography,
  RadioGroup,
  TextField,
  IconButton,
} from '@material-ui/core';
import ButtonComponent from '../../components/Button';
import InputNumber from '../../components/InputNumber';
import CheckLabel from '../../components/CheckLabel';
import TextArea from '../../components/TextArea';

const PlusIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 3V13"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 8H3"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const useStyles = makeStyles(() => ({
  containerDiv: {
    marginTop: 90,
  },
  marginTop32: {
    marginTop: 32,
  },
  marginBottom24: {
    marginBottom: 24,
  },
  avarageStore: {
    flexDirection: 'row',
    position: 'relative',
    top: 12,
    marginLeft: 24,
  },
  fontSize14: {
    fontSize: 14,
  },
  marginRight16: {
    marginRight: 16,
  },
}));

export default function Account() {
  const [joorRadio, setJoorRadio] = useState('No');
  const [storeRadio, setStoreRadio] = useState('');
  const [files, setFiles] = useState('');
  const [brands, setBrands] = useState(0);
  const [yearInBusiness, setYearInBusiness] = useState(1985);
  const [numberOfDoors, setNumberOfDoors] = useState(0);
  const classes = useStyles();

  const handleStoreRadio = e => {
    setStoreRadio(e.target.value);
  };

  const handleJoorRadio = e => {
    setJoorRadio(e.target.value);
  };

  const changeDocuments = e => {
    if (e.target.files && e.target.files?.length > 0) {
      const filesURL = [];
      const filess = [];
      let stop = false;
      [...e.target.files].map(file => {
        if (file.size > 5 * 1000 * 1000) {
          stop = true;
        }
        filesURL.push(URL.createObjectURL(file));
        return filess.push(file);
      });
      if (!stop) setFiles(filess);
    }
  };

  const handleRemoveFile = index => {
    setFiles(files.filter((doc, i) => index !== i));
  };

  return (
    <Grid
      item
      container
      xs={9}
      justifyContent="space-between"
      className={classes.marginTop32}
    >
      <Grid container>
        <Grid item xs={4}>
          <Typography className={classes.fontSize14}>
            Business Profile
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Grid item xs={8} className={classes.marginBottom24}>
            <Typography className={classes.fontSize14}>
              Buying Season
            </Typography>
          </Grid>
          <Grid item xs={8} container>
            <CheckLabel label="Spring / Summer" />
            <Grid style={{ marginLeft: 92 }}>
              <CheckLabel label="High Summer / Pre-Fall " />
            </Grid>
          </Grid>
          <Grid item container xs={8}>
            <CheckLabel label="Fall" />

            <Grid style={{ marginLeft: 170 }}>
              <CheckLabel label="Winter / Resort / Holiday" />
            </Grid>
          </Grid>
          <Grid
            item
            xs={8}
            className={`${classes.marginBottom24} ${classes.marginTop32}`}
          >
            <Typography className={classes.fontSize14}>
              Products of interest
            </Typography>
          </Grid>
          <Grid item container xs={8}>
            <CheckLabel label="Ready to Wear" />

            <Grid style={{ marginLeft: 104 }}>
              <CheckLabel label="Kids" />
            </Grid>
          </Grid>
          <Grid item container xs={8}>
            <CheckLabel label="Swimsuits" />

            <Grid style={{ marginLeft: 131 }}>
              <CheckLabel label="Home" />
            </Grid>
          </Grid>
          <Grid item xs={8}>
            <CheckLabel label="Shoes" />
          </Grid>

          <Grid item container xs={8} className={classes.marginTop32}>
            <Typography
              className={`${classes.marginRight16} ${classes.fontSize14}`}
            >
              In business since
            </Typography>
            <InputNumber
              value={yearInBusiness}
              setValue={setYearInBusiness}
              year
            />
          </Grid>
          <Grid container item xs={8} style={{ marginTop: 45 }}>
            <Typography className={classes.fontSize14}>
              Are you on JOOR?
            </Typography>
            <Grid style={{ position: 'relative', bottom: 5, marginLeft: 20 }}>
              <RadioGroup
                aria-label="freight forwarder"
                value={joorRadio}
                onChange={handleJoorRadio}
                style={{ flexDirection: 'row' }}
              >
                <FormControlLabel
                  value="No"
                  control={<Radio size="small" />}
                  label={
                    <Typography
                      style={{
                        fontSize: 12,
                        position: 'relative',
                        top: -2,
                        marginRight: 30,
                      }}
                    >
                      No
                    </Typography>
                  }
                />
                <FormControlLabel
                  value="Yes"
                  control={<Radio size="small" />}
                  label={
                    <Typography
                      style={{
                        fontSize: 12,
                        position: 'relative',
                        top: -2,
                        marginRight: 30,
                      }}
                    >
                      Yes
                    </Typography>
                  }
                />
              </RadioGroup>
            </Grid>
            {joorRadio === 'Yes' && (
              <TextField style={{ width: '70%' }} label="Joor Profile Link" />
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid container className={classes.containerDiv}>
        <Grid item xs={4}>
          <Typography className={classes.fontSize14}>Store Profile</Typography>
        </Grid>
        <Grid item xs={8}>
          <Grid item xs={8} style={{ marginBottom: 19 }}>
            <Typography className={classes.fontSize14}>
              Online / Brick & Mortar / Both
            </Typography>
          </Grid>
          <Grid container item xs={8}>
            <RadioGroup
              aria-label="freight forwarder"
              value={storeRadio}
              onChange={handleStoreRadio}
              style={{ flexDirection: 'row' }}
            >
              <FormControlLabel
                value="Online"
                control={<Radio size="small" />}
                label={
                  <Typography
                    style={{
                      fontSize: 12,
                      position: 'relative',
                      top: -2,
                      marginRight: 30,
                    }}
                  >
                    Online
                  </Typography>
                }
              />
              <FormControlLabel
                value="Brick & Mortar"
                control={<Radio size="small" />}
                label={
                  <Typography
                    style={{
                      fontSize: 12,
                      position: 'relative',
                      top: -2,
                      marginRight: 30,
                    }}
                  >
                    Brick & Mortar
                  </Typography>
                }
              />
              <FormControlLabel
                value="Both"
                control={<Radio size="small" />}
                label={
                  <Typography
                    style={{
                      fontSize: 12,
                      position: 'relative',
                      top: -2,
                      marginRight: 30,
                    }}
                  >
                    Both
                  </Typography>
                }
              />
            </RadioGroup>
          </Grid>
          {storeRadio === 'Online' ? (
            <>
              <Grid
                item
                container
                xs={8}
                wrap="nowrap"
                className={classes.marginTop32}
              >
                <Typography
                  className={`${classes.marginRight16} ${classes.fontSize14}`}
                >
                  Number of brands currently sold
                </Typography>
                <InputNumber value={brands} setValue={setBrands} />
              </Grid>
              <Grid item xs={8} className={classes.marginTop32}>
                <TextArea
                  title="Brands Carried"
                  subtitle="Use comma to separate"
                  fullWidth
                />
              </Grid>

              <Grid item xs={8} className={classes.marginTop32}>
                <TextArea
                  title="Tell us about your costumer profile"
                  subtitle="Helper text goes here"
                  fullWidth
                />
              </Grid>
            </>
          ) : storeRadio === 'Brick & Mortar' ? (
            <>
              <Grid item container xs={8} className={classes.marginTop32}>
                <Typography
                  className={`${classes.marginRight16} ${classes.fontSize14}`}
                >
                  Number of doors
                </Typography>
                <InputNumber
                  value={numberOfDoors}
                  setValue={setNumberOfDoors}
                />
              </Grid>
              <Grid item container xs={8} className={classes.marginTop32}>
                <TextField label="Avarage Store Size" />
                <RadioGroup
                  aria-label="freight forwarder"
                  // value={storeRadio}
                  // onChange={handleStoreRadio}
                  className={classes.avarageStore}
                >
                  <FormControlLabel
                    value="m²"
                    control={<Radio size="small" />}
                    label={
                      <Typography
                        style={{
                          fontSize: 12,
                          position: 'relative',
                          top: -2,
                          marginRight: 30,
                        }}
                      >
                        m²
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value="sq²"
                    control={<Radio size="small" />}
                    label={
                      <Typography
                        style={{
                          fontSize: 12,
                          position: 'relative',
                          top: -2,
                          marginRight: 30,
                        }}
                      >
                        sq²
                      </Typography>
                    }
                  />
                </RadioGroup>
              </Grid>
              <Grid item xs={8} style={{ marginTop: 54 }}>
                <Typography className={classes.fontSize14}>
                  Store Images
                </Typography>
                <Grid container style={{ marginTop: 10, width: 420 }}>
                  <Grid>
                    <Grid container>
                      {files?.length > 0 &&
                        files.map((document, index) => (
                          <Grid
                            item
                            style={{
                              margin: '6px 10px 6px 0px',
                              position: 'relative',
                            }}
                          >
                            <Grid container justifyContent="center">
                              <Grid
                                style={{
                                  position: 'absolute',
                                  left: 61,
                                  bottom: 61,
                                }}
                                onClick={() => handleRemoveFile(index)}
                              >
                                <IconButton size="small">
                                  <ClearIcon
                                    style={{
                                      fontSize: 14,
                                      color: '#000000',
                                    }}
                                  />
                                </IconButton>
                              </Grid>
                              <img
                                src={URL.createObjectURL(document)}
                                style={{
                                  width: 80,
                                  height: 80,
                                }}
                                alt="doc"
                              />
                            </Grid>
                          </Grid>
                        ))}
                      <Grid item style={{ margin: '6px 10px 6px 0px' }}>
                        <input
                          style={{ display: 'none' }}
                          className="files-dropzone"
                          accept="image/gif, image/jpeg, image/png"
                          id="button-import-docs"
                          type="file"
                          onChange={changeDocuments}
                          multiple
                        />
                        <label
                          htmlFor="button-import-docs"
                          style={{ display: 'inherit', cursor: 'pointer' }}
                        >
                          <Grid
                            container
                            style={{
                              width: 80,
                              height: 80,
                              alignItems: 'center',
                              border: '1px solid #000000',
                            }}
                            justifyContent="center"
                            alignItems="center"
                          >
                            <PlusIcon />
                          </Grid>
                        </label>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={8} className={classes.marginTop32}>
                <TextArea
                  title="Tell us about your Store Profile"
                  subtitle="Helper text goes here"
                  fullWidth
                />
              </Grid>
              <Grid item xs={8} className={classes.marginTop32}>
                <TextArea
                  title="Tell us about your Costumer Profile"
                  subtitle="Helper text goes here"
                  fullWidth
                />
              </Grid>
              <Grid item xs={8} className={classes.marginTop32}>
                <TextArea
                  title=" Brands Carried"
                  subtitle="Helper text goes here"
                  fullWidth
                />
              </Grid>
            </>
          ) : storeRadio === 'Both' ? (
            <>
              <Grid item xs={8} className={classes.marginTop32}>
                <TextArea
                  title="Tell us about your Costumer Profile"
                  subtitle="Helper text goes here"
                  fullWidth
                />
              </Grid>
              <Grid
                item
                container
                xs={8}
                wrap="nowrap"
                className={classes.marginTop32}
              >
                <Typography
                  className={`${classes.marginRight16} ${classes.fontSize14}`}
                >
                  Number of brands currently sold
                </Typography>
                <InputNumber value={brands} setValue={setBrands} />
              </Grid>
              <Grid item xs={8} className={classes.marginTop32}>
                <TextArea
                  title="Brands Carried"
                  subtitle="Helper text goes here"
                  fullWidth
                />
              </Grid>
              <Grid item container xs={8} className={classes.marginTop32}>
                <Typography
                  className={`${classes.marginRight16} ${classes.fontSize14}`}
                >
                  Number of doors
                </Typography>
                <InputNumber
                  value={numberOfDoors}
                  setValue={setNumberOfDoors}
                />
              </Grid>
              <Grid item container xs={8} className={classes.marginTop32}>
                <TextField label="Avarage Store Size" />
                <RadioGroup
                  aria-label="freight forwarder"
                  // value={storeRadio}
                  // onChange={handleStoreRadio}
                  className={classes.avarageStore}
                >
                  <FormControlLabel
                    value="m²"
                    control={<Radio size="small" />}
                    label={
                      <Typography
                        style={{
                          fontSize: 12,
                          position: 'relative',
                          top: -2,
                          marginRight: 30,
                        }}
                      >
                        m²
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value="sq²"
                    control={<Radio size="small" />}
                    label={
                      <Typography
                        style={{
                          fontSize: 12,
                          position: 'relative',
                          top: -2,
                          marginRight: 30,
                        }}
                      >
                        sq²
                      </Typography>
                    }
                  />
                </RadioGroup>
              </Grid>
              <Grid item xs={8} style={{ marginTop: 54 }}>
                <Typography className={classes.fontSize14}>
                  Store Images
                </Typography>
                <Grid container style={{ marginTop: 10, width: 420 }}>
                  <Grid>
                    <Grid container>
                      {files?.length > 0 &&
                        files.map((document, index) => (
                          <Grid
                            item
                            style={{
                              margin: '6px 10px 6px 0px',
                              position: 'relative',
                            }}
                          >
                            <Grid container justifyContent="center">
                              <Grid
                                style={{
                                  position: 'absolute',
                                  left: 61,
                                  bottom: 61,
                                }}
                                onClick={() => handleRemoveFile(index)}
                              >
                                <IconButton size="small">
                                  <ClearIcon
                                    style={{
                                      fontSize: 14,
                                      color: '#000000',
                                    }}
                                  />
                                </IconButton>
                              </Grid>
                              <img
                                src={URL.createObjectURL(document)}
                                style={{
                                  width: 80,
                                  height: 80,
                                }}
                                alt="doc"
                              />
                            </Grid>
                          </Grid>
                        ))}
                      <Grid item style={{ margin: '6px 10px 6px 0px' }}>
                        <input
                          style={{ display: 'none' }}
                          className="files-dropzone"
                          accept="image/gif, image/jpeg, image/png"
                          id="button-import-docs"
                          type="file"
                          onChange={changeDocuments}
                          multiple
                        />
                        <label
                          htmlFor="button-import-docs"
                          style={{ display: 'inherit', cursor: 'pointer' }}
                        >
                          <Grid
                            container
                            style={{
                              width: 80,
                              height: 80,
                              alignItems: 'center',
                              border: '1px solid #000000',
                            }}
                            justifyContent="center"
                            alignItems="center"
                          >
                            <PlusIcon />
                          </Grid>
                        </label>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={8} className={classes.marginTop32}>
                <TextArea
                  title="Tell us about your Store Profile"
                  subtitle="Helper text goes here"
                  style={{ marginTop: 16 }}
                  fullWidth
                />
              </Grid>
            </>
          ) : null}
        </Grid>
      </Grid>

      <Grid
        justifyContent="center"
        container
        style={{ marginBottom: 30, marginTop: 40 }}
      >
        <ButtonComponent
          width={180}
          height={40}
          textColor="#fff"
          color="#000"
          text="Save Changes"
          justifyContent="center"
        />
      </Grid>
    </Grid>
  );
}
