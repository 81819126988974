import React from 'react';
import { Grid, Typography, TextField, FormHelperText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

export const dictIdDelivery = {
  '5f34a6b5ed5ff21fbc9939c6': 'Delivery 1 FEB 15-28',
  '5f34a7b6bc82e351349c76bf': 'Delivery 2 MAR 15-30',
  '5f34a7c2bc82e351349c76c0': 'Delivery 3 APR 15-30',
  All: 'All',
};

const useStyles = makeStyles({
  root: ({ width, height, minHeight }) => ({
    '& .MuiTypography-root': {
      paddingLeft: 8,
    },
    '& .MuiOutlinedInput-root': {
      width,
      height: height ? height + 5 : 'auto',
      minHeight,
      display: 'flex',
      flexWrap: 'wrap',
      borderRadius: 0,
      padding: 0,
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '1.5px solid #000000',
    },

    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #000000',
    },

    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '2px solid #000000',
    },
    '& .MuiOutlinedInput-inputAdornedStart': {
      marginLeft: 35,
    },
  }),
  menuPaper: {
    maxHeight: 300,
  },
  defaultValue: () => ({
    color: 'rgba(0, 0, 0, 0.38)',
  }),
});

const TextInput = ({
  field,
  width,
  height,
  placeholder,
  onChange,
  value,
  type,
  colorTextField,
  marginTop,
  background,
  marginRight,
  money,
  comments,
  select,
  options,
  response,
  step,
  country,
  multiline = false,
  error,
  helperText,
  defaultValue,
  minHeight,
  rows,
  autofocus,
  InputProps,
  disabled,
  maxWidth,
  blur,
  focus,
  label,
  sizes,
  comment,
  socialMedia,
  variant,
  delivery,
  date,
  limit,
  name,
}) => {
  const classes = useStyles({
    width,
    height,
    minHeight,
    number: type === 'number',
  });

  return (
    <Grid
      style={{
        // width,
        // height,
        marginTop,
        marginRight,
        minHeight,
        maxWidth,
        position: 'relative',
      }}
    >
      <Grid>
        <Typography
          align={sizes && 'right'}
          style={{
            color: comment ? '#000' : colorTextField || '#5b5b5b',
            marginTop: field ? 0 : 24,
            marginBottom: multiline || sizes ? 8 : (select || comment) && 12,
            fontSize: !comment ? 12 : 18,
          }}
        >
          {field}
        </Typography>
      </Grid>

      {!select ? (
        <>
          <TextField
            className={classes.root}
            variant={multiline || sizes || variant ? 'outlined' : 'standard'}
            name={name}
            style={{
              width,
              height,
              minHeight,
              background: background || '#ffffff',
              borderRadius: 4,
              textAlign: 'center',
              bottom: socialMedia && 8,
              left: socialMedia && 3,
              padding: 3,
            }}
            value={!money ? value : `R$ ${value},00`}
            onChange={onChange}
            placeholder={placeholder}
            type={type}
            autoFocus={autofocus}
            inputProps={{
              step,
              style: {
                textAlign: (type === 'number' || sizes) && 'center',
                width,
                height,
                minHeight,
                paddingBottom: socialMedia && 15,
                padding: type !== 'number' && 8,
              },
            }}
            multiline={multiline}
            error={error}
            fullWidth
            rows={rows}
            helperText={error && helperText}
            InputProps={InputProps}
            label={label}
            disabled={disabled}
            onFocus={focus}
            onBlur={blur}
          />
          {limit && (
            <Typography
              style={{
                color: '#616161',
                fontSize: 10,
                position: 'absolute',
                left: '89.25%',
                bottom: '7.5%',
              }}
            >{`${value.length}/${limit}`}</Typography>
          )}
        </>
      ) : (
        <>
          <Select
            className={classes.root}
            style={{
              width,
              height,
              background: background || '#ffffff',
              borderRadius: width > 100 && 0,
              zIndex: 999,
            }}
            name={name}
            value={value}
            onChange={onChange}
            disableUnderline
            // variant="outlined"
            placeholder={placeholder}
            // error={error}
            disabled={disabled}
            // IconComponent={CustomExpandMore}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
              classes: { paper: classes.menuPaper },
              style: { zIndex: 9999 },
            }}
          >
            {!date && defaultValue && (
              <MenuItem value={0}>
                <div className={classes.defaultValue}>{defaultValue}</div>
              </MenuItem>
            )}
            {!date &&
              options &&
              options.map(item => {
                return (
                  <MenuItem
                    key={item}
                    // eslint-disable-next-line no-nested-ternary
                    value={country ? item?.code : response ? item.id : item}
                  >
                    <Typography
                      style={{
                        fontWeight: item === 'Criar nova empresa' && 'bold',
                      }}
                    >
                      {delivery
                        ? dictIdDelivery[item]
                        : response || country
                        ? item.name
                        : item}
                    </Typography>
                  </MenuItem>
                );
              })}
            {date && (
              <DateRange
                moveRangeOnFirstSelection={false}
                ranges={[value]}
                onChange={onChange}
                maxDate={new Date()}
                minDate={new Date(2020, 1, 1)}
              />
            )}
          </Select>
          {error && (
            <FormHelperText style={{ color: '#f44366' }}>
              {helperText}
            </FormHelperText>
          )}
        </>
      )}
      {comments && (
        <Typography
          style={{
            fontSize: 12,
            letterSpacing: '0.04em',
            color: '#061B3A',
            marginTop: 8,
          }}
        >
          {comments}
        </Typography>
      )}
    </Grid>
  );
};

export default TextInput;
