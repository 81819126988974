import { Grid, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import SRButton from './styles';

const Button = ({
  text,
  color,
  textColor,
  width,
  height,
  justify,
  link,
  type,
  loading,
  hover,
  loadingTheme,
  marginTop,
  border,
  borderColor,
  onClick,
  fontSize,
  disabled,
}) => (
  <Grid container onClick={onClick} justifyContent={justify}>
    <SRButton
      disabled
      type={type}
      style={{
        cursor: 'pointer',
        width,
        height,
        marginTop,
        borderRadius: border,
        border: borderColor,
      }}
      color={color}
      hover={hover}
    >
      {!loading ? (
        <Typography
          style={{
            textTransform: 'capitalize',
            color: textColor,
            marginLeft: 0,
            fontSize: fontSize || 14,
          }}
        >
          {text}
        </Typography>
      ) : (
        <CircularProgress
          size={20}
          style={{ color: loadingTheme || '#fafafa' }}
        />
      )}
    </SRButton>
  </Grid>
);

export default Button;
