import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Grid, Fade, Typography } from '@material-ui/core';
import { useRouteMatch, useLocation, useHistory } from 'react-router-dom';
import Img from 'react-cool-img';
import ModalProductMobile from '../ModalProductMobile';
import { loadProducts } from '../../components/DeliveryView/api';
import OverviewBar from '../OverviewBar';
import DeliveryBar from '../DeliveryBar';
import useConfigContext from '../../hooks/useConfigContext';

const productObjectToFilter = {
  delivery: 'MAIN',
  swimwear: 'SWIMWEAR',
  shoes: 'FOOTWEAR',
};

function DeliveryView({ bg, by }) {
  const { configState } = useConfigContext();
  const { swimwear, deliveries, shoes } = configState;
  const seasonName = configState.season.name || '';
  const [open, setOpen] = React.useState(false);
  const location = useLocation();
  const history = useHistory();
  const [selectedProduct, setSelectedProduct] = React.useState(false);
  const [orderedProducts, setOrderedProducts] = useState([]);
  const collection = useRef('');
  const splittedLocation = location.pathname.split('/');
  const [allProducts, setAllProducts] = useState([]);
  collection.current = splittedLocation[1];
  const capsulaBanner = useRef(null);

  const selectDeliveriesConst = deliveryName => {
    switch (deliveryName) {
      case 'delivery':
        return deliveries;
      case 'swimwear':
        return swimwear;
      default:
        return shoes;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const query = new URLSearchParams(location.search);
    const style_code = query.get('style_code');
    if (!style_code) return;
    setSelectedProduct({ style_code });
    setOpen(true);
  }, [location]);

  const handleClose = () => {
    setOpen(false);
  };

  const { params } = useRouteMatch();
  const { delivery_id } = params;

  const handleLoadProducts = useCallback(async () => {
    setOrderedProducts([]);
    setAllProducts([]);
    const products = await loadProducts({
      delivery_id,
    });

    let productsFiltered = [];

    if (products)
      productsFiltered = products.filter(
        product =>
          product.style_collection === productObjectToFilter[collection.current]
      );

    const main = [];
    const capsula = [];
    if (!document.location.pathname.includes('delivery')) {
    } else {
      productsFiltered.forEach(product =>
        product.capsula === 'MAIN COLLECTION'
          ? main.push(product)
          : capsula.push(product)
      );

      setOrderedProducts(main);
      setAllProducts([...main, ...capsula]);
    }
    setAllProducts([]);
    setOrderedProducts(productsFiltered);
  }, [delivery_id]);

  useEffect(() => {
    handleLoadProducts();
  }, [delivery_id, handleLoadProducts, location]);

  const handlePrevProduct = useCallback(() => {
    const products = allProducts.length > 0 ? allProducts : orderedProducts;
    const selectedProductIndex = products.findIndex(
      p => p.style_code === selectedProduct.style_code
    );
    if (selectedProductIndex === 0) {
      // vou pro ultimo item
      const product = products.find(
        (p, index) => index === products.length - 1
      );
      // setSelectedProduct(product);
      history.replace({ search: `?style_code=${product.style_code}` });
    } else {
      // volto um item
      const product = products.find(
        (p, index) => index === selectedProductIndex - 1
      );
      history.replace({ search: `?style_code=${product.style_code}` });

      // setSelectedProduct(product);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProduct, history, orderedProducts, allProducts, delivery_id]);

  const handleNextProduct = useCallback(() => {
    const products = allProducts.length > 0 ? allProducts : orderedProducts;
    const selectedProductIndex = products.findIndex(
      p => p.style_code === selectedProduct.style_code
    );
    if (selectedProductIndex >= products.length - 1) {
      setSelectedProduct(products[0]);
      history.replace({
        search: `?style_code=${products[0].style_code}`,
      });
    } else {
      const product = products.find(
        (p, index) => index === selectedProductIndex + 1
      );
      // setSelectedProduct(product);
      history.replace({ search: `?style_code=${product.style_code}` });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProduct, orderedProducts, allProducts, history, delivery_id]);

  const bannerCurrent = capsulaBanner?.current;

  useEffect(() => {
    if (window.location.search.includes('capsula=mothers')) {
      if (capsulaBanner?.current) {
        capsulaBanner.current.scrollIntoView({ behavior: 'smooth' });
        window.scrollTo(0, window.scrollY - 100);
      }
    }
  }, [bannerCurrent]);
  return (
    <Fade in timeout={300}>
      <div>
        {bg ? (
          <Grid style={{ marginTop: '150px' }}>
            <Typography
              align="center"
              style={{ fontSize: 30, color: '#ffffff', letterSpacing: '0.1em' }}
            >
              {document.location.pathname.includes('shoes')
                ? 'SHOES'
                : seasonName}
            </Typography>

            <Typography
              align="center"
              style={{
                fontSize: 28,
                color: '#ffffff',
                letterSpacing: '0.1em',
              }}
            />
            <Typography
              align="center"
              style={{
                color: '#fff',
                marginTop: 45,
                fontSize: 24,
                letterSpacing: '0.1em',
              }}
            >
              {delivery_id === 'weddingcapsule'
                ? 'WEDDING BELLS'
                : `DELIVERY ${delivery_id}`}
            </Typography>

            <Typography
              align="center"
              style={{
                fontSize: 13.3,
                color: '#fff',
                letterSpacing: '0.1em',
                marginTop: 5,
              }}
            >
              {selectDeliveriesConst(collection.current)[
                delivery_id - 1
              ]?.subTitle2?.toUpperCase()}
            </Typography>

            <Typography
              align="center"
              style={{
                fontSize: 13.3,
                color: '#fff',
                letterSpacing: '0.1em',
                marginTop: 5,
              }}
            >
              {selectDeliveriesConst(collection.current)[
                delivery_id - 1
              ]?.subTitle1?.toUpperCase()}
            </Typography>
          </Grid>
        ) : (
          <>
            <Grid
              container
              style={{
                position: 'fixed',
                alignItems: 'center',
                justifyContent: 'center',
                background: '#fff',
                top: 0,
              }}
            >
              <Typography
                align="center"
                style={{
                  fontSize: 32,
                  marginTop: bg ? '0px' : '53px',
                  marginBottom: '5px',
                }}
              >
                {seasonName}
              </Typography>
              <Grid container>
                <DeliveryBar delivery={delivery_id} by={by} bg={bg} />
              </Grid>

              <Grid container>
                <OverviewBar delivery={delivery_id} by={by} bg={bg} />
              </Grid>
            </Grid>
          </>
        )}
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{
            width: '100%',
            height: '100%',
            marginTop: bg ? window.innerHeight / 10 : '240px',
          }}
        >
          {orderedProducts.map((item, index) => (
            <Img
              key={String(item.mainly_image)}
              onClick={async () => {
                history.replace({ search: `?style_code=${item.style_code}` });
              }}
              style={{
                margin: '3px',
                width: '46%',
                height: 'auto',
                cursor: 'pointer',
              }}
              src={
                item.mainly_image ||
                'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAPFBMVEXu7u62trbx8fHPz8/t7e2zs7O8vLy5ubnl5eWrq6vc3Nzq6urZ2dnh4eHV1dW6urrHx8fBwcHKysqlpaXy5cTEAAAEF0lEQVR4nO3bjXKkKBQFYLj8CAIK5v3fdS/Y9mi3md1UZavFOt9UTTpgujwBQaEjBAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAEDH6N2nT+l3TfaNulNEGo18Y+KnT+sXUXkPKGW5USOGs4BShk+f1++JJ52Uu+l0m0akfN6GN+im66RA7jygNK7veYNcnBo1fJNQDmo9IrouQ2az+S4gt+ImdzjqlLMp0Pz5/6XGfvp8f4qm9xhmVimwNNmTys4GVv0+x5uStiGFKO1a+NGktq+EQtjXgGo3ZOpdG5tlaiPR0HvC+BJgu1U1C7eo6T+heQQkEYJ4vHzc6PD1F/pPaHI7fXKZi+2yzn60tLrBhdx/Qqlbmdpmi6WlCcN+pOk7YXvYpeU5fj4iqv2k0XVC48TLU/B6XYbbJGz3K/rQqi0PDXdJ2MYZd7iPMXW0OTxV9ZzQ1MuQjk/BrZvS/kLsOmEdV15uVNttKKm7tGEbOceTNlxuknBdrTiuRpn6PHi4Pe864VAnfMr7RmyhSd4loRlfG7HNkCLdZj5cb0t3U/464R+X4LpOKE1qiRw/2/M/Y9db7yTvk1DOayEllbNKdHZI5wkfj09itzx6HHm6T8gR9SEB6ZeA3SeUxnLn1FttuyRvlrA247aIz8/678uJN0hYB9FlinFa7NmacG8Jz/dE12X885re1kvP1rz/qrc17/pI/6OIZtb//p4XE6z5gdLh3hPfwEzqP5pSb1304eRTQt/49JkCAAAAAEBftHt/0D0rq9zFHhmfH1n7U0LHkrY749uC/lnZ/r3aMet+zjXoGEaVRN18UfG58hvVWBdGp6kGiDoqRy76aWxV9S8RjmXCRdLCjfwjiotDNOo6f66g/TBYHwUVX8y6cSaCNPwdTd5yDWkvZ+vHcfBz4apZSSv2ZbWfpi/H71Bo9Hnx0Q1+3Xe8BG0yUbGkfOCv87pzZohCCF+RKPog/EJkCyd1RLnwU70f92X1XWjO/DoJ7p2csvXSy3RTXU9GzWTr/svo2wb29gGM+pqb1yc+whJfc1w1lFI43q6s4t+EiIac59rC7Xmp69AkUROW0hK2Pe0tIX9DX9xe9YgtYR6Z25e1d+HIVnHCWKv1JROO3PXCY8NX8XmnxD1R6MwxazJOo/2oSZlAgct3Ze2CIyVr+8vMv5lIwkx0neuwtYbkPueNL4+yzK8XSsZ749r1RYvlq9Rz3FqV9b5snfoCD008lBqu5mEre3+dGbG1Qe2PIYXnVNdek3CtD9Zz1aFO7+552KGsvY1v00b9kdrFt+IrOblhOfvyTRlN5v85rasYzdfrh8FvJox9/m3QD9w9HwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAANzJP/MdJGqyWhqXAAAAAElFTkSuQmCC'
              }
              alt="a"
            />
          ))}
        </Grid>

        <ModalProductMobile
          products={orderedProducts}
          handlePrevProduct={handlePrevProduct}
          handleNextProduct={handleNextProduct}
          open={open}
          handleClose={handleClose}
          style_code={selectedProduct}
        />
      </div>
    </Fade>
  );
}

export default DeliveryView;
