import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Typography, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { ReactComponent as FacebookLogo } from '../../assets/facebook-icon-size=default.svg';
import { ReactComponent as InstagramLogo } from '../../assets/instagram-icon-size=default.svg';
import { ReactComponent as InfoIcon } from '../../assets/info.svg';

const useStyles = makeStyles(() => ({
  containerDiv: {
    marginTop: 90,
  },
  marginTop: {
    marginTop: 48,
  },
  subtitleText: {
    fontSize: 12,
    color: '#616161',
  },
  steptTitle: {
    fontSize: 14,
    lineHeight: '18px',
  },
  stepTitleMobile: {
    fontSize: 18,
    lineHeight: '18px',
    paddingBottom: 16,
  },
  infoText: {
    fontSize: '10px',
    lineHeight: '12px',
    color: '#616161',
    width: 165,
    position: 'relative',
    left: 4,
  },
}));

export default function Account({ state, handleChange, error, width }) {
  const classes = useStyles();

  const wid =
    width < 1024
      ? width * 0.8
      : window.innerWidth > 1300
      ? 377
      : window.innerWidth > 1150
      ? 277
      : 210;

  return (
    <Grid
      item
      container
      justifyContent={width < 1024 ? 'center' : 'space-between'}
      style={{
        marginTop: width < 1024 ? 20 : 66,
        // maxHeight:
        //   width < 1024 ? window.innerHeight - 248 : window.innerHeight - 350,
        overflowY: 'auto',
        overflowX: 'hidden',
        background: width < 1024 && '#fff',
        padding: width < 1024 && '5%',
        width: width < 1024 && width * 0.9,
        paddingBottom: 50,
      }}
    >
      <Grid container>
        <Grid item xs={12} md={4}>
          <Typography
            className={
              width > 1024 ? classes.steptTitle : classes.stepTitleMobile
            }
          >
            Account Information
          </Typography>
        </Grid>
        <Grid item>
          <Grid item container alignItems="center">
            <TextField
              label="Account / Shop Name"
              name="shopName"
              type="text"
              fullWidth
              onChange={e => {
                handleChange(e);
              }}
              inputProps={{ 'aria-label': 'Account' }}
              value={state.shopName}
              error={error.shopName}
              style={{ width: wid }}
              helperText={error.shopName}
            />
            <Grid>
              <Grid
                container
                style={{
                  position: 'relative',
                  left: width > 1024 && '20%',
                  top: 12,
                }}
              >
                <InfoIcon />
                <Typography className={classes.infoText}>
                  Please make sure your account name is correct. You won't be
                  able to change this later.
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item className={classes.marginTop}>
            <TextField
              label="Website"
              type="text"
              name="website"
              fullWidth
              value={state.website}
              onChange={e => {
                handleChange(e);
              }}
              inputProps={{ 'aria-label': 'Website' }}
              style={{ width: wid }}
            />
          </Grid>

          <Grid
            item
            container
            alignItems="flex-end"
            className={classes.marginTop}
          >
            <Grid item style={{ position: 'relative', top: 7 }}>
              <FacebookLogo />
            </Grid>
            <Grid item style={{ flexGrow: 1 }}>
              <TextField
                label="Facebook"
                type="text"
                name="facebook"
                fullWidth
                value={state.facebook}
                onChange={e => {
                  handleChange(e);
                }}
                style={{ width: wid - 36 }}
                inputProps={{ 'aria-label': 'Facebook' }}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            alignItems="flex-end"
            className={classes.marginTop}
          >
            <Grid item>
              <InstagramLogo style={{ position: 'relative', top: 7 }} />
            </Grid>
            <Grid item style={{ flexGrow: 1 }}>
              <TextField
                label="Instagram"
                type="text"
                name="instagram"
                fullWidth
                value={state.instagram}
                onChange={e => {
                  handleChange(e);
                }}
                inputProps={{ 'aria-label': 'Last Name' }}
                style={{ width: wid - 36 }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
