import React, { createContext, useState } from 'react';

const ConfigContext = createContext();

export { ConfigContext };

export default function ConfigContextProvider({ children }) {
  const state = useState('');

  return (
    <ConfigContext.Provider value={state}>{children}</ConfigContext.Provider>
  );
}
