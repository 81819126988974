/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import {
  Typography,
  TextField,
  FormControlLabel,
  Radio,
  RadioGroup,
  IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import CheckLabel from '../../components/CheckLabel';
import Input from '../../components/TextInput';
import InputNumber from '../../components/InputNumber';
import ModalAddress from '../../components/ModalAddresses';

import { ReactComponent as InfoIcon } from '../../assets/info.svg';

const RemoveIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 6C4 5.72386 3.77614 5.5 3.5 5.5C3.22386 5.5 3 5.72386 3 6H4ZM13 6C13 5.72386 12.7761 5.5 12.5 5.5C12.2239 5.5 12 5.72386 12 6H13ZM12 6V12.5H13V6H12ZM11.5 13H4.5V14H11.5V13ZM4 12.5V6H3V12.5H4ZM4.5 13C4.22386 13 4 12.7761 4 12.5H3C3 13.3284 3.67157 14 4.5 14V13ZM12 12.5C12 12.7761 11.7761 13 11.5 13V14C12.3284 14 13 13.3284 13 12.5H12Z"
        fill="black"
      />
      <path
        d="M6.5 7V11.5M9.5 7V11.5"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 3C9.5 3.27614 9.72386 3.5 10 3.5C10.2761 3.5 10.5 3.27614 10.5 3H9.5ZM5.5 3C5.5 3.27614 5.72386 3.5 6 3.5C6.27614 3.5 6.5 3.27614 6.5 3H5.5ZM7 2H9V1H7V2ZM9.5 2.5V3H10.5V2.5H9.5ZM6.5 3V2.5H5.5V3H6.5ZM9 2C9.27614 2 9.5 2.22386 9.5 2.5H10.5C10.5 1.67157 9.82843 1 9 1V2ZM7 1C6.17157 1 5.5 1.67157 5.5 2.5H6.5C6.5 2.22386 6.72386 2 7 2V1Z"
        fill="black"
      />
      <path d="M2 4.5L14 4.5" stroke="black" strokeLinecap="round" />
    </svg>
  );
};

const EditIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 2.5C5.27614 2.5 5.5 2.27614 5.5 2C5.5 1.72386 5.27614 1.5 5 1.5V2.5ZM10.5 7C10.5 6.72386 10.2761 6.5 10 6.5C9.72386 6.5 9.5 6.72386 9.5 7H10.5ZM9 10.5H2V11.5H9V10.5ZM1.5 10V3H0.5V10H1.5ZM2 2.5H5V1.5H2V2.5ZM9.5 7V10H10.5V7H9.5ZM2 10.5C1.72386 10.5 1.5 10.2761 1.5 10H0.5C0.5 10.8284 1.17157 11.5 2 11.5V10.5ZM9 11.5C9.82843 11.5 10.5 10.8284 10.5 10H9.5C9.5 10.2761 9.27614 10.5 9 10.5V11.5ZM1.5 3C1.5 2.72386 1.72386 2.5 2 2.5V1.5C1.17157 1.5 0.5 2.17157 0.5 3H1.5Z"
        fill="black"
      />
      <path
        d="M4 5.96098L3.65149 5.60246C3.55464 5.6966 3.5 5.82592 3.5 5.96098H4ZM6.02911 8V8.5C6.16404 8.5 6.29324 8.44547 6.38736 8.34879L6.02911 8ZM4 8H3.5C3.5 8.27614 3.72386 8.5 4 8.5V8ZM10.7015 1.76597L11.0551 1.41241V1.41241L10.7015 1.76597ZM10.7015 3.20076L10.3479 2.84718L10.3433 2.85197L10.7015 3.20076ZM8.79924 1.29584L9.14778 1.65439L9.15214 1.65004L8.79924 1.29584ZM10.2327 1.29716L9.87916 1.65071L9.87917 1.65071L10.2327 1.29716ZM4.34851 6.31951L9.14775 1.65437L8.45073 0.937312L3.65149 5.60246L4.34851 6.31951ZM9.87917 1.65071L10.348 2.11952L11.0551 1.41241L10.5863 0.943602L9.87917 1.65071ZM10.3433 2.85197L5.67085 7.65121L6.38736 8.34879L11.0598 3.54955L10.3433 2.85197ZM6.02911 7.5H4V8.5H6.02911V7.5ZM4.5 8V5.96098H3.5V8H4.5ZM10.348 2.11952C10.5489 2.32047 10.5489 2.64626 10.348 2.84721L11.0551 3.55432C11.6466 2.96284 11.6466 2.00388 11.0551 1.41241L10.348 2.11952ZM9.15214 1.65004C9.3532 1.44973 9.67848 1.45002 9.87916 1.65071L10.5863 0.943602C9.99557 0.352901 9.03813 0.352022 8.44634 0.941636L9.15214 1.65004Z"
        fill="black"
      />
      <path
        d="M7.70467 2.26615L9.60696 4.16844L10.3141 3.46133L8.41178 1.55904L7.70467 2.26615Z"
        fill="black"
      />
    </svg>
  );
};

const PlusIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 8V24"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24 16H8"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const useStyles = makeStyles(() => ({
  divAddress: {
    backgroundColor: 'white',
    border: '1px solid white',
    '&:hover': {
      border: '1px solid black',
    },
  },
  containerDiv: {
    marginTop: 90,
  },
  marginTop: {
    marginTop: 40,
  },
  subtitleText: {
    fontSize: 12,
    color: '#616161',
  },
  steptTitle: {
    fontSize: 14,
    lineHeight: '18px',
  },
  stepTitleMobile: {
    fontSize: 18,
    lineHeight: '18px',
    paddingBottom: 16,
  },
  infoText: {
    fontSize: '10px',
    lineHeight: '12px',
    color: '#616161',
    width: 165,
    position: 'relative',
    left: 4,
  },
}));

export default function Business({
  state,
  handleChange,
  error,
  arrayBuyingSeason,
  arrayInterest,
  radioBuyingSeason,
  handleChangeBuyingSeason,
  handleChangeInterest,
  handleChangeRadio,
  setState,
  changeDocuments,
  setError,
  width,
}) {
  const classes = useStyles();
  const [openAddress, setOpenAddress] = useState(false);
  const [id, setId] = useState(-1);
  const [editValues, setEditValues] = useState('');

  const wid =
    width < 1024
      ? width * 0.8
      : window.innerWidth > 1300
      ? 377
      : window.innerWidth > 1150
      ? 277
      : 210;

  const handleRemoveFile = index => {
    setState({ ...state, files: state.files.filter((doc, i) => index !== i) });
  };

  const handleClose = () => {
    setOpenAddress(false);
    setId(-1);
    setError({ ...error, doorsAddress: false });
  };

  const handleSaveAddress = address => {
    if (id === -1) {
      const newArr = state.doorsAddress.map(doorsAddress => doorsAddress);
      newArr.push(address);
      setState({ ...state, doorsAddress: newArr });
    } else {
      const newArr = state.doorsAddress.map((item, i) =>
        i !== id ? item : address
      );
      setState({ ...state, doorsAddress: newArr });
    }
    handleClose();
  };

  const handleOpenEdit = (ad, cit, adName, stProv, zcode, ct, i, st, unit) => {
    setOpenAddress(true);
    setEditValues({
      modalAddress: ad,
      modalCity: cit,
      modalStoreProv: stProv,
      modalZipcode: zcode,
      modalStreet: st,
      modalUnit: unit,
      modalCountry: ct,
    });

    setId(i);
  };

  const handleDeleteDoorAddress = index => {
    setState({
      ...state,
      doorsAddress: state.doorsAddress.filter((item, i) => index !== i),
    });
  };

  return (
    <Grid
      item
      container
      justifyContent={width < 1024 ? 'center' : 'space-between'}
      style={{
        marginTop: width < 1024 ? 20 : 66,
        maxHeight:
          width < 1024 ? window.innerHeight - 248 : window.innerHeight - 350,
        overflowY: 'auto',
        overflowX: 'hidden',
        background: width < 1024 && '#fff',
        padding: width < 1024 && '5%',
        width: width < 1024 && width * 0.9,
        paddingBottom: 50,
      }}
    >
      <Grid container>
        <Grid item xs={12} md={4}>
          <Typography
            className={
              width > 1024 ? classes.steptTitle : classes.stepTitleMobile
            }
          >
            Business Profile
          </Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid>
            <Grid container>
              <Typography style={{ fontSize: 14, marginBottom: 12 }}>
                Buying Season
              </Typography>
              <Grid container>
                <CheckLabel
                  onChange={() => handleChangeBuyingSeason('Spring / Summer')}
                  checked={arrayBuyingSeason?.includes('Spring / Summer')}
                  label="Spring / Summer"
                  marginRight={47}
                />
                <CheckLabel
                  onChange={() =>
                    handleChangeBuyingSeason('High Summer / Pre Fall')
                  }
                  checked={arrayBuyingSeason?.includes(
                    'High Summer / Pre Fall'
                  )}
                  label="High Summer / Pre Fall"
                />
              </Grid>
              <Grid container style={{ marginTop: 8 }}>
                <CheckLabel
                  onChange={() => handleChangeBuyingSeason('Fall')}
                  checked={arrayBuyingSeason?.includes('Fall')}
                  label="Fall"
                  marginRight={125.2}
                />
                <CheckLabel
                  onChange={() =>
                    handleChangeBuyingSeason('Winter / Resort / Holiday')
                  }
                  checked={arrayBuyingSeason?.includes(
                    'Winter / Resort / Holiday'
                  )}
                  label="Winter / Resort / Holiday"
                />
              </Grid>

              {error.buyingSeason && (
                <Typography
                  style={{ fontSize: 12, color: '#f44336', marginTop: 3 }}
                >
                  {error.buyingSeason}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid style={{ marginTop: 42 }}>
            <Grid>
              <Grid container>
                <Typography style={{ fontSize: 14, marginBottom: 12 }}>
                  Products of interest
                </Typography>
                <Grid container>
                  <CheckLabel
                    onChange={() => handleChangeInterest('Ready To Wear')}
                    checked={arrayInterest?.includes('Ready To Wear')}
                    label="Ready to Wear"
                    marginRight={56}
                  />
                  <CheckLabel
                    onChange={() => handleChangeInterest('Swimsuits')}
                    checked={arrayInterest?.includes('Swimsuits')}
                    label="Swimwear"
                  />
                </Grid>

                <Grid container style={{ marginTop: 8 }}>
                  <CheckLabel
                    onChange={() => handleChangeInterest('Shoes')}
                    checked={arrayInterest?.includes('Shoes')}
                    label="Shoes"
                  />
                </Grid>

                {error.interest && (
                  <Typography
                    style={{ fontSize: 12, color: '#f44336', marginTop: 3 }}
                  >
                    {error.interest}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: 48, alignItems: 'center' }}>
            <Typography
              style={{
                fontSize: 14,
                marginRight: 15,
              }}
            >
              In business since
            </Typography>
            <InputNumber
              value={state.inBusinessSince}
              setValue={e => setState({ ...state, inBusinessSince: e })}
              year
            />
          </Grid>
          <Grid container style={{ alignItems: 'center', marginTop: 48 }}>
            <Typography style={{ marginRight: width > 1024 ? 32 : 16 }}>
              Are you on JOOR?
            </Typography>
            <Grid>
              <Grid container>
                <RadioGroup
                  row
                  aria-label="gender"
                  name="gender1"
                  value={state.isOnJOOR}
                  onChange={e =>
                    setState({ ...state, isOnJOOR: e.target.value })
                  }
                  style={{ postion: 'relative', top: 8 }}
                >
                  <FormControlLabel
                    value="false"
                    control={<Radio size="small" />}
                    label={
                      <Typography
                        style={{
                          fontSize: 12,
                          position: 'relative',
                          top: -2,
                          marginRight: width > 1024 ? 32 : 8,
                        }}
                      >
                        No
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value="true"
                    control={<Radio size="small" />}
                    label={
                      <Typography
                        style={{
                          fontSize: 12,
                          position: 'relative',
                          top: -2,
                          marginRight: 20,
                        }}
                      >
                        Yes
                      </Typography>
                    }
                  />
                </RadioGroup>
              </Grid>
            </Grid>
          </Grid>
          {state.isOnJOOR === 'true' && (
            <TextField
              label="Joor Profile Link"
              style={{ width: wid, marginTop: 32 }}
              value={state.joorURL}
              onChange={e => {
                setState({ ...state, joorURL: e.target.value });
                setError({ ...error, joorURL: false });
              }}
              helperText={error.joorURL}
              error={error.joorURL}
            />
          )}
        </Grid>
        <Grid item xs={12} md={4} style={{ marginTop: 84 }}>
          <Typography
            className={
              width > 1024 ? classes.steptTitle : classes.stepTitleMobile
            }
          >
            Store Profile
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={8}
          style={{ marginTop: width > 1024 ? 84 : 24, paddingBottom: 66 }}
        >
          <Grid item xs={12} md={8}>
            <Grid container>
              <Typography style={{ fontSize: 14, marginBottom: 18 }}>
                Online / Brick & Mortar
              </Typography>
              <Grid container>
                <RadioGroup
                  row
                  aria-label="gender"
                  name="gender1"
                  value={state.radioBuyingSeason}
                  onChange={event => handleChangeRadio(event.target.value)}
                >
                  <FormControlLabel
                    value="Online"
                    control={<Radio size="small" />}
                    label={
                      <Typography
                        style={{
                          fontSize: 12,
                          position: 'relative',
                          top: -2,
                          marginRight: width > 1024 ? 30 : 8,
                        }}
                      >
                        Online
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value="BM"
                    control={<Radio size="small" />}
                    label={
                      <Typography
                        style={{
                          fontSize: 12,
                          position: 'relative',
                          top: -2,
                          marginRight: width > 1024 ? 20 : 8,
                        }}
                      >
                        Brick &amp; Mortar
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value="Both"
                    control={<Radio size="small" />}
                    label={
                      <Typography
                        style={{
                          fontSize: 12,
                          position: 'relative',
                          top: -2,
                        }}
                      >
                        Both
                      </Typography>
                    }
                  />
                </RadioGroup>
              </Grid>
            </Grid>
            {state.radioBuyingSeason === 'Online' ? (
              <>
                <Grid container style={{ marginTop: 30, alignItems: 'center' }}>
                  <Typography
                    style={{
                      fontSize: 14,
                      marginRight: 15,
                    }}
                  >
                    Number of brands currently sold
                  </Typography>
                  {/* <Input
                    background="#fff"
                    width={40}
                    height={36}
                    value={brandsSold}
                    type="number"
                    onChange={event => {
                      if (event.target.value < 1) return;
                      setBrandsSold(event.target.value);
                    }}
                    options={[1, 2, 3, 4]}
                  /> */}
                  <InputNumber
                    value={state.brandsSold}
                    setValue={e => setState({ ...state, brandsSold: e })}
                  />
                </Grid>
                <Grid container>
                  <Input
                    field="Brands Carried"
                    placeholder="Add brands here"
                    width={wid}
                    minHeight={34}
                    marginTop={61}
                    value={state.brandsCarried}
                    onChange={event => {
                      setState({ ...state, brandsCarried: event.target.value });
                      setError({ ...error, brandsCarried: false });
                    }}
                    multiline
                    rows={2}
                    error={error.brandsCarried}
                    helperText={error.brandsCarried}
                  />
                </Grid>
                <Typography
                  style={{
                    padding: '12px 0px',
                    fontSize: 10,
                    // fontHeight: '12px',
                    color: '#616161',
                  }}
                >
                  Use commas to separete
                </Typography>
                <Grid container>
                  <Input
                    field="Tell us about your Custumer Profile"
                    placeholder="Customer profile"
                    width={wid}
                    minHeight={54}
                    marginTop={20}
                    value={state.costumersProfile}
                    onChange={event => {
                      if (event.target.value.length > 250) return;
                      setState({
                        ...state,
                        costumersProfile: event.target.value,
                      });
                      setError({ ...error, costumersProfile: false });
                    }}
                    multiline
                    rows={0}
                    error={error.costumersProfile}
                    helperText={error.costumersProfile}
                    limit={250}
                  />
                </Grid>
              </>
            ) : state.radioBuyingSeason === 'BM' ? (
              <>
                <Grid container style={{ marginTop: 30, alignItems: 'center' }}>
                  <Typography
                    style={{
                      fontSize: 14,
                      marginRight: 15,
                    }}
                  >
                    Number of doors
                  </Typography>
                  {/* <Input
                    background="#fff"
                    width={40}
                    height={36}
                    value={brandsSold}
                    type="number"
                    onChange={event => {
                      if (event.target.value < 1) return;
                      setBrandsSold(event.target.value);
                    }}
                    options={[1, 2, 3, 4]}
                  /> */}
                  <InputNumber
                    value={state.numDoors}
                    setValue={e => setState({ ...state, numDoors: e })}
                  />
                </Grid>

                <Grid
                  container
                  spacing={1}
                  style={{ marginTop: 51, maxWidth: 400 }}
                >
                  <Grid container>
                    <Typography style={{ color: '#3a3a3a', fontSize: 14 }}>
                      Addresses for each location
                    </Typography>
                  </Grid>
                  <Grid container>
                    <Grid
                      item
                      container
                      className={classes.divAddress}
                      style={{
                        maxWidth: 190,
                        height: 120,
                        padding: '4px 4px 12px 12px',
                        gap: '16px',
                        cursor: 'pointer',
                        alignItems: 'flex-start',
                        marginTop: 20,
                        marginRight: 8,
                      }}
                      onClick={() => {
                        setOpenAddress(true);
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: 12,
                          lineHeight: '16px',
                          fontWeight: 'bold',
                        }}
                      >
                        Add New Address
                      </Typography>
                      <Grid container>
                        <PlusIcon />
                      </Grid>
                    </Grid>
                    {state.doorsAddress.map((item, index) => (
                      <Grid item>
                        <Grid
                          container
                          className={classes.divAddress}
                          style={{
                            width: 190,
                            minHeight: 110,
                            padding: '4px 4px 12px 12px',
                            gap: '16px',
                            cursor: 'pointer',
                            alignItems: 'flex-start',
                            marginTop: 20,
                            marginRight: 8,
                          }}
                        >
                          <Grid
                            container
                            justifyContent="space-between"
                            // style={{ width: 134 }}
                          >
                            <Typography
                              style={{
                                fontSize: 12,
                                fontWeight: 'bold',
                                marginBottom: 12,
                              }}
                            >
                              {item.modalAddress}
                            </Typography>
                            <Grid style={{ position: 'relative', bottom: 5 }}>
                              <div
                                onClick={() => {
                                  setId(index);
                                  handleOpenEdit(
                                    item.modalAddress,
                                    item.modalCity,
                                    item.modalAddName,
                                    item.modalStoreProv,
                                    item.modalZipcode,
                                    item.modalCountry,
                                    index,
                                    item.modalStreet,
                                    item.modalUnit
                                  );
                                }}
                              >
                                <EditIcon />
                              </div>
                              <div
                                onClick={() => handleDeleteDoorAddress(index)}
                                style={{ position: 'relative', right: 2 }}
                              >
                                <RemoveIcon />
                              </div>
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            justifyContent="space-between"
                            style={{ position: 'relative', bottom: 22 }}
                          >
                            <Grid style={{ width: 134 }}>
                              <Typography
                                style={{ fontSize: 10, lineHeight: '12px' }}
                              >
                                {item.modalStreet}{' '}
                                {item?.modalUnit && ` ${item.modalUnit}`}
                              </Typography>
                              <Typography
                                style={{ fontSize: 10, lineHeight: '12px' }}
                              >{`${item.modalCity} - ${item.modalStoreProv}`}</Typography>
                              <Typography
                                style={{ fontSize: 10, lineHeight: '12px' }}
                              >{`${item.modalZipcode} - ${item.modalCountry}`}</Typography>
                            </Grid>
                            {/* <Grid style={{ width: 46 }}>
                              <Typography
                                
                                align="right"
                                style={{
                                  fontSize: 12,

                                  cursor: 'pointer',
                                }}
                              >
                                Edit
                              </Typography>
                              <Typography
                                align="right"
                                style={{ fontSize: 12, cursor: 'pointer' }}
                                onClick={() => handleDeleteDoorAddress(index)}
                              >
                                Remove
                              </Typography>
                            </Grid> */}
                            <Grid />
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                  {error.doorsAddress && (
                    <Typography
                      style={{ fontSize: 12, color: '#f44336', marginTop: 3 }}
                    >
                      {error.doorsAddress}
                    </Typography>
                  )}
                </Grid>
                <Grid container style={{ alignItems: 'center', marginTop: 60 }}>
                  <TextField
                    label="Average Store Size"
                    style={{ width: 150, marginRight: 32 }}
                    value={state.storeSize}
                    name="storeSize"
                    onChange={e => handleChange(e)}
                  />
                  <Grid style={{ position: 'relative', top: 12 }}>
                    <Grid container>
                      <RadioGroup
                        row
                        aria-label="gender"
                        name="unitStoreSize"
                        value={state.unitStoreSize}
                        onChange={e => handleChange(e)}
                      >
                        <FormControlLabel
                          value="m"
                          control={<Radio size="small" />}
                          label={
                            <Typography
                              style={{
                                fontSize: 12,
                                position: 'relative',
                                top: -2,
                                marginRight: 30,
                              }}
                            >
                              m²
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          value="sq"
                          control={<Radio size="small" />}
                          label={
                            <Typography
                              style={{
                                fontSize: 12,
                                position: 'relative',
                                top: -2,
                                marginRight: 20,
                              }}
                            >
                              sq²
                            </Typography>
                          }
                        />
                      </RadioGroup>
                    </Grid>
                    {error.storeSize && (
                      <Typography
                        style={{ fontSize: 12, color: '#f44336', marginTop: 3 }}
                      >
                        {error.storeSize}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Grid style={{ marginTop: 54 }}>
                  <Grid container>
                    <Typography style={{ color: '#3a3a3a', fontSize: 14 }}>
                      Store Images
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    style={{
                      marginTop: 10,
                      width: width > 1024 ? 420 : wid,
                      position: 'relative',
                    }}
                  >
                    <Grid>
                      <Grid container>
                        {state.files?.length > 0 &&
                          state.files.map((document, index) => (
                            <Grid
                              item
                              sm={4}
                              style={{
                                margin: '6px 10px 6px 0px',
                                position: 'relative',
                              }}
                            >
                              <Grid container justifyContent="center">
                                <Grid
                                  style={{
                                    position: 'absolute',
                                    left: 61,
                                    bottom: 61,
                                  }}
                                  onClick={() => handleRemoveFile(index)}
                                >
                                  <IconButton
                                    size="small"
                                    style={{
                                      position: 'relative',
                                      top: 2,
                                      right: 2,
                                    }}
                                  >
                                    <RemoveIcon />
                                  </IconButton>
                                </Grid>
                                <img
                                  src={URL.createObjectURL(document)}
                                  style={{
                                    width: 80,
                                    height: 80,
                                  }}
                                  alt="doc"
                                />
                              </Grid>
                            </Grid>
                          ))}
                        <Grid
                          item
                          sm={4}
                          style={{ margin: '6px 10px 6px 0px' }}
                        >
                          <input
                            style={{ display: 'none' }}
                            className="files-dropzone"
                            accept="image/gif, image/jpeg, image/png"
                            id="button-import-docs"
                            type="file"
                            onChange={changeDocuments}
                            multiple
                          />
                          <label
                            htmlFor="button-import-docs"
                            style={{ display: 'inherit', cursor: 'pointer' }}
                          >
                            <Grid
                              container
                              style={{
                                width: 80,
                                height: 80,
                                alignItems: 'center',
                                border: '1px solid #000000',
                              }}
                              justifyContent="center"
                              alignItems="center"
                            >
                              <PlusIcon />
                            </Grid>
                          </label>
                        </Grid>
                      </Grid>
                    </Grid>
                    {wid > 1024 && (
                      <Grid>
                        <Grid
                          container
                          style={{
                            position: 'absolute',
                            left: '90%',
                            top: '0%',
                          }}
                        >
                          <InfoIcon />
                          <Typography className={classes.infoText}>
                            Images must be smaller than 2mb.
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid container>
                  <Input
                    field="Tell us about your Store Profile"
                    placeholder="Store profile"
                    width={wid}
                    minHeight={54}
                    marginTop={32}
                    value={state.storeProfile}
                    onChange={event => {
                      if (event.target.value.length > 250) return;
                      setState({
                        ...state,
                        storeProfile: event.target.value,
                      });
                      setError({ ...error, storeProfile: false });
                    }}
                    multiline
                    rows={1}
                    error={error.storeProfile}
                    helperText={error.storeProfile}
                    limit={250}
                  />
                </Grid>

                <Grid container>
                  <Input
                    field="Tell us about your Custumer Profile"
                    placeholder="Customer profile"
                    width={wid}
                    minHeight={54}
                    marginTop={32}
                    value={state.costumersProfile}
                    onChange={event => {
                      if (event.target.value.length > 250) return;
                      setState({
                        ...state,
                        costumersProfile: event.target.value,
                      });
                      setError({
                        ...error,
                        costumersProfile: false,
                      });
                    }}
                    multiline
                    rows={1}
                    error={error.costumersProfile}
                    helperText={error.costumersProfile}
                    limit={250}
                  />
                </Grid>
                <Grid container>
                  <Input
                    field="Brands Carried"
                    placeholder="Add brands here"
                    width={wid}
                    minHeight={34}
                    marginTop={32}
                    value={state.brandsCarried}
                    onChange={event => {
                      if (event.target.value.length > 250) return;
                      setState({
                        ...state,
                        brandsCarried: event.target.value,
                      });
                      setError({
                        ...error,
                        brandsCarried: false,
                      });
                    }}
                    multiline
                    rows={2}
                    error={error.brandsCarried}
                    helperText={error.brandsCarried}
                  />
                </Grid>
                <Typography
                  style={{
                    padding: '12px 0px',
                    fontSize: 10,
                    // fontHeight: '12px',
                    color: '#616161',
                  }}
                >
                  Use commas to separete
                </Typography>
              </>
            ) : (
              <>
                <Grid container>
                  <Input
                    field="Tell us about your Costumer Profile"
                    placeholder="Customer profile"
                    width={wid}
                    minHeight={54}
                    marginTop={20}
                    value={state.costumersProfile}
                    onChange={event => {
                      if (event.target.value.length > 250) return;
                      setState({
                        ...state,
                        costumersProfile: event.target.value,
                      });
                      setError({
                        ...error,
                        costumersProfile: false,
                      });
                    }}
                    multiline
                    rows={1}
                    error={error.costumersProfile}
                    helperText={error.costumersProfile}
                    limit={250}
                  />
                </Grid>

                <Grid container style={{ marginTop: 30, alignItems: 'center' }}>
                  <Typography
                    style={{
                      fontSize: 14,
                      marginRight: 15,
                    }}
                  >
                    Number of brands currently sold
                  </Typography>
                  {/* <Input
                    background="#fff"
                    width={40}
                    height={36}
                    value={brandsSold}
                    type="number"
                    onChange={event => {
                      if (event.target.value < 1) return;
                      setBrandsSold(event.target.value);
                    }}
                    options={[1, 2, 3, 4]}
                  /> */}
                  <InputNumber
                    value={state.brandsSold}
                    setValue={e => setState({ ...state, brandsSold: e })}
                  />
                </Grid>
                <Input
                  field="Brands Carried"
                  placeholder="Add brands here"
                  width={wid}
                  minHeight={34}
                  marginTop={48}
                  value={state.brandsCarried}
                  onChange={event => {
                    if (event.target.value.length > 250) return;
                    setState({
                      ...state,
                      brandsCarried: event.target.value,
                    });
                    setError({
                      ...error,
                      brandsCarried: false,
                    });
                  }}
                  multiline
                  rows={2}
                  error={error.brandsCarried}
                  helperText={error.brandsCarried}
                />
                <Typography
                  style={{
                    padding: '12px 0px',
                    fontSize: 10,
                    // fontHeight: '12px',
                    color: '#616161',
                  }}
                >
                  Use commas to separete
                </Typography>
                <Grid container style={{ marginTop: 48, alignItems: 'center' }}>
                  <Typography
                    style={{
                      fontSize: 14,
                      marginRight: 15,
                    }}
                  >
                    Number of doors
                  </Typography>
                  {/* <Input
                    background="#fff"
                    width={40}
                    height={36}
                    value={brandsSold}
                    type="number"
                    onChange={event => {
                      if (event.target.value < 1) return;
                      setBrandsSold(event.target.value);
                    }}
                    options={[1, 2, 3, 4]}
                  /> */}
                  <InputNumber
                    value={state.numDoors}
                    setValue={e => setState({ ...state, inBusinessSince: e })}
                  />
                </Grid>

                <Grid
                  container
                  spacing={1}
                  style={{ marginTop: 51, maxWidth: 400 }}
                >
                  <Grid container>
                    <Typography style={{ color: '#3a3a3a', fontSize: 14 }}>
                      Addresses for each location
                    </Typography>
                  </Grid>
                  <Grid container>
                    <Grid
                      item
                      container
                      className={classes.divAddress}
                      style={{
                        maxWidth: 190,
                        height: 120,
                        padding: '4px 4px 12px 12px',
                        gap: '16px',
                        cursor: 'pointer',
                        alignItems: 'flex-start',
                        marginTop: 20,
                        marginRight: 8,
                      }}
                      onClick={() => {
                        setOpenAddress(true);
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: 12,
                          lineHeight: '16px',
                          fontWeight: 'bold',
                        }}
                      >
                        Add New Address
                      </Typography>
                      <Grid container>
                        <PlusIcon />
                      </Grid>
                    </Grid>
                    {state.doorsAddress.map((item, index) => (
                      <Grid item>
                        <Grid
                          container
                          className={classes.divAddress}
                          style={{
                            width: 190,
                            minHeight: 110,
                            padding: '4px 4px 12px 12px',
                            gap: '16px',
                            cursor: 'pointer',
                            alignItems: 'flex-start',
                            marginTop: 20,
                            marginRight: 8,
                          }}
                        >
                          <Grid
                            container
                            justifyContent="space-between"
                            // style={{ width: 134 }}
                          >
                            <Typography
                              style={{
                                fontSize: 12,
                                fontWeight: 'bold',
                                marginBottom: 12,
                              }}
                            >
                              {item.modalAddress}
                            </Typography>
                            <Grid style={{ position: 'relative', bottom: 5 }}>
                              <div
                                onClick={() => {
                                  setId(index);
                                  handleOpenEdit(
                                    item.modalAddress,
                                    item.modalCity,
                                    item.modalAddName,
                                    item.modalStoreProv,
                                    item.modalZipcode,
                                    item.modalCountry,
                                    index,
                                    item.modalStreet,
                                    item.modalUnit
                                  );
                                }}
                              >
                                <EditIcon />
                              </div>
                              <div
                                onClick={() => handleDeleteDoorAddress(index)}
                                style={{ position: 'relative', right: 2 }}
                              >
                                <RemoveIcon />
                              </div>
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            justifyContent="space-between"
                            style={{ position: 'relative', bottom: 22 }}
                          >
                            <Grid style={{ width: 134 }}>
                              <Typography
                                style={{ fontSize: 10, lineHeight: '12px' }}
                              >
                                {item.modalStreet}{' '}
                                {item?.modalUnit && ` ${item.modalUnit}`}
                              </Typography>
                              <Typography
                                style={{ fontSize: 10, lineHeight: '12px' }}
                              >{`${item.modalCity} - ${item.modalStoreProv}`}</Typography>
                              <Typography
                                style={{ fontSize: 10, lineHeight: '12px' }}
                              >{`${item.modalZipcode} - ${item.modalCountry}`}</Typography>
                            </Grid>
                            {/* <Grid style={{ width: 46 }}>
                              <Typography
                                
                                align="right"
                                style={{
                                  fontSize: 12,

                                  cursor: 'pointer',
                                }}
                              >
                                Edit
                              </Typography>
                              <Typography
                                align="right"
                                style={{ fontSize: 12, cursor: 'pointer' }}
                                onClick={() => handleDeleteDoorAddress(index)}
                              >
                                Remove
                              </Typography>
                            </Grid> */}
                            <Grid />
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                  {error.doorsAddress && (
                    <Typography
                      style={{ fontSize: 12, color: '#f44336', marginTop: 3 }}
                    >
                      {error.doorsAddress}
                    </Typography>
                  )}
                </Grid>
                <Grid container style={{ alignItems: 'center', marginTop: 60 }}>
                  <TextField
                    label="Average Store Size"
                    style={{ width: 150, marginRight: 32 }}
                    value={state.storeSize}
                    name="storeSize"
                    onChange={e => handleChange(e)}
                  />
                  <Grid style={{ position: 'relative', top: 12 }}>
                    <Grid container>
                      <RadioGroup
                        row
                        aria-label="gender"
                        name="gender1"
                        value={state.unitStoreSize}
                        onChange={event =>
                          setState({
                            ...state,
                            unitStoreSize: event.target.value,
                          })
                        }
                      >
                        <FormControlLabel
                          value="m"
                          control={<Radio size="small" />}
                          label={
                            <Typography
                              style={{
                                fontSize: 12,
                                position: 'relative',
                                top: -2,
                                marginRight: 30,
                              }}
                            >
                              m²
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          value="sq"
                          control={<Radio size="small" />}
                          label={
                            <Typography
                              style={{
                                fontSize: 12,
                                position: 'relative',
                                top: -2,
                                marginRight: 20,
                              }}
                            >
                              sq²
                            </Typography>
                          }
                        />
                      </RadioGroup>
                    </Grid>
                    {error.storeSize && (
                      <Typography
                        style={{ fontSize: 12, color: '#f44336', marginTop: 3 }}
                      >
                        {error.storeSize}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Grid style={{ marginTop: 54 }}>
                  <Grid container>
                    <Typography style={{ color: '#3a3a3a', fontSize: 14 }}>
                      Store Images
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    justifyContent="space-between"
                    style={{
                      marginTop: 10,
                      width: width > 1024 ? 420 : wid,
                      position: 'relative',
                    }}
                  >
                    <Grid>
                      <Grid container>
                        {state.files?.length > 0 &&
                          state.files.map((document, index) => (
                            <Grid
                              item
                              style={{
                                margin: '6px 10px 6px 0px',
                                position: 'relative',
                              }}
                            >
                              <Grid container justifyContent="center">
                                <Grid
                                  style={{
                                    position: 'absolute',
                                    left: 61,
                                    bottom: 61,
                                  }}
                                  onClick={() => handleRemoveFile(index)}
                                >
                                  <IconButton
                                    size="small"
                                    style={{
                                      position: 'relative',
                                      top: 2,
                                      right: 2,
                                    }}
                                  >
                                    <RemoveIcon />
                                  </IconButton>
                                </Grid>
                                <img
                                  src={URL.createObjectURL(document)}
                                  style={{
                                    width: 80,
                                    height: 80,
                                  }}
                                  alt="doc"
                                />
                              </Grid>
                            </Grid>
                          ))}
                        <Grid item style={{ margin: '6px 10px 6px 0px' }}>
                          <input
                            style={{ display: 'none' }}
                            className="files-dropzone"
                            accept="image/gif, image/jpeg, image/png"
                            id="button-import-docs"
                            type="file"
                            onChange={changeDocuments}
                            multiple
                          />
                          <label
                            htmlFor="button-import-docs"
                            style={{ display: 'inherit', cursor: 'pointer' }}
                          >
                            <Grid
                              container
                              style={{
                                width: 80,
                                height: 80,
                                alignItems: 'center',
                                border: '1px solid #000000',
                              }}
                              justifyContent="center"
                              alignItems="center"
                            >
                              <PlusIcon />
                            </Grid>
                          </label>
                        </Grid>
                      </Grid>
                    </Grid>
                    {wid > 1024 && (
                      <Grid>
                        <Grid
                          container
                          style={{
                            position: 'absolute',
                            left: '90%',
                            top: '0%',
                          }}
                        >
                          <InfoIcon />
                          <Typography className={classes.infoText}>
                            Images must be smaller than 2mb.
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid container>
                  <Input
                    field="Tell us about your Store Profile"
                    placeholder="Store profile"
                    width={wid}
                    minHeight={54}
                    marginTop={32}
                    value={state.storeProfile}
                    onChange={event => {
                      if (event.target.value.length > 250) return;
                      setState({
                        ...state,
                        storeProfile: event.target.value,
                      });
                      setError({
                        ...error,
                        storeProfile: false,
                      });
                    }}
                    multiline
                    rows={1}
                    error={error.storeProfile}
                    helperText={error.storeProfile}
                    limit={250}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
      <ModalAddress
        open={openAddress}
        handleClose={handleClose}
        handleSaveAddress={handleSaveAddress}
        editValues={editValues}
      />
    </Grid>
  );
}
