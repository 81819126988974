import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Snackbar from '../../util/SnackBar';
import ButtonFarm from '../../components/Button';

const useStyles = makeStyles({
  root: {
    '& .MuiDialog-scrollPaper': {
      alignItems: 'center',
    },
    '& .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded': {
      minHeight: 555,
      minWidth: 350,
    },
    '& .MuiDialogTitle-root': {
      padding: '16px 21px',
    },
  },

  span: {
    height: 15,
    width: 15,
    borderRadius: '50%',
  },

  grid: {
    display: 'flex',
  },

  bold: {
    fontWeight: 'bold',
  },

  modalText: {
    textAlign: 'center',
    fontSize: 14,
  },
});

export default function ModalChangeUserStatus(props) {
  const classes = useStyles();
  const {
    onClose,
    open,
    users,
    deleteUsers,
    setUsers,
    updateUsers,
    shopName,
    userEmails,
  } = props;
  const [isFinish, setIsFinish] = React.useState(false);
  const [selectedStatus, setSelectedStatus] = React.useState('');

  // * ------------- Funções para lógica do React ---------------*

  const handleClose = () => {
    if (isFinish) {
      setIsFinish(false);
      setUsers([]);
      shopName.current = [];
      userEmails.current = [];
      setSelectedStatus('');
    }
    onClose(false);
  };

  const handleChangeButton = () => {
    if (selectedStatus === '' && users.length === 0) {
      return Snackbar.error('Select a user and status');
    }
    if (users.length === 0) {
      return Snackbar.error('Select a user');
    }
    if (selectedStatus === '') {
      return Snackbar.error('Select a status');
    }
    updateUsers(selectedStatus);
    setIsFinish(true);
  };

  const handleStatusClick = status => {
    setSelectedStatus(status);
  };

  // * ------------- Funções de componentes React ---------------*

  function ModalTitle() {
    return (
      <>
        <DialogTitle id="simple-dialog-title">
          {!isFinish ? (
            <Typography
              align="center"
              style={{
                fontSize: 18,
                letterSpacing: '0.1em',
                justifyContent: 'center',
              }}
            >
              CHANGE USER STATUS
            </Typography>
          ) : (
            <>
              <Typography
                align="center"
                style={{
                  fontSize: 18,
                  letterSpacing: '0.1em',
                  justifyContent: 'center',
                }}
              >
                THE FOLLOWING
              </Typography>
              <Typography
                align="center"
                style={{
                  fontSize: 18,
                  letterSpacing: '0.1em',
                  justifyContent: 'center',
                }}
              >
                USERS ARE NOW
              </Typography>

              <Typography
                style={{
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                  textAlign: 'center',
                }}
              >
                {selectedStatus}
              </Typography>
            </>
          )}
        </DialogTitle>
        <hr />
        <Typography
          align="center"
          style={{
            fontSize: 18,
            fontWeight: 'bold',
            lineHeight: '21px',
            marginTop: 18,
          }}
        >
          {users.length} user(s) selected
        </Typography>
      </>
    );
  }

  function RenderUsers() {
    return (
      <List
        style={{
          minHeight: 278,
          maxHeight: 300,
          overflow: 'hidden',
          overflowY: 'scroll',
        }}
      >
        {users.map((user, index) => (
          <ListItem disableGutters key={user}>
            <Grid container>
              <Grid container>
                <Typography className={classes.bold} style={{ fontSize: 14 }}>
                  Name
                </Typography>
                <Typography style={{ marginLeft: 20, fontSize: 14 }}>
                  {user}
                </Typography>
              </Grid>
              <Grid container>
                <Typography className={classes.bold} style={{ fontSize: 14 }}>
                  Shop
                </Typography>
                <Typography style={{ marginLeft: 26, fontSize: 14 }}>
                  {shopName.current[index]}
                </Typography>
              </Grid>
              <CloseButton id={user} />
            </Grid>
          </ListItem>
        ))}
      </List>
    );
  }

  function CloseButton(props) {
    return (
      <ListItemSecondaryAction onClick={deleteUsers} id={props.id}>
        <IconButton edge="end" aria-label="close">
          <CloseIcon color="primary" />
        </IconButton>
      </ListItemSecondaryAction>
    );
  }

  function StatusArea() {
    return (
      <>
        <Grid container style={{ marginTop: 15 }}>
          <Typography
            style={{
              fontSize: 12,
              color: 'rgba(91,91,91, 1)',
              marginBottom: 6,
            }}
          >
            New Status
          </Typography>
        </Grid>
        <Grid container style={{ marginTop: 5 }}>
          <span
            className={classes.span}
            style={{
              backgroundColor: 'green',
              marginLeft: 30,
              opacity: selectedStatus === 'Approved' ? 1 : 0.6,
            }}
          />

          <Typography
            style={{
              fontSize: 12,
              color: 'rgba(91,91,91, 1)',
              marginBottom: 6,
              marginLeft: 15,
              cursor: 'pointer',
              opacity: selectedStatus === 'Approved' ? 1 : 0.6,
            }}
            onClick={() => handleStatusClick('Approved')}
          >
            Active
          </Typography>
          <span
            className={classes.span}
            style={{
              backgroundColor: 'red',
              marginLeft: 30,
              opacity: selectedStatus === 'Disapproved' ? 1 : 0.6,
            }}
          />

          <Typography
            style={{
              fontSize: 12,
              color: 'rgba(91,91,91, 1)',
              marginBottom: 6,
              marginLeft: 15,
              cursor: 'pointer',
              opacity: selectedStatus === 'Disapproved' ? 1 : 0.6,
            }}
            onClick={() => handleStatusClick('Disapproved')}
          >
            Inactive
          </Typography>
        </Grid>
      </>
    );
  }

  function ButtonsArea() {
    return (
      <Grid
        className={classes.grid}
        style={{ marginBottom: 20, marginTop: 25 }}
      >
        <ButtonFarm
          text="Cancel"
          textColor="#000"
          color="#fff"
          width={128}
          height={40}
          borderColor="1px solid #000"
          justifyContent="center"
          onClick={handleClose}
        />

        <ButtonFarm
          text="Change"
          width={128}
          height={40}
          textColor="#fff"
          color="#000"
          borderColor="1px solid #ebebeb"
          justifyContent="center"
          onClick={handleChangeButton}
        />
      </Grid>
    );
  }

  function EmailText() {
    return (
      <Grid style={{ marginTop: 15, marginBottom: 25 }}>
        <Typography className={classes.modalText}>
          An automatic email has been sent to
        </Typography>
        <Typography className={classes.modalText}>
          your buyers to let them know they have
        </Typography>
        <Typography className={classes.modalText}>
          been {selectedStatus} at Farm Rio Wholesale
        </Typography>
      </Grid>
    );
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      className={classes.root}
    >
      <Grid style={{ width: '80%', margin: 'auto' }}>
        <ModalTitle />
        <RenderUsers />
        <hr />
        {isFinish ? (
          <EmailText />
        ) : (
          <>
            <StatusArea /> <ButtonsArea />
          </>
        )}
      </Grid>
    </Dialog>
  );
}
