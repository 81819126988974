import React from 'react';
import { Typography, Grid } from '@material-ui/core';

function CategoryItem({ top, left, img, txt, onClick }) {
  return (
    <Grid
      style={{
        position: 'relative',
        margin: '10% 0%',
        cursor: 'pointer',
        width: '60%',
      }}
      onClick={() => onClick()}
    >
      <img
        style={{
          width: window.innerWidth * 0.6,
          height: 'auto',
          position: 'relative',
          left: left ? '25%' : '-25%',
        }}
        src={img}
        alt="farm"
      />
      {txt !== 'TOPS & BLOUSES' ? (
        <Typography
          variant="h3"
          style={{
            fontSize: window.innerWidth / 15,
            fontWeight: 'light',
            lineHeight: txt === 'BIKINI BOTTOMS' ? 'unset' :'25px',
            color: 'black',
            // height:  txt === 'BIKINI BOTTOMS' ? 'unset' : 32,
            position: 'absolute',
            top: top ? '10%' : '75%',
            left: left ? window.innerWidth / 5 - 100 : window.innerWidth / 3.5,
            minWidth: 150,
            maxWidth: txt !== 'BIKINI BOTTOMS' &&  200,
            display: 'flex',
            justifyContent: left ? 'flex-end' : 'flex-start',
            background: 'white',
            padding: '0px 5px',
          }}
        >
          {txt}
        </Typography>
      ) : (
        <Grid>
          <Typography
            variant="h3"
            style={{
              fontSize: window.innerWidth / 15,
              fontWeight: 'light',
              lineHeight: '25px',
              height: 32,
              color: 'black',
              position: 'absolute',
              top: top ? '10%' : '75%',
              left: left
                ? window.innerWidth / 5 - 100
                : window.innerWidth / 3.5,
              minWidth: 150,
              maxWidth: 200,
              display: 'flex',
              justifyContent: left ? 'flex-end' : 'flex-start',
              background: 'white',
              padding: '0px 5px',
            }}
          >
            TOPS &amp;
          </Typography>
          <Typography
            variant="h3"
            style={{
              fontSize: window.innerWidth / 15,
              fontWeight: 'light',
              lineHeight: '25px',
              height: 32,
              color: 'black',
              position: 'absolute',
              top: top ? '21.5%' : '75%',
              left: left ? 0 : window.innerWidth / 3.5,
              minWidth: 150,
              maxWidth: 200,
              display: 'flex',
              justifyContent: left ? 'flex-end' : 'flex-start',
              background: 'white',
              padding: '0px 5px',
            }}
          >
            BLOUSES
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}

export default CategoryItem;
