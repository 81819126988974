import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ButtonFarm from '../../components/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    '& .MuiDialog-scrollPaper': {
      alignItems: 'center',
    },

    '& .MuiDialogTitle-root': {
      padding: '16px 21px',
    },
  },

  span: {
    height: 15,
    width: 15,
    borderRadius: '50%',
  },

  grid: {
    display: 'flex',
  },

  bold: {
    fontWeight: 'bold',
  },

  modalText: {
    textAlign: 'center',
    fontSize: 14,
  },
});

export default function RemoveUsersFromAccountModal(props) {
  const classes = useStyles();
  const { onClose, open, costumer, handleDisapproveUser } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      className={classes.root}
    >
      <Grid
        style={{
          padding: 25,
        }}
      >
        <Typography>Are you sure you want to remove</Typography>
        <Typography>
          <span style={{ color: 'red' }}>{costumer.name}</span> from your
          Account?
        </Typography>

        <Grid className={classes.grid} style={{ marginTop: 25 }}>
          <ButtonFarm
            text="Cancel"
            textColor="#000"
            color="#fff"
            width={128}
            height={40}
            borderColor="1px solid #000"
            justify="center"
            onClick={handleClose}
          />

          <Grid style={{ marginLeft: 10 }}>
            <ButtonFarm
              text="Confirm"
              width={128}
              height={40}
              textColor={'#fff'}
              color={'#000'}
              borderColor="1px solid #ebebeb"
              justify="center"
              onClick={handleDisapproveUser}
            />
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
}
