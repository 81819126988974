import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
* {
    margin:0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
}
body {
  /* display:flex;
  justify-content:center;
  align-items:center; */
  background-color: white;
    -webkit-font-smoothing:antialiased;
    /* width:100%; */
    /* max-width: 1260px; */
}
.ExcelButtonStyle {
  border:0;
  background:transparent;
  padding:5px;
  cursor:pointer;
  font-size:12px;
}

html,body,#root {
    min-height:100%;
    background:white;
}


&::-webkit-scrollbar {
  height: 3px;
  width: 3px;
  border-radius: 8px;
}
/* Track */
&::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 8px;
  width: 3px;
  border: 0;
}

/* Handle */
&::-webkit-scrollbar-thumb {
  background: #7E8182;
  border-radius: 8px;
  width: 3px;
}

/* Handle on hover */
&::-webkit-scrollbar-thumb:hover {
  background: #565B5C;
}

@media all {
  .page-break {
    display: none;
  }
}
 
@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}
 
@media print {

  .page-break {
    display: flex;
    page-break-before: always;
    margin: 0;
   
  }
}
 
@page {
  margin: -0.33cm;
          size: landscape;





`;
