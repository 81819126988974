import { useCallback, useContext } from 'react';
import { ModalLoginContext } from '../contexts/modalLogin';

export default function useUserContext() {
  const [state, setState] = useContext(ModalLoginContext);

  const show = useCallback(() => {
    setState(true);
  }, [setState]);

  const hide = useCallback(() => {
    setState(false);
  }, [setState]);

  return {
    isShowing: state,
    show,
    hide,
  };
}
