import React, { useState } from 'react';
import { Typography, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { BsArrowDown } from 'react-icons/bs';
import Header from '../../components/HeaderView';
import style from './styles';
import Button from '../../components/Button';

const styles = theme => style(theme);

function Home() {
  const history = useHistory();
  const [wid, setWid] = useState(0);
  const [hgt, sethgt] = useState(0);

  const downloadSustainability = async () => {
    const a = document.createElement('a');
    a.href =
      'https://storage.googleapis.com/showroom-bucket-images/ALL/FarmRioSustainabilityReport.rar';
    a.setAttribute('download', 'filename');
    a.click();
  };

  React.useEffect(() => {
    function updateSize() {
      setWid(window.innerWidth);
      sethgt(window.innerHeight);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [wid, hgt]);

  const TreePlanted = () => {
    return (
      <Grid
        contaienr
        justifyContent="center"
        alignItems="center"
        style={{
          width: wid,
          height: wid > 1024 ? 800 : 284,
          marginTop: 20,
          background:
            wid > 1024
              ? 'url("https://storage.googleapis.com/showroom-bucket-images/ALL/tree_planted.png") no-repeat  '
              : 'url("https://storage.googleapis.com/showroom-bucket-images/ALL/tree_mobile.png") no-repeat  center',
        }}
      >
        <Grid
          style={{
            position: 'relative',
            left: window.innerWidth > 1024 ? '7%' : 0,
            marginLeft: window.innerWidth < 1024 && 25,
            top: window.innerWidth > 1024 ? '75%' : 225,
          }}
        >
          <Grid
            style={{
              width: wid > 1024 ? 537 : 297,
              height: wid > 1024 ? 294 : 300,
              background: 'rgba(255, 255, 255, 0.92)',
              padding: wid > 1024 ? '20px 28px 54px 40px' : '10px 20px 20px',
            }}
          >
            <Typography
              style={{
                fontSize: wid > 1024 ? 32 : 24,
                marginBottom: wid > 1024 ? 32 : 15,
              }}
            >
              One Tree Planted
            </Typography>
            <Typography
              style={{
                fontSize: wid > 1024 ? 14 : 12,
                lineHeight: wid > 1024 ? '28px' : '24px',
                width: wid > 1024 ? 473 : 262,
              }}
            >
              We partnered with One Tree Planted to aid in restoration efforts
              in the Amazon rainforest—the largest rainforest in the world—and
              the Atlantic Forest. For each online and in-store purchase,
              including through authorized retailers, we donate the funds to
              plant one tree. For every three pieces ordered through our
              wholesale channel, we donate one tree. By the end of 2021, we’ll
              have planted 500,000 trees.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const Yawanawa = () => {
    return (
      <Grid
        contaienr
        justifyContent="center"
        alignItems="center"
        style={{
          width: wid,
          height: wid > 1024 ? 800 : 284,
          marginTop: wid > 1024 ? 130 : 260,
          background:
            wid > 1024
              ? 'url("https://storage.googleapis.com/showroom-bucket-images/ALL/yawanawa.png") no-repeat  '
              : 'url("https://storage.googleapis.com/showroom-bucket-images/ALL/yawanawa_mobile.png") no-repeat  center',
        }}
      >
        <Grid
          style={{
            position: 'relative',
            left: window.innerWidth > 1024 ? '7%' : 0,
            marginLeft: window.innerWidth < 1024 && 25,
            top: window.innerWidth > 1024 ? '75%' : 225,
          }}
        >
          <Grid
            style={{
              width: wid > 1024 ? 537 : 329,
              height: wid > 1024 ? 294 : 197,
              background: 'rgba(255, 255, 255, 0.92)',
              padding: wid > 1024 ? '20px 28px 54px 40px' : '10px 15px 20px',
            }}
          >
            <Typography
              style={{
                fontSize: wid > 1024 ? 32 : 24,
                marginBottom: wid > 1024 ? 32 : 15,
              }}
            >
              THE YAWANAWA PEOPLE
            </Typography>
            <Typography
              style={{
                fontSize: wid > 1024 ? 14 : 12,
                lineHeight: wid > 1024 ? '28px' : '24px',
                width: wid > 1024 ? 473 : 296,
              }}
            >
              Some of our most meaningful FARM designs are created in
              partnership with the Yawanawa people, who have transformed us and
              our perspective on the natural world. True artists and guardians
              of the rainforest, they have inspired us to protect their rich
              Indigenous heritage and facilitate healing and empowerment.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const Responsible = () => {
    return wid > 1024 ? (
      <Grid
        container
        alignItems="center"
        style={{ padding: '0px 32px 32px', zIndex: 2 }}
      >
        <Grid
          style={{
            width: 424,
            position: 'relative',
            left: '7.8%',
          }}
        >
          <Typography
            style={{ fontSize: 24, lineHeight: '24px', marginBottom: 30 }}
          >
            Responsible Materials
          </Typography>
          <Typography style={{ fontSize: 12, lineHeight: '18px' }}>
            A vast amount of research goes into the fibers we use in our
            manufacturing, and this process is always evolving.
            <br />
            <br />
            Our team sources the best sustainable textiles the industry has to
            offer, ensuring each is independently certified.
          </Typography>
          <Grid
            style={{
              width: 314,
              height: 194,
              background: '#000',
              padding: '17px 56px 8px 43px',
              margin: '30px 0px',
            }}
          >
            <Typography
              style={{ fontSize: 14, lineHeight: '32px', color: '#fff' }}
            >
              100% Certified Organic Cotton
            </Typography>
            <Typography
              style={{ fontSize: 14, lineHeight: '32px', color: '#fff' }}
            >
              Lenzing Ecovero Viscose
            </Typography>
            <Typography
              style={{ fontSize: 14, lineHeight: '32px', color: '#fff' }}
            >
              Recycled Polyester
            </Typography>
            <Typography
              style={{ fontSize: 14, lineHeight: '32px', color: '#fff' }}
            >
              Recycled Yam
            </Typography>
            <Typography
              style={{ fontSize: 14, lineHeight: '32px', color: '#fff' }}
            >
              Fluity CO2 A.O.P
            </Typography>
          </Grid>
        </Grid>

        <img
          src="https://storage.googleapis.com/showroom-bucket-images/ALL/responsible_materials.png"
          alt="borogodo"
          style={{
            width: 610,
            height: 366,
            position: 'relative',
            left: '40%',
          }}
        />
      </Grid>
    ) : (
      <Grid container justifyContent="center" style={{ marginTop: 30 }}>
        <Grid style={{ width: 306 }}>
          <Typography
            style={{ fontSize: 24, lineHeight: '24px', marginBottom: 30 }}
          >
            Responsible Materials
          </Typography>
          <Typography style={{ fontSize: 12, lineHeight: '18px' }}>
            A vast amount of research goes into the fibers we use in our
            manufacturing, and this process is always evolving.
            <br />
            <br />
            Our team sources the best sustainable textiles the industry has to
            offer, ensuring each is independently certified.
          </Typography>
          <Grid
            style={{
              width: 314,
              height: 194,
              background: '#000',
              padding: '17px 56px 8px 43px',
              margin: '30px 0px',
            }}
          >
            <Typography
              style={{ fontSize: 14, lineHeight: '32px', color: '#fff' }}
            >
              100% Certified Organic Cotton
            </Typography>
            <Typography
              style={{ fontSize: 14, lineHeight: '32px', color: '#fff' }}
            >
              Lenzing Ecovero Viscose
            </Typography>
            <Typography
              style={{ fontSize: 14, lineHeight: '32px', color: '#fff' }}
            >
              Recycled Polyester
            </Typography>
            <Typography
              style={{ fontSize: 14, lineHeight: '32px', color: '#fff' }}
            >
              Recycled Yam
            </Typography>
            <Typography
              style={{ fontSize: 14, lineHeight: '32px', color: '#fff' }}
            >
              Fluity CO2 A.O.P
            </Typography>
          </Grid>
        </Grid>
        <img
          src="https://storage.googleapis.com/showroom-bucket-images/ALL/responsible_mobile.png"
          alt="borogodo"
          style={{
            width: 360,
            height: 250,
            paddingBottom: 20,
          }}
        />
      </Grid>
    );
  };

  const Inline = () => {
    return wid > 1024 ? (
      <Grid
        container
        style={{ padding: '32px 32px 0px', marginTop: 100, zIndex: 2 }}
      >
        <Grid
          style={{
            width: 424,
            position: 'relative',
            left: '7.8%',
          }}
        >
          <Typography style={{ fontSize: 14, height: 505, lineHeight: '28px' }}>
            In line with the UN&apos;s 2030 Carbon Agenda, we are comitted to
            zeroing ou scope 1 and 2 carbon emissions by 2030, and scope by
            2050. We are currently working on our first carbon inventory for our
            international operation to define the reduction goals, but have
            already introduced carbon neutral shipping for all our online
            orders. Our retail pop ups are both carbon neutral and we are
            working to replicate this across all our stores. In addition,
            we&apos;ve joined the Sustainable Apparel Coalition to certify our
            entire supply chain and are continuing to introduce more responsible
            fibers into our collection.
            <br />
            <br />
            As nature lovers, we have also started our compensation strategy
            through reforestation projects, resulting in the reforestation of 21
            hectares of land in Brazil and in the Latin American Amazon.
          </Typography>
        </Grid>

        <img
          src="https://storage.googleapis.com/showroom-bucket-images/ALL/inline.png"
          alt="borogodo"
          style={{
            width: 432,
            height: 400,
            position: 'relative',
            left: '40%',
          }}
        />
      </Grid>
    ) : (
      <Grid container justifyContent="center" style={{ marginTop: 230 }}>
        <img
          src="https://storage.googleapis.com/showroom-bucket-images/ALL/inline_mobile.png"
          alt="borogodo"
          style={{
            width: 360,
            height: 250,
          }}
        />
        <Typography
          style={{
            marginTop: 28,
            fontSize: 12,
            lineHeight: '20px',
            width: 294,
          }}
        >
          In line with the UN&apos;s 2030 Carbon Agenda, we are comitted to
          zeroing ou scope 1 and 2 carbon emissions by 2030, and scope by 2050.
          We are currently working on our first carbon inventory for our
          international operation to define the reduction goals, but have
          already introduced carbon neutral shipping for all our online orders.
          Our retail pop ups are both carbon neutral and we are working to
          replicate this across all our stores. In addition, we&apos;ve joined
          the Sustainable Apparel Coalition to certify our entire supply chain
          and are continuing to introduce more responsible fibers into our
          collection.
          <br />
          <br />
          As nature lovers, we have also started our compensation strategy
          through reforestation projects, resulting in the reforestation of 21
          hectares of land in Brazil and in the Latin American Amazon.
        </Typography>
      </Grid>
    );
  };

  const Compact = () => {
    return (
      <Grid
        contaienr
        justifyContent="center"
        alignItems="center"
        style={{
          width: wid,
          height: wid > 1024 ? 800 : 284,
          marginTop: wid > 1024 ? 93 : 0,
          background:
            wid > 1024
              ? 'url("https://storage.googleapis.com/showroom-bucket-images/ALL/global_compact.png") center/cover no-repeat  '
              : 'url("https://storage.googleapis.com/showroom-bucket-images/ALL/compact_mobile.png") no-repeat  center',
        }}
      >
        <Grid
          style={{
            position: 'relative',
            left: window.innerWidth > 1024 ? '7%' : 0,
            marginLeft: window.innerWidth < 1024 && 25,
            top: window.innerWidth > 1024 ? '75%' : 225,
          }}
        >
          <Grid
            style={{
              width: wid > 1024 ? 537 : 329,
              height: wid > 1024 ? 294 : 197,
              background: 'rgba(255, 255, 255, 0.92)',
              padding: wid > 1024 ? '20px 28px 54px 40px' : '10px 15px 20px',
            }}
          >
            <Typography
              style={{
                fontSize: wid > 1024 ? 32 : 18,
                marginBottom: 32,
              }}
            >
              United Nations Global Compact
            </Typography>
            <Typography
              style={{
                fontSize: wid > 1024 ? 14 : 12,
                lineHeight: wid > 1024 ? '28px' : '24px',
                width: wid > 1024 ? 488 : 296,
                paddingBottom: 50,
              }}
            >
              We are working to reduce CO2 emissions in the enviroment
              establishing a sustainability agenda that&apos;s fully aligned
              with the Sustainable Development Goals (SGDs). We are working to
              incorporate the SDGs into all areas of the company, creating a
              path toward a more sustainable for our business, our society and
              future generations.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const Nature = () => {
    return wid > 1024 ? (
      <Grid container style={{ padding: 32, marginTop: 25, zIndex: 2 }}>
        <img
          alt="nature"
          src={
            wid > 1024
              ? 'https://storage.googleapis.com/showroom-bucket-images/ALL/nature.png'
              : 'https://storage.googleapis.com/showroom-bucket-images/ALL/nature_mobile.png'
          }
          style={{
            width: 590,
            height: 410,
            position: 'relative',
            left: '13%',
          }}
        />
        <Grid
          style={{
            width: 383,
            position: 'relative',
            left: '30%',
          }}
        >
          <Typography
            style={{ fontSize: 32, lineHeigt: '24px', marginBottom: 32 }}
          >
            Nature is everything
          </Typography>
          <Typography style={{ fontSize: 14, lineHeight: '28px' }}>
            Nature has always been our home. We are passionate about taking
            impactful steps to ensure it will keep inspiring new generations
            with its beauty and diversity. <br /> <br /> Becoming a more
            sustainable brand is a journey where every act matters. Step by
            step, we move forward, reviewing our actions and attitudes as we
            build our effort to be a better company. We are doing this for the
            people and the planet.
          </Typography>
          <Grid
            style={{ marginTop: 30 }}
            onClick={() => history.push('/sustainability')}
          >
            <Button
              width={163}
              height={34}
              textColor="#fff"
              color="#000"
              fontSize={11.25}
              onClick={downloadSustainability}
              // loading={loadingOrder}
              text="Discover the report"
            />
          </Grid>
        </Grid>
      </Grid>
    ) : (
      <Grid
        container
        justifyContent="center"
        style={{ marginTop: 25, zIndex: 2 }}
      >
        <Grid
          style={{
            width: 285,
          }}
        >
          <Typography
            style={{ fontSize: 32, lineHeigt: '24px', marginBottom: 32 }}
          >
            Nature is everything
          </Typography>
          <Typography style={{ fontSize: 14, lineHeight: '28px' }}>
            Nature has always been our home. We are passionate about taking
            impactful steps to ensure it will keep inspiring new generations
            with its beauty and diversity. <br /> <br /> Becoming a more
            sustainable brand is a journey where every act matters. Step by
            step, we move forward, reviewing our actions and attitudes as we
            build our effort to be a better company. We are doing this for the
            people and the planet.
          </Typography>
          <Grid
            style={{ marginTop: 30 }}
            onClick={() => history.push('/sustainability')}
          >
            <Button
              width={163}
              height={34}
              textColor="#fff"
              color="#000"
              fontSize={11.25}
              // onClick={handleNext}
              // loading={loadingOrder}
              text="Discover the report"
            />
          </Grid>
        </Grid>
        <img
          src="https://storage.googleapis.com/showroom-bucket-images/ALL/nature_mobile.png"
          alt="nature"
          style={{
            width: wid - '1%',
            height: 250,
            marginTop: 24,
          }}
        />
      </Grid>
    );
  };

  const WeHave = () => {
    return wid > 1024 ? (
      <Grid
        container
        style={{ padding: 32, marginTop: 140, zIndex: 2, alignItems: 'center' }}
      >
        <Grid
          style={{
            width: 460,
            position: 'relative',
            height: 312,
            left: '13%',
            padding: wid > 1024 ? '30px 28px 26px 32px' : 0,
            background: '#000',
          }}
        >
          <Typography
            style={{
              fontsize: 14,
              fontWeight: 'bold',
              lineHeight: '32px',
              color: '#fff',
              marginBottom: 30,
            }}
          >
            In 4 years, we have...
          </Typography>

          <Typography
            style={{
              fontSize: 14,
              lineHeight: '32px',
              color: '#fff',
            }}
          >
            <span style={{ fontWeight: 'bold', fontSize: 14 }}>EMPOWERED</span>{' '}
            160 artisans
          </Typography>

          <Typography
            style={{
              fontSize: 14,
              lineHeight: '32px',
              color: '#fff',
            }}
          >
            <span style={{ fontWeight: 'bold', fontSize: 14 }}>PRODUCED</span>{' '}
            3,223 PIECES
          </Typography>

          <Typography
            style={{
              fontSize: 14,
              lineHeight: '32px',
              color: '#fff',
            }}
          >
            <span style={{ fontWeight: 'bold', fontSize: 14 }}>GENERATED</span>{' '}
            $183.150 thousand dollars in direct income
          </Typography>

          <Typography
            style={{
              fontSize: 14,
              lineHeight: '32px',
              color: '#fff',
            }}
          >
            <span style={{ fontWeight: 'bold', fontSize: 14 }}>PLANTED</span>{' '}
            2,000 trees in deforested areas
          </Typography>

          <Typography
            style={{
              fontSize: 14,
              lineHeight: '32px',
              color: '#fff',
            }}
          >
            <span style={{ fontWeight: 'bold', fontSize: 14 }}>INVESTED</span>{' '}
            $73.000 thousand dollars in local initiatives
          </Typography>
          <Typography
            style={{
              fontSize: 14,
              lineHeight: '32px',
              color: '#fff',
            }}
          >
            <span style={{ fontWeight: 'bold', fontSize: 14 }}>FOSTERED</span> a
            robust artisan partnership
          </Typography>
        </Grid>
        <img
          src="https://storage.googleapis.com/showroom-bucket-images/ALL/wehave.png"
          alt="wehave"
          style={{
            width: 590,
            height: 410,
            position: 'relative',
            left: '30%',
          }}
        />
      </Grid>
    ) : (
      <Grid
        container
        justifyContent="center"
        style={{ padding: 32, marginTop: 160, zIndex: 2, alignItems: 'center' }}
      >
        <img
          src="https://storage.googleapis.com/showroom-bucket-images/ALL/wehave_mobile.png"
          alt="wehave"
          style={{
            width: wid,
            height: 250,
            marginBottom: 22,
          }}
        />
        <Grid
          style={{
            width: 314,
            height: 240,
            padding: wid > 1024 ? '30px 28px 26px 32px' : '19px 7px 9px 20px',
            background: '#000',
          }}
        >
          <Typography
            style={{
              fontsize: 14,
              fontWeight: 'bold',
              lineHeight: '32px',
              color: '#fff',
              marginBottom: wid > 1024 ? 30 : 15,
            }}
          >
            In 4 years, we have...
          </Typography>

          <Typography
            style={{
              fontSize: 10,
              lineHeight: '32px',
              color: '#fff',
            }}
          >
            <span style={{ fontWeight: 'bold', fontSize: 12 }}>EMPOWERED</span>{' '}
            160 artisans
          </Typography>

          <Typography
            style={{
              fontSize: 10,
              lineHeight: '32px',
              color: '#fff',
            }}
          >
            <span style={{ fontWeight: 'bold', fontSize: 12 }}>PRODUCED</span>{' '}
            3,223 PIECES
          </Typography>

          <Typography
            style={{
              fontSize: 10,
              lineHeight: '32px',
              color: '#fff',
            }}
          >
            <span style={{ fontWeight: 'bold', fontSize: 12 }}>GENERATED</span>{' '}
            $183.150 thousand dollars in direct income
          </Typography>

          <Typography
            style={{
              fontSize: 10,
              lineHeight: '32px',
              color: '#fff',
            }}
          >
            <span style={{ fontWeight: 'bold', fontSize: 12 }}>PLANTED</span>{' '}
            2,000 trees in deforested areas
          </Typography>

          <Typography
            style={{
              fontSize: 10,
              lineHeight: '32px',
              color: '#fff',
            }}
          >
            <span style={{ fontWeight: 'bold', fontSize: 12 }}>INVESTED</span>{' '}
            $73.000 thousand dollars in local initiatives
          </Typography>
          <Typography
            style={{
              fontSize: 10,
              lineHeight: '32px',
              color: '#fff',
            }}
          >
            <span style={{ fontWeight: 'bold', fontSize: 12 }}>FOSTERED</span> a
            robust artisan partnership
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const WhoWeAre = () => {
    return (
      <Grid
        contaienr
        justifyContent="center"
        alignItems="center"
        style={{
          width: wid,
          height: wid > 1024 ? hgt : 634,
          background:
            wid > 1024
              ? 'url("https://storage.googleapis.com/showroom-bucket-images/ALL/sustainability.jpeg") no-repeat top center '
              : 'url("https://storage.googleapis.com/showroom-bucket-images/ALL/sustain_mobile.png") no-repeat  center',
        }}
      >
        <Typography
          align="center"
          style={{
            fontSize: wid > 1024 ? 32 : 24,
            lineHeight: 24,
            color: '#fff',
            letterSpacing: '0.1em',
            position: 'relative',
            bottom: wid < 1024 && 115,
          }}
        >
          We are nature lovers
        </Typography>
        <Typography
          onClick={() => window.scrollTo({ behavior: 'smooth', top: hgt })}
          align="center"
          style={{
            fontSize: wid > 1024 ? 24 : 18,
            color: '#fff',
            marginBottom: wid > 1024 && 20,
            position: 'absolute',
            bottom: hgt * 0.05,
            left: '44.5%',
            cursor: 'pointer',
          }}
        >
          Our commitments
          <Grid container justifyContent="center">
            <BsArrowDown size={48} />
          </Grid>
        </Typography>
      </Grid>
    );
  };

  return (
    <div>
      <Header />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{
          width: wid,
          position: 'sticky',
          top: wid > 1024 ? 69 : 60,
          background: '#fff',
          zIndex: 1,
        }}
      >
        <Typography
          style={{
            fontSize: wid > 1024 ? 24 : 18,
            fontFamily: 'Galano Grotesque Light',
            letterSpacing: '0.1em',
          }}
        >
          SUSTAINABILITY
        </Typography>
      </Grid>

      <WhoWeAre />

      <Nature />

      <TreePlanted />

      <Yawanawa />
      <WeHave />

      <Compact />

      <Inline />
      <Responsible />
    </div>
  );
}

const wrapperComponent = withStyles(styles)(Home);

export default wrapperComponent;
