import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import history from '../../services/history';

export default function RightLateralBar({ by, delivery, bg }) {
  return (
    <Grid
      container
      justifyContent="space-around"
      style={{
        transform: 'rotate(90deg)',
        minWidth: 550,
        maxWidth: 550,
        position: 'fixed',

        // PRA MIM ESSE É O MELHOR

        // right: window.innerWidth * -0.18,
        // top: window.innerHeight * 0.5,
        // paddingTop: window.innerHeight * 0.08,
        right: -230,
        top: '50%',
        // paddingTop: window.innerHeight * 0.06,
      }}
    >
      <Grid
        // container
        // justifyContent="center"
        // alignItems="center"
        onClick={() => {
          if (history.location.pathname.includes('europe')) {
            history.replace(`/europe/delivery/${delivery}/view`);
          } else if (history.location.pathname.includes('swimwear')) {
            history.replace(`/swimwear/${delivery}/view`);
          } else if (history.location.pathname.includes('shoes')) {
            history.replace(`/shoes/${delivery}/view`);
          } else {
            history.replace(`/delivery/${delivery}/view`);
          }
        }}
        style={{
          // width: 160,

          // padding: '4px 20px',
          // border:
          //   by === 'view' || by === 'smiley'
          //     ? bg
          //       ? '2px solid #FFFFFF'
          //       : '2px solid #000000'
          //     : null,
          marginLeft: 3,
          cursor: 'pointer',
        }}
      >
        <Typography
          variant="h6"
          style={{
            padding: '4px 20px',
            textAlign: 'center',
            marginLeft: 4,
            fontSize: 14,
            border:
              by === 'view'
                ? bg
                  ? '2px solid #FFFFFF'
                  : '2px solid #000000'
                : '2px solid transparent',
            color:
              by === 'view' || by === 'smiley'
                ? bg
                  ? '#ffffff'
                  : '#000000'
                : '#000000',
            fontFamily:
              by === 'view' || (by === 'smiley' && 'GalanoGrotesque-Medium'),
          }}
        >
          Overview
        </Typography>
      </Grid>
      {/* {delivery === '2' && (
        <Grid
          // container
          // justifyContent="center"
          // alignItems="center"
          onClick={() => {
            if (history.location.pathname.includes('europe')) {
              history.replace(`/europe/delivery/${delivery}/takeaway`);
            } else {
              history.replace(`/delivery/${delivery}/takeaway`);
            }
          }}
          style={{
            // width: 160,

            // padding: '4px 20px',
            // border:
            //   by === 'view' || by === 'smiley' 
            //     ? bg
            //       ? '2px solid #FFFFFF'
            //       : '2px solid #000000'
            //     : null,
            marginLeft: 3,
            cursor: 'pointer',
          }}
        >
          <Typography
            variant="h6"
            style={{
              padding: '4px 20px',
              textAlign: 'center',

              border:
                by === 'smiley' //eslint-disable-line
                  ? bg
                    ? '2px solid #ffffff'
                    : '2px solid #000000'
                  : '2px solid transparent',
              fontSize: 14,
              color:
                by === 'view' || by === 'smiley' 
                  ? bg
                    ? '#ffffff'
                    : '#000000'
                  : '#000000',
              fontFamily: by === 'smiley' && 'GalanoGrotesque-Medium',
            }}
          >
            smiley
          </Typography>
        </Grid>
      )} */}
      {/* {!document.location.pathname.includes('shoes') && (
        <Grid
          onClick={() => {
            if (history.location.pathname.includes('europe')) {
              history.replace(`/europe/delivery/${delivery}/print`);
            } else {
              history.replace(`/delivery/${delivery}/print`);
            }
          }}
          style={{
            // marginLeft: 60,
            // marginRight: 60,
            // width: 160,

            // padding: '2px 20px',
            cursor: 'pointer',
          }}
        >
          <Typography
            variant="h6"
            style={{
              padding: '4px 20px',
              textAlign: 'center',

              border:
                by === 'print' //eslint-disable-line
                  ? (bg && by === 'view') || by === 'smiley'
                    ? '2px solid #ffffff'
                    : '2px solid #000000'
                  : '2px solid transparent',
              fontSize: 14,
              color:
                by === 'view' || by === 'smiley'
                  ? bg
                    ? '#ffffff'
                    : '#000000'
                  : '#000000',
              fontFamily: by === 'print' && 'GalanoGrotesque-Medium',
            }}
          >
            Prints
          </Typography>
        </Grid>
      )} */}
      {!document.location.pathname.includes('4') && (
        <Grid
          // container
          // justifyContent="center"
          // alignItems="center"
          onClick={() => {
            if (history.location.pathname.includes('europe')) {
              return history.replace(`/europe/delivery/${delivery}/category`);
            }
            if (history.location.pathname.includes('swimwear')) {
              return history.replace(`/swimwear/${delivery}/category`);
            }
            if (history.location.pathname.includes('shoes')) {
              return history.replace(`/shoes/${delivery}/category`);
            }
            if (history.location.pathname.includes('men')) {
              return history.replace(`/men/${delivery}/category`);
            }
            return history.replace(`/delivery/${delivery}/category`);
          }}
          style={
            {
              // width: 160,
            }
          }
        >
          <Typography
            variant="h6"
            style={{
              textAlign: 'center',
              border:
                by === 'category' //eslint-disable-line
                  ? (bg && by === 'view') || by === 'smiley'
                    ? '2px solid #ffffff'
                    : '2px solid #000000'
                  : '2px solid transparent',
              padding: '4px 20px',
              cursor: 'pointer',
              fontSize: 14,
              color:
                by === 'view' || by === 'smiley'
                  ? bg
                    ? '#ffffff'
                    : '#000000'
                  : '#000000',
              fontFamily: by === 'category' && 'GalanoGrotesque-Medium',
            }}
          >
            Categories
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}
