import React, { useState, useEffect, useCallback } from 'react';
import { TextField, Typography, FormGroup } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import Grid from '@material-ui/core/Grid';

import FormControlLabel from '@material-ui/core/FormControlLabel';

import Container from '@material-ui/core/Container';
import { useDebounce } from 'use-lodash-debounce';
import { useHistory } from 'react-router-dom';
import Snackbar from '../../util/SnackBar';
import api from '../../services/api';
import Header from '../../components/HeaderView';

import style from './styles';

const styles = theme => style(theme);
function Login({ classes }) {
  const [userEmail, setUserEmail] = useState('');
  const [userBrand, setUserBrand] = useState('');
  const [userName, setUserName] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  // const [selectedOption, setSelectedOption] = useState(false);
  const history = useHistory();
  const [editUser, setEditUser] = useState('');
  const queryParam = useDebounce(userEmail, 200);
  const [isSwimwear, setIsSwimwear] = useState(false);

  const [userOptions, setUserOptions] = useState([]);

  // if (signed) history.push('/home');
  const clearInputs = useCallback(async () => {
    setUserEmail('');
    setUserBrand('');
    setUserName('');
    setIsAdmin(false);
    setIsSwimwear(false);
  }, []);
  const refreshUserList = useCallback(async () => {
    const response = await api.get('/listCostumers', {
      params: {
        email: queryParam,
      },
    });
    setUserOptions(response.data.users);
  }, [queryParam]);

  const handleSubmit = useCallback(async () => {
    const nameSplit = userName.split(' ');

    if (!userName) {
      return alert('Insert a name and surname');
    }
    if (
      !userName.includes(' ') ||
      (nameSplit.length > 0 && nameSplit[1].length < 1)
    ) {
      return alert('Insert a name and surname');
    }
    try {
      if (!editUser) {
        await api.post('/newCostumers', {
          email: userEmail,
          name: userName,
          brand: userBrand,
          admin: isAdmin,
          password: 'FARMRIO',
          isApproved: 'Approved',
          isEurope: history.location.pathname.includes('europe'),
          createByAdmin: true,
          isSwimwear,
        });
        clearInputs();
        Snackbar.success('User created');
      } else {
        await api.put('/newCostumers', {
          email: userEmail,
          name: userName,
          brand: userBrand,
          admin: isAdmin,
        });
        // clearInputs();
        Snackbar.success('User edited');
      }
    } catch (err) {
      if (err.response.data.error) {
        Snackbar.warning(err.response.data.error);
      }
    }
  }, [
    userEmail,
    userName,
    userBrand,
    isAdmin,
    editUser,
    clearInputs,
    history,
    isSwimwear,
  ]);

  useEffect(() => {
    refreshUserList();
  }, [queryParam, refreshUserList]);

  return (
    <>
      <Header isAdmin />

      <Container
        style={{
          flexDirection: 'column',
          height: '100vh',
          paddingTop: '10%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          maxWidth: 1024,
        }}
      >
        <Grid container justifyContent="center" alignItems="center">
          <ButtonGroup disableElevation variant="contained" color="primary">
            <Button
              style={{
                background: editUser ? '#FAFAFA' : '#424242',
                height: 45,
                width: window.innerWidth > 960 ? 180 : 155,
                textTransform: 'none',
              }}
              onClick={() => setEditUser(false)}
            >
              <Typography
                style={{
                  color: !editUser ? '#FAFAFA' : '#424242',
                }}
              >
                Create users
              </Typography>
            </Button>
            <Button
              style={{
                background: editUser ? '#424242' : '#FAFAFA',
                height: 45,
                width: window.innerWidth > 960 ? 180 : 155,
                textTransform: 'none',
              }}
              onClick={() => setEditUser(true)}
            >
              <Typography
                style={{
                  color: editUser ? '#FAFAFA' : '#424242',
                }}
              >
                Edit users
              </Typography>
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          {editUser ? (
            <Autocomplete
              id="combo-box-demo"
              options={userOptions}
              onChange={(event, value) => {
                if (!value) {
                  setUserEmail('');
                  setUserBrand('');
                  setUserName('');
                  setIsAdmin(false);
                } else {
                  setUserEmail(value.email);
                  setUserBrand(value.brand);
                  setUserName(value.name);
                  setIsAdmin(value.admin);
                }
              }} // prints the selected value
              getOptionLabel={option => option.email}
              style={{ width: 290, marginTop: 15 }}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Find user by email"
                  variant="outlined"
                />
              )}
            />
          ) : (
            <TextField
              type="email"
              color="primary"
              variant="outlined"
              fullWidth
              autoFocus
              label="E-mail"
              value={userEmail}
              onChange={e => {
                // setLoginError('');
                setUserEmail(e.target.value);
              }}
              style={{ width: 290, marginTop: 15 }}

              // helperText={usernameError}
            />
          )}
          <TextField
            type="text"
            color="primary"
            variant="outlined"
            fullWidth
            autoFocus
            label="Brand"
            value={userBrand}
            onChange={e => {
              // setLoginError('');
              setUserBrand(e.target.value);
            }}
            style={{ width: 290, marginTop: 15 }}

            // helperText={usernameError}
          />{' '}
          <TextField
            type="text"
            color="primary"
            variant="outlined"
            fullWidth
            autoFocus
            label="Name"
            value={userName}
            onChange={e => {
              // setLoginError('');
              setUserName(e.target.value);
            }}
            style={{ width: 290, marginTop: 15 }}

            // helperText={usernameError}
          />
          <FormGroup style={{ alignSelf: 'center', marginRight: 190 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isAdmin}
                  onChange={() => setIsAdmin(!isAdmin)}
                />
              }
              label="Admin"
            />
          </FormGroup>
          <FormGroup
            style={{
              alignSelf: 'center',
              marginRight: 190,
              position: 'relative',
              left: 21,
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={isSwimwear}
                  onChange={() => setIsSwimwear(!isSwimwear)}
                />
              }
              label="isSwimwear"
            />
          </FormGroup>
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            style={{ marginTop: 15, padding: 5, width: 200 }}
          >
            {editUser ? 'Edit user' : 'Create user'}
          </Button>
        </Grid>
      </Container>
    </>
  );
}

const wrapperComponent = withStyles(styles)(Login);

export default wrapperComponent;
