import React, { createContext, useState } from 'react';

export const initialState = {
  showModal: false,
  selectedUser: null,
  selectedAccount: null,
  accounts: null,
  users: null,
};

const ViewAsContext = createContext();

export { ViewAsContext };

export default function ViewAsContextProvider({ children }) {
  const state = useState(initialState);

  return (
    <ViewAsContext.Provider value={state}>{children}</ViewAsContext.Provider>
  );
}
