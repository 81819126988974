import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './routes';
import Home from '../pages/Home';
import Deliveries from '../pages/Deliveries';
import ManageUsers from '../pages/ManageUsers';
import Users from '../components/Users';
import WhoWeAre from '../pages/WhoWeAre';
import ResetPassword from '../pages/ResetPassword';
import Sustainability from '../pages/Sustainability';
import Admin from '../pages/Admin';
import Cart from '../components/templates/cart';
import Collection from '../components/templates/collection';
import Client from '../pages/ClientArea';
import Register from '../pages/RegisterAccount';
import useUserContext from '../hooks/useUserContext';

export default function Routes() {
  const { userState } = useUserContext();
  return process.env.REACT_APP_MAINTENANCE_MODE === 'enabled' &&
    !userState.user.admin ? (
    <Route exact path="/" component={Home} />
  ) : (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/europe" component={Home} isEurope />

      <Route path="/whoweare" component={WhoWeAre} />
      <Route path="/sustainability" component={Sustainability} />

      <Route path="/register" component={Register} />
      <Route path="/reset/:tokenId" component={ResetPassword} />

      <Route
        path="/delivery/:delivery_id/:view"
        component={Deliveries}
        isPrivate
      />
      <Route
        path="/shoes/:delivery_id/:view"
        component={Deliveries}
        isPrivate
      />
      <Route
        path="/swimwear/:delivery_id/:view"
        component={Deliveries}
        isPrivate
      />
      <Route path="/men/:delivery_id/:view" component={Deliveries} isPrivate />
      <Route
        path="/europe/delivery/:delivery_id/:view"
        component={Deliveries}
        isPrivate
        isEurope
      />
      <Route path="/admin" component={Admin} isPrivate isAdmin />
      <Route path="/register" component={Register} />
      <Route path="/users" component={Users} />
      <Route path="/admin/:email?" component={Admin} />

      <Route path="/collection" component={Collection} isPrivate />
      <Route path="/orders" component={Cart} isPrivate />

      <Route path="/admin" component={Admin} isPrivate isAdmin />
      <Route path="/users" component={Users} isPrivate isAdmin />
      <Route
        path="/europe/admin"
        component={ManageUsers}
        isPrivate
        isAdmin
        isEurope
      />

      <Route path="/client" component={Client} isPrivate />
    </Switch>
  );
}
