import axios from 'axios';

const baseURL = process.env.REACT_APP_BACKEND_URL;

const api = axios.create({
  baseURL,
});

const errorHandler = err => {
  if (err.response) {
    return {
      status: err.response.status || 500,
      message: err.response.data.error || 'Unknow error',
    };
  }
  return {
    status: 500,
    message: err.message || 'Unknown error',
  };
};

export const getUserInfo = async ({ email, token }) => {
  const response = await api
    .get(`/newCostumers/${email}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch(errorHandler);
  return response;
};

export const updateUser = async data => {
  const response = await api.put('/newCostumers', data).catch(errorHandler);
  return response;
};

export const createUser = async data => {
  const response = await api.post('/newCostumers', data).catch(errorHandler);
  return response;
};

export const login = async ({ email, password }) => {
  const response = await api
    .post('/session', {
      email,
      password,
    })
    .catch(errorHandler);

  return response;
};

export const resetPassword = async ({ email }) => {
  const response = await api
    .post('/password/forgot', {
      email,
    })
    .catch(errorHandler);

  return response;
};

export const listAccounts = async () => {
  const response = await api.get('/accounts').catch(errorHandler);
  return response;
};

export const listAccountUsers = async id => {
  const response = await api
    .get(`/findCostumersByAccount/${id}`)
    .catch(errorHandler);
  return response;
};

export const updateAccount = async data => {
  const response = await api.put('/account', data).catch(errorHandler);
  return response;
};

export const createAccount = async data => {
  const response = await api.post('/account', data).catch(errorHandler);
  return response;
};

export const findAccountByName = async name => {
  const response = await api
    .get(`findAccountByName/${name}`)
    .catch(errorHandler);
  return response;
};

export const updateOrder = async (id, data) => {
  const response = await api.put(`/order/${id}`, data).catch(errorHandler);
  return response;
};

export const createOrder = async data => {
  const response = await api.post('/order', data).catch(errorHandler);
  return response;
};

export const findOrderByUserAndAddress = async data => {
  const response = await api.post(`/findOrderByUserAndAddress`, data);
  return response;
};

export const findLatestConfig = async () => {
  const response = await api.get('/config/latest');
  return response;
};

export const uploadProductImages = async () => {
  const response = await api.get('/google-files');
  return response;
};

export default api;
