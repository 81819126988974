export default theme => ({
  hover: {
    '&:hover': {
      opacity: 1,
    },
    opacity: 0,
    bottom: 30,
    left: 15,
    fontSize: 26,
    position: 'absolute',
    color: 'white',
    lineHeight: 1,
    zIndex: 200,
    transition: 'opacity 0.3s',
    // zIndex: 100,
  },
});
