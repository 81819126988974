/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useRef } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useRouteMatch, useLocation } from 'react-router-dom';
import HeaderMobile from '../../componentsMobile/HeaderMobile';
import ModalCollection from '../../componentsMobile/ModalCollectionMobile';
import DeliveryViewMobile from '../../componentsMobile/DeliveryViewMobile';
import PrintViewMobile from '../../componentsMobile/PrintViewMobile';
import CategoryViewMobile from '../../componentsMobile/CategoryViewMobile';
import useConfigContext from '../../hooks/useConfigContext';

// import TakeAwayViewMobile from '../../componentsMobile/TakeAwayViewMobile';

import OverviewBar from '../../componentsMobile/OverviewBar';
import DeliveryBar from '../../componentsMobile/DeliveryBar';

function Deliveries({ isSwimwear }) {
  const { configState } = useConfigContext();
  const seasonName = configState.season.name || '';
  const { deliveries, swimwear, shoes } = configState;
  const { params } = useRouteMatch();
  const { delivery_id, view } = params;
  const [open, setOpen] = React.useState(false);
  const [bg, setBg] = React.useState(true);
  const [, setOpenLogin] = React.useState(false);
  const [, setCollection] = useState(false);
  const location = useLocation();
  const collection = useRef('');
  const splittedLocation = location.pathname.split('/');
  collection.current = splittedLocation[1];
  const backgroundImageObject = {
    view: {
      delivery: `url(${deliveries[+delivery_id - 1].bgMobile}) top/cover`,
      swimwear: `url(${swimwear[+delivery_id - 1].bgMobile}) top/cover`,
      shoes: `url(${shoes[+delivery_id - 1].bgMobile}) top/cover`,
    },
    category: {
      delivery: '#fff',
      swimwear: '#fff',
      shoes: '#fff',
    },
  };

  const handleOpenLogin = () => {
    setOpenLogin(true);
  };

  const handleClickCollection = () => {
    setCollection(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const listenScrollEvent = event => {
    if (window.scrollY >= 0 && window.scrollY <= 100) setBg(true);
    if (window.scrollY > 101) setBg(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent);
    return () => window.removeEventListener('scroll', listenScrollEvent);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    setBg(true);
  }, [delivery_id]);

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="center"
        style={{
          background: bg
            ? backgroundImageObject[view][collection.current]
            : '#fff',
          height: window.innerHeight,
          width: window.innerWidth,
          // 'background-attachment': 'fixed',
          // '-o-background-size': 'cover',
          // '-moz-background-size': 'cover',
          // '-webkit-background-size': 'cover',
          // 'background-repeat': 'no-repeat',
        }}
      >
        <Grid
          style={{
            width: '100%',
            paddingTop: 70,
          }}
        >
          <Grid
            style={{
              paddingBottom: 102,
            }}
          >
            {view === 'view' && (
              <DeliveryViewMobile
                isSwimwear={isSwimwear}
                bg={bg}
                deliveryID={delivery_id}
                by={view}
              />
            )}
            {view === 'category' && <CategoryViewMobile />}
            {view === 'print' && <PrintViewMobile />}
          </Grid>
        </Grid>
        {view !== 'view' ? (
          <>
            <Grid
              container
              style={{
                position: 'fixed',
                alignItems: 'center',
                justifyContent: 'center',
                background: '#fff',
                top: 0,
                zIndex: 1000,
              }}
            >
              <HeaderMobile
                // isTransparent={!!bg}
                login={handleOpenLogin}
                handleClickCollection={handleClickCollection}
              />
              <Typography
                align="center"
                style={{
                  fontSize: 20,
                  marginTop: '60px',
                  marginBottom: '5px',
                  letterSpacing: '0.2em',
                  fontWeight: 'bold',
                }}
              >
                {document.location.pathname.includes('shoes')
                  ? 'SHOES'
                  : seasonName}
              </Typography>
              <Grid container>
                <DeliveryBar delivery={delivery_id} by={view} bg={bg} />
              </Grid>

              <Grid container>
                <OverviewBar delivery={delivery_id} by={view} bg={bg} />
              </Grid>
            </Grid>
          </>
        ) : !bg ? (
          <>
            <Grid
              container
              style={{
                position: 'fixed',
                alignItems: 'center',
                justifyContent: 'center',
                background: '#fff',
                top: 0,
                zIndex: 1,
              }}
            >
              <HeaderMobile
                // isTransparent={!!bg}
                bg={bg}
                login={handleOpenLogin}
                handleClickCollection={handleClickCollection}
              />
              <Typography
                align="center"
                style={{
                  fontSize: 20,
                  marginTop: '60px',
                  marginBottom: '5px',
                  letterSpacing: '0.2em',
                  fontWeight: 'bold',
                }}
              >
                {document.location.pathname.includes('shoes')
                  ? 'SHOES'
                  : seasonName}
              </Typography>
              <Grid container>
                <DeliveryBar delivery={delivery_id} by={view} bg={bg} />
              </Grid>

              <Grid container>
                <OverviewBar delivery={delivery_id} by={view} bg={bg} />
              </Grid>
            </Grid>
          </>
        ) : (
          <HeaderMobile
            isTransparent={!!bg}
            login={handleOpenLogin}
            handleClickCollection={handleClickCollection}
          />
        )}
      </Grid>
      <ModalCollection open={open} handleClose={handleClose} />
    </>
  );
}

export default Deliveries;
