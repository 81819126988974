import { useContext, useCallback } from 'react';
import { ConfigContext } from '../contexts/config';
import { findLatestConfig } from '../services/api';

export default function useUserContext() {
  const [state, setState] = useContext(ConfigContext);

  const getConfig = useCallback(async () => {
    const { data } = await findLatestConfig();
    const configDTO = {
      season: {
        name: data.config.season.name,
        line: data.config.season.line,
      },
      home: {
        sustainability: {
          bgDeskSignedIn: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Home/Logada/Desk/Sustainability.jpg`,
          bgMobSignedIn: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Home/Logada/Mobile/Sustainability.jpg`,
          bgDeskNotSignedIn: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Home/Na%CC%83o%20Logada/Desk/Sustainability.jpg`,
          bgMobNotSignedIn: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Home/Na%CC%83o%20Logada/Mobile/Sustainability.jpg`,
        },
        whoWeAre: {
          bgDeskSignedIn: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Home/Logada/Desk/Who_We_Are.jpg`,
          bgMobSignedIn: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Home/Logada/Mobile/Who_We_Are.jpg`,
          bgDeskNotSignedIn: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Home/Na%CC%83o%20Logada/Desk/Who_We_Are.jpg`,
          bgMobNotSignedIn: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Home/Na%CC%83o%20Logada/Mobile/Who_We_Are.jpg`,
        },
        welcome: {
          bgDeskSignedIn: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Home/Logada/Desk/Welcome.jpg`,
          bgMobSignedIn: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Home/Logada/Mobile/Welcome.jpg`,
          bgDeskNotSignedIn: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Home/Na%CC%83o%20Logada/Desk/Welcome.jpg`,
          bgMobNotSignedIn: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Home/Na%CC%83o%20Logada/Mobile/Welcome.jpg`,
        },
      },
      collections: [
        {
          backgroundUrl: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Home/Delivery1_Cover.jpg`,
          url: 'delivery/1/view',
          title: 'Ready to wear',
          id: 1,
          active: true,
        },
        {
          backgroundUrl: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Home/Delivery1_Cover.jpg`,
          url: 'delivery/1/view',
          title: 'Ready to wear',
          id: 1,
          active: true,
        },
        {
          backgroundUrl: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Home/Delivery1_Cover.jpg`,
          url: 'delivery/1/view',
          title: 'Ready to wear',
          id: 1,
          active: true,
        },
        {
          backgroundUrl: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery1/Swimwear/Cover/SWIM_Cover.jpg`,
          url: 'swimwear/1/view',
          title: 'Swimwear',
          id: 2,
          active: true,
        },
        {
          backgroundUrl: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery1/Shoes/Cover/SHOES_Cover.jpg`,
          url: 'shoes/1/view',
          title: 'Footwear and handbags ',
          id: 3,
          active: true,
        },
      ],
      deliveries: [
        {
          backgroundUrl: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Home/Delivery1_Cover.jpg`,
          url: 'delivery/1/view',
          title: 'DELIVERY 1',
          subTitle1: data.config.deliveries[0].subTitle1,
          subTitle2: data.config.deliveries[0].subTitle2,
          id: 1,
          active: true,
          bgWeb: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery1/Ready_To_Wear/BG/Delivery1_BG.jpg`,
          bgMobile: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery1/Ready_To_Wear/BG/Delivery1_BG.jpg`,
          collection: 'MAIN COLLECTION',
        },
        {
          backgroundUrl: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Home/Delivery2_Cover.jpg`,
          url: 'delivery/2/view',
          title: 'DELIVERY 2',
          subTitle1: data.config.deliveries[1].subTitle1,
          subTitle2: data.config.deliveries[1].subTitle2,
          id: 2,
          active: true,
          bgWeb: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery2/Ready_To_Wear/BG/Delivery2_BG.jpg`,
          bgMobile: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery2/Ready_To_Wear/BG/Delivery2_BG.jpg`,
          collection: 'MAIN COLLECTION',
        },
        {
          backgroundUrl: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Home/Delivery3_Cover.jpg`,
          url: 'delivery/3/view',
          title: 'DELIVERY 3',
          subTitle1: data.config.deliveries[2].subTitle1,
          subTitle2: data.config.deliveries[2].subTitle2,
          id: 3,
          active: true,
          bgWeb: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery3/Ready_To_Wear/BG/Delivery3_BG.jpg`,
          bgMobile: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery3/Ready_To_Wear/BG/Delivery3_BG.jpg`,
          collection: 'MAIN COLLECTION',
        },
        // {
        //   backgroundUrl: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery3/Ready_To_Wear/Cover/Delivery3_Cover.jpg`,
        //   url: 'delivery/4/view',
        //   title: 'SKI',
        //   subTitle1: data.config.deliveries[3].subTitle1,
        //   subTitle2: data.config.deliveries[3].subTitle2,
        //   id: 4,
        //   active: false,
        //   bgWeb: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Capsula1/BG/Capsula1_BG.jpg`,
        //   bgMobile: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Capsula1/BG/Capsula1_BG.jpg`,
        //   collection: 'MAIN COLLECTION',
        // },
      ],
      // ski: [
      //   {
      //     backgroundUrl: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Capsula1/Cover/Capsula1_Cover.jpg`,
      //     title: 'Ski',
      //     subTitle1: data.config.deliveries[3].subTitle1,
      //     subTitle2: data.config.deliveries[3].subTitle2,
      //     id: 1,
      //     url: '/delivery/4/view',
      //     active: true,
      //     // upperTitle: 'Main Collection',
      //     bgWeb: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Capsula1/BG/Capsula1_BG.jpg`,
      //     bgMobile: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Capsula1/BG/Capsula1_BG.jpg`,
      //   },
      // ],
      swimwear: [
        {
          backgroundUrl: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Home/Swim_Cover.jpg`,
          title: 'Swimwear',
          subTitle1: data.config.deliveries[0].subTitle1,
          subTitle2: data.config.deliveries[0].subTitle2,
          id: 1,
          url: '/swimwear/1/view',
          active: true,
          // upperTitle: 'Main Collection',
          bgWeb: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery1/Swimwear/BG/Delivery1_BG.jpg`,
          bgMobile: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery1/Swimwear/BG/Delivery1_BG.jpg`,
        },
        {
          backgroundUrl: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Home/Logada/Desk/Swimwear.jpg`,
          title: 'Swimwear',
          subTitle1: data.config.deliveries[1].subTitle1,
          subTitle2: data.config.deliveries[1].subTitle2,
          id: 2,
          url: '/swimwear/2/view',
          active: false,
          bgWeb: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery2/Swimwear/BG/Delivery2_BG.jpg`,
          bgMobile: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery2/Swimwear/BG/Delivery2_BG.jpg`,
        },
        {
          backgroundUrl: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Home/Logada/Desk/Swimwear.jpg`,
          title: 'Swimwear',
          subTitle1: data.config.deliveries[2].subTitle1,
          subTitle2: data.config.deliveries[2].subTitle2,
          id: 3,
          url: '/swimwear/3/view',
          active: false,
          bgWeb: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery3/Swimwear/BG/Delivery3_BG.jpg`,
          bgMobile: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery3/Swimwear/BG/Delivery3_BG.jpg`,
        },
      ],
      shoes: [
        {
          backgroundUrl: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery1/Shoes/Cover/Delivery1_Cover.jpg`,
          title: 'SHOES',
          url: '/shoes/1/view',
          subTitle1: data.config.deliveries[0].subTitle1,
          subTitle2: data.config.deliveries[0].subTitle2,
          id: 1,
          active: true,
          // upperTitle: 'Main Collection',
          bgWeb: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery1/Shoes/BG/Delivery1_BG.jpg`,
          bgMobile: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery1/Shoes/BG/Delivery1_BG.jpg`,
        },
        {
          backgroundUrl: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery1/Swimwear/Cover/delivery1_cover.jpg`,
          title: 'DELIVERY 2',
          url: '/shoes/2/view,',
          subTitle1: data.config.deliveries[1].subTitle1,
          subTitle2: data.config.deliveries[1].subTitle2,
          id: 2,
          active: false,
          bgWeb: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery2/Shoes/BG/Delivery2_BG.jpg`,
          bgMobile: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery2/Shoes/BG/Delivery2_BG.jpg`,
        },
        {
          backgroundUrl: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery1/Swimwear/Cover/delivery1_cover.jpg`,
          title: 'DELIVERY 3',
          url: '/shoes/3/view,',
          subTitle1: data.config.deliveries[2].subTitle1,
          subTitle2: data.config.deliveries[2].subTitle2,
          id: 3,
          active: false,
          bgWeb: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery3/Shoes/BG/Delivery3_BG.jpg`,
          bgMobile: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery3/Shoes/BG/Delivery3_BG.jpg`,
        },
      ],

      categories: {
        ACCESSORIES: {
          1: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery1/Swimwear/Categories/ACCESSORIES.jpg`,
          2: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery2/Swimwear/Categories/ACCESSORIES.jpg`,
          3: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery3/Swimwear/Categories/ACCESSORIES.jpg`,
        },
        DRESSES: {
          1: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery1/Ready_To_Wear/Categories/DRESSES.jpg`,
          2: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery2/Ready_To_Wear/Categories/DRESSES.jpg`,
          3: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery3/Ready_To_Wear/Categories/DRESSES.jpg`,
          holiday: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Capsula1/Categories/DRESSES.jpg`,
          takeaway: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Capsula2/Categories/DRESSES.jpg`,
          'pre-spring': `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Capsula3/Categories/DRESSES.jpg`,
        },
        BOTTOMS: {
          1: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery1/Ready_To_Wear/Categories/BOTTOM.jpg`,
          2: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery2/Ready_To_Wear/Categories/BOTTOM.jpg`,
          3: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery3/Ready_To_Wear/Categories/BOTTOM.jpg`,
          holiday: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Capsula1/Categories/BOTTOM.jpg`,
          takeaway: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Capsula2/Categories/BOTTOM.jpg`,
          'pre-spring': `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Capsula3/Categories/BOTTOM.jpg`,
        },
        'TOPS AND BLOUSES': {
          1: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery1/Ready_To_Wear/Categories/TOPS.jpg`,
          2: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery2/Ready_To_Wear/Categories/TOPS.jpg`,
          3: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery3/Ready_To_Wear/Categories/TOPS.jpg`,
          holiday: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Capsula1/Categories/TOPS.jpg`,
          takeaway: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Capsula2/Categories/TOPS.jpg`,
          'pre-spring': `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Capsula3/Categories/TOPS.jpg`,
        },
        JUMPSUITS: {
          1: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery1/Ready_To_Wear/Categories/JUMPSUITS.jpg`,
          2: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery2/Ready_To_Wear/Categories/JUMPSUITS.jpg`,
          3: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery3/Ready_To_Wear/Categories/JUMPSUITS.jpg`,
          holiday: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Capsula1/Categories/JUMPSUITS.jpg`,
          takeaway: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Capsula2/Categories/JUMPSUITS.jpg`,
          // 'pre-spring': `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Capsula3/Categories/JUMPSUITS.jpg`,
        },
        OUTERWEAR: {
          1: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery1/Ready_To_Wear/Categories/OUTERWEAR.jpg`,
          2: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery2/Ready_To_Wear/Categories/OUTERWEAR.jpg`,
          3: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery3/Ready_To_Wear/Categories/OUTERWEAR.jpg`,
          // holiday: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Capsula1/Categories/OUTERWEAR.jpg`,
          // takeaway: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Capsula2/Categories/OUTERWEAR.jpg`,
          // 'pre-spring': `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Capsula3/Categories/OUTERWEAR.jpg`,
        },
        KNITWEAR: {
          1: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery1/Ready_To_Wear/Categories/KNITWEAR.jpg`,
          2: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery2/Ready_To_Wear/Categories/KNITWEAR.jpg`,
          3: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery3/Ready_To_Wear/Categories/KNITWEAR.jpg`,
          holiday: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Capsula1/Categories/KNITWEAR.jpg`,
          // takeaway: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Capsula2/Categories/KNITWEAR.jpg`,
          'pre-spring': `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Capsula3/Categories/KNITWEAR.jpg`,
        },
        BOOTS: {
          1: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery1/Shoes/Categories/BOOTS.jpg`,
          2: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery2/Shoes/Categories/BOOTS.jpg`,
          3: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery3/Shoes/Categories/BOOTS.jpg`,
        },
        BALLERINA: {
          // 1: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery1/Shoes/Categories/BALLLERINA.jpg`,
          2: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery2/Shoes/Categories/BALLERINA.jpg`,
          // 3: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery3/Shoes/Categories/BALLLERINA.jpg`,
        },
        LOAFER: {
          1: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery1/Shoes/Categories/LOAFER.jpg`,
          // 2: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery2/Shoes/Categories/LOAFER.jpg`,
          3: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery3/Shoes/Categories/LOAFER.jpg`,
        },
        MULE: {
          1: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery1/Shoes/Categories/MULE.jpg`,
          2: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery2/Shoes/Categories/MULE.jpg`,
          3: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery3/Shoes/Categories/MULE.jpg`,
        },
        SANDALS: {
          1: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery1/Shoes/Categories/SANDALS.jpg`,
          2: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery2/Shoes/Categories/SANDALS.jpg`,
          3: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery3/Shoes/Categories/SANDALS.jpg`,
        },
        SLIPPER: {
          // 1: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery1/Shoes/Categories/SLIPPER.jpg`,
          // 2: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery2/Shoes/Categories/SLIPPER.jpg`,
          // 3: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery3/Shoes/Categories/SLIPPER.jpg`,
        },
        SNEAKERS: {
          1: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery1/Shoes/Categories/SNEAKERS.jpg`,
          2: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery2/Shoes/Categories/SNEAKERS.jpg`,
          3: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery3/Shoes/Categories/SNEAKERS.jpg`,
        },

        'BIKINI BOTTOMS': {
          1: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery1/Swimwear/Categories/BIKINIBOTTOM.jpg`,
          2: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery2/Swimwear/Categories/BIKINIBOTTOM.jpg`,
          3: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery3/Swimwear/Categories/BIKINIBOTTOM.jpg`,
        },
        'BIKINI TOPS': {
          1: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery1/Swimwear/Categories/BIKINITOP.jpg`,
          2: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery2/Swimwear/Categories/BIKINITOP.jpg`,
          3: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery3/Swimwear/Categories/BIKINITOP.jpg`,
        },
        'COVER UP': {
          1: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery1/Swimwear/Categories/COVER_UP.jpg`,
          // 2: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery2/Swimwear/Categories/COVER_UP.jpg`,
          3: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery3/Swimwear/Categories/COVER_UP.jpg`,
        },
        'ONE PIECE': {
          1: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery1/Swimwear/Categories/ONEPIECE.jpg`,
          2: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery2/Swimwear/Categories/ONEPIECE.jpg`,
          3: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery3/Swimwear/Categories/ONEPIECE.jpg`,
        },
        SARONGS: {
          1: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery1/Swimwear/Categories/SARONG.jpg`,
          2: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery2/Swimwear/Categories/SARONG.jpg`,
          3: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery3/Swimwear/Categories/SARONG.jpg`,
        },
        TOPS: {
          1: `https://storage.googleapis.com/showroom-bucket-images/HS23/Delivery1/Swimwear/Categories/TOP.jpg`,
          2: 'https://storage.googleapis.com/showroom-bucket-images/HS23/Delivery2/Swimwear/Categories/TOP.jpg',
        },
        BOTTOMS_SWIM: {
          1: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery1/Swimwear/Categories/BOTTOMS.jpg`,
          2: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery2/Swimwear/Categories/BOTTOMS.jpg`,
          3: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery3/Swimwear/Categories/BOTTOMS.jpg`,
        },
        DRESS: {
          1: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery1/Swimwear/Categories/DRESSES.jpg`,
          2: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery2/Swimwear/Categories/DRESSES.jpg`,
          3: `https://storage.googleapis.com/showroom-bucket-images/${data.config.season.line}/Delivery3/Swimwear/Categories/DRESSES.jpg`,
        },
      },
    };
    return setState(configDTO);
  }, [setState]);

  return {
    configState: state,
    getConfig,
  };
}
