import React, { useState } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import Skeleton from '@material-ui/lab/Skeleton';

import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Checkbox,
  TableSortLabel,
  TableHead,
} from '@material-ui/core';
import { BiChevronRight, BiChevronLeft } from 'react-icons/bi';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useDebounce } from 'use-lodash-debounce';
import ClearIcon from '@material-ui/icons/Clear';
import Button from '../../components/Button';
import TextInput from '../../components/TextInput';

import api from '../../services/api';
import {
  shippingDate,
  dictIdDeliveryFormatted,
  dictIdDeliveryToNumber,
  dictIdDelivery,
} from '../../consts';

const headCells = [
  { id: 'mainly_image', numeric: true, disablePadding: false, label: '' },

  { id: 'style_code', numeric: true, disablePadding: false, label: 'SKU' },
  { id: 'description', numeric: false, disablePadding: true, label: 'Name' },
  {
    id: 'category_name',
    numeric: false,
    disablePadding: false,
    label: 'Category',
  },
  {
    id: 'wholesale',
    numeric: false,
    disablePadding: false,
    label: 'Wholesale',
  },
];

const StyledTableRow = withStyles(theme => ({
  root: {
    border: 0,
    background: '#fff',
  },

  tableCell: {
    '$selected &': {
      color: 'yellow',
    },
  },
  selected: {
    border: '1px solid #000',
  },
}))(TableRow);

const Chip = ({ label, handleDelete }) => {
  return (
    <div style={{ margin: 10 }} onClick={handleDelete}>
      <Typography display="inline">{label}</Typography>
      <img
        alt="icon"
        src="https://icons-for-free.com/iconfiles/png/512/close+cross+delete+remove+square+icon-1320195275754208861.png"
        style={{
          cursor: 'pointer',
          width: 16,
          height: 16,
          marginLeft: 5,
          position: 'relative',
          top: 3,
        }}
      />
    </div>
  );
};

const StyledDialog = withStyles({
  root: {
    width: 860,
  },
  paper: {
    width: 860,
    margin: 0,
    display: 'flex',
    justifyContent: 'center',
    borderRadius: 0,
  },
  paperFullScreen: {
    margin: 0,
    height: '100%',
    backgroundColor: 'white',
    borderRadius: 0,
  },
  paperWidthLg: {
    // maxWidth: '100%',
  },
  scrollPaper: {
    alignItems: 'center',
    borderRadius: 0,
  },
  paperFullWidth: {
    // width: '100%',
  },
  container: {
    height: 'auto',
    borderRadius: 0,
  },
})(Dialog);

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#fff',
    color: '#000',
    border: 0,
    borderBottom: '1px solid #000',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);
function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{ background: 'blue' }}>
      <TableRow>
        <StyledTableCell padding="checkbox">
          <span />
        </StyledTableCell>
        {headCells.map(headCell => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.id === 'status' ? 'left' : 'center'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              style={{ marginLeft: 30 }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: '0px 30px',
    maxHeight: '700px',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableRow: {
    '&$selected, &$selected:hover': {
      border: '3px solid #000',
      borderBottom: '1px solid #000',
      background: '#fff',
    },
  },
  tableCell: {
    borderBottom: 'none',
    '$selected &': {
      borderTop: '1px solid #000',
      borderBottom: '1px solid #000 !important',
    },
  },
  tableCellFirst: {
    borderBottom: 'none',
    '$selected &': {
      borderTop: '1px solid #000',
      borderLeft: '1px solid #000',
      borderBottom: '1px solid #000 !important',
    },
  },
  tableCellLast: {
    borderBottom: 'none',
    '$selected &': {
      borderTop: '1px solid #000',
      borderRight: '1px solid #000',
      borderBottom: '1px solid #000 !important',
    },
  },
  selected: {},
}));

const deliveries = [
  {
    deliveryId: dictIdDelivery[1],
    line: 'READY TO WEAR',
  },
  {
    deliveryId: dictIdDelivery[2],
    line: 'READY TO WEAR',
  },
  {
    deliveryId: dictIdDelivery[3],
    line: 'READY TO WEAR',
  },
  {
    deliveryId: dictIdDelivery[1],
    line: 'SWIMWEAR',
  },
  {
    deliveryId: dictIdDelivery[2],
    line: 'SWIMWEAR',
  },
];

export default function AddStyles({
  open,
  onClose,
  email,
  delivery,
  orderId,
  handleLoading,
}) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('style_code');
  const [currentDelivery, setCurrentDelivery] = useState(delivery);
  const [printsOptions, setPrintOptions] = React.useState([]);
  const [categoryOptions, setCategoryOptions] = React.useState([]);
  const [printName, setPrintName] = React.useState('All');
  const [styleCode, setStyleCode] = React.useState('');
  const [categoryName, setCategoryName] = React.useState('All');
  const [selected, setSelected] = React.useState([]);
  const [setSelectStatus] = React.useState('');
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleAddStyles = async () => {
    setLoading(true);
    await api.put(`/order/${orderId}`, {
      products: selected,
    });
    setLoading(false);
    onClose();
    handleLoading();
  };

  const handleDelete = id => {
    setSelected(selected.filter(item => item !== id));
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    // if (selectStatus && selectStatus !== status)
    //   if (!selectStatus) setSelectStatus(status);

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      if (newSelected.length === 0) setSelectStatus('');
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = name => selected.indexOf(name) !== -1;

  // const removeProduct = index => {
  //   setSelectedInfo(selectedInfo.filter((item, i) => index !== i));
  //   setSelected(selected.filter((item, i) => index !== i));
  //   if (selectedInfo?.length === 1) setOpenChangestatus(false);
  // };

  const getProducts = async () => {
    const { data } = await api.get(
      `/productsManage?limit=${2000}&skip=${0}&style_code=${styleCode}&delivery_id=${
        deliveries[currentDelivery].deliveryId
      }&print_name=${printName}&category_name=${categoryName}&style_collection=${
        deliveries[currentDelivery].line === 'SWIMWEAR' ? 'SWIMWEAR' : 'MAIN'
      }`
    );
    const arr = data.products.map((item, index) => ({
      mainly_image: item.mainly_image,
      style_code: item.style_code,
      description: item.description,
      category_name: item.category_name,
      wholesale: `$ ${item?.wholesale_usd} / € ${item?.wholesale_eur}`,
      id: index + 1,
    }));
    const prints = ['All'];
    const categories = ['All'];
    data.products.forEach(item => {
      if (!prints.includes(item.print_name[0])) prints.push(item.print_name[0]);
      if (!categories.includes(item.category_name))
        categories.push(item.category_name);
    });
    setPrintOptions(prints);
    setCategoryOptions(categories);
    setRows(arr);
    setLoading(false);
  };
  const debouncedSKU = useDebounce(styleCode, 500);

  React.useEffect(() => {
    getProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSKU, styleCode, printName, categoryName, currentDelivery]);

  const handleNext = () => {
    if (currentDelivery === 4) return setCurrentDelivery(0);
    return setCurrentDelivery(currentDelivery + 1);
  };

  const handlePrev = () => {
    if (currentDelivery === 0) return setCurrentDelivery(4);
    return setCurrentDelivery(currentDelivery - 1);
  };
  return (
    <StyledDialog
      aria-labelledby="confirmation-dialog-title"
      open={open}
      onClose={onClose}
      hideBackdrop
      maxWidth="md"
      style={{
        position: 'fixed',
        top: '35%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 860,
        zIndex: 2000,
        borderRadius: 0,
      }}
    >
      <DialogTitle id="confirmation-dialog-title">
        <Grid container justifyContent="space-between">
          <Grid style={{ width: 100 }} />

          <Grid>
            <Grid container alignItems="center">
              <BiChevronLeft
                style={{ cursor: 'pointer' }}
                onClick={handlePrev}
              />
              <Typography
                style={{
                  margin: '0px 15px 7px 15px',
                  width: 300,
                  fontSize: 20,
                }}
                align="center"
                display="inline"
              >
                Delivery{' '}
                {dictIdDeliveryToNumber[deliveries[currentDelivery].deliveryId]}{' '}
                {deliveries[currentDelivery].line}
              </Typography>
              <BiChevronRight
                style={{ cursor: 'pointer' }}
                onClick={handleNext}
              />
            </Grid>
            <Grid>
              <Typography align="center">
                {
                  dictIdDeliveryFormatted[
                    deliveries[currentDelivery].deliveryId
                  ]
                }{' '}
                in Stores |{' '}
                {shippingDate[deliveries[currentDelivery].deliveryId]}
              </Typography>
            </Grid>
          </Grid>

          <Grid style={{ width: 100 }} onClick={onClose}>
            <Typography style={{ cursor: 'pointer' }} align="right">
              Close X
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <Grid
        container
        justifyContent="space-evenly"
        style={{ marginBottom: 20 }}
      >
        <TextInput
          select
          field="Category"
          width={160}
          options={categoryOptions}
          value={categoryName}
          onChange={event => {
            setCategoryName(event.target.value);
          }}
          height={29}
          background="#ebebeb"
        />
        <TextInput
          select
          field="Print"
          width={350}
          options={printsOptions}
          value={printName}
          onChange={event => {
            setPrintName(event.target.value);
          }}
          height={29}
          background="#ebebeb"
        />
        <TextInput
          field="Find product by SKU"
          width={167}
          // height={39}
          value={styleCode}
          onChange={event => setStyleCode(event.target.value)}
          // variant
        />
      </Grid>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          {loading ? (
            <Grid container justifyContent="center">
              <Skeleton width={750} height={window.innerHeight * 0.5} />
            </Grid>
          ) : (
            <TableContainer style={{ height: window.innerHeight * 0.5 }}>
              <Table
                stickyHeader
                className={classes.table}
                aria-labelledby="tableTitle"
                size="medium"
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  // onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  style={{ background: 'blue' }}
                />
                <TableBody align>
                  {stableSort(rows, getComparator(order, orderBy)).map(
                    (row, index) => {
                      const isItemSelected = isSelected(row.style_code);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <StyledTableRow
                          hover
                          onClick={event =>
                            handleClick(
                              event,
                              row.style_code,
                              row.status,
                              row.description,
                              row.mainly_image
                            )
                          }
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.description}
                          selected={isItemSelected}
                          classes={{ selected: classes.selected }}
                          className={classes.tableRow}
                        >
                          <TableCell
                            padding="checkbox"
                            style={{ borderBottom: 'none' }}
                            className={classes.tableCellFirst}
                          >
                            <Checkbox
                              checked={isItemSelected}
                              inputProps={{ 'aria-labelledby': labelId }}
                            />
                          </TableCell>
                          <TableCell
                            className={classes.tableCell}
                            align="center"
                            onClick={() =>
                              window.open(`/register/${row.email}`)
                            }
                            style={{ borderBottom: 'none' }}
                          >
                            <div
                              style={{
                                cursor: 'pointer',
                              }}
                            >
                              <img
                                alt="product_image"
                                src={row.mainly_image}
                                style={{ width: 34, height: 49 }}
                              />
                            </div>
                          </TableCell>
                          <TableCell
                            className={classes.tableCell}
                            style={{ borderBottom: 'none' }}
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                            align="center"
                          >
                            {row.style_code}
                          </TableCell>
                          <TableCell
                            className={classes.tableCell}
                            style={{ borderBottom: 'none' }}
                            align="center"
                          >
                            {row.description}
                          </TableCell>
                          <TableCell
                            className={classes.tableCell}
                            style={{ borderBottom: 'none' }}
                            align="center"
                          >
                            {row.category_name}
                          </TableCell>
                          <TableCell
                            className={classes.tableCell}
                            style={{ borderBottom: 'none' }}
                            align="center"
                          >
                            {row?.wholesale}
                          </TableCell>
                        </StyledTableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Paper>
      </div>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ padding: '20px 30px' }}
      >
        <Grid
          style={{
            maxWidth: 600,
            height: 35,
            overflow: 'auto',
          }}
        >
          <ScrollMenu width={300}>
            <Grid container>
              {selected.map(item => (
                <Chip label={item} handleDelete={() => handleDelete(item)} />
              ))}
            </Grid>
          </ScrollMenu>
        </Grid>
        <Grid style={{ cursor: 'pointer' }} onClick={() => setSelected([])}>
          {selected.length > 0 && (
            <Grid container alignItems="center">
              Clear Selection
              <ClearIcon
                style={{ position: 'relative', top: 3 }}
                size="small"
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid container justifyContent="center" style={{ paddingBottom: 20 }}>
        <Button
          width={240}
          height={40}
          text="Add styles"
          textColor={selected.length <= 0 ? '#8e8e8e' : '#fff'}
          color={selected.length > 0 ? '#000' : '#fff'}
          borderColor="1px solid #8e8e8e"
          justifyContent="center"
          onClick={handleAddStyles}
          loading={loading}
        />
      </Grid>
    </StyledDialog>
  );
}
