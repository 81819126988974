import React from 'react';
import { Grid } from '@material-ui/core';
import OrderTable from './EditOrderTable';
import MakeOrder from './MakeOrder';

export default function EditOrder() {
  const [orderSelected, setOrderSelected] = React.useState('');

  return !orderSelected ? (
    <Grid
      container
      style={{ alignContent: 'flex-start', padding: '20px 30px 0px 35px' }}
    >
      <OrderTable orderSelected={setOrderSelected} />
    </Grid>
  ) : (
    <MakeOrder
      orderSelected={orderSelected}
      handleBack={() => setOrderSelected('')}
    />
  );
}
