import React, { useEffect, useRef } from 'react';
import { Grid } from '@material-ui/core';
import { useRouteMatch, useLocation } from 'react-router-dom';
import Media from 'react-media';
import RightLateralBar from '../../components/RightLateralBar';
import LeftLateralBar from '../../components/LeftLateralBar';
import HeaderView from '../../components/HeaderView';
import ModalCollection from '../../components/ModalCollection';
import DeliveryView from '../../components/DeliveryView';
import TakeAwayView from '../../components/TakeAwayView';
import PrintView from '../../components/PrintView';
import CategoryView from '../../components/CategoryView';
import DeliveriesMobile from '../../pagesMobile/DeliveriesMobile';
import useConfigContext from '../../hooks/useConfigContext';

function Deliveries() {
  const { configState } = useConfigContext();
  const { params } = useRouteMatch();
  const { delivery_id, view } = params;
  const [open, setOpen] = React.useState(false);
  const [showDND, setShowDND] = React.useState(false);
  const [isCategoryDetail, setIsCategoryDetail] = React.useState(false);
  const handleIsCategoryDetail = React.useCallback(value => {
    setIsCategoryDetail(value);
  }, []);
  const handleShowDND = React.useCallback(() => {
    setShowDND(s => !s);
  }, []);
  const location = useLocation();
  const [bg, setBg] = React.useState(true);
  const collection = useRef('');
  const splittedLocation = location.pathname.split('/');
  // eslint-disable-next-line prefer-destructuring
  collection.current = splittedLocation[1];

  const backgroundImageObject = {
    view: {
      delivery: `url(${
        configState.deliveries[+delivery_id - 1]?.bgWeb
      }) top/cover`,
      swimwear: `url(${
        configState.swimwear[+delivery_id - 1]?.bgWeb
      }) top/cover`,
      shoes: `url(${configState.shoes[+delivery_id - 1]?.bgWeb}) top/cover`,
      men: `url(https://storage.googleapis.com/showroom-bucket-images/SS24/Delivery1/Male/BG/Delivery1_BG.jpg) top/cover`,
      // ski: `url(https://storage.googleapis.com/showroom-bucket-images/FW24/Capsula1/BG/Capsula1_BG.jpg) top/cover`,
    },
    category: {
      delivery: '#fff',
      swimwear: '#fff',
      shoes: '#fff',
    },
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const listenScrollEvent = event => {
    if (window.scrollY >= 0 && window.scrollY <= 100) setBg(true);
    if (window.scrollY > 101) setBg(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent);
    return () => window.removeEventListener('scroll', listenScrollEvent);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    setBg(true);
  }, [delivery_id]);

  return (
    <>
      <Media
        queries={{
          small: '(max-width: 1050px)',
          large: '(min-width: 1051px)',
        }}
      >
        {matches => (
          <>
            {matches.small && <DeliveriesMobile />}
            {matches.large && (
              <>
                <HeaderView
                  handleShowDND={handleShowDND}
                  isCategoryDetail={isCategoryDetail}
                  isDelivery
                  open={handleClickOpen}
                  bg={bg}
                />{' '}
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  style={{
                    background: bg
                      ? backgroundImageObject[view][collection.current]
                      : '#fff',
                    height: window.innerHeight,
                    width: window.innerWidth,
                  }}
                >
                  <Grid
                    style={{
                      width: '100%',
                      paddingTop: 0,
                    }}
                  >
                    <Grid
                      style={{
                        paddingBottom: 102,
                      }}
                    >
                      {view === 'view' && (
                        <DeliveryView
                          showDND={showDND}
                          handleShowDND={handleShowDND}
                          bg={bg}
                        />
                      )}
                      {view === 'smiley' && <TakeAwayView bg={bg} />}

                      {view === 'category' && (
                        <CategoryView
                          showDND={showDND}
                          handleShowDND={handleShowDND}
                          handleIsCategoryDetail={handleIsCategoryDetail}
                        />
                      )}
                      {view === 'print' && <PrintView />}
                    </Grid>
                  </Grid>
                </Grid>
                <ModalCollection open={open} handleClose={handleClose} />
                <LeftLateralBar
                  DELIVERIES={configState.deliveries}
                  delivery={delivery_id}
                  by={view}
                  bg={bg}
                />
                <RightLateralBar delivery={delivery_id} by={view} bg={bg} />
              </>
            )}
          </>
        )}
      </Media>
    </>
  );
}

export default Deliveries;
