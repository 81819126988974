import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, useLocation } from 'react-router-dom';
import useUserContext from '../hooks/useUserContext';
import useConfigcontext from '../hooks/useConfigContext';

import useModalLoginContext from '../hooks/useModalLoginContext';

export default function RouteWrapper({
  component: Component,
  isPrivate,
  isAdmin,
  isEurope,
  ...rest
}) {
  const { userState, reAuth } = useUserContext();
  const { configState, getConfig } = useConfigcontext();

  const location = useLocation();
  const { show: showModalLogin, hide: hideModalLogin } = useModalLoginContext();

  const userShouldRegister = useMemo(() => {
    return (
      userState &&
      userState.token &&
      userState.user &&
      userState.user.account &&
      userState.user.account.isFirstAccess &&
      location.pathname !== '/register'
    );
  }, [userState, location]);

  const isAdminOrSelectedEmails = useMemo(
    () =>
      userState.user?.admin ||
      userState.user?.email?.includes('nordstrom') ||
      userState.user?.email?.includes('anthropologie') ||
      userState.user?.email?.includes('saks'),
    [userState.user.admin, userState.user.email]
  );

  useEffect(() => {
    if (!userState) return;
    if (!userState.user) return;
    if (!userState.token) return;
    if (userState.user.account) return;
    reAuth();
  }, [reAuth, userState]);

  useEffect(() => {
    if (!configState) {
      getConfig();
    }
  }, [configState, getConfig]);

  if (userShouldRegister) {
    hideModalLogin();
    return <Redirect to="/register" />;
  }

  if (!isAdminOrSelectedEmails && location.pathname.includes('shoes'))
    return <Redirect to="/" />;

  if (isPrivate && !userState.token) {
    showModalLogin();
    return <Redirect to="/" />;
  }

  return (
    configState && (
      <Route {...rest} render={props => <Component {...props} />} />
    )
  );
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
};
