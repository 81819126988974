import React, { useCallback, useEffect, useState, useRef } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { CircularProgress, TextField, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';

import Divider from '../atoms/Divider';

import useUserContext from '../../hooks/useUserContext';
import useModalLoginContext from '../../hooks/useModalLoginContext';

import styles from './styles.module.scss';
import globalStyles from '../../styles/global.module.scss';

const StyledDialog = withStyles({
  paper: {
    margin: 0,
  },
  paperFullScreen: {
    backgroundColor: 'transparent',
  },
  paperWidthLg: {
    maxWidth: '100%',
  },
  scrollPaper: {
    alignItems: 'center',
  },
  paperFullWidth: {
    width: '100%',
  },
})(Dialog);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalLogin() {
  const { enqueueSnackbar } = useSnackbar();
  const { isShowing: open, hide: hideModalLogin } = useModalLoginContext();

  const {
    userState,
    signIn,
    clearRequestErrors,
    resetPassword,
  } = useUserContext();

  const [email, setEmail] = useState(userState.user.email || '');
  const [password, setPassword] = useState('');

  const inputEmail = useRef();

  const loading = userState.request.pending;
  const signRequestError = userState.request.error.message;

  const displayPasswordInput =
    userState.user.email && !userState.token && userState.user.password;

  const buttonText = displayPasswordInput ? 'Log In' : 'Next';

  const snack = useCallback(
    (message, options = {}) => {
      enqueueSnackbar(message, {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        persist: false,
        transitionDuration: { enter: 300, exit: 500 },
        ...options,
      });
    },
    [enqueueSnackbar]
  );

  const handleClose = useCallback(() => {
    hideModalLogin();
  }, [hideModalLogin]);

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      signIn({ email, password });
    },
    [signIn, email, password]
  );

  const forgotPassword = useCallback(async () => {
    if (!inputEmail.current.validity.valid) {
      return snack('You need to provide a valid e-mail', { variant: 'error' });
    }

    const okay = await resetPassword(email);
    if (okay) {
      return snack('An email has been sent for you to reset the password.', {
        variant: 'success',
      });
    }

    return null;
  }, [email, resetPassword, snack]);

  useEffect(() => {
    if (signRequestError) {
      snack(signRequestError, { variant: 'error' });
    }
  }, [snack, signRequestError]);

  useEffect(() => {
    if (userState.user.password === false) {
      snack('An email has been sent for you to create the password');
    }
  }, [snack, userState.user.password]);

  return (
    <StyledDialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {
        handleClose();
        clearRequestErrors();
      }}
    >
      <div className={styles.wrapper}>
        <h3 className={styles.title}>Login</h3>
        <Divider />
        <form className={styles.form} onSubmit={handleSubmit}>
          <TextField
            type="email"
            color="primary"
            fullWidth
            autoFocus
            label="E-mail"
            value={email}
            onChange={e => {
              setEmail(e.target.value);
              clearRequestErrors();
            }}
            // eslint-disable-next-line no-return-assign
            inputProps={{ ref: input => (inputEmail.current = input) }}
            required
          />
          {displayPasswordInput && (
            <>
              <TextField
                type="password"
                color="primary"
                fullWidth
                autoFocus
                label="Password"
                value={password}
                onChange={e => {
                  setPassword(e.target.value);
                  clearRequestErrors();
                }}
                className={styles.passwordInput}
                required
              />
              <button
                onClick={forgotPassword}
                className={`${globalStyles.textButton} ${styles.forgotPassword}`}
                type="button"
              >
                Forgot the password
              </button>
            </>
          )}
          <Button
            className={styles.button}
            variant="contained"
            fullWidth
            type="submit"
          >
            {loading && <CircularProgress color="#ffffff" size={26} />}
            {!loading && <>{buttonText}</>}
          </Button>
        </form>
      </div>
    </StyledDialog>
  );
}
