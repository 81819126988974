/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';
import { useRouteMatch } from 'react-router-dom';
import { Typography, Grid, Paper, IconButton } from '@material-ui/core';
import ReactPlayer from 'react-player';
import AddIcon from '@material-ui/icons/Add';
import { events, dictColor } from '../../consts';
import api from '../../services/api';
import { useEventDispatcher } from '../../hooks';
import SustainableTag from '../SustainableTagButton';

import useViewAsContext from '../../hooks/useViewAsContext';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalProduct({
  open,
  product,
  handleClose,
  handleNextProduct,
  handlePrevProduct,
  style_code,
}) {
  const { params } = useRouteMatch();
  const { delivery_id } = params;
  const [photoOne, setOne] = useState(0);
  const [photoTwo, setTwo] = useState(null);
  const [set, setPhoto] = useState(false);
  const [photos, setPhotos] = useState(null);

  const { viewAsState } = useViewAsContext();
  const { selectedUser } = viewAsState;

  const [isFavorite, setIsFavorite] = useState(false);

  const [productFetched, setProductFetched] = useState(false);

  const dispatchEvent = useEventDispatcher();

  useEffect(() => {
    setOne(0);
  }, [open, params]);

  async function handleAddFavorite() {
    if (productFetched.status === 'Sold Out' && !isFavorite) return;
    dispatchEvent(events.addToCollection, {
      click: !isFavorite,
      location: 'Products',
    });
    try {
      setIsFavorite(!isFavorite);

      await api.post(`/collections`, {
        style_code: productFetched.style_code,
        user_id: selectedUser._id || null,
      });
      // setProducts(
      //   products.map(produto => {
      //     if (produto.style_code === product.style_code) {
      //       return { ...produto, isFavorite: !produto.isFavorite };
      //     }

      //     return produto;
      //   })
      // );
    } catch (err) {
      if (selectedUser) {
        const response = await api.get(
          `/collections/${productFetched?.style_code}/check?user_id=${selectedUser._id}`
        );
        setIsFavorite(response.data);
      } else {
        const response = await api.get(
          `/collections/${productFetched?.style_code}/check`
        );
        setIsFavorite(response.data);
      }
    }
  }
  useEffect(() => {
    async function getDetailProducts() {
      const response = await api.get(`/product/${style_code.style_code}`);
      if (response.data && response.data.print_name) {
        response.data.print_name = response.data.print_name.join(' + ');
      }
      if (!response.data.msg) {
        setProductFetched(response.data);
        setTwo(
          !response.data?.viewer3DUrl
            ? response.data.other_images.length
            : response.data.other_images.length + 1
        );
      }
    }
    if (!product) {
      getDetailProducts();
    } else {
      setTwo(
        !product?.viewer3DUrl
          ? product.other_images.length
          : product.other_images.length + 1
      );
      setProductFetched(product);
    }
  }, [product, style_code]);

  useEffect(() => {
    if (productFetched.other_images && productFetched.mainly_image) {
      setPhotos([productFetched.mainly_image, ...productFetched.other_images]);
    } else if (productFetched.other_images) {
      setPhotos(productFetched.other_images);
    }
    // return setPhotos([
    //   'https://storage.googleapis.com/showroom-bucket-images/ZggLz9GA.jpeg',
    //   'https://storage.googleapis.com/showroom-bucket-images/ZggLz9GA.jpeg',
    // ]);
  }, [productFetched]);

  useEffect(() => {
    async function checkIsFavorite() {
      if (selectedUser) {
        const response = await api.get(
          `/collections/${productFetched?.style_code}/check?user_id=${selectedUser._id}`
        );
        setIsFavorite(response.data);
      } else {
        const response = await api.get(
          `/collections/${productFetched?.style_code}/check`
        );
        setIsFavorite(response.data);
      }
    }
    if (productFetched) checkIsFavorite();
  }, [selectedUser, productFetched]);

  const DetailProduct = () => {
    return (
      productFetched && (
        <Paper
          style={{
            zIndex: 5000,
            width: 400,
            height: 'auto',
            borderRadius: 0,
            padding: '5px 40px',
            background: dictColor[productFetched.color] || '#000',
            // backgroundColor: 'transparent',
            position: 'relative',
          }}
        >
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid>
              <Grid
                container
                justifyContent="space-between"
                style={{ width: 150, marginTop: 5 }}
              >
                <Typography
                  onClick={handlePrevProduct}
                  variant="body2"
                  style={{
                    color:
                      productFetched.color === 'OFF-WHITE' ||
                      productFetched.color === 'WHITE' ||
                      productFetched.color === 'OFF-WHITE GUIPIRE' ||
                      productFetched.color === 'OFF WHITE' ||
                      productFetched.color === 'OFF-WHITE ' ||
                      productFetched.color === 'ORANGE' ||
                      productFetched.color === 'RAINBOW'
                        ? '#000000'
                        : '#fff',
                    cursor: 'pointer',
                  }}
                >
                  {' '}
                  &lt; Previous
                </Typography>
                <Typography
                  onClick={handleNextProduct}
                  variant="body2"
                  style={{
                    color:
                      productFetched.color === 'OFF-WHITE' ||
                      productFetched.color === 'WHITE' ||
                      productFetched.color === 'OFF-WHITE GUIPIRE' ||
                      productFetched.color === 'OFF WHITE' ||
                      productFetched.color === 'OFF-WHITE ' ||
                      productFetched.color === 'ORANGE' ||
                      productFetched.color === 'RAINBOW'
                        ? '#000000'
                        : '#fff',
                    cursor: 'pointer',
                  }}
                >
                  Next &gt;
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container style={{ margin: '10px 0px' }}>
            <Grid style={{ position: 'relative' }}>
              <Typography
                style={{
                  transform: 'rotate(-90deg)',
                  position: 'absolute',
                  top:
                    delivery_id === 'weddingcapsule'
                      ? 70
                      : delivery_id === 'takeaway'
                      ? 35
                      : 38,
                  right: delivery_id === 'weddingcapsule' ? 142 : 170,
                  width: delivery_id === 'weddingcapsule' ? 160 : 100,
                  paddingTop: 9,
                  color:
                    productFetched.color === 'OFF-WHITE' ||
                    productFetched.color === 'WHITE' ||
                    productFetched.color === 'OFF-WHITE GUIPIRE' ||
                    productFetched.color === 'OFF WHITE' ||
                    productFetched.color === 'OFF-WHITE ' ||
                    productFetched.color === 'ORANGE' ||
                    productFetched.color === 'RAINBOW'
                      ? '#000000'
                      : '#fff',
                }}
              >
                {delivery_id === 'weddingcapsule'
                  ? 'WEDDING CAPSULE'
                  : delivery_id === 'takeaway'
                  ? 'TAKE AWAY'
                  : delivery_id === 'pre-spring'
                  ? 'PRE-SPRING'
                  : document.location.pathname.includes('swimwear')
                  ? 'SWIMWEAR'
                  : `DELIVERY ${delivery_id}`}{' '}
              </Typography>
              <Typography
                style={{
                  color:
                    productFetched.color === 'OFF-WHITE' ||
                    productFetched.color === 'WHITE' ||
                    productFetched.color === 'OFF-WHITE GUIPIRE' ||
                    productFetched.color === 'OFF WHITE' ||
                    productFetched.color === 'OFF-WHITE ' ||
                    productFetched.color === 'ORANGE' ||
                    productFetched.color === 'RAINBOW'
                      ? '#000000'
                      : '#fff',
                  fontSize: 29,
                  lineHeight: 1.1,
                  width: 200,
                }}
              >
                {productFetched.description}
              </Typography>
            </Grid>
          </Grid>
          <Grid>
            <Typography
              variant="body2"
              style={{
                color:
                  productFetched.color === 'OFF-WHITE' ||
                  productFetched.color === 'WHITE' ||
                  productFetched.color === 'OFF-WHITE GUIPIRE' ||
                  productFetched.color === 'OFF WHITE' ||
                  productFetched.color === 'OFF-WHITE ' ||
                  productFetched.color === 'ORANGE' ||
                  productFetched.color === 'RAINBOW'
                    ? '#000000'
                    : '#fff',
              }}
            >
              {productFetched.style_code}
            </Typography>
          </Grid>
          <Grid container style={{ marginTop: 10 }}>
            {productFetched?.sustainableTags.map(tagTitle => (
              <SustainableTag
                tagName={tagTitle.toUpperCase()}
                style={{
                  marginRight: 12,
                  color:
                    productFetched.color === 'OFF-WHITE' ||
                    productFetched.color === 'WHITE' ||
                    productFetched.color === 'OFF-WHITE GUIPIRE' ||
                    productFetched.color === 'OFF WHITE' ||
                    productFetched.color === 'OFF-WHITE ' ||
                    productFetched.color === 'ORANGE' ||
                    productFetched.color === 'RAINBOW'
                      ? '#000000'
                      : '#fff',
                  cursor: 'text',
                }}
              />
            ))}
          </Grid>

          {productFetched?.composition && (
            <Grid style={{ marginTop: 38, marginBottom: 20 }}>
              <Typography
                style={{
                  color:
                    productFetched.color === 'OFF-WHITE' ||
                    productFetched.color === 'WHITE' ||
                    productFetched.color === 'OFF-WHITE GUIPIRE' ||
                    productFetched.color === 'OFF WHITE' ||
                    productFetched.color === 'OFF-WHITE ' ||
                    productFetched.color === 'ORANGE' ||
                    productFetched.color === 'RAINBOW'
                      ? '#000000'
                      : '#fff',
                  fontSize: 10,
                  width: 200,
                }}
              >
                Composition
              </Typography>
              <Typography
                variant="body2"
                style={{
                  color:
                    productFetched.color === 'OFF-WHITE' ||
                    productFetched.color === 'WHITE' ||
                    productFetched.color === 'OFF-WHITE GUIPIRE' ||
                    productFetched.color === 'OFF WHITE' ||
                    productFetched.color === 'OFF-WHITE ' ||
                    productFetched.color === 'ORANGE' ||
                    productFetched.color === 'RAINBOW'
                      ? '#000000'
                      : '#fff',
                  textTransform: 'capitalize',
                  width: 200,
                }}
              >
                {productFetched.composition}
              </Typography>
              <Typography
                variant="body2"
                style={{
                  color:
                    productFetched.color === 'OFF-WHITE' ||
                    productFetched.color === 'WHITE' ||
                    productFetched.color === 'OFF-WHITE GUIPIRE' ||
                    productFetched.color === 'OFF WHITE' ||
                    productFetched.color === 'OFF-WHITE ' ||
                    productFetched.color === 'ORANGE' ||
                    productFetched.color === 'RAINBOW'
                      ? '#000000'
                      : '#fff',
                  textTransform: 'capitalize',
                  width: 200,
                }}
              >
                {productFetched.fabric_name}
              </Typography>
            </Grid>
          )}
          {(productFetched?.print_name || productFetched?.color) && (
            <Grid>
              <Typography
                style={{
                  fontSize: 10,
                  marginTop: !productFetched?.composition && 20,
                  color:
                    productFetched.color === 'OFF-WHITE' ||
                    productFetched.color === 'WHITE' ||
                    productFetched.color === 'OFF-WHITE GUIPIRE' ||
                    productFetched.color === 'OFF WHITE' ||
                    productFetched.color === 'OFF-WHITE ' ||
                    productFetched.color === 'ORANGE' ||
                    productFetched.color === 'RAINBOW'
                      ? '#000000'
                      : '#fff',
                }}
              >
                Print
              </Typography>
              <Typography
                style={{
                  width: '70%',
                  color:
                    productFetched.color === 'OFF-WHITE' ||
                    productFetched.color === 'WHITE' ||
                    productFetched.color === 'OFF-WHITE GUIPIRE' ||
                    productFetched.color === 'OFF WHITE' ||
                    productFetched.color === 'OFF-WHITE ' ||
                    productFetched.color === 'ORANGE' ||
                    productFetched.color === 'RAINBOW'
                      ? '#000000'
                      : '#fff',
                  textTransform: 'capitalize',
                  fontSize:
                    productFetched.print_name &&
                    (productFetched.print_name.length > 100
                      ? 10
                      : productFetched.print_name.length > 50
                      ? 12
                      : 14),
                }}
              >
                {productFetched?.print_name || productFetched?.color}
              </Typography>
            </Grid>
          )}
          <Grid style={{ margin: '20px 0px' }}>
            <Typography
              style={{
                fontSize: 10,
                color:
                  productFetched.color === 'OFF-WHITE' ||
                  productFetched.color === 'WHITE' ||
                  productFetched.color === 'OFF-WHITE GUIPIRE' ||
                  productFetched.color === 'OFF WHITE' ||
                  productFetched.color === 'OFF-WHITE ' ||
                  productFetched.color === 'ORANGE' ||
                  productFetched.color === 'RAINBOW'
                    ? '#000000'
                    : '#fff',
              }}
            >
              Price
            </Typography>
            <Typography
              variant="body2"
              style={{
                color:
                  productFetched.color === 'OFF-WHITE' ||
                  productFetched.color === 'WHITE' ||
                  productFetched.color === 'OFF-WHITE GUIPIRE' ||
                  productFetched.color === 'OFF WHITE' ||
                  productFetched.color === 'OFF-WHITE ' ||
                  productFetched.color === 'ORANGE' ||
                  productFetched.color === 'RAINBOW'
                    ? '#000000'
                    : '#fff',
              }}
            >
              {productFetched.wholesale_eur
                ? `Wholesale ${productFetched.wholesale_usd}$ / ${productFetched.wholesale_eur}€`
                : `Wholesale ${productFetched.wholesale_usd}$`}{' '}
              <br />
              {productFetched.msr_price_eur
                ? ` MSRP ${productFetched.msr_price_usd}$ /  ${productFetched.msr_price_eur}€`
                : ` MSRP ${productFetched.msr_price_usd}$`}
            </Typography>
          </Grid>
          <Grid
            container
            alignItems="flex-end"
            style={{ cursor: 'pointer', marginBottom: '40px', marginTop: 55 }}
            onClick={handleAddFavorite}
          >
            <Typography
              variant="body2"
              style={{
                color:
                  productFetched.color === 'OFF-WHITE' ||
                  productFetched.color === 'WHITE' ||
                  productFetched.color === 'OFF-WHITE GUIPIRE' ||
                  productFetched.color === 'OFF WHITE' ||
                  productFetched.color === 'OFF-WHITE ' ||
                  productFetched.color === 'ORANGE' ||
                  productFetched.color === 'RAINBOW'
                    ? '#000000'
                    : '#fff',
                marginRight: 4,
                // paddingBottom: 5,
              }}
            >
              {isFavorite ? 'Added' : 'Add'} to{' '}
              {selectedUser
                ? `${selectedUser.name.split(' ')[0]}'s collection`
                : 'collection'}
            </Typography>
            {isFavorite ? (
              <AiFillHeart
                style={{
                  color:
                    productFetched.color === 'OFF-WHITE' ||
                    productFetched.color === 'WHITE' ||
                    productFetched.color === 'OFF-WHITE GUIPIRE' ||
                    productFetched.color === 'OFF WHITE' ||
                    productFetched.color === 'OFF-WHITE ' ||
                    productFetched.color === 'ORANGE' ||
                    productFetched.color === 'RAINBOW'
                      ? '#000000'
                      : '#fff',
                  fontSize: 14,
                }}
              />
            ) : (
              <AiOutlineHeart
                style={{
                  color:
                    productFetched.color === 'OFF-WHITE' ||
                    productFetched.color === 'WHITE' ||
                    productFetched.color === 'OFF-WHITE GUIPIRE' ||
                    productFetched.color === 'OFF WHITE' ||
                    productFetched.color === 'OFF-WHITE ' ||
                    productFetched.color === 'ORANGE' ||
                    productFetched.color === 'RAINBOW'
                      ? '#000000'
                      : '#fff',
                  fontSize: 14,
                }}
              />
            )}
            {productFetched.status === 'Sold Out' && (
              <div
                style={{
                  width: 64,
                  height: 16,
                  background: '#fff',
                  marginLeft: 9,
                }}
              >
                <Typography
                  align="center"
                  style={{ fontSize: 9, letterSpacing: '0.1em' }}
                >
                  SOLD OUT
                </Typography>
              </div>
            )}
          </Grid>
          {productFetched?.qr_code && (
            <Grid
              container
              alignItems="center"
              style={{ position: 'absolute', bottom: '5%' }}
            >
              <img
                src={productFetched?.qr_code}
                style={{ width: 80, height: 80 }}
                alt="qr code"
              />
              <Typography
                align="center"
                style={{
                  width: 140,
                  marginLeft: 8,
                  fontSize: 14,
                  color:
                    productFetched.color === 'OFF-WHITE' ||
                    productFetched.color === 'WHITE' ||
                    productFetched.color === 'OFF-WHITE GUIPIRE' ||
                    productFetched.color === 'OFF WHITE' ||
                    productFetched.color === 'OFF-WHITE ' ||
                    productFetched.color === 'ORANGE' ||
                    productFetched.color === 'RAINBOW'
                      ? '#000000'
                      : '#fff',
                }}
                display="inline"
              >
                Scan this QR code to try it on
              </Typography>
            </Grid>
          )}
        </Paper>
      )
    );
  };
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullScreen
        style={{ backgroundColor: 'rgba(0,0,0,0.4)' }}
      >
        <Grid container justifyContent="center">
          <Grid
            container
            style={{
              width: '100%',
              position: 'relative',
              top: '5.3%',
              left: '40%',
            }}
          >
            {photos &&
              photos.map((photo, index) =>
                index !== productFetched.other_images.length ? (
                  <IconButton
                    key={String(index)}
                    onClick={() =>
                      set ? null : photoTwo !== index && setOne(index)
                    }
                    size="small"
                  >
                    {' '}
                    <Typography
                      variant="body2"
                      style={{
                        fontSize: 14,
                        fontFamily: 'Galano Grotesque Light',
                        color: '#fff',
                        borderBottom:
                          photoOne === index || photoTwo === index
                            ? '2px solid #ffffff'
                            : null,
                        fontWeight:
                          photoOne === index || photoTwo === index
                            ? 'bold'
                            : '',
                      }}
                    >
                      {String(index + 1).padStart(2, '0')}
                    </Typography>
                  </IconButton>
                ) : (
                  productFetched?.viewer3DUrl && (
                    <IconButton
                      key={String(index)}
                      onClick={() =>
                        set ? null : photoTwo !== index && setOne(index)
                      }
                      size="small"
                    >
                      {' '}
                      <Typography
                        variant="body2"
                        style={{
                          fontSize: 14,
                          fontFamily: 'Galano Grotesque Light',
                          color: '#fff',
                          borderBottom:
                            photoOne === index || photoTwo === index
                              ? '2px solid #ffffff'
                              : null,
                          fontWeight:
                            photoOne === index || photoTwo === index
                              ? 'bold'
                              : '',
                        }}
                      >
                        {String(index + 1).padStart(2, '0')}
                      </Typography>
                    </IconButton>
                  )
                )
              )}
            <Grid style={{ position: 'relative', left: 100 }}>
              <Typography
                onClick={handleClose}
                variant="body2"
                style={{
                  // display: 'flex',
                  // flexDirection: 'row',
                  color: '#fff',
                  cursor: 'pointer',
                }}
              >
                Close{' '}
                <AddIcon
                  style={{
                    marginBottom: -4,
                    color: '#fff',
                    fontSize: 18,
                    transform: 'rotate(135deg)',
                  }}
                />
              </Typography>
            </Grid>
          </Grid>
          <DetailProduct />
          <Grid
            style={{
              zIndex: 6000,
              position: 'relative',
              top: '7%',
              right: '6%',
            }}
          >
            <Grid
              container
              justifyContent="space-between"
              // style={{ background: 'green' }}
            >
              <Grid
                onClick={() => setPhoto(false)}
                style={{
                  borderRight: '0.5px solid #8a8a8a',
                  cursor: 'pointer',
                  display: 'inline-block',
                  position: 'relative',
                }}
              >
                {productFetched.status === 'Sold Out' && photoOne === 0 && (
                  <Typography
                    align="center"
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      color: '#fff',
                      zIndex: 1000,
                      fontSize: 61,
                      letterSpacing: '0.1em',
                      lineHeight: '40px',
                      width: 432,
                    }}
                  >
                    SOLD OUT{' '}
                  </Typography>
                )}
                {photos &&
                photos[photoOne] &&
                !photos[photoOne].includes('.mp4') ? (
                  <img
                    src={photos && photos[photoOne]}
                    style={{
                      width: 432,
                      height: 626,
                      filter:
                        productFetched.status === 'Sold Out' &&
                        photoOne === 0 &&
                        'brightness(65%)',
                    }}
                    alt="farm"
                  />
                ) : (
                  <ReactPlayer
                    // style={{ width: 475, height: 'auto' }}
                    loop
                    url={photos && photos[photoOne]}
                    height="626px"
                    width="436px"
                    playing
                  />
                )}
              </Grid>
              {!productFetched?.viewer3DUrl ? (
                <Grid
                  // onClick={() => setPhoto(true)}
                  style={{
                    cursor: 'pointer',
                  }}
                >
                  {photos &&
                  photos[photoTwo] &&
                  !photos[photoTwo].includes('.mp4') ? (
                    <img
                      src={photos && photos[photoTwo]}
                      style={{ width: 432, height: 626 }}
                      alt="farm"
                    /> // /> //   alt="farm" //   style={{ width: 432, height: 626 }} //   src={photos && photos[photoTwo]} // <img
                  ) : (
                    <ReactPlayer
                      loop
                      url={photos && photos[photoTwo]}
                      height="626px"
                      width="370px"
                      playing
                    />
                  )}
                </Grid>
              ) : (
                <Grid style={{ width: 370, height: 626 }}>
                  <iframe
                    src={productFetched?.viewer3DUrl}
                    style={{
                      width: '100%',
                      display: 'block',
                      height: 626,
                      background: 'white',
                    }}
                    title="3DViewer"
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
}
